// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    InputAdornment,
    IconButton,
    Button,
    Paper,
    Typography,
    FormControl,
    TextField,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Loader from '../Loader';
import { LinkList, LinkListItem } from '../LinkList';
import ErrorMessage from '../ErrorMessage';
import { processForm, resetForm, login } from '../../actions';
import { getForm } from '../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './LoginForm.module.scss';

type Props = {
    processing: boolean,
    result: any,
    error: any,
    onSubmit: Function,
    onReset: Function,
    onNavigate: Function,
};

type State = {
    form: any,
    showPassword: boolean,
};

const mapStateToProps = (store) => {
    return getForm('login')(store);
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (username, password, redirect) => {
            dispatch(processForm('login', login(username, password, redirect)));
        },
        onNavigate: (url) => {
            dispatch(push(url));
        },
        onReset: () => {
            dispatch(resetForm('login'));
        },
    };
};


class LoginForm extends React.Component {
    props: Props;
    state: State = {
        form: {
            username: '',
            password: '',
        },
        showPassword: false,
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    handleChange = (event) => {
        let text = event.target.value
        text = text.trim()
        if (event.target.id === 'username') {
            this.setState({
                form: {
                    ...this.state.form,
                    [event.target.id]: text,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [event.target.id]: event.target.value,
                },
            });
        }
    };

    handleLinkItemClick = (event) => {
        event.preventDefault();
        this.reset();
        this.props.onNavigate(event.target.href.replace(/^.*\/\/[^\/]+/, '')); // eslint-disable-line no-useless-escape
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPasssword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    renderMessageParam(messageParam) {
        const { t } = this.props;
        if (messageParam === 'registered') {
            return t('Registration succes.');
        }
    }
    submit() {
        const params = new URLSearchParams(this.props.location.search);
        this.props.onSubmit(
            this.state.form.username,
            this.state.form.password,
            params.get('redirect')
        );
    }

    reset() {
        this.setState({
            form: { username: '', password: '' },
            showPassword: false,
        });
        this.props.onReset();
    }

    render() {
        const { location, processing, t } = this.props;
        let { error } = this.props;
        const messageParam = new URLSearchParams(location.search).get(
            'message'
        );
        if (
            error &&
            error.message === 'Authentication Failed: Bad credentials'
        ) {
            error.message = `Authentication Failed: ${t(
                'Invalid email or password'
            )}`;
        }

        return (
            <div>
                <Paper elevation={4} className={classes.paper}>
                    <Typography
                        variant="h1"
                        type="display1"
                        gutterBottom={true}
                        align="center"
                    >
                        {t('Login')}
                    </Typography>
                    <Loader size={100} loading={processing}>
                        <ErrorMessage error={error} />
                        {messageParam && (
                            <div className={classes.message}>
                                {this.renderMessageParam(messageParam)}
                            </div>
                        )}
                        <form onSubmit={this.handleSubmit}>
                            <FormControl>
                                <TextField
                                    id="username"
                                    label={t('Emailadres')}
                                    variant="standard"
                                    onChange={this.handleChange}
                                    value={this.state.form.username}

                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    id="password"
                                    label={t('Password')}
                                    variant="standard"
                                    type={ this.state.showPassword ? 'text' : 'password'}
                                    onChange={this.handleChange}
                                    value={this.state.form.password}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleClickShowPasssword}
                                            onMouseDown={this.handleMouseDownPassword}
                                            style={{
                                                position: 'absolute',
                                                top: '-10px',
                                                right: '0px',
                                            }}
                                            size="large">
                                            {this.state.showPassword ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                />

                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.button}
                            >
                                {t('Login')}
                            </Button>
                            <p className={classes.applink}>
                                {t('Use Veilig vakwerk online?')}{' '}
                                <a href="/web" style={{ color: 'black' }}>
                                    {t('Click here')}
                                </a>
                            </p>
                            <LinkList>
                                <LinkListItem
                                    onClick={this.handleLinkItemClick}
                                    href="/auth/forgot"
                                >
                                    {t('Reset password')}
                                </LinkListItem>
                                <LinkListItem
                                    target="_blank"
                                    href="https://veiligvakwerk.nl/aanvragen"
                                >
                                    {t('Request account')}
                                </LinkListItem>
                            </LinkList>
                        </form>
                    </Loader>
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(LoginForm);
