import React from "react";
import {FormControl, Input, InputLabel, InputAdornment} from '@mui/material';


type Props = {
	title: string,
	id: string,
	value: any,
	suffix: string,
	onChange: (key: string, value: any) => mixed,
};

type State = {};

class DialogInputField extends React.Component<Props, State> {
	render() {
		const {title, id, value, onChange, suffix} = this.props;

		return (
			<React.Fragment>
				<FormControl>
					<InputLabel htmlFor={id}>{title}</InputLabel>
					<Input
						fullWidth={true}
						id={id}
						
						onChange={event => onChange(id, event.target.value)}
						label={title}
						value={value}
						{...(suffix && {
							endAdornment: (<InputAdornment position="end">{suffix}</InputAdornment>)
						})}
					/>
				</FormControl>
			</React.Fragment>
		);
	}
}

export default (DialogInputField)