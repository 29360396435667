import { createSelector } from 'reselect'

const getQuestionBlockTypeEntities = store => store.entities.questionBlockTypes;

const getQuestionBlockTypes = createSelector(
    [getQuestionBlockTypeEntities],
    (questionBlockTypeEntities) => {
        return questionBlockTypeEntities.allIds.map(id => questionBlockTypeEntities.byId[id]);
    }
)

export default getQuestionBlockTypes;
