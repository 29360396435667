import React from 'react'; // eslint-disable-line no-unused-vars
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    handleClose: () => mixed,
    onCreditsBuy: () => mixed,
    creditsSetup: boolean,
};

type State = {};

class CreditsDialog extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { t } = this.props;
        const creditsSetup = this.props.creditsSetup;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogTitle>{t('Upgrade credits')}</DialogTitle>
                <DialogContent>
                    {creditsSetup ? (
                        <DialogContentText>
                            {t('Upgrade sneldelen credits for')}{' '}
                            {t('STANDARD CREDITS COSTS')}{' '}
                            {t('for 100 credits.')}.
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            {t('Upgrade sneldelen credits for')}{' '}
                            {t('STANDARD CREDITS COSTS')}{' '}
                            {t('for 100 credits.')}
                            <br />
                            {t('One-off setup costs are')}{' '}
                            {t('ONE OFF SETUP COSTS')}.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button   onClick={this.props.handleClose}>
                            {t('Cancel')}
                        </Button>
                        <Button
                             
                            onClick={this.props.onCreditsBuy}
                        >
                            {t('Confirm')}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}
export default compose(withTranslation())(CreditsDialog);
