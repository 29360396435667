import { createSelector } from 'reselect';

const getUsergroupEntities = store => store.entities.usergroups;

const getUsergroups = createSelector(
    [getUsergroupEntities],
    (usergroupEntities) => {
        return usergroupEntities.allIds.map(id => usergroupEntities.byId[id]);
    }
)

export default getUsergroups;