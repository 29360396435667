import uuidv4 from 'uuid/v4';
import {
    CREATE_CONTENT_BLOCK_DRAFT,
    DELETE_CONTENT_BLOCK_DRAFT,
    EDIT_TEXT_BLOCK_DRAFT,
    EDIT_LIST_BLOCK_DRAFT,
    EDIT_LIST_ITEM_BLOCK_DRAFT,
    EDIT_CONTENT_BLOCK_DRAFT,
    EDIT_VIDEO_BLOCK_DRAFT,
    EDIT_PHOTOS_ITEM_BLOCK_DRAFT,
    MOVE_LIST_ITEM_BLOCK_DRAFT,
    DELETE_LIST_ITEM_BLOCK_DRAFT,
    ADD_LIST_ITEM_BLOCK_DRAFT,
    MOVE_PHOTOS_ITEM_BLOCK_DRAFT,
    DELETE_PHOTOS_ITEM_BLOCK_DRAFT,
    ADD_PHOTOS_ITEM_BLOCK_DRAFT,
    ADD_MULTIPLE_LIST_ITEM_BLOCK_DRAFT,
    ADD_PHOTOS_PDF_BLOCK_DRAFT
} from '../actions';

const initialState = {};

export default function contentBlockDraft(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_CONTENT_BLOCK_DRAFT: {
            const { data } = action.payload;
            const draftBlock = { title: '', subtitle: '', highlight: data.highlighted, ...data };
            if (data.type === 'videoBlock') {
                draftBlock.videoBlock.url = draftBlock.videoBlock.url || '';
                delete draftBlock.videoBlock.youtubeid;
            }
            if (data.type === 'listBlock') {
                if (!draftBlock.listBlock) {
                    draftBlock.listBlock = { items: [] };
                }
                draftBlock.listBlock = { ...draftBlock.listBlock, items: draftBlock.listBlock.items.map(i => ({ id: uuidv4(), text: i })) };
            }
            if (data.type === 'photosBlock') {
                if (!draftBlock.photosBlock) {
                    draftBlock.photosBlock = { photos: [] };
                }
                draftBlock.photosBlock = { ...draftBlock.photosBlock, photos: draftBlock.photosBlock.photos.map(i => ({ id: i.photo, ...i, url: i.photo === null ? '' : i.url })) };
            }
            return { ...state, [data.id]: draftBlock };
        } case DELETE_CONTENT_BLOCK_DRAFT: {
            const { id } = action.payload;
            const newState = {...state};
            delete(newState[id]);
            return newState;
        } case EDIT_CONTENT_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: { ...state[id], [key]: value } };
        } case EDIT_TEXT_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: { ...state[id], textBlock: { ...state[id].videoBlock, [key]: value } } };
        } case EDIT_LIST_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: { ...state[id], listBlock: { ...state[id].listBlock, [key]: value } } };
        } case EDIT_LIST_ITEM_BLOCK_DRAFT: {
            const { id, index, key, value } = action.payload;
            const items = [...state[id].listBlock.items];
            items[index] = { ...items[index], [key]: value };
            return { ...state, [id]: { ...state[id], listBlock: { ...state[id].listBlock, items: [ ...items ] } } };
        }  case MOVE_LIST_ITEM_BLOCK_DRAFT: {
            const { id, fromIndex, toIndex } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.listBlock.items];
            newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
            return { ...state, [id]: { ...state[id], listBlock: {...state[id].listBlock, items: newItems } } };
        } case DELETE_LIST_ITEM_BLOCK_DRAFT: {
            const { id, index } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.listBlock.items];
            newItems.splice(index, 1);
            return { ...state, [id]: { ...state[id], listBlock: {...state[id].listBlock, items: newItems } } };
        } case ADD_LIST_ITEM_BLOCK_DRAFT: {
            const { id } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.listBlock.items];
            newItems.push({ id: uuidv4(), text: ''});
            return { ...state, [id]: { ...state[id], listBlock: {...state[id].listBlock, items: newItems } } };
        } case ADD_MULTIPLE_LIST_ITEM_BLOCK_DRAFT: {
            const { id, items } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.listBlock.items];
            items.map(item => ({ id: uuidv4(), text: item.trim() })).forEach(i => {
                newItems.push(i);
            });
            return { ...state, [id]: { ...state[id], listBlock: {...state[id].listBlock, items: newItems } } };
        } case EDIT_PHOTOS_ITEM_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            const photos = [ ...state[id].photosBlock.photos];
            const photo = { ...state[id].photosBlock.photos[key] };
            Object.keys(value).forEach(k => {
                photo[k] = value[k];
            });
            photos[key] = photo;
            return { ...state, [id]: { ...state[id], photosBlock: { ...state[id].photosBlock, photos: photos } } };
        } case MOVE_PHOTOS_ITEM_BLOCK_DRAFT: {
            const { id, fromIndex, toIndex } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.photosBlock.photos];
            newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
            return { ...state, [id]: { ...state[id], photosBlock: {...state[id].photosBlock, photos: newItems } } };
        } case DELETE_PHOTOS_ITEM_BLOCK_DRAFT: {
            const { id, index } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.photosBlock.photos];
            newItems.splice(index, 1);
            return { ...state, [id]: { ...state[id], photosBlock: {...state[id].photosBlock, photos: newItems } } };
        } case ADD_PHOTOS_ITEM_BLOCK_DRAFT: {
            const { id } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.photosBlock.photos];
            newItems.push({ id: uuidv4(), url: '' });
            return { ...state, [id]: { ...state[id], photosBlock: {...state[id].photosBlock, photos: newItems } } };
        } case ADD_PHOTOS_PDF_BLOCK_DRAFT: {
            const { id, result } = action.payload;
            const contentBlock = state[id];
            const newItems = [...contentBlock.photosBlock.photos];

            let resultIndex = 0;
            while(result[resultIndex] != null){
                newItems.push({ id: result[resultIndex].id, url: result[resultIndex].url });
                resultIndex++;
            }


            return { ...state, [id]: { ...state[id], photosBlock: {...state[id].photosBlock, photos: newItems } } };
        } case EDIT_VIDEO_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: { ...state[id], videoBlock: { ...state[id].videoBlock, [key]: value } } };
        } default:
            return state;
    }
}
