// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { FormControl, InputLabel, Input, InputAdornment } from '@mui/material';


import { editVideoBlockDraft, showSnackbar } from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './VideoBlockForm.module.scss';

type Props = {
    classes: any,
    contentBlock: any,
    onChange: Function,
    update: Function,
    textfull: Function,
};

type State = {
    error: boolean,
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editVideoBlockDraft(props.contentBlock.id, key, value)),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};


class VideoBlockForm extends React.Component {
    props: Props;
    state: State = {
        value: this.props.contentBlock.videoBlock.url,
    };
    timer = null;

    getYoutubeUrl(url) {
        const isNormalYoutubeLink = url && url.toLowerCase().includes("youtu") && url.toLowerCase().includes("?v=");
        const isMobileYoutubeLink = url && url.toLowerCase().includes("youtu") && url.toLowerCase().includes(".be/");
        const isShortLink = url && url.toLowerCase().includes("shorts");

        let src = isNormalYoutubeLink ? "https://youtu.be/" + url.split('?v=')[1] :
            isMobileYoutubeLink ? "https://youtu.be/" + url.split('.be/')[1] : (isShortLink ? "https://youtu.be/" + url.split('youtube.com/shorts/')[1] : '');

        if (src !== '') {
            // Strip ?si=* from the src to keep only: https://youtu.be/7kdfdVswIDI
            src = src.replace(/\?si=.*$/,"");
            // Strip: also remove possible start time
            src = src.replace(/&t=.*$/,"");
        }
        return src;
    }

    handleFormChange = (key) => (event) => {
        const { t } = this.props;
        var value = event.target.value;
        if (key === 'url') {
            if (value.length > 500) {
                this.props.textfull(
                    t('Text cannot be longer than 500 characters')
                );
                return;
            }
            if (!value.toLowerCase().includes('youtu')) {
                // alert("Alleen YouTube-video's zijn toegestaan");
                this.setState({
                    error: true,
                });
            } else {
                this.setState({
                    error: false,
                });
            }
            value = this.getYoutubeUrl(value);

        }

        this.setState({ value });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange();
            }

            this.props.update(key, value);
        }, 400);
    };

    getInputId(path) {
        return this.props.contentBlock.id + '-videoBlock-' + path;
    }

    render() {
        const { contentBlock, t } = this.props;

        return (
            <form className={classes.root}>
                <FormControl>
                    <InputLabel htmlFor={this.getInputId('url')}>
                        {t('URL')}
                    </InputLabel>
                    <Input
                        fullWidth={true}
                        id={this.getInputId('url')}
                        
                        onChange={this.handleFormChange('url')}
                        label="https://link.naar.video"
                        value={this.state.value}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                style={{ fontSize: '14px', opacity: '0.2' }}
                            >
                                {500 - contentBlock.videoBlock.url.length}
                            </InputAdornment>
                        }
                        // error={this.state.error}
                    />
                </FormControl>
                <div
                    style={
                        this.state.error === true
                            ? {
                                  color: 'red',
                                  marginTop: '-15px',
                                  fontSize: '12px',
                              }
                            : { display: 'none' }
                    }
                >
                    {' '}
                    {t("Only youtube video's are allowed")}
                </div>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    
    withTranslation()
)(VideoBlockForm);
