import React from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

import { compose } from 'recompose';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { getLanguages } from '../../lib/utils';
import moment from "moment";
import classes from './LanguageDropdown.module.scss'


class LanguageDropdown extends React.Component {
    state = {
        language: '',
    };

    componentDidMount() {
        const lang = Cookies.get('lang');
        if (lang) {
            this.props.i18n.changeLanguage(lang);
            moment.locale(lang);

            this.setState({
                language: lang,
            });
        }
    }

    handleChange = (event) => {
        const { i18n, extraKey = false } = this.props;
        const value = event.target.value;
        this.setState({ [event.target.name]: value });
        Cookies.set('lang', value, { expires: 365 });
        moment.locale(value);

        if (extraKey) Cookies.set(extraKey, value);

        i18n.changeLanguage(value);
        if (this.props.onChange) this.props.onChange(value);
    };

    render() {
        const {
            className = '',
            t
        } = this.props;
        const languages = getLanguages();
        if (languages.length < 2) return '';
        return (
            <div className={classNames(classes.form, className)}>
                <FormControl className={classes.formControl}>
                    <InputLabel
                        className={classes.color}
                        htmlFor="language"
                        color="secondary"
                    >
                        {t('Language')}
                    </InputLabel>
                    <Select
                        variant='standard'
                        value={this.state.language}
                        onChange={this.handleChange}
                        name="language"
                        autoWidth
                        className={classes.LanguageDropdown}
                    >
                        {languages.map((lang) => (
                            <MenuItem
                                key={lang}
                                value={lang}>{t(lang)}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default compose(withTranslation(), )(LanguageDropdown);
