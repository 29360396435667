// @flow
import { List } from 'immutable';
import TypedRecord from '../components/Util/IRecord';
import type { TEmployeeAction } from '../actions/EmployeeActions';

export type TEmployee = {
    id?: number,
    externalId?: string,
    accountExpired?: boolean,
    accountLocked?: boolean,
    credentialsExpired?: boolean,
    accountEnabled?: boolean,
    username?: string,
    firstname: string,
    middlename?: ?string,
    lastname: string,
    phonenumber?: string,
    state: 'ADDED' | 'INVITED' | 'ACTIVE' | 'INACTIVE',
    importType: 'MANUAL' | 'IMPORT',
    roles?: Array<string>,
    subscriptionHolder?: TEmployee,
    invited?: boolean,
    newFeatures?:boolean,
    whitelabelPackageName?:string
};

export class TStateEmployee extends TypedRecord({
    didInvalidate: true,
    lastUpdated: 0,
    error: null,
    employees: List(),
    editemployee: null,
    currentUser: null,
    diff: null,
    inProgress: false
}) {
    didInvalidate: boolean;
    lastUpdated: number;
    error: ?mixed;
    employees: List<TEmployee>;
    editemployee: TEmployee;
    currentUser: TEmployee;
    inProgress: boolean;
    diff: { removed: List<TEmployee>, added: List<TEmployee> };
}

const initialState = new TStateEmployee();

export default function employeesReducer(state: TStateEmployee = initialState, action: TEmployeeAction) {
    switch (action.type) {
        case 'EMPLOYEE_IMPORT_SUCCESS': {
            state = state.set('inProgress', false);
            return state.set('diff', action.diff);
        }
        case 'EMPLOYEE_CLEAR_DIFF': {
            return state.set('diff', null);
        }
        case 'EMPLOYEE_IMPORT_ERROR': {
            return state.set('inProgress', false);
        }
        case 'EMPLOYEE_IMPORT_START': {
            return state.set('inProgress', true);
        }
        case 'EMPLOYEE_EDIT': {
            return state.set('editemployee', action.employee);
        }
        case 'FETCH_CURRENT_USER': {
            return state.set('currentUser', action.user);
        }
        case 'FETCH_SUBSCRIPTION': {
            const currentUser = state.currentUser;

            currentUser.subscription = action.subscription;

            return state.set('currentUser', currentUser);
        }
        default:
            return state;
    }
}
