// @flow
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Input,
    FormControl,
    InputLabel,
    Switch,
    Stepper,
    Step,
    StepLabel,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    Dialog,
    Button,
    FormControlLabel,
} from '@mui/material';

import {getPreviousInspectionResult} from '../../../lib/api'
import _ from 'lodash';

import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import {
    fetchEmployees,
    fetchProjects,
    getPublicInspections,
    sendPublicInspectionResult,
    editProject,
} from '../../../actions';
import classes from './PublicInspectionOpdracht.module.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from 'connected-react-router';
import TitleDescription from '../../TitleDescription';
import TableActionHeader from '../../TableActionHeader';
import { inProgress } from '../../../selectors';
import LegacyThemeProvider from '../../LegacyThemeProvider';
import SimpleImageInput from '../../SimpleImageInput';
import moment from 'moment';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutoCompleteView from '../../AutoCompleteView';
import ProjectEdit from '../../Project/ProjectEdit';
import { bindActionCreators } from 'redux';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { parseClassesForAutocompleteView } from '../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    toolboxId: string,
    inspection: Array<any>,
    editproject: any,
};

type State = {
    feedbackText: string,
    results: any,
    unsafe: boolean,
    weatherType: string,
    employees: any,
    location: any,
    photos: any,
    collapsed: any,
    project: any,
    showAddProjectDialog: boolean,
    finished: boolean,
    dialogOpen: boolean,
};


const mapStateToProps = (store, props) => {
    let inspection = store.entities.publicInspections;
    const inspectionAssignId = props.match.params.toolboxId;

    return {
        loading: !inProgress(store),
        inspection: inspection && inspection.byId[inspectionAssignId],
        employees: store.entities.employees,
        projects: store.entities.projects,
        editproject: store.drafts.projects.editproject,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendResults: (result) => {
            dispatch(
                sendPublicInspectionResult(
                    ownProps.match.params.toolboxId,
                    result
                )
            );
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getPublicInspections: () => {
            dispatch(getPublicInspections());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        editProject: bindActionCreators(editProject, dispatch),
    };
};

class PublicInspectionOpdracht extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        feedbackText: '',
        results: [],
        unsafe: false,
        weatherType: '',
        employees: [],
        location: { lat: 0.0, lon: 0.0 },
        photos: [],
        collapsed: [],
        project: '',
        showAddProjectDialog: false,
        finished: false,
        dialogOpen: false,
    };

    componentWillMount() {
        this.props.getPublicInspections();
        this.props.fetchEmployees();
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition(this.getLocation);
    }

    componentWillReceiveProps(newProps: Props) {
        if (newProps.inspection && this.state.inspection === undefined) {
            this.setState({
                inspection: newProps.inspection,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.inspection !== this.state.inspection) {
            this.getPreviousInspection(this.state.inspection.inspection.projectNumber)
        }
    }
    handleFormChange = (key) => (event) => {
        if (event.target.value.length < 255) {
            this.setState({
                feedbackText: event.target.value,
            });
        }
    };

    handleChange = (event) => {
        if (event.target.id === 'activity') {
            this.setState({
                inspection: {
                    ...this.state.inspection,
                    inspection: {
                        ...this.state.inspection.inspection,
                        activity: event.target.value,
                    },
                },
            });
        } else if (event.target.id === 'externalemployees') {
            this.setState({
                inspection: {
                    ...this.state.inspection,
                    inspection: {
                        ...this.state.inspection.inspection,
                        results: {
                            ...this.state.inspection.result,
                            externalEmployees: event.target.value,
                        },
                    },
                },
            });
        } else if (event.target.id === 'unsafe') {
            this.setState({
                inspection: {
                    ...this.state.inspection,
                    inspection: {
                        ...this.state.inspection.inspection,
                        results: {
                            ...this.state.inspection.result,
                            remark: {
                                ...this.state.inspection.result.remark,
                                unsafe: event.target.value,
                            },
                        },
                    },
                },
            });
        } else if (event.target.id === 'description') {
            this.setState({
                inspection: {
                    ...this.state.inspection,
                    description: event.target.value,
                },
            });
        }
    };

    handleChangeProject = (single) => {
        this.setState({ 
            project: single,
            projectNumber: this.props.projects.byId[single.value].externalId,
        });
        this.getPreviousInspection(this.props.projects.byId[single.value].externalId)
    };

    handleUnsafeChange = (event) => {
        this.setState({
            unsafe: !this.state.unsafe,
        });
    };

    showAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: true,
        });
        this.cleanEditProject(this.props);
    };

    cleanEditProject = (props: Props) => {
        props.editProject({
            omschrijving: '',
            opdrachtgever: '',
            importType: 'MANUAL',
        });
    };

    hideAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: false,
        });
    };

    handleImageUpload = (event) => {
        this.setState({
            photos: [
                ...this.state.photos,
                { id: event.id, url: event.url, photo: event.id },
            ],
        });
    };

    isEmpty = (obj) => {
        for(var prop in obj) {
            if(Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }


    handleImageQuestion = (selected, event, id) => {
        if (this.isEmpty(event)) {
            console.log('INFO handleImageQuestion empty event: ', event );
            return;
        }
        if (this.state.results[selected]) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            photos: [
                                ...this.state.results[selected].remark.photos,
                                {
                                    id: event.id,
                                    url: event.url,
                                    photo: event.id,
                                },
                            ],
                        },
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            photos: [
                                {
                                    id: event.id,
                                    url: event.url,
                                    photo: event.id,
                                },
                            ],
                            text: null,
                            unsafe: false,
                        },
                        result: null,
                    },
                },
            });
        }
    };

    handleChangeEmployees = (event) => {
        this.setState({
            employees: event.target.value,
        });
    };

    toSelfTest = () => {
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    getPreviousInspection = (projectId) => {
        getPreviousInspectionResult(this.state.inspection.id, projectId)
        .then((res) => {
            let previousInspection = res.body._embedded.previousInspection
            this.setState({
                previousInspection : previousInspection
            })
        }).catch((error) => {
            throw error;
        })
    }

    toEndPage = () => {
        const results = this.state.results;
        let questionAmount = 0;
        let questionsCompleted = 0;
        let photoQuestionsComplete = 0;
        let manditoryPhotoQuestions = [];


        this.state.inspection.inspection.categories.forEach((cat) => {
            cat.items.forEach((item, index) => {
                if (item.photoQuestion) {
                    manditoryPhotoQuestions.push(item.id);
                } else {
                    questionAmount++;
                }
            });
        });

        // Check photo answers
        for (let i = 0; i < manditoryPhotoQuestions.length; i++) {
            let id = manditoryPhotoQuestions[i];
            for (let key in results) {
                if ( results[key] && results[key].id === id && (results[key].remark.photos.length > 0) ) {
                    photoQuestionsComplete++;
                }
            }
        }
        if (manditoryPhotoQuestions.length !== photoQuestionsComplete) {
            this.setState({
                dialogOpen: true,
            });
            return;
        }


        // Check check-item answers
        for (let key in results) {
            if (results[key] && results[key].result === 'nok') {
                if (
                    results[key].remark.text !== null &&
                    results[key].remark.text.length > 0
                ) {
                    questionsCompleted++;
                }
            } else if (results[key] && results[key].result !== null) {
                questionsCompleted++;
            }
        }

        if (questionsCompleted === questionAmount) {
            this.setState({
                stepIndex: this.state.stepIndex + 1,
            });

            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        } else {
            this.setState({
                dialogOpen: true,
            });
        }
    };

    sendResults = () => {
        this.setState({
            finished: true,
        });

        let resultsArray = [];

        for (var key in this.state.results) {
            resultsArray.push(this.state.results[key]);
        }

        let results = {
            complete: true,
            results: resultsArray,
            externalEmployees:
                this.state.inspection.inspection.results &&
                this.state.inspection.inspection.results.externalEmployees
                    ? this.state.inspection.inspection.results.externalEmployees
                    : '',
            weatherType: this.state.weatherType,
            date: moment(),
            activity: this.state.inspection.inspection.activity
                ? this.state.inspection.inspection.activity
                : '',
            projectId: this.state.project.value,
            remarks: {
                photos: this.state.photos,
                text: this.state.feedbackText,
                unsafe: this.state.unsafe,
            },
            gpsLocation: this.state.location,
            employees: this.state.employees,
            omschrijving: this.state.inspection.description
                ? this.state.inspection.description
                : '',
        };

        this.props.sendResults(results);
    };

    backToOverview = () => {
        this.props.goTo('/web/nieuweinspectie');
    };

    getLocation = (event) => {
        this.setState({
            location: {
                lat: event.coords.latitude,
                lon: event.coords.longitude,
            },
        });
    };

    handleAnswerClick = (selected, result, id) => {
        if (
            this.state.results[selected] &&
            this.state.results[selected].result === 'nok' &&
            this.state.results[selected].remark &&
            this.state.results[selected].remark.text === null
        ) {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: false },
                },
            });
        }
        if (
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        result: result,
                        id: id,
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        result: result,
                        id: id,
                        remark: { photos: [], text: null, unsafe: false },
                    },
                },
            });
        }
        if (result === 'nok') {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: true },
                },
            });
        }
    };

    handleCollapseClick = (selected) => {
        if (this.state.collapsed && this.state.collapsed[selected]) {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: {
                        collapsed: !this.state.collapsed[selected].collapsed,
                    },
                },
            });
        } else {
            this.setState({
                collapsed: {
                    ...this.state.collapsed,
                    [selected]: { collapsed: true },
                },
            });
        }
    };

    handleUnsafeAnswer = (selected, id) => {
        if (
            this.state.results &&
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            unsafe: !this.state.results[selected].remark.unsafe,
                        },
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: { photos: [], text: null, unsafe: true },
                    },
                },
            });
        }
    };

    handleChangeOpenAnswer = (selected, event, id) => {
        this.setState({
            results: {
                ...this.state.results,
                [selected]: {
                    ...this.state.results[selected],
                    openAnswer: event.target.value,
                    id: id,
                    remark: { photos: [], text: null, unsafe: false },
                },
            },
        });
    };


    handleChangeOpmerking = (selected, event, id) => {
        if (
            this.state.results &&
            this.state.results[selected] &&
            this.state.results[selected].remark
        ) {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            ...this.state.results[selected].remark,
                            text: event.target.value,
                        },
                    },
                },
            });
        } else {
            this.setState({
                results: {
                    ...this.state.results,
                    [selected]: {
                        ...this.state.results[selected],
                        id: id,
                        remark: {
                            photos: [],
                            text: event.target.value,
                            unsafe: false,
                        },
                        result: null,
                    },
                },
            });
        }
    };

    handlePageBack = () => {
        if (this.state.stepIndex === 0) {
            this.backToOverview();
        } else {
            this.setState({
                stepIndex: this.state.stepIndex - 1,
            });
        }
    };

    render() {
        const {
            loading,
            employees,
            unsafe,
            projects,
            editproject,
            t,
        } = this.props;
        const {
            stepIndex,
            inspection,
            showAddProjectDialog,
            dialogOpen,
            previousInspection,
        } = this.state;

        const tableActionHeader = <TableActionHeader />;

        // window.$crisp.push(['do', 'chat:hide']);

        if (
            !inspection ||
            (inspection &&
                inspection.inspection.results &&
                inspection.inspection.results.complete === true)
        ) {
            return (
                <AppModule className="appModule" loading={loading}>
                    <div>
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t(
                                    'This workplace inspection is no longer available'
                                )}
                            </p>
                            <br />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.backToOverview}
                                    id="sendresultsButton"
                                    style={{
                                        margin: 'auto',
                                        marginBottom: '100px',
                                    }}
                                >
                                    {t('Back to the overview')}
                                </Button>
                            </div>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/facebook.png"
                                        alt="Veilig Vakwerk"
                                    />
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/twitter.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img
                                        src="/assets/images/linkedin.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </AppModule>
            );
        }

        let questions = 0;

        return (
            <div className="App">
                <Dialog
                    open={dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                >
                    <DialogTitle className="title">
                        {t('Not all answers have been entered')}
                    </DialogTitle>
                    <DialogContent>
                        {t(
                            'Check whether all answers have been entered and whether a comment has been entered for incorrect answers'
                        )}
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button
                             
                            className="confirmButton"
                            onClick={() => this.setState({ dialogOpen: false })}
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>

                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        {stepIndex === 0 ||
                        stepIndex === 1 ||
                        stepIndex === 2 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            type="title"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig Vakwerk')}
                        </Typography>
                    </Toolbar>
                    <div className={classes.stepperWrapper}>
                        <div className={classes.stepper} id="stepper">
                            <LegacyThemeProvider>
                                <Stepper activeStep={stepIndex}>
                                    <Step>
                                        <StepLabel>{t('General')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Inspection')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Finish')}</StepLabel>
                                    </Step>
                                </Stepper>
                            </LegacyThemeProvider>
                        </div>
                    </div>
                </AppBar>

                <AppModule
                    className="appModule"
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    <ProjectEdit
                        open={showAddProjectDialog}
                        editproject={editproject}
                        backOnCancel={false}
                        onCancel={this.hideAddProjectDialog}
                    />
                    {stepIndex === 0 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h2>{t('General information')}</h2>
                            <FormControl
                                style={{
                                    width: 'calc(100% - 30px)',
                                    display: 'inline-block',
                                }}
                            >
                                <InputLabel
                                    style={{
                                        display: 'block',
                                        marginTop: '11px',
                                }}>
                                    {t('Project/work area')}
                                </InputLabel>
                                <Input
                                multiline
                                fullWidth
                                className={classes.projectDropDown}                                    
                                inputComponent={(props) => (       
                                       <AutoCompleteView
                                            {...props}
                                            classes={parseClassesForAutocompleteView(
                                                props
                                            )}
                                            isToolbox={true}
                                        />
                                    )}
                                    value={this.state.project}
                                    placeholder=" "
                                    onChange={this.handleChangeProject}
                                    inputProps={{
                                        classes,
                                        instanceId: 'react-select-single',
                                        name: 'react-select-single',
                                        simpleValue: true,
                                        options: projects.allIds
                                            .filter(
                                                (id) =>
                                                    !id.includes(
                                                        'InvullenTijdenInspectie'
                                                    )
                                            )
                                            .map((id) => projects.byId[id])
                                            .sort(function (a, b) {
                                                const numberA = parseInt(a.externalId, 10);
                                                const numberB = parseInt(b.externalId, 10);

                                                if (
                                                    isNaN(numberA) &&
                                                    !isNaN(numberB)
                                                ) {
                                                    return 1;
                                                } else if (
                                                    !isNaN(numberA) &&
                                                    isNaN(numberB)
                                                ) {
                                                    return -1;
                                                } else if (
                                                    !isNaN(numberA) &&
                                                    !isNaN(numberB)
                                                ) {
                                                    return numberA - numberB;
                                                } else {
                                                    return a.externalId >
                                                        b.externalId
                                                        ? 1
                                                        : -1;
                                                }
                                            })
                                            .map((project) => ({
                                                title: project.omschrijving,
                                                value: project.id,
                                                label:
                                                    project.externalId +
                                                    ': ' +
                                                    project.omschrijving,
                                            })),
                                    }}
                                />
                            </FormControl>
                            <IconButton
                                className={'tooltip'}
                                aria-label="Add"
                                onClick={this.showAddProjectDialog}
                                style={{
                                    verticalAlign: 'top',
                                    marginTop: '23px',
                                    width: '30px',
                                }}
                            >
                                <span
                                    className={'tooltiptext'}
                                    style={{ left: '10px', top: '-10px' }}
                                >
                                    {t('Add project')}
                                </span>
                                <AddCircleOutline />
                            </IconButton>
                            <FormControl className={classes.item}>
                                <InputLabel htmlFor="description">
                                    {t('Description')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="description"
                                    inputProps={{ autoComplete: 'off' }}
                                    
                                    onChange={this.handleChange}
                                    label={t('Description')}
                                    required
                                    value={
                                        inspection && inspection.description
                                            ? inspection.description
                                            : ''
                                    }
                                />
                            </FormControl>

                            <h2 style={{ marginTop: '64px' }}>
                                {t('Customer / activity')}
                            </h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={'Klant'}
                                itemDescription={
                                    projects.byId[this.state.project]
                                        ? projects.byId[this.state.project]
                                              .opdrachtgever
                                        : '-'
                                }
                            />
                            <FormControl className={classes.item}>
                                <InputLabel htmlFor="activity">
                                    {t('Work type')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="activity"
                                    inputProps={{ autoComplete: 'off' }}
                                    
                                    onChange={this.handleChange}
                                    label={t('Work type')}
                                    required
                                    value={
                                        inspection && inspection.inspection
                                            ? inspection.inspection.activity
                                            : ''
                                    }
                                />
                            </FormControl>
                            <h2 style={{ marginTop: '64px' }}>{t('Staff')}</h2>
                            <FormControl>
                                <InputLabel htmlFor="EmployeeInputLabel">
                                    {t('Employees')}
                                </InputLabel>
                                <Select
                                    fullWidth={true}
                                    id="employees"
                                    input={<Input id="employeesInput" />}
                                    
                                    onChange={this.handleChangeEmployees}
                                    placeholder={t('Employees')}
                                    multiple
                                    value={this.state.employees}
                                >
                                    {employees.allIds
                                        .map((id) => employees.byId[id])
                                        .sort((a,b) => {
                                            if (a === null || a === undefined) return -1;
                                            if (b === null || b === undefined) return 1;
                                            if (a.fullname > b.fullname) return 1; else return -1; })
                                        .filter(
                                            (empl) => empl.state === 'ACTIVE'
                                        )
                                        .map((employee) => (
                                            <MenuItem
                                                className={classes.menuItemEmployees}
                                                key={employee.id}
                                                value={employee.id}
                                            >
                                                {employee.fullname}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.item}>
                                <InputLabel htmlFor="externalemployees">
                                    {t('Other attendees')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="externalemployees"
                                    inputProps={{ autoComplete: 'off' }}
                                    
                                    onChange={this.handleChange}
                                    label={t('External employees')}
                                    required
                                    value={
                                        inspection &&
                                        inspection.inspection.results &&
                                        inspection.inspection.results
                                            .externalEmployees
                                            ? inspection.inspection.results
                                                  .externalEmployees
                                            : ''
                                    }
                                />
                            </FormControl>
                            <h2 style={{ marginTop: '64px' }}>{t('Others')}</h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Choose weather condition')}
                            />
                            <div
                                style={{ width: '350px', marginBottom: '50px' }}
                            >
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'sunny',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'sunny'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_zon_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_zon.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'rain',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'rain'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_bewolkt_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_bewolkt.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                        id="weatherblock"
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'freezingRain',
                                            })
                                        }
                                        style={
                                            this.state.weatherType ===
                                            'freezingRain'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuw_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuw.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                        id="weatherblock"
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginTop: '25px',
                                    }}
                                    id="weatherlist"
                                >
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'storm',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'storm'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_wind_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_wind.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'thunderstorm',
                                            })
                                        }
                                        style={
                                            this.state.weatherType ===
                                            'thunderstorm'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_onweer_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_onweer.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                        id="weatherblock"
                                    />
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                weatherType: 'snow',
                                            })
                                        }
                                        style={
                                            this.state.weatherType === 'snow'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuwvlok_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor:
                                                          'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage:
                                                          'url("/assets/images/cat_sneeuwvlok.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center',
                                                  }
                                        }
                                        id="weatherblock"
                                    />
                                </div>
                            </div>
                            <hr />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toSelfTest}
                                disabled={
                                    this.state.weatherType === '' ||
                                    this.state.project === ''
                                }
                            >
                                {t('Start inspection')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 1 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            {console.log(previousInspection, "test")}

                            {!_.isEmpty(previousInspection) && (
                                <div classes={classes.previousAnwsers}>
                                    <h2>{t('Previous Findings')}</h2>
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('These shortcomings were noted in the previous WPI')}
                                    />

                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Workplace inspection')}
                                        itemDescription={previousInspection.inspection_title}
                                    />
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Reported by')}
                                        itemDescription={previousInspection.user}
                                    />
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Date')}
                                        itemDescription={moment(previousInspection.date).format('L HH:mm')}
                                    />
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Incorrect')}
                                        itemDescription={previousInspection.incorrect ? t('Yes') : t('No')}
                                    />

                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={t('Unsafe')}
                                        itemDescription={previousInspection.wpi_unsafe ? t('Yes') : t('No')}
                                    />

                                    {previousInspection.wpi_comment &&(
                                        <TitleDescription
                                            className={classes.item}
                                            itemTitle={t('Workplace inspection comment')}
                                            itemDescription={previousInspection.wpi_comment}
                                        />
                                    )}

                                    {previousInspection.incorrect && (
                                        <h2 style={{marginTop: '40px'}}>{t('Check the following items')}</h2>
                                    )}
                                    
                                    {previousInspection.issue_list && previousInspection.issue_list.map((element) => (
                                        <div key={element.item_id}>
                                            <hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Title')}
                                                itemDescription={element.title}
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Comment')}
                                                itemDescription={element.comment}
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Unsafe')}
                                                itemDescription={element.unsafe ? t('Yes') : t('No')}
                                            />   
                                        </div>
                                    ))}
                                    <hr style={{ marginTop: '10px' }} />
                                </div>
                            )}

                            {inspection.inspection.categories.map((cat) => (
                                <div>
                                    <div
                                        style={{
                                            marginBottom: '10px',
                                            marginTop: '40px',
                                        }}
                                    >
                                        <h2>{cat.title}</h2>
                                    </div>{' '}

                                    {cat.items.map((item, index) => (

                                        item.openQuestion ? (
                                            <div style={{ marginTop: '40px' }}>
                                                <TitleDescription className={classes.item} itemTitle={item.title}/>

                                                <FormControl className={classes.item}>
                                                    <InputLabel htmlFor="openvraag">
                                                        {t('Open Question')}
                                                    </InputLabel>
                                                    <Input
                                                        fullWidth={true}
                                                        multiline={true}
                                                        id="opmerking"
                                                        inputProps={{ autoComplete: 'off', }}
                                                        
                                                        onChange={((i) => (event) => this.handleChangeOpenAnswer(i - 1,event,item.id))(questions)}
                                                        label={t('Open Question')}
                                                    />
                                                </FormControl>
                                            </div>

                                        ) : (




                                            <div style={{ marginTop: '40px' }}>
                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={item.title}
                                            />

                                                { item.openQuestion !== true && item.photoQuestion !== true &&
                                                (

                                            <div>
                                                <div
                                                    onClick={((i) => () =>
                                                        this.handleAnswerClick(
                                                            i,
                                                            'ok',
                                                            item.id
                                                        ))(questions)}
                                                    style={
                                                        this.state.results &&
                                                        this.state.results[
                                                            questions
                                                        ] &&
                                                        this.state.results[
                                                            questions
                                                        ].result === 'ok'
                                                            ? {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                                  backgroundColor:
                                                                      '#272D35',
                                                                  color:
                                                                      'white',
                                                              }
                                                            : {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                              }
                                                    }
                                                    id="checklist"
                                                >
                                                    {t('Correct')}
                                                </div>
                                                <div
                                                    onClick={((i) => () =>
                                                        this.handleAnswerClick(
                                                            i,
                                                            'nok',
                                                            item.id
                                                        ))(questions)}
                                                    style={
                                                        this.state.results &&
                                                        this.state.results[
                                                            questions
                                                        ] &&
                                                        this.state.results[
                                                            questions
                                                        ].result === 'nok'
                                                            ? {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                                  backgroundColor:
                                                                      '#272D35',
                                                                  color:
                                                                      'white',
                                                                  marginLeft:
                                                                      '10px',
                                                              }
                                                            : {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                                  marginLeft:
                                                                      '10px',
                                                              }
                                                    }
                                                    id="checklist"
                                                >
                                                    {t('Not correct')}
                                                </div>
                                                <div
                                                    onClick={((i) => () =>
                                                        this.handleAnswerClick(
                                                            i,
                                                            'na',
                                                            item.id
                                                        ))(questions)}
                                                    style={
                                                        this.state.results &&
                                                        this.state.results[
                                                            questions
                                                        ] &&
                                                        this.state.results[
                                                            questions
                                                        ].result === 'na'
                                                            ? {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                                  backgroundColor:
                                                                      '#272D35',
                                                                  color:
                                                                      'white',
                                                                  marginLeft:
                                                                      '10px',
                                                              }
                                                            : {
                                                                  cursor:
                                                                      'pointer',
                                                                  height:
                                                                      '32px',
                                                                  width: '87px',
                                                                  border:
                                                                      '0.9px solid #272D35',
                                                                  borderRadius:
                                                                      '8px',
                                                                  textAlign:
                                                                      'center',
                                                                  lineHeight:
                                                                      '2',
                                                                  float: 'left',
                                                                  marginLeft:
                                                                      '10px',
                                                              }
                                                    }
                                                    id="checklist"
                                                >
                                                    {t('Does not apply')}
                                                </div>
                                            </div>
                                            )}


                                            <IconButton
                                                onClick={((i) => () =>
                                                    this.handleCollapseClick(
                                                        i
                                                    ))(questions)}
                                                style={{ float: 'right' }}
                                                id="expandbutton2"
                                            >
                                                {((this.state.collapsed &&  this.state.collapsed[questions] && this.state.collapsed[questions].collapsed === true) || item.photoQuestion === true)
                                                    ? (
                                                    <ChevronUpIcon />
                                                ) : (
                                                    <ChevronDownIcon />
                                                )}
                                            </IconButton>
                                            {
                                                <div
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                >
                                                    {questions++}
                                                </div>
                                            }
                                            {
                                                <div
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                >
                                                    1
                                                </div>
                                            }
                                            {item.photoQuestion === false && (this.state.collapsed[questions - 1] == null ||
                                                this.state.collapsed[questions - 1].collapsed === false) && (
                                                <Button
                                                    onClick={((i) => () =>
                                                        this.handleCollapseClick(
                                                            i - 1
                                                        ))(questions)}
                                                    style={{
                                                        marginLeft: '-13px',
                                                    }}
                                                    id="expandbutton"
                                                >
                                                    {this.state.results &&
                                                    this.state.results[
                                                        questions - 1
                                                    ] &&
                                                    this.state.results[
                                                        questions - 1
                                                    ].remark.text !== null
                                                        ? t('Change comment')
                                                        : t('Add comment')}
                                                </Button>
                                            )}
                                            <FormControl
                                                className={classes.item}
                                                style={
                                                    this.state.collapsed &&
                                                    ((this.state.collapsed[questions - 1] && this.state.collapsed[questions - 1 ].collapsed === true) || item.photoQuestion === true)
                                                        ? {}
                                                        : {
                                                              visibility:
                                                                  'hidden',
                                                              height: '0',
                                                              width: '0',
                                                          }
                                                }
                                            >
                                                <InputLabel htmlFor="opmerking">
                                                    {t('Comment')}
                                                </InputLabel>
                                                <Input
                                                    fullWidth={true}
                                                    id="opmerking"
                                                    inputProps={{
                                                        autoComplete: 'off',
                                                    }}
                                                    
                                                    onChange={((i) => (event) =>
                                                        this.handleChangeOpmerking(
                                                            i - 1,
                                                            event,
                                                            item.id
                                                        ))(questions)}
                                                    label="Opmerking"
                                                />
                                            </FormControl>
                                            {((this.state.collapsed && this.state.collapsed[questions - 1] && this.state.collapsed[questions - 1].collapsed === true) || item.photoQuestion === true) && (
                                                    <div
                                                        style={{
                                                            marginTop: '20px',
                                                            marginBottom:
                                                                '20px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display:
                                                                    'inline-block',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    float:
                                                                        'left',
                                                                    marginRight:
                                                                        '10px',
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                <SimpleImageInput
                                                                    id={questions -1 + '-0'}
                                                                    onChange={((i) => (event) => this.handleImageQuestion(i -1,event,item.id))(questions)}
                                                                    url={this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[0] ? this.state.results[questions -1].remark.photos[0].url: ''}
                                                                />
                                                            </div>
                                                            {this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[0] && (
                                                                    <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        <SimpleImageInput
                                                                            id={questions -1 + '-1'}
                                                                            onChange={((i) => (event) => this.handleImageQuestion(i -1,event,item.id))(questions)}
                                                                            url={this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[1] ? this.state.results[questions -1].remark.photos[1].url : ''}
                                                                        />
                                                                    </div>
                                                                )}
                                                            {this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[1] && (
                                                                    <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        <SimpleImageInput
                                                                            id={questions - 1 + '-2'}
                                                                            onChange={((i) => (event) =>
                                                                                this.handleImageQuestion(i - 1,event,item.id))(questions)}
                                                                            url={ this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[2] ? this.state.results[questions -1].remark.photos[2].url : '' }
                                                                        />
                                                                    </div>
                                                                )}
                                                            {this.state.results && this.state.results[questions - 1] && this.state.results[questions -1].remark.photos[2] && (
                                                                <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        <SimpleImageInput
                                                                            id={questions -1 + '-3'}
                                                                            onChange={((i) => (event) => this.handleImageQuestion(i -1,event,item.id))(questions)}
                                                                            url={this.state.results && this.state.results[questions - 1] && this.state.results[questions - 1].remark.photos[3] ? this.state.results[questions - 1].remark.photos[3].url : ''}
                                                                        />
                                                                    </div>
                                                                )}
                                                            {this.state.results && this.state.results[questions -1] && this.state.results[questions - 1].remark.photos[3] && (
                                                                    <div
                                                                        style={{
                                                                            float: 'left',
                                                                            marginRight: '10px',
                                                                            marginTop: '10px',
                                                                        }}
                                                                    >
                                                                        <SimpleImageInput
                                                                            id={questions - 1 + '-4'}
                                                                            onChange={((i) => (event) => this.handleImageQuestion(i -1, event, item.id))(questions)}
                                                                            url={this.state.results && this.state.results[questions -1] && this.state.results[questions -1].remark.photos[4] ? this.state.results[questions -1].remark.photos[4].url : ''}
                                                                        />
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <FormControl>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={
                                                                            this
                                                                                .state
                                                                                .results &&
                                                                            this
                                                                                .state
                                                                                .results[
                                                                                questions -
                                                                                    1
                                                                            ] &&
                                                                            this
                                                                                .state
                                                                                .results[
                                                                                questions -
                                                                                    1
                                                                            ]
                                                                                .remark &&
                                                                            this
                                                                                .state
                                                                                .results[
                                                                                questions -
                                                                                    1
                                                                            ]
                                                                                .unsafe
                                                                        }
                                                                        onChange={((
                                                                            i
                                                                        ) => () =>
                                                                            this.handleUnsafeAnswer(
                                                                                i -
                                                                                    1,
                                                                                item.id
                                                                            ))(
                                                                            questions
                                                                        )}
                                                                        id="unsafe"
                                                                    />
                                                                }
                                                                label={t(
                                                                    'Unsafe situation'
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                )}
                                            <hr style={{ marginTop: '10px' }} />
                                        </div>

                                        )


                                    ))}
                                </div>
                            ))}
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toEndPage}
                            >
                                {t('Start rounding')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 2 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h2 style={{ textAlign: 'center' }}>
                                {t('Complete workplace inspection')}
                            </h2>
                            <p>
                                {t(
                                    'Thanks for filling in, complete the workplace inspection here. After you have completed the workplace inspection, you can find the results under Completed assignments in the menu.'
                                )}
                            </p>
                            <br />
                            <p>{t('Enter other comment')}</p>
                            <textarea
                                name="feedbackfield"
                                cols="120"
                                rows="4"
                                value={this.state.feedbackText}
                                onChange={this.handleFormChange('text')}
                                style={{
                                    resize: 'none',
                                    width: '100%',
                                    fontSize: '16px',
                                }}
                            />
                            <br />
                            <div style={{ display: 'inline-block' }}>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <SimpleImageInput
                                        id={'feedbackphoto0'}
                                        onChange={this.handleImageUpload}
                                        url={
                                            this.state.photos &&
                                            this.state.photos[0]
                                                ? this.state.photos[0].url
                                                : ''
                                        }
                                    />
                                </div>
                                {this.state.photos[0] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto1'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[1]
                                                    ? this.state.photos[1].url
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                                {this.state.photos[1] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto2'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[2]
                                                    ? this.state.photos[2].url
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                                {this.state.photos[2] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto3'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[3]
                                                    ? this.state.photos[3].url
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                                {this.state.photos[3] && (
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'feedbackphoto4'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[4]
                                                    ? this.state.photos[4].url
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                            <br />
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={unsafe}
                                            onChange={this.handleUnsafeChange}
                                            id="unsafeInspection"
                                        />
                                    }
                                    label={t('Unsafe situation')}
                                />
                            </FormControl>
                            <br />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.sendResults}
                                disabled={this.state.finished}
                                id="sendresultsButton"
                                style={{ marginTop: '20px' }}
                            >
                                {t('Complete inspection')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(PublicInspectionOpdracht);
