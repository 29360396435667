import uuidv4 from 'uuid/v4';
import {create, fetchAll} from './apiActions';
import {removeEntity, setEntities, setEntity} from './entityActions';
import { push } from 'connected-react-router';
import {closeDialog, showSnackbar} from './uiActions';
import {editToolboxDraft} from './toolboxDraftActions';
import * as api from '../lib/api';
import {fetchDashboard} from './dashboardActions';
import {isUndefinedOrNullOrNaN} from '../lib/utils';
import moment from '../lib/moment';
import i18n from 'i18next';
import {
    V2_PUBLISH_TOOLBOX_API,
    V2_TOOLBOX_ASSIGN_REDUX,
    V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX, V2_TOOLBOX_INFORMATION_REDUX,
    V2_TOOLBOX_OVERVIEW_API,
    V2_TOOLBOX_OVERVIEW_REDUX, V2_TOOLBOX_SCHEDULED_OVERVIEW_API, V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX
} from "../lib/apiTypes";

export function deployToolbox(
    toolbox,
    employees,
    scheduledDate,
    isToolboxMeeting,
    toolboxMeetingDate,
    toolboxMeetingLocation,
    toolboxMeetingChairman,
    toolboxMeetingTopic,
    groups
) {
    return (dispatch) => {
        const scheduledMillies = scheduledDate
            ? '/' + scheduledDate.getTime()
            : '';

        const object = {
            groupIds: groups,
            userIds: employees,
            toolboxMeetingDate: toolboxMeetingDate,
            toolboxMeetingLocation: toolboxMeetingLocation,
            toolboxMeetingTopic: toolboxMeetingTopic,
            toolboxMeetingChairman: toolboxMeetingChairman,
        };

        return create(
            'toolbox/' +
                toolbox +
                '/toolboxMeeting/' +
                (isToolboxMeeting ? 'true' : 'false') +
                '/assignment' +
                scheduledMillies,
            object
        )(dispatch)
            .then((result) => {
                dispatch(setEntity('toolboxes', result)); // add new version
                dispatch(closeDialog('toolboxDeploy'));
                dispatch(showSnackbar(i18n.t('Toolbox has been sent')));
                dispatch(fetchScheduledToolboxesOverview());
                dispatch(fetchDashboard());
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error sending toolbox')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function fetchToolboxByToken(token) {
    return (dispatch) => {
        api.fetchExternToolbox(token)
            .then((res) => {
                dispatch({
                    type: 'EXTERNAL_TOOLBOX_ASSIGN',
                    ta: res.body._embedded.ta,
                });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error fetching toolbox')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function sendResultExtern(tokenId, result) {
    return (dispatch) => {
        api.sendResults('toolbox/extern/' + tokenId + '/resultsextern', result)
            .then((entities) => {})
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function pushSubjectOrComment(type, assignId, date, payload) {
    return (dispatch) => {
        api.sendResults(
            'toolbox/' + type + '/' + assignId + '/' + date,
            payload
        )
            .then((res) => {
                res.forEach((e) => {
                    dispatch(setEntity('toolboxAssigns', e));
                });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error while pushing')} ` +
                            type +
                            ': ' +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function removeSubjectOrComment(type, assignId, date, payload) {
    return (dispatch) => {
        api.sendResults(
            'toolbox/' + type + '/remove/' + assignId + '/' + date,
            payload
        )
            .then((res) => {
                res.forEach((e) => {
                    dispatch(setEntity('toolboxAssigns', e));
                });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error while pushing')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function updateChairman(assignId, date, payload) {
    return (dispatch) => {
        api.sendResults('toolbox/chairman/' + assignId + '/' + date, payload)
            .then((res) => {
                res.forEach((e) => {
                    dispatch(setEntity('toolboxAssigns', e));
                });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error while updating chairman')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function sendResult(tokenId, result) {
    return (dispatch) => {
        api.sendResults('toolbox/' + tokenId + '/results', result)
            .then((entities) => {
                dispatch(push('/web/opdrachten'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function sendAssignResult(tokenId, result) {
    return (dispatch) => {
        api.sendResults('toolboxresults/' + tokenId, result)
            .then((entities) => {
                dispatch(push('/web/opdrachten'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function fetchSpeakups() {
	return (dispatch) => {
		return fetchAll("speakups")(dispatch).then((entities) => {
			dispatch(setEntities("speakups", entities));
		});
	};
}

export function fetchDocuments() {
	return (dispatch) => {
		return fetchAll("document")(dispatch).then((entities) => {
			dispatch(setEntities("document", entities));
		});
	};
}

export function fetchIncidents() {
    return (dispatch) => {
        return fetchAll('incidents')(dispatch).then((entities) => {
            dispatch(setEntities('incidents', entities));
        });
    };
}

export function fetchIncidentTypes() {
    return (dispatch) => {
        return fetchAll('incidenttype')(dispatch).then((entities) => {
            dispatch(setEntities('incidenttype', entities));
        });
    };
}

export function fetchIncidentCause() {
    return (dispatch) => {
        return fetchAll('incidentcause')(dispatch).then((entities) => {
            dispatch(setEntities('incidentcause', entities));
        });
    };
}

export function saveIncident(data) {
    return (dispatch) => {
        if (data.date instanceof Date) {
            data.date = moment(data.date).format('YYYY-MM-DDTHH:mm:ssZ');
        }

        api.saveIncident(data)
            .then((entity) => {
                dispatch(setEntity('incidents', data));
                dispatch(showSnackbar('Incident opgeslagen'));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function seenIncident(data) {
	return (dispatch) => {
		api.seenIncident(data)
			.then((entity) => {
				let incident = data;
				incident.isnew = false;
				dispatch(setEntity("incidents", incident));
			})
			.catch((error) => {
				dispatch(showSnackbar(`${i18n.t("Error")}: ` + error.message));
				throw error;
			});
	};
}

export function documentDownloaded(document, state) {
    return (dispatch) => {
        api.documentDownloaded(document, state)
            .then((entity) => {
                document.downloadCount++;
                if (state) {
                    dispatch(showSnackbar(i18n.t('Download complete')));
                }
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t("Error")}: ` + error.message));
                throw error;
            });
    };
}

export function speekupSeen(data) {
    return (dispatch) => {
        api.speekupSeen(data)
			.then(() => {
				let speakups = data;
				speakups.isnew = false;
				dispatch(setEntity("speakups", speakups));
			})
			.catch((error) => {
				dispatch(showSnackbar(`${i18n.t("Error")}: ` + error.message));
				throw error;
			});
    };
}

export function deleteSpeakup(data) {
	return (dispatch) => {
		api.deleteSpeakup(data)
			.then((entity) => {
				dispatch(removeEntity("speakups", data));
				dispatch(showSnackbar(i18n.t("speakup removed")));
			})
			.catch((error) => {
				dispatch(showSnackbar(`${i18n.t("Error")}: ` + error.message));
				throw error;
			});
	};
}

export function deleteDocument(data) {
	return (dispatch) => {
		api.deleteDocument(data)
			.then(() => {
				dispatch(removeEntity("document", data));
				dispatch(showSnackbar(i18n.t("document removed")));
			})
			.catch((error) => {
				dispatch(showSnackbar(`${i18n.t("Error")}: ` + error.message));
				throw error;
			});
	};
}

export function deleteIncident(data) {
    return (dispatch) => {
        api.deleteIncident(data)
            .then((entity) => {
                dispatch(removeEntity('incidents', data));
                dispatch(showSnackbar(i18n.t('Incident removed')));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function fetchSpeakupContacts() {
    return (dispatch) => {
        return fetchAll('speakupcontact')(dispatch).then((entities) => {
            dispatch(setEntities('speakupcontact', entities));
        });
    };
}

export function fetchSpeakupCompanies() {
    return (dispatch) => {
        return fetchAll('speakupcompany')(dispatch).then((entities) => {
            dispatch(setEntities('speakupcompany', entities));
        });
    };
}

export function fetchToolboxes() {
    return (dispatch) => {
        return fetchAll('toolboxes-projection')(dispatch).then((entities) => {
            dispatch(setEntities('toolboxes', entities));
        });
    };
}

export function getAppToolboxes() {
    return (dispatch) => {
        return fetchAll('toolbox/assigned')(dispatch).then((entities) => {
            dispatch(setEntities('appToolboxes', entities));
        });
    };
}

export function fetchToolboxAssigns() {
    return (dispatch) => {
        return fetchAll('toolbox-assign-projection')(dispatch).then(
            (entities) => {
                dispatch(setEntities('toolboxAssigns', entities));
            }
        );
    };
}

export function fetchToolboxGroupAssigns() {
    return (dispatch) => {
        return fetchAll('toolbox-group-assigns')(dispatch).then((entities) => {
            dispatch(setEntities('toolboxGroupAssigns', entities));
        });
    };
}

export function fetchExternAssigns() {
    return (dispatch) => {
        return fetchAll('extern-assign')(dispatch).then((entities) => {
            dispatch(setEntities('externAssigns', entities));
        });
    };
}

export function saveToolbox(toolboxDraftData, openNewToolbox) {
    return (dispatch, getState) => {
        const contentBlockDrafts = getState().drafts.contentBlocks;
        const questionBlocks = getState().drafts.questionBlocks;

        const toolboxData = {
            ...toolboxDraftData,
            sectors: toolboxDraftData.sectors.filter(
                (sector) => !isUndefinedOrNullOrNaN(sector)
            ),
            toolbox: {
                ...toolboxDraftData.toolbox,
                blocks: toolboxDraftData.toolbox.blocks
                    .map((blockId) => contentBlockDrafts[blockId])
                    .map((block) => {
                        if (block.type === 'listBlock') {
                            block.highlighted = block.highlight;
                            return {
                                ...block,
                                listBlock: {
                                    ...block.listBlock,
                                    items: block.listBlock.items.map(
                                        (item) => item.text
                                    ),
                                },
                            };
                        }
                        block.highlighted = block.highlight;
                        return block;
                    }),
                questions: toolboxDraftData.toolbox.questions
                    .map((questionId) => questionBlocks[questionId])
                    .map((question) => {
                        return {
                            ...question,
                            blocks: question.blocks.map(
                                (blockId) => contentBlockDrafts[blockId]
                            ),
                            possibleAnswers: question.possibleAnswers,
                            correctAnswers: question.possibleAnswers
                                .map((answer, i) => ({
                                    id: answer.id,
                                    index: i,
                                    correct: answer.correct,
                                }))
                                .filter((answer) => answer.correct)
                                .map((answer) => answer.index),
                        };
                    }),
            },
        };

        return create(
            'toolbox',
            toolboxData
        )(dispatch)
            .then((entity) => {
                dispatch(
                    setEntity('toolboxes', {
                        ...toolboxData,
                        ...entity,
                        toolbox: {
                            ...toolboxData.toolbox,
                            ...entity.toolbox,
                            blocks: entity.toolbox.blocks.map((block) => ({
                                id: uuidv4(),
                                ...block,
                            })),
                        },
                    })
                ); // add new version
                dispatch(
                    showSnackbar(
                        `${i18n.t('Toolbox version')} ` +
                            entity.version +
                            `${i18n.t('is Saved')} .`
                    )
                );
                dispatch(
                    editToolboxDraft(toolboxData.id, 'version', entity.version)
                );
                // if (entity.id && openNewToolbox) {
                dispatch(push('/toolboxen/' + entity.id));
                // }
                dispatch(fetchToolboxesOverview());
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error saving toolbox')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function createToolbox(toolboxData, redirect = false) {
    return (dispatch) => {
        return create(
            'toolbox',
            toolboxData
        )(dispatch).then((entity) => {
            if (!entity.toolbox.questions) {
                entity.toolbox.questions = [];
            }
            dispatch(closeDialog('toolboxCreate'));
            dispatch(fetchToolboxesOverview());
            dispatch(setEntity('toolboxes', { ...toolboxData, ...entity }));
            if (redirect) {
                dispatch(push('/toolboxen/' + entity.id));
            }
            return { ...toolboxData, ...entity };
        });
    };
}

export function deleteToolbox(toolboxData) {
    return (dispatch) => {
        api.deleteToolbox('toolbox', toolboxData)
            .then((entities) => {
                if (entities) {
                    entities.forEach((entity) => {
                        dispatch(removeEntity('toolboxes', { ...entity }));
                    });

                    //Just take the first one to get the title. The first one is the newest version
                    if (entities[0]) {
                        dispatch(
                            showSnackbar(
                                `${i18n.t('Toolbox')} ` +
                                    entities[0].title +
                                    ` ${i18n.t('Is deleted')}`
                            )
                        );

                        dispatch(fetchToolboxesOverview())
                    }
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not delete toolbox')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function cancelSingleAssign(assignId) {
    return (dispatch) => {
        api.cancelToolbox('toolbox', assignId)
            .then((entity) => {
                dispatch(fetchScheduledToolboxesOverview());
                dispatch(showSnackbar(i18n.t('Toolbox canceled')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not cancel toolbox')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function cancelGroupAssign(groupId) {
    return (dispatch) => {
        api.cancelToolboxGroup('toolbox', groupId)
            .then((entity) => {
                dispatch(fetchScheduledToolboxesOverview());
                dispatch(showSnackbar(i18n.t('Toolbox canceled')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not cancel toolbox')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function getToolboxById(toolboxId: string) {
    return (dispatch) => {
        api.getToolboxById(toolboxId)
            .then((entity) => {
                const toolbox = entity.body;

                dispatch(
                    showSnackbar(
                        `${i18n.t('Toolbox')} "` +
                            toolbox.id +
                            `" ${i18n.t('Loaded')}. `
                    )
                );
                dispatch(setEntity('toolbox', { ...toolbox }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error fetching toolbox')}: ` +
                            toolboxId +
                            ' : ' +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function getToolboxAssignById(toolboxAssignId: string) {
    return (dispatch) => {
        api.getToolboxAssignById(toolboxAssignId)
            .then((entity) => {
                const toolboxAssign = entity.body;
                if (toolboxAssign !== null) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Sent toolbox')} "` +
                                toolboxAssign.id +
                                `" ${i18n.t('Loaded')}. `
                        )
                    );
                    dispatch(setEntity('toolboxAssign', { ...toolboxAssign }));
                    dispatch(setEntity('toolboxAssigns', { ...toolboxAssign }));
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error fetching sent toolbox')}: ` +
                            toolboxAssignId +
                            ' : ' +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function deleteToolboxAssign(
    toolboxId: string,
    redirect: any,
    id: string,
    deploymentId: number
) {
    return (dispatch) => {
        api.deleteToolboxAssign('toolbox', toolboxId)
            .then((entity) => {
                // Refresh list
                dispatch(getToolboxResultsOverviewForDeployment(toolboxId, deploymentId));

                dispatch(showSnackbar(i18n.t('The results are deleted')));
                if (redirect === false) {
                    dispatch(
                        removeEntity('toolboxAssigns', {
                            ...JSON.parse(entity.text),
                        })
                    );
                } else if (redirect === -1) {
                    dispatch(
                        removeEntity('toolboxAssigns', {
                            ...JSON.parse(entity.text),
                        })
                    );
                    dispatch(push('/toolboxen#uitgestuurd'));
                } else {
                    window.location.assign(
                        '/toolbox/details/' +
                            id.toString() +
                            '/' +
                            redirect.toString() +
                            '?from=uitgestuurd#resultaten'
                    );
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error retracting toolbox')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function retractToolbox(toolboxId: string) {
    return (dispatch) => {
        api.retract('toolbox', toolboxId)
            .then((entity) => {
                const toolbox = entity.body;
                dispatch(
                    showSnackbar(
                        `${i18n.t('Toolbox')} "` +
                            toolbox.title +
                            `" ${i18n.t('Version')} ` +
                            toolbox.version +
                            ` ${i18n.t('Has been withdrawn')}.`
                    )
                );
                dispatch(setEntity('toolboxes', { ...toolbox }));
                dispatch(fetchToolboxesOverview())
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error retracting toolbox')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

/**
 * V2 calls
 */

export function fetchToolboxesOverview() {
    return (dispatch) => {
        return fetchAll(V2_TOOLBOX_OVERVIEW_API)(dispatch).then((entities) => {
            dispatch(setEntities(V2_TOOLBOX_OVERVIEW_REDUX, entities));
        });
    };
}

// export function fetchAssignedToolboxesOverview() {
//     return (dispatch) => {
//         return fetchAll(V2_TOOLBOX_ASSIGN_OVERVIEW_API)(dispatch).then((entities) => {
//             dispatch(setEntities(V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX, entities));
//         });
//     };
// }

export function fetchScheduledToolboxesOverview() {
    return (dispatch) => {
        return fetchAll(V2_TOOLBOX_SCHEDULED_OVERVIEW_API)(dispatch).then((entities) => {
            dispatch(setEntities(V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX, entities));
        });
    };
}

export function publishToolbox(toolboxId: string, callback: (success: boolean, errorMessage: string) => mixed) {
    return (dispatch) => {
        api.publish(V2_PUBLISH_TOOLBOX_API, toolboxId)
            .then((entity) => {
                callback(entity.body, undefined)
                dispatch(fetchToolboxesOverview())
            })
            .catch((error) => {
                if (error.response && error.response.statusCode === 428) {
                    callback(false, error.response.body.code)
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Unknown error publishing toolbox')}: ` +
                            error.message
                        )
                    );
                    throw error;
                }
            });
    };
}

export function getToolboxByIdV2(toolboxId: string) {
    return (dispatch) => {
        api.getToolboxByIdV2(toolboxId)
            .then((entity) => {
                const toolbox = entity.body;

                dispatch(
                    showSnackbar(
                        `${i18n.t('Toolbox')} "` +
                        toolbox.title +
                        `" ${i18n.t('Loaded')}. `
                    )
                );
                dispatch(setEntity('toolbox', { ...toolbox }));
            })
            .catch((error) => {
                if (error.response && error.response.statusCode === 404) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('error_toolbox_not_found')}: ` +
                            toolboxId
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Unknown error fetching toolbox')}: ` +
                            toolboxId +
                            ' : ' +
                            error.message
                        )
                    );
                }

                throw error;
            });
    };
}

export function getToolboxInformation(toolboxId: string) {
    return (dispatch) => {
        api.getToolboxInformation(toolboxId)
            .then((entity) => {
                const toolboxInformation = entity.body;

                dispatch(setEntity(V2_TOOLBOX_INFORMATION_REDUX, {
                    id: toolboxId,
                    information: toolboxInformation
                }));
            })
            .catch((error) => {
                if (error.response && error.response.statusCode === 404) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('error_toolbox_not_found')}: ` +
                            toolboxId
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Unknown error fetching toolbox')}: ` +
                            toolboxId +
                            ' : ' +
                            error.message
                        )
                    );
                }

                throw error;
            });
    };
}

export function getToolboxResultsOverviewForDeployment(toolboxId: string, deploymentId: string) {
    return (dispatch) => {
        api.getToolboxResultsOverviewForDeployment(toolboxId, deploymentId)
            .then((entity) => {
                const toolboxAssigns = entity.body._embedded.toolboxAssigns;

                dispatch(setEntity(V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX, {
                    id: deploymentId,
                    results: toolboxAssigns
                }));
            })
            .catch((error) => {
                if (error.response && error.response.statusCode === 404) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('error_toolbox_not_found')}: ` +
                            toolboxId
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Unknown error fetching toolbox')}: ` +
                            toolboxId +
                            ' : ' +
                            error.message
                        )
                    );
                }

                throw error;
            });
    };
}

export function getToolboxResultForDeploymentAndUser(toolboxId: string, deploymentId: number, userId: number) {
    return (dispatch) => {
        api.getToolboxResultForDeploymentAndUser(toolboxId, deploymentId, userId)
            .then((entity) => {
                const toolboxAssign = entity.body;
                if (toolboxAssign !== null) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Sent toolbox')} "` +
                            toolboxAssign.id +
                            `" ${i18n.t('Loaded')}. `
                        )
                    );
                    dispatch(setEntity(V2_TOOLBOX_ASSIGN_REDUX, { ...toolboxAssign }));
                }
            })
            .catch((error) => {
                if (error.response && error.response.statusCode === 404) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('error_toolbox_assign_not_found')}: ` +
                            toolboxId
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Unknown error fetching sent toolbox')}: ` +
                            deploymentId +
                            ' : ' +
                            error.message
                        )
                    );
                }

                throw error;
            });
    };
}

export function sendToolboxReminder(toolboxId: string, deploymentId: number) {
    return (dispatch) => {
        api.sendToolboxReminder(toolboxId, deploymentId)
            .then((entity) => {
                dispatch(showSnackbar(i18n.t('reminder_sent_to_x_users', { amountOfUsers: entity.body })));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function sendToolboxReminderToUser(toolboxId: string, deploymentId: number, userId: number) {
    return (dispatch) => {
        api.sendToolboxReminderToUser(toolboxId, deploymentId, userId)
            .then((entity) => {
                dispatch(showSnackbar(i18n.t('reminder_sent_to_x_users', { amountOfUsers: entity.body })));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function deleteToolboxAssigns(toolboxId: string, deploymentId: number) {
    return (dispatch) => {
        api.deleteToolboxAssigns(toolboxId, deploymentId)
            .then((response) => {
                // Refresh data

                dispatch(showSnackbar(i18n.t('The results are deleted') + ' (' + response.body + ')'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error deleting results')}: ` +
                        error.message
                    )
                );
                throw error;
            });
    };
}