//@flow
import { fetchAll } from './apiActions';
import { setEntities, setEntity } from './entityActions';
import { showSnackbar } from './uiActions';
import i18n from 'i18next';
import * as api from '../lib/api';
import { List } from 'immutable';

function isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

    return is_ie;
}
export function fetchEmployees(entity) {
    if (!entity) entity = 'employee';
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        return fetchAll(entity)(dispatch).then((entities) => {
            dispatch(setEntities('employees', entities));
        });
    };
}

export function fetchEmployeesAll(entity) {
    if (!entity) entity = 'employee';
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        return fetchAll(entity)(dispatch).then((entities) => {
            dispatch(setEntities('employeesAll', entities));
        });
    };
}

type TEAFetchStart = {
    type: 'FETCH_EMPLOYEES_REQUEST',
};

type TEAFetchError = {
    type: 'FETCH_EMPLOYEES_FAILURE',
    text: string,
    timeout: number,
    level: string,
};

type TEAFetchSuccess = {
    type: 'FETCH_EMPLOYEES_SUCCESS',
    employees: Array<TEmployee>,
};

type TEAFetchEmployeeStart = {
    type: 'FETCH_EMPLOYEE_REQUEST',
    id: number,
};

type TEAFetchEmployeeError = {
    type: 'FETCH_EMPLOYEE_FAILURE',
    text: string,
    timeout: number,
    level: string,
};

type TEAFetchEmployeeSuccess = {
    type: 'FETCH_EMPLOYEE_SUCCESS',
    employee: TEmployee,
};

type TEAEmployeeImportStart = {
    type: 'EMPLOYEE_IMPORT_START',
};

type TEAEmployeeImportError = {
    type: 'EMPLOYEE_IMPORT_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TEAEmployeeImportSuccess = {
    type: 'EMPLOYEE_IMPORT_SUCCESS',
    diff: {
        removed: List<TEmployee>,
        added: List<TEmployee>,
    },
};

type TEAEmployeeUpdateStart = {
    type: 'EMPLOYEE_UPDATE_START',
};

type TEAEmployeeUpdateError = {
    type: 'EMPLOYEE_UPDATE_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TEAEmployeeUpdateSuccess = {
    type: 'EMPLOYEE_UPDATE_SUCCESS',
    employees: List<TEmployee>,
};

type TEAEditEmployee = {
    type: 'EMPLOYEE_EDIT',
    employee?: TEmployee,
};

type TEAUpsertStart = {
    type: 'EMPLOYEE_UPSERT_START',
};

type TEAUpsertError = {
    type: 'EMPLOYEE_UPSERT_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TEAUpsertSuccess = {
    type: 'EMPLOYEE_UPSERT_SUCCESS',
    employee: TEmployee,
};

type TEAInviteStart = {
    type: 'EMPLOYEE_INVITE_START',
};

type TEAInviteError = {
    type: 'EMPLOYEE_INVITE_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TEAClearDiff = {
    type: 'EMPLOYEE_CLEAR_DIFF',
};

type TEADelete = {
    type: 'EMPLOYEE_DELETE_START',
};

export type TEmployeeAction =
    | TEAFetchStart
    | TEAFetchError
    | TEAFetchSuccess
    | TEAFetchEmployeeStart
    | TEAFetchEmployeeError
    | TEAFetchEmployeeSuccess
    | TEAEditEmployee
    | TEAUpsertStart
    | TEAUpsertError
    | TEAUpsertSuccess
    | TEAEmployeeImportStart
    | TEAEmployeeImportError
    | TEAEmployeeImportSuccess
    | TEAEmployeeUpdateStart
    | TEAEmployeeUpdateError
    | TEAEmployeeUpdateSuccess
    | TEAInviteStart
    | TEAInviteError
    | TEAClearDiff
    | TEADelete;

export function currentUser(entity: TEmployee) {
    return {
        type: 'FETCH_CURRENT_USER',
        user: entity,
    };
}

export function fetchEmployeeRequest(employeeId: number) {
    return {
        type: 'FETCH_EMPLOYEE_REQUEST',
        id: employeeId,
    };
}

export function fetchEmployeeSuccess(json: any) {
    return {
        type: 'FETCH_EMPLOYEE_SUCCESS',
        employee: json._embedded,
    };
}

export function fetchEmployeeFailure(json: any) {
    return {
        type: 'FETCH_EMPLOYEE_FAILURE',
        text: json,
        timeout: 5000,
        level: 'error',
    };
}

export function fetchEmployeesRequest() {
    return {
        type: 'FETCH_EMPLOYEES_REQUEST',
    };
}

export function fetchEmployeesSuccess(json: any) {
    return {
        type: 'FETCH_EMPLOYEES_SUCCESS',
        employees: json._embedded.employees,
    };
}

export function fetchEmployeesFailure(json: any) {
    return {
        type: 'FETCH_EMPLOYEES_FAILURE',
        text: json,
        timeout: 5000,
        level: 'error',
    };
}

export function editEmployee(employee?: TEmployee) {
    return {
        type: 'EMPLOYEE_EDIT',
        employee: employee,
    };
}

export function disableEmployee(employee: TEmployee, t): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        const emplToSave = Object.assign({}, employee, { state: 'INACTIVE' });

        api.upsert('employee', emplToSave)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Employee')} ` +
                            entity.firstname +
                            ` ${i18n.t('has been deactivated')}.`
                    )
                );
                dispatch(setEntity('employees', { ...entity }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error when deactivating employee'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function enableEmployee(employee: TEmployee): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        const emplToSave = Object.assign({}, employee, { state: 'ACTIVE' });

        api.upsert('employee', emplToSave)
            .then((entity) => {
                // dispatch(showSnackbar('Medewerker ' + entity.firstname + ' is gedeactiveerd.'));
                dispatch(setEntity('employees', { ...entity }));
            })
            .catch((error) => {
                // dispatch(showSnackbar('Onbekende fout bij deactiveren van medewerker: ' + error.message));
                throw error;
            });
    };
}

export function deleteEmployee(employee: TEmployee, action): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_DELETE_START',
        });

        api.deleteEmployee('employee', employee)
            .then((entity) => {
                const middlename = entity.middlename
                    ? ' ' + entity.middlename
                    : '';
                dispatch(
                    showSnackbar(
                        `${i18n.t('Employee')} ` +
                            entity.firstname +
                            middlename +
                            ' ' +
                            entity.lastname +
                            ` ${i18n.t('Is deleted')}.`
                    )
                );
                dispatch(fetchEmployees(action));
            })
            .catch((error) => {
                dispatch(showSnackbar(error.message));
                throw error;
            });
    };
}

export function switchRoleEmployee(employee: TEmployee, role: string) {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        if (employee.roles && !~employee.roles.indexOf(role)) {
            employee.roles.push(role);
        } else if (employee.roles && ~employee.roles.indexOf(role)) {
            employee.roles.splice(employee.roles.indexOf(role), 1);
        }
        api.upsert('employee', employee)
            .then((entity) => {
                const middlename = entity.middlename
                    ? ' ' + entity.middlename
                    : '';
                if (~entity.roles.indexOf(role)) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Employee')} ` +
                                entity.firstname +
                                middlename +
                                ' ' +
                                entity.lastname +
                                ` ${i18n.t('Is a KAM-coordinator now')}.`
                        )
                    );
                } else {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Employee')} ` +
                                entity.firstname +
                                middlename +
                                ' ' +
                                entity.lastname +
                                ` ${i18n.t(
                                    'Is not a KAM-coordinator anymore'
                                )}.`
                        )
                    );
                }
                dispatch(setEntity('employees', { ...employee, ...entity }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Error with savind employee')}: ` +
                            i18n.t(error.message)
                    )
                );
                throw error;
            });
    };
}

export function upsertEmployee(
    employee: TEmployee,
    callback?: Function
): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_UPSERT_START',
        });

        api.upsert(employee.type, employee)
            .then((entity) => {
                const middlename = entity.middlename
                    ? ' ' + entity.middlename
                    : '';
                dispatch(
                    showSnackbar(
                        `${i18n.t('Employee')} ` +
                            entity.firstname +
                            middlename +
                            ' ' +
                            entity.lastname +
                            ` ${i18n.t('Is changed and saved')}.`
                    )
                );
                dispatch(setEntity('employees', { ...employee, ...entity }));
                dispatch(getCurrentEmployee());
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Error with savind employee')}: ` +
                            i18n.t(error.message)
                    )
                );
                throw error;
            });
    };
}

export function parseImport(file: any, callback: Function): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_IMPORT_START',
        });

        api.parseImport('employee', file, function (err, res) {
            if (err) {
                dispatch({
                    type: 'EMPLOYEE_IMPORT_ERROR',
                    text: err,
                });
                dispatch(showSnackbar(i18n.t('File can not be imported')));
            } else {
                dispatch({
                    type: 'EMPLOYEE_IMPORT_SUCCESS',
                    diff: res.body._embedded.diff,
                });

                if (callback) {
                    dispatch(
                        showSnackbar(`${i18n.t('Something is wrong')}: ` + err)
                    );
                    callback(err);
                    dispatch({
                        type: 'EMPLOYEE_IMPORT_ERROR',
                        text: err,
                    });
                }
            }
        });
    };
}

export function parseImportExtern(file: any, callback: Function): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_IMPORT_START',
        });

        api.parseImport('employeeextern', file, function (err, res) {
            if (err) {
                dispatch({
                    type: 'EMPLOYEE_IMPORT_ERROR',
                    text: err,
                });
                dispatch(showSnackbar(i18n.t('File can not be imported')));
            } else {
                dispatch({
                    type: 'EMPLOYEE_IMPORT_SUCCESS',
                    diff: res.body._embedded.diff,
                });

                if (callback) {
                    dispatch(
                        showSnackbar(`${i18n.t('Something is wrong')}: ` + err)
                    );
                    callback(err);
                    dispatch({
                        type: 'EMPLOYEE_IMPORT_ERROR',
                        text: err,
                    });
                }
            }
        });
    };
}

export function acknowledgeImport(file: any, callback: Function): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_UPDATE_START',
        });

        api.acknowledgeImport('employee', file, function (err, res) {
            if (err) {
                dispatch(showSnackbar('Error processing data: ' + err));
            } else {
                dispatch({
                    type: 'EMPLOYEE_UPDATE_SUCCESS',
                    employees: res.body._embedded.employees,
                });
                dispatch(fetchEmployees());
                dispatch(
                    showSnackbar(
                        (res.body._embedded.employees.length !== 0
                            ? res.body._embedded.employees.length
                            : '') + ` ${i18n.t('Employees changed')}`
                    )
                );
                if (isIE()) {
                    window.location.reload();
                }

                if (callback) {
                    callback(err);
                }
            }
        });
    };
}

export function acknowledgeImportExtern(
    file: any,
    callback: Function
): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_UPDATE_START',
        });

        api.acknowledgeImportExtern('employee', file, function (err, res) {
            if (err) {
                dispatch(showSnackbar('Error processing data: ' + err));
            } else {
                dispatch({
                    type: 'EMPLOYEE_UPDATE_SUCCESS',
                    employees: res.body._embedded.employees,
                });
                dispatch(fetchEmployees('extern'));
                dispatch(
                    showSnackbar(
                        (res.body._embedded.employees.length !== 0
                            ? res.body._embedded.employees.length
                            : '') + ` ${i18n.t('Employees changed')}`
                    )
                );
                if (isIE()) {
                    window.location.reload();
                }

                if (callback) {
                    callback(err);
                }
            }
        });
    };
}

export function inviteEmployee(
    employeeId: number,
    callback: (err: any) => void,
    language: any
) {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_INVITE_START',
        });

        //Invite employees expects a list so wrap id in a list
        let idList = List();
        idList = idList.push(employeeId);

        api.inviteEmployees(
            idList,
            function (err, res) {
                if (err) {
                    dispatch({
                        type: 'EMPLOYEE_INVITE_ERROR',
                        text: 'Error processing data: ' + err,
                        timeout: 5000,
                        level: 'error',
                    });
                } else {
                    if (res.body._embedded.employees) {
                        const employees = res.body._embedded.employees;

                        employees.forEach(function (employee) {
                            dispatch(setEntity('employees', employee)); // add new version
                        });
                        dispatch(showSnackbar(i18n.t('Invited')));
                    }

                    if (callback) {
                        callback(err);
                    }
                }
            },
            language
        );
    };
}

export function getCurrentEmployee() {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {
        dispatch({
            type: 'EMPLOYEE_INVITE_START',
        });
        api.getCurrentEmployee()
            .then((entity) => {
                dispatch(currentUser(entity.body));
            })
            .catch((error) => {
                dispatch(showSnackbar(error.message));
                throw error;
            });
    };
}


export function getEmployeePIN(employeeId: number,  callback?: Function): Function {
    return (dispatch: PlainDispatch<TEmployeeAction>) => {

        api.getPIN(employeeId)
            .then((entity) => {
                dispatch(
                    showSnackbar(`${i18n.t('PIN')} ` + entity.body.pin)
                );
                if (callback) {
                    callback(entity.body.pin);
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while getting PIN'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}


export function clearDiff(): TEmployeeAction {
    return {
        type: 'EMPLOYEE_CLEAR_DIFF',
    };
}

export function disableNewFeatures(user: any): Function {
    return (dispatch: PlainDispatch<any>) => {
        api.upsert('disablenewfeatures', user)
            .then((entity) => {
                // window.location.reload();
                getCurrentEmployee()(dispatch);
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function resetNewFeatures(user: any): Function {
    return (dispatch: PlainDispatch<any>) => {
        api.upsert('resetnewfeatures', null)
            .then((entity) => {
                // window.location.reload();
                getCurrentEmployee()(dispatch);
            })
            .catch((error) => {
                throw error;
            });
    };
}
