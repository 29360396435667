import uuidv4 from 'uuid/v4';
import { createContentBlockDraft, deleteContentBlockDraft } from './contentBlockDraftActions';

export const CREATE_INSPECTION_DRAFT = 'CREATE_INSPECTION_DRAFT';
export const MOVE_INSPECTION_DRAFT_CONTENT_BLOCK = 'MOVE_INSPECTION_DRAFT_CONTENT_BLOCK';
export const DELETE_INSPECTION_DRAFT_CONTENT_BLOCK = 'DELETE_INSPECTION_DRAFT_CONTENT_BLOCK';
export const INSERT_INSPECTION_DRAFT_CONTENT_BLOCK = 'INSERT_INSPECTION_DRAFT_CONTENT_BLOCK';
export const EDIT_INSPECTION_DRAFT = 'EDIT_INSPECTION_DRAFT';

export function initInspectionDraft(data) {
    const draftData = { ...data, inspection: { ...data.inspection, blocks: data.inspection.categories.map(block => block.id) } };

    return (dispatch, getState) => {
        data.inspection.categories.forEach(block => {
            const draftBlock = {
                title: '',
                type: 'checkListBlock',
                listBlock: {
                    items: block.items.map(item => {
                        item.text = item.title;
                        return item;
                    })
                },
                ...block
            };
            dispatch(createContentBlockDraft(draftBlock));
        });
        dispatch(createInspectionDraft(draftData));
    };
}

export function insertInspectionDraftContentBlockType(id, data, index) {
    const contentBlockDraft = { id: uuidv4(), title: '', subtitle: '', ...data.initialData };
    const action = {
        type: INSERT_INSPECTION_DRAFT_CONTENT_BLOCK,
        payload: { id, contentBlockId: contentBlockDraft.id, index }
    };

    return (dispatch, getState) => {
        dispatch(createContentBlockDraft(contentBlockDraft));
        dispatch(action);
    };
}

export function deleteInspectionDraftContentBlock(id, contentBlockId) {
    const action = {
        type: DELETE_INSPECTION_DRAFT_CONTENT_BLOCK,
        payload: { id, contentBlockId }
    };

    return (dispatch, getState) => {
        dispatch(action);
        dispatch(deleteContentBlockDraft(contentBlockId));
    };
}

export function editInspectionDraft(id, key, value) {
    return {
        type: EDIT_INSPECTION_DRAFT,
        payload: { id, key, value }
    };
}

export function createInspectionDraft(data) {
    return {
        type: CREATE_INSPECTION_DRAFT,
        payload: { data }
    };
}

export function moveInspectionDraftContentBlock(id, fromIndex, toIndex) {
    return {
        type: MOVE_INSPECTION_DRAFT_CONTENT_BLOCK,
        payload: { id, fromIndex, toIndex }
    };
}
