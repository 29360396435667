import { createContentBlockDraft } from './contentBlockDraftActions';
import uuidv4 from 'uuid/v4';
export const CREATE_QUESTION_BLOCK_DRAFT = 'CREATE_QUESTION_BLOCK_DRAFT';
export const EDIT_QUESTION_BLOCK_DRAFT = 'EDIT_QUESTION_BLOCK_DRAFT';
export const DELETE_QUESTION_BLOCK_DRAFT = 'DELETE_QUESTION_BLOCK_DRAFT';

export const EDIT_ANSWER_LIST_BLOCK_DRAFT = 'EDIT_ANSWER_LIST_BLOCK_DRAFT';
export const EDIT_ANSWER_LIST_ITEM_BLOCK_DRAFT = 'EDIT_ANSWER_LIST_ITEM_BLOCK_DRAFT';
export const DELETE_ANSWER_LIST_ITEM_BLOCK_DRAFT = 'DELETE_ANSWER_LIST_ITEM_BLOCK_DRAFT';
export const MOVE_ANSWER_LIST_ITEM_BLOCK_DRAFT = 'MOVE_ANSWER_LIST_ITEM_BLOCK_DRAFT';
export const ADD_ANSWER_LIST_ITEM_BLOCK_DRAFT = 'ADD_ANSWER_LIST_ITEM_BLOCK_DRAFT';

export function initQuestionBlockDraft(data) {
    const blockData = data.blocks.map(block => ({ id: uuidv4(), ...block }));

    if (data.blocks.length === 0 && data.title) {
        blockData.push({ id: uuidv4() });
    }

    const draftData = {
        id: uuidv4(),
        ...data,
        possibleAnswers: [].concat(data.possibleAnswers).map((answer, i) => ({
            ...answer,
            correct: [].concat(data.correctAnswers).indexOf(i) >= 0
        })),
        blocks: blockData.map(block => block.id)
    };

    return (dispatch, getState) => {
        blockData.forEach(block => {
            const draftBlock = { title: '', subtitle: '', ...block };
            if (draftBlock.type === 'photosBlock') {
                if (!draftBlock.photosBlock) {
                    draftBlock.photosBlock = { photos: [] };
                }
            }

            dispatch(createContentBlockDraft(draftBlock));
        });
        dispatch(createQuestionBlockDraft(draftData));
    };
}

export function createQuestionBlockDraft(data) {
    return {
        type: CREATE_QUESTION_BLOCK_DRAFT,
        payload: { data }
    };
}

export function deleteQuestionBlockDraft(id) {
    return {
        type: DELETE_QUESTION_BLOCK_DRAFT,
        payload: { id }
    };
}

export function editQuestionBlockDraft(id, key, value) {
    return {
        type: EDIT_QUESTION_BLOCK_DRAFT,
        payload: { id, key, value }
    };
}

export function editAnswerListBlockDraft(id, key, value) {
    return {
        type: EDIT_ANSWER_LIST_BLOCK_DRAFT,
        payload: { id, key, value }
    };
}

export function editAnswerListItemBlockDraft(id, index, key, value) {
    return {
        type: EDIT_ANSWER_LIST_ITEM_BLOCK_DRAFT,
        payload: { id, index, key, value }
    };
}

export function deleteAnswerListItemBlockDraft(id, index) {
    return {
        type: DELETE_ANSWER_LIST_ITEM_BLOCK_DRAFT,
        payload: { id, index }
    };
}

export function moveAnswerListItemBlockDraft(id, fromIndex, toIndex) {
    return {
        type: MOVE_ANSWER_LIST_ITEM_BLOCK_DRAFT,
        payload: { id, fromIndex, toIndex }
    };
}

export function addAnswerListItemBlockDraft(id) {
    return {
        type: ADD_ANSWER_LIST_ITEM_BLOCK_DRAFT,
        payload: { id }
    };
}
