// @flow
import React from 'react';
import {
    AppBar,
    FormControl,
    FormControlLabel,
    IconButton,
    Step,
    StepLabel,
    Stepper,
    Switch,
    Toolbar,
    Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import {
    fetchEmployees,
    fetchInspectionAssigns,
    getAppInspections,
    sendInspectionResult,
} from '../../../actions';
import classes from './InspectionOpdrachtFinished.module.scss';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from 'connected-react-router';
import TitleDescription from '../../TitleDescription';
import TableActionHeader from '../../TableActionHeader';
import { inProgress } from '../../../selectors';
import LegacyThemeProvider from '../../LegacyThemeProvider';
import moment from 'moment';
import { isUndefinedOrNull } from '../../../lib/utils';
import { fetchInspectionDetail } from '../../../lib/api';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    toolboxId: string,
    inspection: Array<any>,
};

type State = {
    feedbackText: string,
    results: any,
    unsafe: boolean,
    weatherType: string,
    employees: any,
    location: any,
    photos: any,
};


const mapStateToProps = (store, props) => {
    const inspectionAssigns = store.entities.inspectionAssigns;
    const inspectionAssignId = props.match.params.toolboxId;

    let inspectionAssign;

    if (inspectionAssigns.byId) {
        inspectionAssign = inspectionAssigns.byId[inspectionAssignId];
    }

    return {
        loading: !inProgress(store),
        inspection:
            store.entities.appInspections &&
            store.entities.appInspections.byId[props.match.params.toolboxId],
        inspectionAssign: inspectionAssign,
        employees: store.entities.employees,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendResults: (result) => {
            dispatch(
                sendInspectionResult(ownProps.match.params.toolboxId, result)
            );
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppInspections: () => {
            dispatch(getAppInspections());
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
    };
};


class InspectionOpdrachtFinished extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        feedbackText: '',
        results: [],
        unsafe: false,
        weatherType: '',
        employees: [],
        location: { lat: 0.0, lon: 0.0 },
        photos: [],
    };

    componentWillMount() {
        this.props.fetchInspectionAssigns();
        this.props.getAppInspections();
        this.props.fetchEmployees();

        const inspectionAssignId = this.props.match.params.toolboxId;
        fetchInspectionDetail(inspectionAssignId)
            .then((entities) => {
                this.setState({
                    inspectionAssign: entities.body._embedded.inspectionAssign
                });
                this.props.setCrumbPath(entities.body._embedded.inspectionAssign);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    componentWillReceiveProps(newProps: Props) {
        if (newProps.inspection && this.state.inspection === undefined) {
            this.setState({
                inspection: newProps.inspection,
            });
        }
        if (
            newProps.inspectionAssign &&
            this.state.inspectionAssign === undefined
        ) {
            this.setState({
                inspectionAssign: newProps.inspection,
            });
        }
    }

    toSelfTest = () => {
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    backToOverview = () => {
        const searchQuery = new URLSearchParams(window.location.search).get('searchQuery');
        const query =
            !isUndefinedOrNull(searchQuery) && searchQuery.length > 0
                ? `?searchQuery=${searchQuery}`
                : '';
        this.props.goTo(`/web/afgerond${query}#werkplekinspecties`);
    };

    handlePageBack = () => {
        if (this.state.stepIndex === 0) {
            this.backToOverview();
        } else {
            this.setState({
                stepIndex: this.state.stepIndex - 1,
            });
        }
    };

    render() {
        const { loading, employees, t } = this.props;
        const { stepIndex, inspection, inspectionAssign } = this.state;

        const tableActionHeader = <TableActionHeader />;

        // window.$crisp.push(['do', 'chat:hide']);

        if (
            !inspection ||
            (inspection &&
                inspection.inspection.results &&
                inspection.inspection.results.complete === false)
        ) {
            return (
                <AppModule className="appModule" loading={loading}>
                    <div>
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt="Veilig Vakwerk"
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t('This workplace inspection is no longer available')}
                            </p>
                            <br />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.backToOverview}
                                    id="sendresultsButton"
                                    style={{
                                        margin: 'auto',
                                        marginBottom: '100px',
                                    }}
                                >
                                    {t('Back to the overview')}
                                </Button>
                            </div>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/facebook.png"
                                        alt="Veilig Vakwerk"
                                    />
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/twitter.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img
                                        src="/assets/images/linkedin.png"
                                        alt="Veilig Vakwerk"
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </AppModule>
            );
        }

        // Create a categories list en inject the results into the items
        // categoriesWithResults
        // categories[]
        //      .items[]
        //          .item
        //          .result
        let categoriesWithResults = Object;
        if (inspectionAssign && inspectionAssign.inspection.inspection.categories) {
            // Create copy of categories
            categoriesWithResults = JSON.parse(JSON.stringify(inspectionAssign.inspection.inspection.categories));
        }

        if (inspectionAssign && inspectionAssign.inspection && inspectionAssign.inspection.inspection.categories)
            inspectionAssign.inspection.inspection.categories.forEach((cat, catIndex) => {

            // Loop the items
            cat.items.forEach((item, itemIndex) => {

                // find corresponding result and add it.
                if (inspectionAssign.result) {
                    inspectionAssign.result.results.forEach((result, index, array) => {
                        if (item.id === result.id) {
                            categoriesWithResults[catIndex].items[itemIndex] = { item, result};
                        }
                    });
                }
            });
        });

        return (
            <div className="App">
                <AppBar position="fixed">
                    <Toolbar disableGutters={true}>
                        {stepIndex === 0 ||
                        stepIndex === 1 ||
                        stepIndex === 2 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            variant="h2"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig Vakwerk')}
                        </Typography>
                    </Toolbar>
                    <div className={classes.stepperWrapper}>
                        <div className={classes.stepper} id="stepper">
                            <LegacyThemeProvider>
                                <Stepper activeStep={stepIndex}>
                                    <Step>
                                        <StepLabel>{t('General')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Inspection')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Shut down')}</StepLabel>
                                    </Step>
                                </Stepper>
                            </LegacyThemeProvider>
                        </div>
                    </div>
                </AppBar>

                <AppModule
                    className="appModule"
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    {stepIndex === 0 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h2>{t('General information')}</h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Planned date')}
                                itemDescription={moment(
                                    inspection.deployed
                                ).format(t('dateTime'))}
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Rounded date')}
                                itemDescription={
                                    inspectionAssign
                                        ? moment(
                                              inspectionAssign
                                                  .result.date
                                          ).format(t('dateTime'))
                                        : '-'
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Project/work area')}
                                itemDescription={
                                    inspection.inspection.projectName
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Project number')}
                                itemDescription={
                                    inspection.inspection.projectNumber
                                }
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Description')}
                                itemDescription={
                                    inspectionAssign &&
                                    inspectionAssign.description
                                        ? inspectionAssign.description
                                        : '-'
                                }
                            />

                            <h2 style={{ marginTop: '64px' }}>
                                {t('Customer / activity')}
                            </h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Work type')}
                                itemDescription={
                                    inspectionAssign &&
                                    inspectionAssign.inspection
                                        ? inspectionAssign.inspection.activity
                                        : ''
                                }
                            />
                            <h2 style={{ marginTop: '64px' }}>{t('Staff')}</h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Medewerkers')}
                                itemDescription={employees.allIds
                                    .map((id) => employees.byId[id])
                                    .filter(
                                        (empl) =>
                                            inspectionAssign &&
                                            inspectionAssign.result.employees.includes(
                                                empl.id
                                            )
                                    )
                                    .map((employee) => (
                                        <div
                                            key={employee.id}
                                            value={employee.id}
                                        >
                                            {employee.fullname}
                                        </div>
                                    ))}
                            />
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Other attendees')}
                                itemDescription={
                                    inspectionAssign &&
                                    inspectionAssign.inspection.results &&
                                    inspectionAssign.inspection.results
                                        .externalEmployees
                                        ? inspectionAssign.inspection.results
                                              .externalEmployees
                                        : '-'
                                }
                            />
                            <h2 style={{ marginTop: '64px' }}>{t('Others')}</h2>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Weather condition')}
                            />
                            <div
                                style={{ width: '350px', marginBottom: '50px' }}
                            >
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        style={
                                            inspectionAssign &&
                                            inspectionAssign.result
                                                .weatherType === 'sunny'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      backgroundImage: 'url("/assets/images/cat_zon_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      backgroundImage: 'url("/assets/images/cat_zon.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                    <div
                                        style={
                                            inspectionAssign &&
                                            inspectionAssign.result
                                                .weatherType === 'rain'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_bewolkt_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_bewolkt.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                    <div
                                        style={
                                            inspectionAssign &&
                                            inspectionAssign.result
                                                .weatherType === 'freezingRain'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_sneeuw_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_sneeuw.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginTop: '25px',
                                    }}
                                    id="weatherlist"
                                >
                                    <div
                                        style={
                                            inspectionAssign && inspectionAssign.result.weatherType === 'storm'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      backgroundImage: 'url("/assets/images/cat_wind_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      backgroundImage: 'url("/assets/images/cat_wind.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                    <div
                                        style={
                                            inspectionAssign && inspectionAssign.result.weatherType === 'thunderstorm'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_onweer_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_onweer.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                    <div
                                        style={
                                            inspectionAssign && inspectionAssign.result.weatherType === 'snow'
                                                ? {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: '#272D35',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_sneeuwvlok_activated.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                                : {
                                                      width: '100px',
                                                      height: '80px',
                                                      borderRadius: '6px',
                                                      backgroundColor: 'rgba(216,216,216,0.3)',
                                                      float: 'left',
                                                      marginLeft: '25px',
                                                      backgroundImage: 'url("/assets/images/cat_sneeuwvlok.png")',
                                                      backgroundSize: '64px',
                                                      backgroundRepeat: 'no-repeat',
                                                      backgroundPosition: 'center',
                                                  }
                                        }
                                        className="weatherblock"
                                    />
                                </div>
                            </div>
                            <hr />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toSelfTest}
                            >
                                {t('To inspection results')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 1 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperMain"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            {categoriesWithResults.map((cat) => (
                                    <div>
                                        <div
                                            style={{
                                                marginBottom: '10px',
                                                marginTop: '40px',
                                            }}
                                        >
                                            {cat.title}
                                        </div>{' '}
                                        {cat.items.map((item, index) => (

                                            item.item.openQuestion
                                                ? (

                                                        <div style={{ marginTop: '40px' }}>
                                                            <TitleDescription
                                                                className={classes.item}
                                                                itemTitle={item.item.title}
                                                                itemDescription={item.result.openAnswer
                                                                        ? item.result.openAnswer
                                                                        : '-'
                                                                }
                                                            />
                                                        </div>

                                                )
                                                : (
                                                        <div style={{ marginTop: '40px' }}>
                                                            <TitleDescription
                                                                className={classes.item}
                                                                itemTitle={item.item.title}
                                                            />


                                                            { item.item.openQuestion === false && item.item.photoQuestion === false &&
                                                                (

                                                                    <div style={{display: 'flex' }}>
                                                                <div
                                                                    style={
                                                                        item.result && item.result.result === 'ok'
                                                                            ? {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                                backgroundColor: '#272D35',
                                                                                color: 'white',
                                                                            }
                                                                            : {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                            }
                                                                    }
                                                                    id={classes.checklist}
                                                                >
                                                                    {t('Correct')}
                                                                </div>
                                                                <div
                                                                    style={
                                                                        item.result && item.result.result === 'nok'
                                                                            ? {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                                backgroundColor: '#272D35',
                                                                                color: 'white',
                                                                                marginLeft: '10px',
                                                                            }
                                                                            : {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                                marginLeft: '10px',
                                                                            }
                                                                    }
                                                                    id={classes.checklist}
                                                                >
                                                                    {t('Not correct')}
                                                                </div>
                                                                <div
                                                                    style={
                                                                        item.result && item.result.result === 'na'
                                                                            ? {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                                backgroundColor: '#272D35',
                                                                                color: 'white',
                                                                                marginLeft: '10px',
                                                                            }
                                                                            : {
                                                                                height: '32px',
                                                                                width: '87px',
                                                                                border: '0.9px solid #272D35',
                                                                                borderRadius: '8px',
                                                                                textAlign: 'center',
                                                                                lineHeight: '2',
                                                                                float: 'left',
                                                                                marginLeft: '10px',
                                                                            }
                                                                    }
                                                                    id={classes.checklist}
                                                                >
                                                                    {t('Does not apply')}
                                                                </div>
                                                            </div>

                                                            )}

                                                            <div
                                                                style={{
                                                                    marginTop: '20px',
                                                                    marginBottom: '20px',
                                                                }}
                                                            >



                                                            <div>
                                                                <FormControl
                                                                    className={classes.item}
                                                                >
                                                                    <TitleDescription
                                                                        className={ classes.item }
                                                                        itemTitle={t('Comment')}
                                                                        itemDescription={item.result.remark && item.result.remark.text
                                                                                ? item.result.remark.text
                                                                                : '-'
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                                <div
                                                                    style={{ display: 'inline-block', }}
                                                                >
                                                                    {item.result.remark &&
                                                                        item.result.remark.photos.map((photo) => (
                                                                                <img style={{
                                                                                    float: 'left',
                                                                                    marginRight: '10px',
                                                                                    marginTop: '10px',
                                                                                }}
                                                                                     src={ photo.url }
                                                                                     alt={ 'inspectie' }
                                                                                     width="128px"
                                                                                     height="128px"
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                            {item.result.remark &&
                                                                item.result.remark.unsafe === true && (
                                                                    <FormControl
                                                                        style={{opacity: '0.5', }}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Switch checked={ true } id="unsafe" />
                                                                            }
                                                                            label={t('Unsafe situation')}
                                                                        />
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </div>
                                                )

                                        ))}
                                    </div>
                                )
                            )}

                            <hr />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toSelfTest}
                            >
                                {t('Towards closure')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 2 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                            style={{
                                minWidth: '128px',
                                maxWidth: '768px',
                                margin: '0 auto',
                            }}
                        >
                            <h2 style={{ textAlign: 'center' }}>
                                {t('Close workplace inspection')}
                            </h2>
                            <p>
                                {t('This is the last page. Here you can find other information.')}
                            </p>
                            <br />
                            <p>{t('Other remark')}:</p>
                            {inspectionAssign.result.remarks.text
                                ? inspectionAssign.result.remarks.text
                                : '-'}
                            <br />
                            <div style={{ display: 'inline-block' }}>
                                {inspectionAssign && inspectionAssign.result.remarks.photos && inspectionAssign.result.remarks.photos.map((photo) => (
                                            <img
                                                style={{
                                                    float: 'left',
                                                    marginRight: '10px',
                                                    marginTop: '10px',
                                                }}
                                                src={photo.url}
                                                alt={t('Inspection')}
                                                width="128px"
                                                height="128px"
                                            />
                                        )
                                    )}
                            </div>
                            <br />
                            <FormControl style={{ opacity: '0.5' }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={inspectionAssign.result.remarks.unsafe}
                                            id="unsafeInspection"
                                        />
                                    }
                                    label={t('Unsafe situation')}
                                />
                            </FormControl>
                            <br />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.backToOverview}
                                disabled={this.state.finished}
                                id="sendresultsButton"
                                style={{ marginTop: '20px' }}
                            >
                                {t('Close inspection')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                </AppModule>
            </div>
        );
    }
}
export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(InspectionOpdrachtFinished);
