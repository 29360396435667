import { push } from 'connected-react-router';
import * as storage from '../lib/storage';
import * as api from '../lib/api';
import { showSnackbar } from './uiActions';
import { setEntity } from './entityActions';
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';

export function login(username, password, redirect) {
    console.log('LOGIN');
    return (dispatch) => {
        return api.create('token', { username, password }).then((json) => {
            storage.storeLogin({ token: json.access_token });
            dispatch(setLoginToken(json.access_token));
            dispatch(push(redirect ? redirect : '/'));
            return json;
        });
    };
}

export function loginWebclient(username, password, redirect) {
    return (dispatch) => {
        return api
            .createWebclient('token', { username, password })
            .then((json) => {
                storage.storeLogin({ token: json.access_token });
                dispatch(setLoginToken(json.access_token));
                dispatch(push(redirect ? redirect : '/web/opdrachten'));
                return json;
            });
    };
}

export function forgotPassword(email) {
    return (dispatch) => {
        return api.create('resetToken', { email });
    };
}

export function resetPassword(newPassword, token, userId) {
    return (dispatch) => {
        return api.patch(
            'user',
            { id: userId, plainPassword: newPassword },
            token
        );
    };
}

export function logout() {
    return (dispatch) => {
        let returnUrl = window.sessionStorage.getItem('secondToken')
            ? '/abonnees'
            : '/';
        storage.clearLogin();
        window.location.replace(returnUrl);
    };
}

export function logoutWebclient() {
    return (dispatch) => {
        storage.clearLogin();
        dispatch(setLoginToken(null));
        dispatch(push('/web/login'));
    };
}

export function setLoginToken(token) {
    return {
        type: SET_LOGIN_TOKEN,
        payload: { token },
    };
}

export function fetchWebclientHelp(t) {
    return (dispatch) => {
        api.fetchWebclientHelp()
            .then((entity) => {
                dispatch(setEntity('help', JSON.parse(entity.body)));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        t('Unknown error on fetching') + ': ' + error.message
                    )
                );
                throw error;
            });
    };
}
