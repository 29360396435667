export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_ENTITY = 'SET_ENTITY';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';

export const PATCH_ENTITY = 'PATCH_ENTITY';


export function setEntities(type, entities) {
    return {
        type: SET_ENTITIES,
        payload: {
            type,
            entities
        }
    }
}

export function setEntity(type, entity) {
    return {
        type: SET_ENTITY,
        payload: {
            type,
            entity
        }
    }
}

export function removeEntity(type, entity) {
    return {
        type: REMOVE_ENTITY,
        payload: {
            type,
            entity
        }
    }
}

export function patchEntity(type, data) {
    return {
        type: PATCH_ENTITY,
        payload: {
            type,
            data
        }
    }
}
