// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Loader from '../Loader';
import { closeDialog } from '../../actions';
import { getForm } from '../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './DialogIE.module.scss';

type Props = {
    open: boolean,
};

const mapStateToProps = (store) => {
    return {
        ...getForm('DialogIE')(store),
        open: store.ui.dialogs.DialogIE.open,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(closeDialog('DialogIE'));
        },
    };
};

class DialogIE extends React.Component {
    props: Props;

    handleRequestClose = (event) => {
        sessionStorage.setItem('IEDialogOpened', true);
        this.props.onClose();
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const { open, processing, t } = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('Internet Explorer')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Loader size={100} loading={processing}>
                        {t(
                            'This application is not optimized for Inernet Explorer'
                        )}
                        <p></p>
                        <center>
                            <a
                                href="https://www.google.com/intl/nl_ALL/chrome/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/8/87/Google_Chrome_icon_%282011%29.png"
                                    alt="chrome"
                                    height="70px"
                                ></img>
                            </a>
                            <a
                                href="https://www.mozilla.org/nl/firefox/new/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    style={{ marginLeft: '50px' }}
                                    src="https://upload.wikimedia.org/wikipedia/commons/d/d2/Firefox_Logo%2C_2017.png"
                                    alt="firefox"
                                    height="70px"
                                ></img>
                            </a>
                        </center>
                        <a
                            href="https://www.google.com/intl/nl_ALL/chrome/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span
                                style={{
                                    marginLeft: '112px',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('Chrome')}
                            </span>
                        </a>
                        <a
                            href="https://www.mozilla.org/nl/firefox/new/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span
                                style={{
                                    marginLeft: '68px',
                                    textDecoration: 'underline',
                                }}
                            >
                                {t('Firefox')}
                            </span>
                        </a>
                    </Loader>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRequestClose}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

/*
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(DialogIE);
