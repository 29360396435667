// @flow
import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { percentColors3 } from '../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    title: string,
    groups: string,
    toolboxAmount: number,
    toolboxMeetingAmount: number,
    inspectionAmount: number,
    totalCompleted: number,
};

const StatisticsRowEmployees = (props: Props, context: Object) => {
    let {
        title,
        groups,
        toolboxAmount,
        toolboxMeetingAmount,
        inspectionAmount,
        inspectionCompleted,
        toolboxCompleted,
        totalCompleted,
        handleRowClick,
        t,
        ...childProps
    } = props;
    let total = inspectionAmount + toolboxAmount;

    return (
        <TableRow
            {...childProps}
            className="selectable"
            onClick={handleRowClick}
        >
            <TableCell style={{ width: '32%', wordBreak: 'break-all' }}>
                {title || '-'}
            </TableCell>
            <TableCell style={{ width: '20%' }}>{groups}</TableCell>
            <TableCell style={{ width: '8%' }}>{toolboxAmount}</TableCell>
            <TableCell style={{ width: '8%' }}>
                {toolboxMeetingAmount}
            </TableCell>
            <TableCell style={{ width: '8%' }}>{inspectionAmount}</TableCell>
            <TableCell style={{ width: '8%' }}>{inspectionCompleted}</TableCell>
            <TableCell style={{ width: '8%' }}>{toolboxCompleted}</TableCell>
            <TableCell style={{ width: '8%' }}>
                {' '}
                <div
                    className={'tooltip'}
                    style={{ position: 'relative', display: 'inline-block' }}
                >
                    <span className={'tooltiptext'} style={{ left: '-40px' }}>
                        {totalCompleted +
                            t(' of ') +
                            total +
                            ' ' +
                            t(
                                'toolboxes and workplace inspections have been completed'
                            )}
                    </span>
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor:
                                total !== 0
                                    ? getColorForPercentage(
                                          totalCompleted / total
                                      )
                                    : getColorForPercentage(1),
                            borderColor:
                                total !== 0
                                    ? getColorForPercentage(
                                          totalCompleted / total
                                      )
                                    : getColorForPercentage(1),
                            width: '60px',
                        }}
                    >
                        {totalCompleted + '/' + total}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};

var percentColors = percentColors3;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(withTranslation())(StatisticsRowEmployees);
