// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Dialog,
    DialogContent,
    Toolbar,
    Typography,
    AppBar,
    Button,
    IconButton,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Loader from '../../Loader';
import { inProgress } from '../../../selectors';
import { bindActionCreators } from 'redux';
import { editPaymentInfo } from '../../../actions/subscriptionActions';
import { TSubscription } from '../../../reducers/subscriptionReducer';
import TextField from '@mui/material/TextField';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './PaymentDialog.module.scss'

type Props = {
    loading: boolean,
    onUpgraded: () => mixed,
    editPaymentInfo: (abonnee: TSubscription) => boolean,
};

type State = {
    warningDialogOpen: boolean,
    subscription: any,
    menuOpen: boolean,
    dialogUpgraded: boolean,
};

const mapStateToProps = (store, props) => {

    return {
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        editPaymentInfo: bindActionCreators(editPaymentInfo, dispatch),
        onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);
            dispatch(push('/abonnement'));
        },
    };
};


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class PaymentInfoEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        warningDialogOpen: false,
        subscription: this.props.subscription,
        iban:
            this.props.subscription.iban === null
                ? ''
                : this.props.subscription.iban,
        ibanName:
            this.props.subscription.bic === null
                ? ''
                : this.props.subscription.bic,
        permission: false,
        containsStars: false,
        saveButtonDisabled: true,
        updated: false,
    };

    handleValidation = () => {
        const { iban, ibanName, permission } = this.state;
        if (iban != null && ibanName != null) {
            console.log(iban, 'contains *:', iban.indexOf('*') > -1);
            if (iban.length >= 18 && ibanName.length >= 3 && permission) {
                if (!(iban.indexOf('*') > -1)) {
                    this.setState({ saveButtonDisabled: false });
                }
            } else {
                this.setState({ saveButtonDisabled: true });
            }
        }
    };

    requestWarningDialogClose = () => {
        this.setState({
            subscription: {
                ...this.state.subscription,
                vv_functions: false,
            },
        });

        this.setState({
            warningDialogOpen: false,
        });
    };

    handleClose = (event) => {
        this.props.onClose();
        if (this.props.subscription.vv_functions) {
            this.setState({
                subscription: {
                    ...this.props.subscription,
                    price: this.state.defaultPrice,
                },
            });
        }
    };

    handleIbanChange = (event) => {
        console.log(this.props.subscription);
        this.setState({ iban: event.target.value });
        this.setState(
            {
                subscription: {
                    ...this.state.subscription,
                    iban: event.target.value,
                },
            },
            () => this.handleValidation()
        );
    };

    handleUpgradeDialog = (event) => {
        this.setState({
            subscription: {
                ...this.state.subscription,
                iban: this.state.iban,
                bic: this.state.ibanName,
            },
            dialogUpgraded: false,
        });
        this.props.editPaymentInfo(this.state.subscription);
        // this.setState({upgraded:true})
    };

    handleIbanNameChange = (event) => {
        this.setState({ ibanName: event.target.value });
        this.setState(
            {
                subscription: {
                    ...this.state.subscription,
                    bic: event.target.value,
                },
            },
            () => this.handleValidation()
        );
    };

    handlePermissionChage = (event) => {
        this.setState({ permission: event.target.checked }, () =>
            this.handleValidation()
        );
    };
    render() {
        const { open, loading, t } = this.props;

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar   className={classes.appBar}>
                    <Toolbar disableGutters>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Change payment details')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.content}>
                    <Loader loading={loading}>
                        <div className={classes.abonnementContent}>
                            <div className={classes.abonnementInfoContent}>
                                <h2 style={{ fontSize: '24px' }}>
                                    {t('Payment details')}
                                </h2>
                                <p
                                    style={{
                                        opacity: '0.54',
                                        marginBottom: '10px',
                                        lineHeight: '24px',
                                    }}
                                >
                                    {t(
                                        'Change your SEPA direct debit information.'
                                    )}
                                </p>
                                {!this.props.subscription.trial &&
                                    !this.props.subscription.stripeId && (
                                        <p
                                            style={{
                                                opacity: '0.54',
                                                marginBottom: '10px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t('subscription payment info')}
                                        </p>
                                    )}

                                {this.props.subscription.trial &&
                                    !this.props.subscription.stripeId && (
                                        <p
                                            style={{
                                                opacity: '0.54',
                                                marginBottom: '10px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t(
                                                'trial subscription payment info'
                                            )}
                                        </p>
                                    )}
                                <TextField
                                    id="iban"
                                    className={classes.achternaam}
                                    label={t('IBAN')}
                                    required
                                    value={this.state.iban}
                                    onChange={this.handleIbanChange}
                                />
                                <TextField
                                    id="ibanName"
                                    className={classes.achternaam}
                                    label={t('Account in the name of')}
                                    required
                                    value={this.state.ibanName}
                                    onChange={this.handleIbanNameChange}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.permission}
                                            onChange={
                                                this.handlePermissionChage
                                            }
                                            value="permission"
                                            color="primary"
                                        />
                                    }
                                    style={{
                                        opacity: '0.8',
                                        marginBottom: '20px',
                                        marginTop: '40px',
                                        lineHeight: '24px',
                                    }}
                                    label={t('I authorize')}
                                />

                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.upgradeButton}
                                    onClick={this.handleUpgradeDialog}
                                    disabled={this.state.saveButtonDisabled}
                                >
                                    {t('Save')}
                                </Button>
                            </div>
                        </div>
                    </Loader>
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(PaymentInfoEdit);
