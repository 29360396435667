// @flow

import { connect } from 'react-redux';
import React from 'react';
import { setCrumbPath } from '../../../actions';
import InspectionDetailView from '../../Inspection/InspectionDetailView';
import ToolboxResultsViewStats from "../../Toolbox/ToolboxResultsView/ToolboxResultsViewStats";

type Props = {};

let employerId;
let employee;

const mapStateToProps = (state, props) => {
    const inspectionId = props.match.params.inspectionId;
    employerId = props.match.params.employerId;
    employee = state.entities.employees.byId[employerId];
    const inspectionAssign =
        state.entities.inspectionAssigns.byId[inspectionId];
    return {
        inspectionAssign,
        inspection: inspectionAssign ? inspectionAssign.inspection : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: (item) => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        {
                            name:
                                'Medewerker: ' + employee &&
                                employee !== undefined &&
                                'Medewerker: ' + employee.fullname,
                            link: '/rapportages#medewerkers',
                        },
                        item.toolbox
                            ? {
                                  name: 'Toolbox: ' + item.toolbox.title,
                                  link: '/rapportages/medewerker/' + employerId,
                              }
                            : {
                                  name:
                                      'Werkplekinspectie: ' +
                                      (item.inspection
                                          ? item.inspection.title
                                          : ''),
                                  link: '/rapportages/medewerker/' + employerId,
                              },
                    ],
                })
            );
        },
    };
};

class StatisticsEmployeeDetailView extends React.Component<Props, void> {
    props: Props;

    componentDidMount() {
        if (
            this.props.inspectionAssign &&
            this.props.inspectionAssign.inspection
        ) {
            setCrumbPath(this.props.inspectionAssign);
        }
    }

    componentWillReceiveProps(newProps: Props) {
        if (
            this.props.inspectionAssign !== newProps.inspectionAssign &&
            newProps.inspectionAssign
        ) {
            this.props.setCrumbPath(newProps.inspectionAssign);
        }
    }

    render() {
        if (this.props.inspection === null) {
            return <ToolboxResultsViewStats {...this.props} />;
        } else {
            return <InspectionDetailView {...this.props} />;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsEmployeeDetailView);
