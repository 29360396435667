// @flow

import { connect } from 'react-redux';
import React from 'react';
import type { StoreState } from '../../../reducers/index';
import StatisticsInspectionDetailCard from '../StatisticsCard/StatisticsInspectionDetailCard';
import type { Dispatch as ReduxDispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
    clearAllFilters,
    filterPeriodMonth,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    setViewkey,
    setCrumbPath,
    filterPeriodFrom,
    filterPeriodTill,
    filterProject,
    filterUitvoerder,
    filterAfgerond,
    filterResultaat,
    filterSituatie, showSnackbar,
} from '../../../actions';
import { loadInspectionData } from '../../../actions/statisticsActions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import { getCurrentFilterSet } from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListInspectionAssign from '../StatisticsList/StatisticsListInspectionAssign';
import getFilteredInspectionAssigns from '../../../selectors/getFilteredInspectionAssigns';
import {fetchCSV, fetchInspectionDetailCollection, fetchPDF} from '../../../lib/api';
import DescIcon from '@mui/icons-material/Description';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/GetApp';
import {Fab, Menu, MenuItem} from '@mui/material';
import FilterHeader from '../../FilterHeader';
import $ from 'jquery';
import { List } from 'immutable';
import CloseIcon from '@mui/icons-material/Close';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {
    filterProject: Function,
    filterUitvoerder: Function,
    filterAfgerond: Function,
    filterResultaat: Function,
    filterSituatie: Function,
    projectFilter: String,
    uitvoerderFilter: number,
    afgerondFilter: number,
    resultaatFilter: number,
};

type State = {
    openProject: boolean,
    openUitvoerder: boolean,
    openAfgerond: boolean,
    openResultaat: boolean,
    anchorEl?: any,
    openExport: boolean,
};

const mapStateToProps = (state, props) => {
    const inspections = state.entities.inspections;
    let filters = getCurrentFilterSet(state.filters);

    const inspectionId = props.match.params.id;
    const inspection = state.entities.inspections.byId[inspectionId];

    let inspectionAssigns = getFilteredInspectionAssigns(state);
    inspectionAssigns = inspectionAssigns.map((insp) => {
        return {
            ...insp,
            inspection: {
                ...inspections.byId[insp.inspectionId],
            },
        };
    });

    if (inspectionAssigns && inspection) {
        inspectionAssigns = inspectionAssigns.filter((ia) => {
            return (
                (ia.inspection ? (ia.inspection.title + ia.inspection.version) || '' : '') ===
                (inspection ? (inspection.title + inspection.version) || '' : '')
            );
        });
    } else {
        inspectionAssigns = null;
    }

    return {
        inspection: inspection,
        inspectionAssigns: inspectionAssigns,
        chartData: state.statistics.chartData,
        listData: state.statistics.listData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        projectFilter: filters.project,
        uitvoerderFilter: filters.uitvoerder,
        afgerondFilter: filters.afgerond,
        resultaatFilter: filters.resultaat,
        situatieFilter: filters.situatie,
        projects: state.entities.projects,
        employees: state.entities.employees,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadChartData: bindActionCreators(loadInspectionData, dispatch),
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        filterProject: bindActionCreators(filterProject, dispatch),
        filterUitvoerder: bindActionCreators(filterUitvoerder, dispatch),
        filterAfgerond: bindActionCreators(filterAfgerond, dispatch),
        filterResultaat: bindActionCreators(filterResultaat, dispatch),
        filterSituatie: bindActionCreators(filterSituatie, dispatch),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (title) => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        {
                            name: 'Werkplekinspectie: ' + title,
                            link: '/rapportages#werkplekinspecties',
                        },
                    ],
                })
            );
        },
    };
};

let projects = [];
let projectFilterList = [];

class StatisticsInspectionView extends React.Component<Props, State> {
    props: Props;
    state: {
        openProject: false,
        anchorEl: 0,
        exportOpen: false,
    };

    componentDidMount() {
        //set view key
        this.props.setViewkey();

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        this.props.setCrumbPath(
            this.props.inspection
                ? this.props.inspection.title
                : 'Werkplekinspecties'
        );
        this.onFilterChanged();
    }

    componentWillReceiveProps(newProps: Props) {
        if (
            this.props.periodType !== newProps.periodType ||
            this.props.periodYear !== newProps.periodYear ||
            this.props.periodMonth !== newProps.periodMonth ||
            this.props.periodFrom !== newProps.periodFrom ||
            this.props.periodTill !== newProps.periodTill ||
            this.props.periodWeek !== newProps.periodWeek ||
            this.props.projectFilter !== newProps.projectFilter ||
            this.props.uitvoerderFilter !== newProps.uitvoerderFilter ||
            this.props.resultaatFilter !== newProps.resultaatFilter ||
            this.props.situatieFilter !== newProps.situatieFilter ||
            this.props.afgerondFilter !== newProps.afgerondFilter
        ) {
            this.onFilterChanged(newProps);
        }
        if (this.props.inspection !== newProps.inspection) {
            this.props.setCrumbPath(
                newProps.inspection
                    ? newProps.inspection.title
                    : 'Werkplekinspectie'
            );
            this.onFilterChanged(newProps);
        }
    }

    onFilterChanged = (props?: Props) => {
        const { t } = this.props
        if (!props) {
            props = this.props;
        }

        let path: string =
            this.props.location.pathname + '?year=' + props.periodYear;

        //Calculate period string
        let period = moment();
        let periodTill = '';

        period.year(props.periodYear);
        switch (props.periodType) {
            case 'month':
                period.month(props.periodMonth);
                path += '&month=' + (props.periodMonth + 1);
                break;
            case 'week':
                let locale: string =
                    window.navigator.userLanguage || window.navigator.language;
                period.week(props.periodWeek).locale(locale).weekday(0);
                path += '&week=' + props.periodWeek;
                break;
            case 'fromtill':
                //TODO: Format this
                //path = this.props.location.pathname + '?from=' + props.periodFrom + '&till=' + props.periodTill;
                if (props.periodFrom && props.periodTill) {
                    period = moment(props.periodFrom);
                    periodTill = moment(props.periodTill);
                }
                break;
            default:
                break;
        }

        if (props.periodType !== 'fromtill') {
            let periodString = period.format(t('yearMonthDay')) + 'T00:00:00.000Z';

            this.props.goTo(path);

            // execute chart api call
            if (props.inspection) {
                props.loadChartData(
                    props.periodType,
                    periodString,
                    null,
                    props.inspection.id,
                    this.props.projectFilter,
                    this.props.uitvoerderFilter,
                    this.props.afgerondFilter,
                    this.props.resultaatFilter,
                    this.props.situatieFilter
                );
            }
        } else if (period && periodTill) {
            let periodString = period.format(t('yearMonthDay')) + 'T00:00:00.000Z';
            let periodEnd = periodTill.format(t('yearMonthDay')) + 'T00:00:00.000Z';

            this.props.goTo(path);

            // execute chart api call
            if (props.inspection) {
                props.loadChartData(
                    props.periodType,
                    periodString,
                    periodEnd,
                    props.inspection.id,
                    this.props.projectFilter,
                    this.props.uitvoerderFilter,
                    this.props.afgerondFilter,
                    this.props.resultaatFilter,
                    this.props.situatieFilter
                );
            }
        }
    };

    onItemClicked = (clickedInspectionAssign) => {
        let target =
            '/rapportages/werkplekinspectie/details/' +
            clickedInspectionAssign.id +
            '?from=rapportages#resultaten';
        this.props.goTo(target);
    };

    getList = () => {
        return (
            <StatisticsListInspectionAssign
                year={this.props.periodYear}
                inspectionAssigns={this.props.inspectionAssigns}
                onItemClick={this.onItemClicked}
                searchtext={this.state ? this.state.searchtext : ''}
                project={this.props.projectFilter}
                uitvoerder={this.props.uitvoerderFilter}
                afgerond={this.props.afgerondFilter}
                resultaat={this.props.resultaatFilter}
                situatie={this.props.situatieFilter}
            />
        );
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openProject: false,
            openUitvoerder: false,
            openAfgerond: false,
            openResultaat: false,
            openSituatie: false,
        });
        this.setState(newState);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openProject: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openUitvoerder: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openAfgerond: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap4 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openResultaat: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap5 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSituatie: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleInspectionAfgerondFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ toolboxFilter: event.target.value });

        this.setState(
            { afgerondFilter: event.target.value },
            this.onFilterChanged
        );
        this.props.filterAfgerond(event.target.value);
    };

    handleInspectionProjectFilterChange = (event) => {
        this.handleRequestClose();

        let project = 0;
        if (event.target.value === -1) {
            project = 'all';
        } else {
            if (projectFilterList[event.target.value]) {
                project = projectFilterList[event.target.value].id;
            }
        }
        this.setState({ toolboxFilter: project });

        this.setState({ projectFilter: project }, this.onFilterChanged);
        this.props.filterProject(project);
    };

    handleInspectionUitvoerderFilterChange = (event) => {
        this.handleRequestClose();

        let employee = 0;
        if (event.target.value === -1) {
            employee = 0;
        } else {
            employee = event.target.value;
        }

        this.setState({ toolboxFilter: employee });

        this.setState({ uitvoerderFilter: employee }, this.onFilterChanged);
        this.props.filterUitvoerder(employee);
    };

    handleInspectionResultaatFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ toolboxFilter: event.target.value });

        this.setState(
            { resultaatFilter: event.target.value },
            this.onFilterChanged
        );
        this.props.filterResultaat(event.target.value);
    };

    handleInspectionSituatieFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ toolboxFilter: event.target.value });

        this.setState(
            { situatieFilter: event.target.value },
            this.onFilterChanged
        );
        this.props.filterSituatie(event.target.value);
    };

    getFilterBar = () => {
        const { t } = this.props;
        let projectIndex = 0;

        let userIds = [];
        let users = [];

        let projectIds = [];
        projects = [];
        projectFilterList = [];

        this.props.inspectionAssigns &&
            this.props.inspectionAssigns.forEach((assign) => {
                if (
                    assign &&
                    assign.user &&
                    !userIds.includes(assign.user.id)
                ) {
                    userIds.push(assign.user.id);
                }
                if (
                    assign &&
                    assign.project &&
                    !projectIds.includes(assign.project.id)
                ) {
                    projectIds.push(assign.project.id);
                }
                // Create projectFilterList based on the unique projects from the InspectionsAssign list
                let found = false;
                if (projectFilterList.length > 0) {
                    if (
                        projectFilterList.some(
                            (p) =>
                                p != null && p !== undefined &&
                                assign != null &&
                                assign.projectId != null &&
                                p.id === assign.projectId
                        )
                    ) {
                        found = true;
                    }
                }
                if (!found) {
                    let theProject = this.props.projects.byId[assign.projectId];
                    projectFilterList.push(theProject);
                }
            });

        userIds.forEach((user) => {
            if (this.props.employees.byId[user]) {
                users.push(this.props.employees.byId[user]);
            }
        });

        projectIds.forEach((projectId) => {
            if (
                this.props.projects.byId[projectId] &&
                projectId !== '999InvullenTijdenInspectie'
            ) {
                projects.push(this.props.projects.byId[projectId]);
            }
        });

        if (this.props.projects.byId['999InvullenTijdenInspectie']) {
            projects.push(
                this.props.projects.byId['999InvullenTijdenInspectie']
            );
        }

        // Set current filter
        let projectFilterLocale = t('No project');
        if (this.props.projectFilter === 'all') {
            projectFilterLocale = 'Alles';
        } else {
            let searchResult = projectFilterList.find(
                (p) => p.id === this.props.projectFilter
            );
            if (searchResult && searchResult.omschrijving.length > 0) {
                projectFilterLocale = searchResult.omschrijving;
            } else {
                projectFilterLocale = t('No project');
            }
        }
        //                         <FilterHeader filterTitle={"Project/werkgebied"} filterValue={this.props.projectFilter === 'all' ? 'Alles' : this.props.projects.byId[this.props.projectFilter].omschrijving.length > 0 ? this.props.projects.byId[this.props.projectFilter].omschrijving : 'Geen project'} />

        return (
            <div className="filterbar" style={{ paddingRight: '500px' }}>
                <PeriodFilterBlock />

                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={t('Project/work area')}
                            filterValue={projectFilterLocale}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openProject : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.props.projectFilter ? this.props.projectFilter : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleInspectionProjectFilterChange}
                        value={-1}
                    >
                        Alles
                    </MenuItem>
                    {projectFilterList &&
                        projectFilterList.map((project, index) => {
                            return (
                                <MenuItem
                                    onClick={
                                        this.handleInspectionProjectFilterChange
                                    }
                                    value={projectIndex++}
                                >
                                    {project != null &&
                                    project.omschrijving != null &&
                                    project.omschrijving.length > 0
                                        ? project.omschrijving
                                        : t('No project')}
                                </MenuItem>
                            );
                        })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={t('Performer')}
                            filterValue={
                                this.props.uitvoerderFilter === 0
                                    ? t('Everything')
                                    : this.props.employees.byId[
                                          this.props.uitvoerderFilter
                                      ].fullname
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openUitvoerder : false}
                    onClose={this.handleRequestClose}
                    value={this.props.uitvoerderFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem
                        onClick={this.handleInspectionUitvoerderFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {users &&
                        users.map((employee) => {
                            return (
                                <MenuItem
                                    onClick={
                                        this
                                            .handleInspectionUitvoerderFilterChange
                                    }
                                    value={employee.id}
                                >
                                    {employee.fullname}
                                </MenuItem>
                            );
                        })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={'Afgerond'}
                            filterValue={
                                this.props.afgerondFilter === 0
                                    ? t('Everything')
                                    : this.props.afgerondFilter === 1
                                    ? t('Yes')
                                    : t('No')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openAfgerond : false}
                    onClose={this.handleRequestClose}
                    value={this.props.afgerondFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem
                        style={{ width: '130px' }}
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={1}
                    >
                        {t('Yes')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={2}
                    >
                        {t('No')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap4}>
                        <FilterHeader
                            filterTitle={t('Result')}
                            filterValue={
                                this.props.resultaatFilter === 0
                                    ? t('Everything')
                                    : this.props.resultaatFilter === 1
                                    ? t('Correct')
                                    : t('Not correct')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openResultaat : false}
                    onClose={this.handleRequestClose}
                    value={this.props.resultaatFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem
                        style={{ width: '130px' }}
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={1}
                    >
                        {t('Correct')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={2}
                    >
                        {t('Not correct')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap5}>
                        <FilterHeader
                            filterTitle={t('Situation')}
                            filterValue={
                                this.props.situatieFilter === 0
                                    ? t('Everything')
                                    : this.props.situatieFilter === 1
                                    ? t('Is save')
                                    : t('Unsafe')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSituatie : false}
                    onClose={this.handleRequestClose}
                    value={this.props.resultaatFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem
                        style={{ width: '130px' }}
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={1}
                    >
                        {t('Is save')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={2}
                    >
                        {t('Unsafe')}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    getFilteredData = () => {
        let filteredinspectionAssigns = [];

        this.props.inspectionAssigns &&
        this.props.inspectionAssigns.forEach((assign) => {
            let add = true;
            if (
                this.props.situatieFilter &&
                this.props.situatieFilter !== 0
            ) {
                let found = false;
                if (
                    this.props.situatieFilter === 1 &&
                    assign.result &&
                    !assign.result.unsafe
                ) {
                    found = true;
                    assign.result.results.forEach((result) => {
                        if (result.remark.unsafe) {
                            found = false;
                        }
                    });
                } else if (
                    this.props.situatieFilter === 2 &&
                    assign.result &&
                    assign.result.unsafe
                ) {
                    found = true;
                } else if (
                    this.props.situatieFilter === 2 &&
                    assign.result
                ) {
                    assign.result.results.forEach((result) => {
                        if (result.unsafe) {
                            found = true;
                        }
                    });
                }
                if (!found) {
                    add = false;
                }
            }

            if (add) {
                filteredinspectionAssigns.push(assign);
            }
        });

        filteredinspectionAssigns = List(
            filteredinspectionAssigns
                ? filteredinspectionAssigns
                    .filter((inspection) =>
                        this.state && this.state.searchtext
                            ? inspection.user.shortname
                            .replace('  ', ' ')
                            .toLowerCase()
                            .indexOf(
                                this.state.searchtext.toLowerCase()
                            ) > -1
                            : true
                    )
                    .filter((inspection) =>
                        this.props.projectFilter &&
                        this.props.projectFilter !== 'all'
                            ? inspection.project.id ===
                            this.props.projectFilter
                            : true
                    )
                    .filter((inspection) =>
                        this.props.uitvoerderFilter &&
                        this.props.uitvoerderFilter !== 0
                            ? inspection.user.id ===
                            this.props.uitvoerderFilter
                            : true
                    )
                    .filter((inspection) =>
                        this.props.afgerondFilter &&
                        this.props.afgerondFilter !== 0
                            ? this.props.afgerondFilter === 1
                                ? inspection.result &&
                                inspection.result.complete
                                : !inspection.result ||
                                !inspection.result.complete
                            : true
                    )
                    .filter((inspection) =>
                        this.props.resultaatFilter &&
                        this.props.resultaatFilter !== 0
                            ? this.props.resultaatFilter === 1
                                ? inspection.result &&
                                inspection.result.allCorrect
                                : inspection.result &&
                                !inspection.result.allCorrect
                            : true
                    )
                : []
        );

        let data = [];

        filteredinspectionAssigns.forEach((item) => {
            data.push({
                deployed: item.deployed,
                project: {
                    externalId: item.project ? item.project.externalId : null,
                    omschrijving: item.project
                        ? item.project.omschrijving
                        : null,
                    opdrachtgever: item.project
                        ? item.project.opdrachtgever
                        : null,
                },
                inspection: {
                    version: item.inspection.version,
                    title: item.inspection.title,
                },
                result: item.result
                    ? {
                        allCorrect: item.result.allCorrect,
                        date: item.result.date,
                    }
                    : null,
                activity: item.activity,
                user: {
                    fullname: item.user.shortname ? item.user.shortname : ' - ',
                },
                kamResolved: item.kamResolved ? this.props.t('Yes') : this.props.t('No'),
                kamResolvedBool : item.kamResolved,
            });
        });

        return data;
    }

    getFilteredCollectionData = () => {
        console.log('XXX getFilteredCollectionData');
        let filteredinspectionAssigns = [];

        this.props.inspectionAssigns &&
        this.props.inspectionAssigns.forEach((assign) => {
            let add = true;
            if (
                this.props.situatieFilter &&
                this.props.situatieFilter !== 0
            ) {
                let found = false;
                if (
                    this.props.situatieFilter === 1 &&
                    assign.result &&
                    !assign.result.unsafe
                ) {
                    found = true;
                    assign.result.results.forEach((result) => {
                        if (result.remark.unsafe) {
                            found = false;
                        }
                    });
                } else if (
                    this.props.situatieFilter === 2 &&
                    assign.result &&
                    assign.result.unsafe
                ) {
                    found = true;
                } else if (
                    this.props.situatieFilter === 2 &&
                    assign.result
                ) {
                    assign.result.results.forEach((result) => {
                        if (result.unsafe) {
                            found = true;
                        }
                    });
                }
                if (!found) {
                    add = false;
                }
            }

            if (add) {
                filteredinspectionAssigns.push(assign);
            }
        });

        filteredinspectionAssigns = List(
            filteredinspectionAssigns
                ? filteredinspectionAssigns
                    .filter((inspection) =>
                        this.state && this.state.searchtext
                            ? inspection.user.shortname
                            .replace('  ', ' ')
                            .toLowerCase()
                            .indexOf(
                                this.state.searchtext.toLowerCase()
                            ) > -1
                            : true
                    )
                    .filter((inspection) =>
                        this.props.projectFilter &&
                        this.props.projectFilter !== 'all'
                            ? inspection.project.id ===
                            this.props.projectFilter
                            : true
                    )
                    .filter((inspection) =>
                        this.props.uitvoerderFilter &&
                        this.props.uitvoerderFilter !== 0
                            ? inspection.user.id ===
                            this.props.uitvoerderFilter
                            : true
                    )
                    .filter((inspection) =>
                        this.props.afgerondFilter &&
                        this.props.afgerondFilter !== 0
                            ? this.props.afgerondFilter === 1
                                ? inspection.result &&
                                inspection.result.complete
                                : !inspection.result ||
                                !inspection.result.complete
                            : true
                    )
                    .filter((inspection) =>
                        this.props.resultaatFilter &&
                        this.props.resultaatFilter !== 0
                            ? this.props.resultaatFilter === 1
                                ? inspection.result &&
                                inspection.result.allCorrect
                                : inspection.result &&
                                !inspection.result.allCorrect
                            : true
                    )
                : []
        );

        let data = [];
        filteredinspectionAssigns.forEach((inspection) => {
            data.push(inspection.id);
        })

        return data;
    }

    downloadCsv = () => {
        let data = this.getFilteredData();

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTIONASSIGN_RAPPORTAGE',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute(
                    'download',
                    this.props.inspection.title + '.csv'
                );
                link.click();
            })
            .catch((err) => console.log(err));
    };



    getPDF = () => {
        let data = this.getFilteredData();

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTIONASSIGN_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                chartData: this.props.chartData,
                periodType: this.props.periodType,
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    'werkplekinspectierapportage_' +
                        this.props.inspectionAssigns[0].inspection.title +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    getDetailPDF = () => {
        let data = this.getFilteredCollectionData();

        fetchInspectionDetailCollection(data)
            .then((entities) => {
                let ia = entities.body._embedded.inspectionAssign;
                let items = this.props.inspection.inspection.categories;

                fetchPDF({
                    platform: 'VV',
                    customer: 'VV',
                    template: 'INSPECTIONASSIGN_DETAIL_COLLECTION_RAPPORTAGE',
                    options: {},
                    data: items,
                    info: {
                        chartData: this.props.chartData,
                        periodType: this.props.periodType,
                        periodYear: this.props.periodYear,
                        periodMonth: this.props.periodMonth,
                        periodWeek: this.props.periodWeek,
                        periodFrom: moment(this.props.periodFrom).format('LL'),
                        periodTill: moment(this.props.periodTill).format('LL'),
                        inspection: this.props.inspection,
                        ia: ia,
                        showResults: true,
                    },
                })
                    .then((res) => {
                        var encodedUri = window.URL.createObjectURL(res.body);
                        var link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute(
                            'download',
                            'werkplekinspectierapportage_' +
                            this.props.inspectionAssigns[0].inspection.title +
                            '.pdf'
                        );
                        document.body.appendChild(link);
                        link.click(); //
                    })
                    .catch((err) => console.log(err));
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching fetchInspectionDetailCollection'
                    )}: ` + error.message
                )
                throw error;
            });


    };


    render() {
        const { t } = this.props;
        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                widemode={true}
                searchPlaceholder={t('Search workplace inspections')}
            />
        );

        return (
            <div className="Statistics">
                <AppModule loading={false} prepend={tableActionHeader}>
                    <StatisticsInspectionDetailCard
                        inspectionBarData={
                            this.props.chartData
                                ? this.props.chartData.correctnessBarData
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList()}
                        totalInspectionsIncorrect={
                            this.props.chartData
                                ? this.props.chartData.totalInspectionsIncorrect
                                : 0
                        }
                        totalInspectionsCorrect={
                            this.props.chartData
                                ? this.props.chartData.totalInspectionsCorrect
                                : 0
                        }
                    />
                </AppModule>
                <div
                    style={{
                        position: 'fixed',
                        top: '32px',
                        right: '36px',
                        zIndex: 1500,
                    }}
                >
                    <div>
                        <Fab   onClick={this.toggleExport}>
                            {this.state && this.state.exportOpen ? (
                                <CloseIcon />
                            ) : (
                                <DownloadIcon />
                            )}
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                    marginTop: '20px',
                                    marginLeft: '8px',
                                    visibility: 'visible',
                                    transition: 'margin-top 300ms',
                                }
                                : {
                                    marginTop: '0px',
                                    marginLeft: '8px',
                                    visibility: 'hidden',
                                    transition: 'margin-top 300ms',
                                }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '94px', right: '60px' }}
                        >
                            {t('Download Verzamel Detail PDF')}
                        </span>
                        <Fab color="primary"  onClick={this.getDetailPDF}>
                            <InsertIcon />
                        </Fab>
                    </div>

                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '20px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '170px', right: '60px' }}
                        >
                            {t('Download PDF bestand')}
                        </span>
                        <Fab color="primary"  onClick={this.getPDF}>
                            <InsertIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '240px', right: '60px' }}
                        >
                            {t('Download CSV bestand')}
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={this.downloadCsv}
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsInspectionView);
