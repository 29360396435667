// @flow
import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from '../../../lib/moment';
import { push } from 'connected-react-router';
import EnhancedTable from '../../EnhancedTable';
import { deleteProject } from '../../../actions';
import ConfirmDialog from '../../ConfirmDialog';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";

type Props = {
    onItemClick: (number, number, number) => mixed,
    inspectionsAssignsByProject: [],
    projects: Array<any>,
    deleteProject: Function,
};

type State = {
    page: number,
    rowsPerPage: number,
    startPage: number,
    startRowsPerPage: number,
    confirmDialogOpen: boolean,
    projectToDelete: String,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        editProject: (id) => {
            dispatch(push('/projecten/' + id));
        },
        deleteProject: (Id) => {
            dispatch(deleteProject(Id));
        },
    };
};

class ProjectsListOverview extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        page: 0,
        rowsPerPage: 10,

        actions: [
            { id: 'edit', label: 'Wijzigen' },
            { id: 'delete', label: 'Verwijderen' },
        ],
        columns: [
            { id: 'externalId', numeric: false, size: '10%', label: 'Nr' },
            {
                id: 'omschrijving',
                numeric: false,
                size: '30%',
                label: 'Project / werkgebied',
            },
            {
                id: 'opdrachtgever',
                numeric: false,
                size: '25%',
                label: 'klant',
            },
            {
                id: 'lastSent',
                numeric: false,
                size: '15%',
                label: 'Laatst verstuurd',
            },
            {
                id: 'totalWPIs',
                numeric: false,
                size: '10%',
                label: "Aantal WPI's",
            },
            {
                id: 'openWPIs',
                numeric: false,
                size: '10%',
                label: 'Openstaand',
            },
        ],
        confirmDialogOpen: false,
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            actions: [
                { id: 'edit', label: 'Change' },
                { id: 'delete', label: 'Delete' },
            ],
            columns: [
                { id: 'externalId', numeric: false, size: '10%', label: 'Nr' },
                {
                    id: 'omschrijving',
                    numeric: false,
                    size: '85%',
                    label: 'Project/work area',
                }
            ],
        });

    }

    onRowClick = (project) => {
        console.log('INFO: ProjectsListOverview onRowClick: page: ' + this.props.startPage);
        this.props.onItemClick(project, this.props.startPage, this.props.startRowsPerPage);
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    mapProjectToRow = (project) => {
        let projectData = this.props.inspectionsAssignsByProject[project.id];

        return {
            ...project,
            totalWPIs: projectData
                ? projectData.length
                : 0,
            lastSent: projectData
                ? projectData.length !== 0
                    ? moment(
                          this.props.getLastSentDate(
                              projectData
                          )
                      ).format(this.props.t('dateYear'))
                    : '-'
                : '-',
            finishedWPIs: projectData
                ? projectData.length !== 0
                    ? this.props.countFinishedInspectionsPerProject(
                        projectData
                      )
                    : '0'
                : '0',
            openWPIs: projectData
                ? projectData.length !== 0
                    ? projectData.length -
                      this.props.countFinishedInspectionsPerProject(
                          projectData
                      )
                    : '0'
                : '0',
        };
    };


    handleAction = (event, action) => {
        if (action.id === 'edit') {
            this.props.editProject(action.rowId);
        } else if (action.id === 'delete') {
            //this.props.deleteProject(action.rowId);
            this.setState({
                projectToDelete: action.rowId,
            });
            this.showConfirmDialog();
        }
    };

    projectSorter = () => {
        return {
            omschrijving: (a, b) => {
                a = a.omschrijving.toLowerCase();
                b = b.omschrijving.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            totalWPIs: (a, b) => {
                if (
                    this.props.inspectionsAssignsByProject &&
                    this.props.inspectionsAssignsByProject[a.id]
                ) {
                    a = this.props.inspectionsAssignsByProject[a.id].length;
                } else {
                    a = 0;
                }
                if (
                    this.props.inspectionsAssignsByProject &&
                    this.props.inspectionsAssignsByProject[b.id]
                ) {
                    b = this.props.inspectionsAssignsByProject[b.id].length;
                } else {
                    b = 0;
                }

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            lastSent: (a, b) => {
                a = moment(
                    this.props.getLastSentDate(
                        this.props.inspectionsAssignsByProject[a.id]
                    )
                );
                b = moment(
                    this.props.getLastSentDate(
                        this.props.inspectionsAssignsByProject[b.id]
                    )
                );

                if (b.isBefore(a)) {
                    return 1;
                } else if (a.isBefore(b)) {
                    return -1;
                } else {
                    return 0;
                }
            },
            finishedWPIs: (a, b) => {
                a = this.props.countFinishedInspectionsPerProject(
                    this.props.inspectionsAssignsByProject[a.id]
                );
                b = this.props.countFinishedInspectionsPerProject(
                    this.props.inspectionsAssignsByProject[b.id]
                );

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            openWPIs: (a, b) => {
                if (
                    this.props.inspectionsAssignsByProject &&
                    this.props.inspectionsAssignsByProject[a.id]
                ) {
                    a =
                        this.props.inspectionsAssignsByProject[a.id].length -
                        this.props.countFinishedInspectionsPerProject(
                            this.props.inspectionsAssignsByProject[a.id]
                        );
                } else {
                    a = 0;
                }
                if (
                    this.props.inspectionsAssignsByProject &&
                    this.props.inspectionsAssignsByProject[b.id]
                ) {
                    b =
                        this.props.inspectionsAssignsByProject[b.id].length -
                        this.props.countFinishedInspectionsPerProject(
                            this.props.inspectionsAssignsByProject[b.id]
                        );
                } else {
                    b = 0;
                }

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    render() {
        const { t } = this.props;
        const data = List(
            this.props.projects.filter(
                (item) => !item.id.includes('InvullenTijdenInspectie')
            )
        );


        const { actions, columns } = this.state;

        const searchresults = data.filter((project) => {
            return (
                (project.omschrijving &&
                    project.omschrijving
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1) ||
                (project.opdrachtgever &&
                    project.opdrachtgever
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1) ||
                (project.externalId &&
                    project.externalId
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1)
            );
        });

        // EnhancedTable only works with Arrays not with lists.
        const arrayFromList = Array.from(searchresults);

        return (
            <div>
                <ConfirmDialog
                    open={this.state.confirmDialogOpen}
                    handleClose={this.hideConfirmDialog}
                    deleteproject={this.state.projectToDelete}
                    deleteProjectFunction={this.props.deleteProject}
                />
                <EnhancedTable
                    hover
                    columns={columns}
                    onClick={this.onRowClick}
                    defaultOrder="desc"
                    defaultOrderBy={t('Description')}
                    rows={arrayFromList}
                    formatter={this.mapProjectToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    sorter={this.projectSorter()}
                    emptyState={
                        this.props.searchtext.length > 0
                            ? t('No searchresults')
                            : t(
                                  'No projects added click on the plus sign to add a project'
                              )
                    }

                    onPageChange={this.props.setPage}
                    onRowsPerPageChange={this.props.setRowsPerPage}
                    startPage={this.props.startPage}
                    startRowsPerPage={this.props.startRowsPerPage}

                />
            </div>
        );
    }
}


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(ProjectsListOverview))
);
