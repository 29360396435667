// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {Card, CardContent, ImageList, ImageListItem} from '@mui/material';


import BlockTypeCard from '../BlockTypeCard';
import ContentBlock from '../ContentBlock';
import { DragDropContainer, DragDropItem, DragDropSource } from '../DragDrop';
import {
    moveToolboxDraftContentBlock,
    deleteToolboxDraftContentBlock,
    insertToolboxDraftContentBlockType,
} from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ToolboxContentEditor.module.scss';

type Props = {
    toolbox: any,
    onMoveContentBlock: Function,
    contentBlockTypes: Array<any>,
    onChange: () => any,
};

type State = {
    activeTab: number,
};

const mapStateToProps = (store) => {
    return {
        contentBlockDrafts: store.drafts.contentBlocks,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddContentBlock: (index, data) =>
            dispatch(
                insertToolboxDraftContentBlockType(
                    props.toolbox.id,
                    data,
                    index
                )
            ),
        onMoveContentBlock: (fromIndex, toIndex) =>
            dispatch(
                moveToolboxDraftContentBlock(
                    props.toolbox.id,
                    fromIndex,
                    toIndex
                )
            ),
        onDeleteContentBlock: (contentBlockId) =>
            dispatch(
                deleteToolboxDraftContentBlock(props.toolbox.id, contentBlockId)
            ),
    };
};


class ToolboxContentEditor extends React.PureComponent<void, Props, State> {
    props: Props;
    state: State;

    handleAddContentBlock = (index, data) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onAddContentBlock(index, data);
    };

    handleMoveContentBlock = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onMoveContentBlock(fromIndex, toIndex);
    };

    handleDeleteContentBlock = (contentBlockId) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onDeleteContentBlock(contentBlockId);
    };

    render() {
        const {
            style,
            toolbox,
            contentBlockTypes,
            contentBlockDrafts,
            t,
        } = this.props;

        return (
            <div style={style} className={classes.root}>
                <Card className={classes.cardLeft}>
                    <CardContent>
                        <DragDropContainer
                            onMove={this.handleMoveContentBlock}
                            onAdd={this.handleAddContentBlock}
                            accepts="ContentBlockType"
                            emptyText={t('Drag your content blocks here to format the toolbox content')}
                        >
                            {toolbox.toolbox.blocks.map((contentBlockId, i) => {
                                return (
                                    <DragDropItem
                                        key={contentBlockId}
                                        data={
                                            contentBlockDrafts[contentBlockId]
                                        }
                                    >
                                        <ContentBlock
                                            onChange={this.props.onChange}
                                            onDelete={
                                                this.handleDeleteContentBlock
                                            }
                                            contentBlock={
                                                contentBlockDrafts[
                                                    contentBlockId
                                                ]
                                            }
                                            contentBlockTypes={
                                                contentBlockTypes
                                            }
                                        />
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>
                    </CardContent>
                </Card>

                <Card className={classes.cardRight}>
                    <CardContent>
                        <ImageList
                            rowHeight={'auto'}
                            gap={16}
                            className={classes.imageList}
                            cols={3}
                        >
                            {contentBlockTypes.map((contentBlockType, i) => (
                                <ImageListItem
                                    key={contentBlockType.id}
                                    cols={1}
                                >
                                    <DragDropSource
                                        data={contentBlockType}
                                        transfers="ContentBlockType"
                                    >
                                        <BlockTypeCard
                                            blockType={contentBlockType}
                                            key={i}
                                            onClick={() => {
                                                this.handleAddContentBlock(
                                                    this.props.toolbox.toolbox
                                                        .blocks.length,
                                                    contentBlockType
                                                );
                                            }}
                                        />
                                    </DragDropSource>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxContentEditor);
