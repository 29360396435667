import { fetchAll } from './apiActions';
import { setEntities, setEntity } from './entityActions';
import { showSnackbar } from './uiActions';
import i18n from 'i18next';
import * as api from '../lib/api';

export function fetchSectors() {
    return (dispatch) => {
        return fetchAll('sector')(dispatch).then((entities) => {
            dispatch(setEntities('sectors', entities));
        });
    };
}

export function createSector(data, callback) {
    return (dispatch) => {
        api.createSector(data)
            .then((entity) => {
                const sector = entity.body;
                dispatch(setEntity('sectors', sector));

                if (callback) {
                    callback(sector);
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not add sector')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}
