import React from 'react';
import { Map } from 'immutable';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import Pagination from '../Pagination';
import './PaginatedSortFilterView.css';
import TableSortLabel from '@mui/material/TableSortLabel';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    data: List<>,
    headerProvider?: () => mixed,
    totalElements?: number,
    usesPaginatedAPI?: boolean,
    rowProvider: () => mixed,
    onRowClick: () => mixed,
    sorters?: Map<string, (Object, Object) => number>,
    onSize: (page: number, size: number, sort: string, asc: boolean) => mixed,
};

type State = {
    sort: string,
    asc: boolean,
    totalElements: number,
    pageSize: number,
    page: number,
};

class PaginatedSortFilterView extends React.Component<void, Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            sort: props.initialSort,
            asc: props.asc !== undefined ? props.asc : true,
            pageSize: 10,
            totalElements: 0,
            page: 1,
        };
    }

    nextPage = () => {
        const newState = Object.assign({}, this.state, {
            page: this.state.page + 1,
        });
        this.setState(newState);

        if (this.props.onSize) {
            this.props.onSize(
                newState.page - 1,
                newState.pageSize,
                newState.sort,
                newState.asc
            );
        }
    };

    previousPage = () => {
        const newState = Object.assign({}, this.state, {
            page: this.state.page - 1,
        });
        this.setState(newState);

        if (this.props.onSize) {
            this.props.onSize(
                newState.page - 1,
                newState.pageSize,
                newState.sort,
                newState.asc
            );
        }
    };

    pageSizeChanged = (size: number) => {
        if (this.state.pageSize !== size) {
            const newState = Object.assign({}, this.state, {
                pageSize: size,
                page: 1,
            });
            this.setState(newState);

            if (this.props.onSize) {
                this.props.onSize(
                    newState.page - 1,
                    newState.pageSize,
                    newState.sort,
                    newState.asc
                );
            }
        }
    };

    resetPageNumber = () => {
        if (this.state.page !== 1) {
            const newState = Object.assign({}, this.state, {
                page: 1,
            });
            this.setState(newState);
        }
    };

    sortOnColumn = (key: string) => {
        let asc = true;
        if (this.state.sort === key) {
            asc = !this.state.asc;
        }
        const newState = Object.assign({}, this.state, {
            sort: key,
            asc: asc,
        });
        this.setState(newState);
        if (this.props.onSize) {
            this.props.onSize(
                newState.page - 1,
                newState.pageSize,
                newState.sort,
                newState.asc
            );
        }
    };

    getHeaders() {
        const items = this.props.headerProvider();
        const self = this;
        return Object.keys(items).map((key) => {
            const value = items[key];
            return (
                <TableCell key={key}>
                    <TableSortLabel
                        active={self.state.sort === key}
                        direction={self.state.asc ? 'asc' : 'desc'}
                        onClick={() => {
                            this.sortOnColumn(key);
                        }}
                    >
                        {value}
                    </TableSortLabel>
                </TableCell>
            );
        });
    }

    sortData(data: Array): Array {
        if (this.props.sorters) {
            let sorter: (Array) => Array = this.props.sorters.get(
                this.state.sort
            );
            if (sorter) {
                let sortedData = data.sort(sorter);
                if (!this.state.asc) {
                    sortedData.reverse();
                }
                return sortedData;
            }
        }
        return this.props.data.toArray().sort(this.sorter);
    }
    sorter = (a, b) => {
        //console.log('sort on ' + this.state.sort + ' ' + (this.state.asc ? 'asc' : 'desc'));
        if (b[this.state.sort] === null || b[this.state.sort] === undefined) {
            return this.state.asc ? -1 : 1;
        }
        if (a[this.state.sort] === null || a[this.state.sort] === undefined) {
            return this.state.asc ? 1 : -1;
        }
        if (typeof a[this.state.sort] === 'string') {
            return a[this.state.sort].toLowerCase() >
                b[this.state.sort].toLowerCase()
                ? this.state.asc
                    ? 1
                    : -1
                : b[this.state.sort].toLowerCase() >
                  a[this.state.sort].toLowerCase()
                ? this.state.asc
                    ? -1
                    : 1
                : 0;
        }
        return a[this.state.sort] > b[this.state.sort]
            ? this.state.asc
                ? 1
                : -1
            : b[this.state.sort] > a[this.state.sort]
            ? this.state.asc
                ? -1
                : 1
            : 0;
    };

    componentWillReceiveProps = (nextProps: Props) => {
        if (!nextProps.usesPaginatedAPI && nextProps.data !== this.props.data) {
            this.resetPageNumber();
        }
    };

    render() {
        const { t } = this.props;
        const start = (this.state.page - 1) * this.state.pageSize;
        const end = this.state.page * this.state.pageSize;

        const header = this.getHeaders();

        let rows = this.sortData(this.props.data.toArray());

        if (!this.props.usesPaginatedAPI) {
            rows = rows.slice(start, end);
        }

        const rowComponents = rows.map((rowdata) => {
            return this.props.rowProvider(rowdata);
        });
        let size = this.props.data ? this.props.data.toArray().length : 0;
        if (this.state.totalElements) {
            size = this.state.totalElements;
        }

        return (
            <div>
                <Table
                    style={{
                        tableLayout: 'auto',
                        marginTop: '20px',
                        overflow: 'visible',
                        marginBottom: '58px',
                    }}
                >
                    <TableHead>
                        <TableRow>{header}</TableRow>
                    </TableHead>

                    {rows.length > 0 && (
                        <TableBody className="table-body">
                            {rowComponents}
                        </TableBody>
                    )}
                </Table>
                {rows.length === 0 && (
                    <div className="emptyState">
                        {this.props.emptyState || t('No items')}
                    </div>
                )}
                <Pagination
                    page={this.state.page}
                    size={size}
                    pageSize={this.state.pageSize}
                    onPrevious={this.previousPage}
                    onNext={this.nextPage}
                    onSizeChange={this.pageSizeChanged}
                />
            </div>
        );
    }
}

export default compose(withTranslation())(PaginatedSortFilterView);
