// @flow
import TypedRecord from '../components/Util/IRecord';
import { Map } from 'immutable';
import type { TFilterAction } from '../actions/FilterActions';
import moment from 'moment';

export class TStateFilterPerView extends TypedRecord({
  minduration: null,
  maxduration: null,
  mincustomers: null,
  maxcustomers: null,
  mindevices: null,
  maxdevices: null,
  progidsFiltered: new Map(),
  progtypesFiltered: new Map(),
  distributorsFiltered: new Map(),
  customersFiltered: new Map(),
  clinicsFiltered: new Map(),
  devicesFiltered: new Map(),
  firmwareFilter: '',
  cityFilter: '',
  contactFilter: '',
  periodType: 'year',
  periodYear: moment().year(),
  periodMonth: moment().month(),
  periodWeek: moment().week(),
    periodFrom: null,
    periodTill: null,
  sector: 0,
  category: "all",
  group: 0,
  afgerond: 0,
  project: "all",
  uitvoerder: 0,
  resultaat: 0,
  situatie: 0,
  firstTherapyBeginDate: null,
  firstTherapyEndDate: null,
  lastTherapyBeginDate: null,
  lastTherapyEndDate: null,
  // therapyProcessBeginDate: new moment().subtract(1, 'months').toDate(),
  // therapyProcessEndDate: new moment().toDate(),
  therapyProcessBeginDate: null,
  therapyProcessEndDate: null,
  isToolboxMeetingChecked:false,
  isNoToolboxMeetingChecked:false,

  // for API-paginated List
  currentPage: 0
}) {
  minduration: ?number;
  maxduration: ?number;
  mincustomers: ?number;
  maxcustomers: ?number;
  mindevices: ?number;
  maxdevices: ?number;
  progidsFiltered: Map<string, boolean>;
  progtypesFiltered: Map<string, boolean>;
  distributorsFiltered: Map<number, boolean>;
  customersFiltered: Map<number, boolean>;
  clinicsFiltered: Map<number, boolean>;
  devicesFiltered: Map<string, boolean>;
  firmwareFilter: string;
  cityFilter: string;
  contactFilter: string;
  periodType: string;
  periodYear: number;
  periodMonth: number;
  periodWeek: number;
  firstTherapyBeginDate: Date;
  firstTherapyEndDate: Date;
  lastTherapyBeginDate: Date;
  lastTherapyEndDate: Date;
  therapyProcessBeginDate: Date;
  therapyProcessEndDate: Date;
  currentPage: number;
  isToolboxMeetingChecked: boolean;
  isNoToolboxMeetingChecked: boolean;
  sector: number;
  category: String;
  group: number;
  afgerond: number;
  project: String;
  uitvoerder: number;
  resultaat: number;
  situatie: number;
}

export class TStateFilter extends TypedRecord({
  filterMap: new Map(),
  currentViewkey: null
}) {
  filterMap: Map<string, TStateFilterPerView>;
  currentViewkey: ?string;
}

const initialstate = new TStateFilter();

const reduceFilterForView = (state: TStateFilterPerView, action: TFilterAction) => {
  switch (action.type) {
    case 'CLEAR_ALL_FILTERS':
      return new TStateFilterPerView();
    case 'FILTER_DURATION_MIN':
      state = state.set('currentPage', 0);
      return state.set('minduration', action.payload);
    case 'FILTER_DURATION_MAX':
      state = state.set('currentPage', 0);
      return state.set('maxduration', action.payload);
    case 'FILTER_CUSTOMERS_MIN':
      state = state.set('currentPage', 0);
      return state.set('mincustomers', action.payload);
    case 'FILTER_CUSTOMERS_MAX':
      state = state.set('currentPage', 0);
      return state.set('maxcustomers', action.payload);
    case 'FILTER_DEVICES_MIN':
      state = state.set('currentPage', 0);
      return state.set('mindevices', action.payload);
    case 'FILTER_DEVICES_MAX':
      state = state.set('currentPage', 0);
      return state.set('maxdevices', action.payload);
    case 'TOGGLE_PROGID_FILTER':
      state = state.set('currentPage', 0);
      const newProgidFiltered = state.progidsFiltered.set(action.payload.name, action.payload.select);
      return state.set('progidsFiltered', newProgidFiltered);
    case 'CLEAR_PROGID_FILTERS':
      state = state.set('currentPage', 0);
      return state.set('progidsFiltered', new Map());
    case 'TOGGLE_PROGTYPE_FILTER':
      state = state.set('currentPage', 0);
      const newProgtypeFiltered = state.progtypesFiltered.set(action.payload.name, action.payload.select);
      return state.set('progtypesFiltered', newProgtypeFiltered);
    case 'CLEAR_PROGTYPE_FILTERS':
      state = state.set('currentPage', 0);
      return state.set('progtypesFiltered', new Map());
    case 'TOGGLE_DISTRIBUTOR_FILTER':
      state = state.set('currentPage', 0);
      const newDistributorFiltered = state.distributorsFiltered.set(action.payload.id, action.payload.select);
      return state.set('distributorsFiltered', newDistributorFiltered);
    case 'CLEAR_DISTRIBUTOR_FILTERS':
      state = state.set('currentPage', 0);
      return state.set('distributorsFiltered', new Map());
    case 'FILTER_CITY':
      state = state.set('currentPage', 0);
      return state.set('cityFilter', action.payload);
    case 'FILTER_CONTACT':
      state = state.set('currentPage', 0);
      return state.set('contactFilter', action.payload);
    case 'TOGGLE_CUSTOMER_FILTER':
      state = state.set('currentPage', 0);
      const newCustomersFiltered = state.customersFiltered.set(action.payload.id, action.payload.select);
      return state.set('customersFiltered', newCustomersFiltered);
    case 'CLEAR_CUSTOMER_FILTERS':
      state = state.set('currentPage', 0);
      return state.set('customersFiltered', new Map());
    case 'TOGGLE_CLINIC_FILTER':
      state = state.set('currentPage', 0);
      const newClinicsFiltered = state.clinicsFiltered.set(action.payload.id, action.payload.select);
      return state.set('clinicsFiltered', newClinicsFiltered);
    case 'CLEAR_CLINIC_FILTERS':
      state = state.set('currentPage', 0);
      return state.set('clinicsFiltered', new Map());
    case 'TOGGLE_DEVICE_FILTER':
      state = state.set('currentPage', 0);
      const newDevicesFiltered = state.devicesFiltered.set(action.payload.name, action.payload.select);
      return state.set('devicesFiltered', newDevicesFiltered);
    case 'FILTER_FIRMWARE':
      state = state.set('currentPage', 0);
      return state.set('firmwareFilter', action.payload);
    case 'FILTER_FIRST_THERAPY':
      state = state.set('currentPage', 0);
      state = state.set('firstTherapyBeginDate', action.payload.beginDate);
      return state.set('firstTherapyEndDate', action.payload.endDate);
    case 'FILTER_LAST_THERAPY':
      state = state.set('currentPage', 0);
      state = state.set('lastTherapyBeginDate', action.payload.beginDate);
      return state.set('lastTherapyEndDate', action.payload.endDate);
    case 'FILTER_PROCESS_DATE':
      state = state.set('currentPage', 0);
      state = state.set('therapyProcessBeginDate', action.payload.beginDate);
      return state.set('therapyProcessEndDate', action.payload.endDate);
    case 'FILTER_PERIOD_TYPE':
      state = state.set('currentPage', 0);
      return state.set('periodType', action.periodType);
    case 'FILTER_PERIOD_YEAR':
      state = state.set('currentPage', 0);
      return state.set('periodYear', action.periodYear);
    case 'FILTER_PERIOD_MONTH':
      state = state.set('currentPage', 0);
      return state.set('periodMonth', action.periodMonth).set('periodType', 'month');
    case 'FILTER_PERIOD_WEEK':
      state = state.set('currentPage', 0);
      return state.set('periodWeek', action.periodWeek).set('periodType', 'week');
    case 'CURRENT_PAGENUMBER':
      return state.set('currentPage', action.payload);
    case 'FILTER_TOOLBOX_MEETING':
        return state.set('isToolboxMeetingChecked', action.isToolboxMeetingChecked);
    case 'FILTER_NO_TOOLBOX_MEETING':
        return state.set('isNoToolboxMeetingChecked', action.isNoToolboxMeetingChecked);
      case 'FILTER_PERIOD_FROM':
          return state.set('periodFrom', action.periodFrom);
      case 'FILTER_PERIOD_TILL':
          return state.set('periodTill', action.periodTill);
    case 'FILTER_SECTOR':
      return state.set('sector', action.sector);
    case 'FILTER_CATEGORY':
      return state.set('category', action.category);
    case 'FILTER_GROUP':
      return state.set('group', action.group);
    case 'FILTER_AFGEROND':
      return state.set('afgerond', action.afgerond);
    case 'FILTER_PROJECT':
      return state.set('project', action.project);
    case 'FILTER_UITVOERDER':
      return state.set('uitvoerder', action.uitvoerder);
    case 'FILTER_RESULTAAT':
      return state.set('resultaat', action.resultaat);
    case 'FILTER_SITUATIE':
      return state.set('situatie', action.situatie);
    default:
      return state;
  }
};

const FilterReducer = (state: TStateFilter = initialstate, action: TFilterAction) => {
  if (action.type === 'LOGOUT_USER') {
    return initialstate;
  }
  if (action.type === 'SET_VIEWKEY') {
    return state.set('currentViewkey', action.viewkey);
  }

  if (!state.currentViewkey) {
    return state;
  }

  const viewkey = state.currentViewkey;
  let filterMap = state.filterMap;

  let stateForView: TStateFilterPerView = filterMap.get(viewkey) || new TStateFilterPerView();
  stateForView = reduceFilterForView(stateForView, action);
  filterMap = filterMap.set(viewkey, stateForView);

  return state.set('filterMap', filterMap);
};

export const getFiltersForView = (
  filterMap: Map<string, TStateFilterPerView>,
  viewkey: ?string
): TStateFilterPerView => {
  let stateForView: TStateFilterPerView = new TStateFilterPerView();
  if (viewkey) {
    stateForView = filterMap.get(viewkey) || stateForView;
  }
  return stateForView;
};

export const getCurrentFilterSet = (filterState: TStateFilter): TStateFilterPerView => {
  return getFiltersForView(filterState.filterMap, filterState.currentViewkey);
};

export default FilterReducer;
