// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classnames from 'classnames';
import type { TBreadCrumb } from '../../reducers/uiReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from  './BreadCrumbs.module.scss'

type Props = {
    goto: (url: string) => void,
    breadCrumb: TBreadCrumb,
};

type State = {};

class BreadCrumbs extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { breadCrumb, goto, t } = this.props;

        return (

            <div className={classes.root}>
                {breadCrumb.title && (
                    <Typography className={classes.crumb} variant="h2">
                        {t(breadCrumb.title)}
                    </Typography>
                )}

                {breadCrumb.crumbs &&
                    breadCrumb.crumbs.map((crumb, index) => {
                        return (
                            <Typography
                                className={classes.crumb}
                                type="body1"
                                key={index}
                            >
                                {crumb.link && (
                                    <IconButton
                                        className={classnames({
                                            [classes.crumb]: true,
                                            [classes.backLink]: true,
                                        })}
                                        onClick={() => goto(crumb.link)}
                                        size="large">
                                        <ArrowBackIcon />
                                    </IconButton>
                                )}
                                <span className={classes.crumbText}>
                                    {crumb.name}
                                </span>
                            </Typography>
                        );
                    })}
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect((store) => ({ breadCrumb: store.ui.breadCrumb })),
)(BreadCrumbs);
