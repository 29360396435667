import { SET_LOGIN_TOKEN } from '../actions';
import * as storage from '../lib/storage';

const loginStorage = storage.fetchLogin();

const initalState = {
    token: loginStorage.token
}

const authReducer = (state = initalState, action = {}) => {
    switch (action.type) {
        case SET_LOGIN_TOKEN: {
            return { ...state, token: action.payload.token };
        } default:
            return state;
    }
};

export default authReducer;
