import React from 'react';
import { compose } from "recompose";
import {Fab} from '@mui/material';
import classnames from "classnames";

import AddIcon from "@mui/icons-material/Add";
import { withTranslation } from "react-i18next";
import classes from './FixedAddButton.module.scss'


const FixedAddButton = props => {
    const { onClick, tooltipText} = props;
    return (
		<div
			className={classnames({
				[classes.button]: true,
				"mui-fixed": true,
			})}
		>
			<div className={"tooltip"}>
				<span className={"tooltiptext"} style={{ top: "60px" }}>
					{tooltipText}
				</span>
				<Fab   onClick={onClick}>
					<AddIcon />
				</Fab>
			</div>
		</div>
	);
}

export default compose(
    
    withTranslation()
)(FixedAddButton)