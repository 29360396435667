// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Dialog, DialogTitle } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { List } from 'immutable';
import './DialogChoose.css';

import EmployeeEditCard from '../Employee/EmployeeEditCard';
import UploadDropdownDialog from '../Util/UploadDropdownDialog';
import type { TEmployee } from '../../reducers/employeesReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    handleClose: () => mixed,
    diff: { removed: List<TEmployee>, added: List<TEmployee> },
    mapToDiffRow: (list: List<mixed>) => mixed,
    mapToConflictRow: (list: List<mixed>) => mixed,
    editemployee: TEmployee,
    onUpload: (file: any) => mixed,
    acknowledgeImport: (file: any) => mixed,
    inProgress: boolean,
    mapToDiffRow: Function,
    editEmployeeFunction: (employee?: TEmployee) => mixed,
    onSave: (employee?: TEmployee) => mixed,
    display: boolean,
};

type State = {
    tab: number,
};

class DialogChoose extends React.PureComponent<Props, State> {
    state: State = {
        tab: 0,
    };

    handleTabChange = (event: mixed, value: number) => {
        this.setState({ tab: value });
    };

    handleChangeIndex = (index: number) => {
        this.setState({ tab: index });
    };

    componentDidMount() {
        this.processProps(this.props);
    }

    componentWillReceiveProps(newProps: Props) {
        this.processProps(newProps);
    }

    cleanEditEmployee = (props: Props) => {
        props.editEmployeeFunction({
            username: '',
            firstname: '',
            middlename: '',
            lastname: '',
            phonenumber: '',
            importType: 'MANUAL',
            state: 'ADDED',
            roles: ['EMPLOYEE'],
        });
    };

    processProps = (props: Props) => {
        if (props.open && !props.editemployee) {
            this.cleanEditEmployee(props);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogTitle className="title">{t('Add user')}</DialogTitle>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    className="tab-bar"
                >
                    <Tab label={t('Manual')} />
                    <Tab label={t('Import')} />
                </Tabs>
                <SwipeableViews
                    axis={'x'}
                    index={this.state.tab}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <EmployeeEditCard
                        employee={this.props.editemployee}
                        onEdit={this.props.editEmployeeFunction}
                        onSave={this.props.onSave}
                        goBack={this.props.handleClose}
                        clearDiff={this.props.handleClose}
                        display={this.props.display}
                        employees={this.props.employees}
                    />
                    <UploadDropdownDialog
                        onUpload={this.props.onUpload}
                        diff={this.props.diff}
                        acknowledgeImport={
                            this.props.display === 'extern'
                                ? this.props.acknowledgeImportExtern
                                : this.props.acknowledgeImport
                        }
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.props.mapToDiffRow}
                        mapToConflictRow={this.props.mapToConflictRow}
                        handleClose={this.props.handleClose}
                        importType={'employee'}
                        employees={this.props.employees}
                    />
                </SwipeableViews>
            </Dialog>
        );
    }
}

export default compose(withTranslation())(DialogChoose);
