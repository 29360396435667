// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';


import _ from 'lodash';
import {getToolboxResultForDeploymentAndUser, setCrumbPath, showSnackbar,} from '../../../actions';

import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import AppModule from '../../AppModule/AppModule';
import TitleDescription from '../../TitleDescription';
import moment from '../../../lib/moment';
import {formatFullName, formatV2User, percentColors1} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';
import {V2_TOOLBOX_ASSIGN_REDUX} from "../../../lib/apiTypes";
import classes from './ToolboxResultsView.module.scss';
import {getToolboxAssignById} from "../../../lib/api";

type Props = {
    match: any,
    toolboxAssign: any,
    getToolboxAssignById: () => mixed,
    setCrumbPath: (toolboxName: string) => void,
};

const mapStateToProps = (store, props) => {
    let cachedAssignsEntities = store.entities[V2_TOOLBOX_ASSIGN_REDUX]
    let cachedAssigns = cachedAssignsEntities.allIds.map((id) => cachedAssignsEntities.byId[id]);

    const toolboxId = props.match.params.toolboxId;
    const deploymentId = parseInt(props.match.params.deploymentId,10);
    const employerId = parseInt(props.match.params.employerId,10);
    const toolboxAssignId = parseInt(props.match.params.toolboxAssignId,10);

    const toolboxAssign = cachedAssigns.find((assign) => {
        return assign.toolbox.id === toolboxId && assign.deploymentId === deploymentId && assign.user.id === employerId
    })


    return {
        toolboxAssignId: toolboxAssignId
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        setCrumbPath:
            ownProps.setCrumbPath ||
            ((toolboxAssign) => {
                const hash = new URLSearchParams(ownProps.location.search).get(
                    'from'
                );

                const toolbox = (toolboxAssign && toolboxAssign.toolbox) || {}
                const employeeName = ((toolboxAssign && toolboxAssign.user && formatV2User(toolboxAssign.user)) || "")

                if (toolboxAssign === undefined) {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name: (toolbox.title || ""),
                                    link:
                                        '/toolboxen#' +
                                        (hash && hash.length
                                            ? hash
                                            : 'overzicht'),
                                },
                            ],
                        })
                    );
                    return;
                }

                if (hash === 'toolboxen') {
                    dispatch(
                        setCrumbPath({
                            title: 'Medewerkers',
                            crumbs: [
                                {
                                    name: employeeName,
                                    link: '/medewerkers',
                                },
                                {
                                    name: (toolbox.title || ""),
                                    link:
                                        '/medewerker/details/' +
                                        this.props.employerId +
                                        '/#toolboxen',
                                },
                            ],
                        })
                    );
                } else if (hash === 'uitgestuurd') {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name:
                                        i18n.t('Sent out') +
                                        ': ' +
                                        (toolbox.title || ""),
                                    link:
                                        '/toolboxen#' +
                                        (hash.length ? hash : 'overzicht'),
                                },
                                {
                                    name: employeeName,
                                    link:
                                        '/toolbox/details/' +
                                        toolbox.id +
                                        '/' +
                                        toolboxAssign.id +
                                        '?from=uitgestuurd#resultaten',
                                },
                            ],
                        })
                    );
                } else if (hash === 'overzicht') {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name:
                                        'Toolboxen: ' +
                                        (toolbox.title || ""),
                                    link:
                                        '/toolboxen#' +
                                        (hash.length ? hash : 'overzicht'),
                                },
                                {
                                    name: moment(toolboxAssign.deployed, '').format(
                                        'DD/MM/YYYY, HH:mm'
                                    ),
                                    link:
                                        '/toolbox/details/' +
                                        toolbox.id +
                                        '/' +
                                        toolbox.date +
                                        '?from=overzicht#resultaten',
                                },
                                {
                                    name: employeeName,
                                    link:
                                        '/toolbox/details/assign/' +
                                        toolboxAssign.id +
                                        '?from=overzicht',
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportages') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        (toolboxAssign.toolbox.title || ""),
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: employeeName,
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportagesTM') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        (toolboxAssign.toolbox.title || ""),
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: 'Toolboxmeeting',
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                                {
                                    name: employeeName,
                                    link:
                                        '/toolbox/details/' +
                                        toolboxAssign.toolbox.id +
                                        '/' +
                                        toolboxAssign.id +
                                        '?from=rapportagesTM#resultaten',
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportagesTMgroups') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        (toolboxAssign.toolbox.title || ""),
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: 'Toolboxmeeting',
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                                {
                                    name: employeeName,
                                    link:
                                        '/rapportages/toolboxMeeting/' +
                                        (toolboxAssign.toolboxAssign ? toolboxAssign.toolboxAssign.id : toolboxAssign.id),
                                },
                            ],
                        })
                    );
                } else {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name: (toolboxAssign.toolbox.title || ""),
                                    link:
                                        '/toolboxen#' +
                                        (hash && hash.length
                                            ? hash
                                            : 'overzicht'),
                                },
                            ],
                        })
                    );
                }
            }),
        getToolboxAssignById: (toolboxId: string, deploymentId: number, userId: number) => {
            // dispatch(getToolboxResultForDeploymentAndUser(toolboxId, deploymentId, userId));
        },
    };
};


class ToolboxResultsView extends React.Component<Props, State> {
    props: Props;

    // componentWillMount() {
    //     const { match, toolboxAssignId  } = this.props;
    //
    //     // getToolboxAssignById(match.params.toolboxId, match.params.deploymentId, match.params.employerId)
    //
    //     // const myId = 12576;
    //
    //
    // }


    componentDidMount() {
        const { toolboxAssignId  } = this.props;

        getToolboxAssignById(toolboxAssignId)
            .then((entities) => {
                this.setState({
                    toolboxAssign: entities.body
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching toolboxAssign'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    render() {
        const { t } = this.props;

        const toolboxAssign = this.state && this.state.toolboxAssign;

        const toolbox = toolboxAssign && toolboxAssign.toolbox;
        const user = toolboxAssign && toolboxAssign.user;

        if (!toolboxAssign) {
            return t('Loading');
        }

        this.props.setCrumbPath(toolboxAssign);

        const tableActionHeader = (
            <TableActionHeader
                title={
                    `${t('Results')} ${user ? formatFullName(user.firstname, user.middlename, user.lastname) : ""}`
                }
            />
        );

        const letters = [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ];

        return (
            <AppModule loading={false} prepend={tableActionHeader}>
                <div className="myContainer">
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Title')}
                        itemDescription={
                            toolbox && toolbox.title != null && toolbox.title
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Author')}
                        itemDescription={
                            toolbox && toolbox.author != null && toolbox.author
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Sent')}
                        itemDescription={
                            toolboxAssign &&
                            toolboxAssign.deployed &&
                            moment(toolboxAssign.deployed).format(t('dateTime'))
                        }
                    />
                </div>
                <div style={{ marginBottom: '100px' }}>
                    {toolbox &&
                        toolbox.toolbox &&
                        toolbox.toolbox.questions.map((question, index) => {
                            return (
                                <div key={index}>
                                    {
                                        //check toegevoegd zodat oudere toolboxen ook nog werken
                                        <div className={classes.container2}>
                                            <div
                                                style={{
                                                    fontWeight: '500',
                                                    width: '900px',
                                                    fontSize: '15x',
                                                }}
                                            >
                                                {question.title !== null
                                                    ? question.title
                                                    : question.blocks &&
                                                      question.blocks[0] &&
                                                      question.blocks[0]
                                                          .textBlock.text &&
                                                      question.blocks[0]
                                                          .textBlock.text}
                                            </div>
                                            {toolboxAssign &&
                                            toolboxAssign.result &&
                                            toolboxAssign.result
                                                    .complete && (
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {t('Chosen answer')}
                                                    </div>
                                                )}
                                        </div>
                                    }
                                    {question &&
                                        question.possibleAnswers &&
                                        question.possibleAnswers.map(
                                            (answer, index2) => (
                                                <div
                                                    key={index2}
                                                    style={{
                                                        fontWeight: '300',
                                                        fontSize: '14px',
                                                    }}
                                                    className={
                                                        classes.container
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            width: '950px',
                                                        }}
                                                    >
                                                        {letters[
                                                            index2 %
                                                                letters.length
                                                        ] +
                                                            '. ' +
                                                            answer.text +
                                                            ' '}{' '}
                                                        {question.correctAnswers.indexOf(
                                                            index2
                                                        ) > -1 && (
                                                            <b
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {t(
                                                                    '(right answer)'
                                                                )}
                                                            </b>
                                                        )}
                                                    </div>
                                                    {toolboxAssign &&
                                                    toolboxAssign.result &&
                                                    toolboxAssign.result
                                                        .complete &&
                                                    toolboxAssign.result
                                                        .answers &&
                                                    toolboxAssign.result
                                                        .answers.length >
                                                    0 &&
                                                    toolboxAssign.result
                                                        .answers[0]
                                                        .answers !==
                                                            undefined && (
                                                            <React.Fragment>
                                                                {toolboxAssign
                                                                    .result
                                                                    .answers[
                                                                    index
                                                                ].answers &&
                                                                toolboxAssign.result.answers[
                                                                    index
                                                                ].answers.indexOf(
                                                                    index2
                                                                ) > -1 ? (
                                                                    <div
                                                                        className={
                                                                            'border-text'
                                                                        }
                                                                        style={{
                                                                            backgroundColor: getColorForPercentage(
                                                                                toolboxAssign &&
                                                                                toolboxAssign.result &&
                                                                                toolboxAssign
                                                                                        .result
                                                                                        .complete &&
                                                                                toolboxAssign
                                                                                        .result
                                                                                        .answers[
                                                                                        index
                                                                                    ] &&
                                                                                toolboxAssign.result.answers[
                                                                                        index
                                                                                    ].answers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1 &&
                                                                                    question.correctAnswers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1
                                                                                    ? 1
                                                                                    : 0
                                                                            ),
                                                                            borderColor: getColorForPercentage(
                                                                                toolboxAssign &&
                                                                                toolboxAssign.result &&
                                                                                toolboxAssign
                                                                                        .result
                                                                                        .complete &&
                                                                                toolboxAssign
                                                                                        .result
                                                                                        .answers[
                                                                                        index
                                                                                    ]
                                                                                        .answers &&
                                                                                toolboxAssign.result.answers[
                                                                                        index
                                                                                    ].answers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1 &&
                                                                                    question.correctAnswers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1
                                                                                    ? 1
                                                                                    : 0
                                                                            ),
                                                                            width:
                                                                                '70px',
                                                                            height:
                                                                                '23px',
                                                                            display:
                                                                                'inline-block',
                                                                            float:
                                                                                'right',
                                                                            marginTop:
                                                                                '-20px',
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Chosen'
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    question.correctAnswers.indexOf(
                                                                        index2
                                                                    ) > -1 &&
                                                                    question.type ===
                                                                        'multiResponse' &&
                                                                    toolboxAssign &&
                                                                    toolboxAssign.result &&
                                                                    toolboxAssign
                                                                        .result
                                                                        .complete && (
                                                                        <div
                                                                            className={
                                                                                'border-text'
                                                                            }
                                                                            style={{
                                                                                backgroundColor: getColorForPercentage(
                                                                                    0
                                                                                ),
                                                                                borderColor: getColorForPercentage(
                                                                                    0
                                                                                ),
                                                                                width:
                                                                                    '90px',
                                                                                height:
                                                                                    '23px',
                                                                                display:
                                                                                    'inline-block',
                                                                                float:
                                                                                    'right',
                                                                                marginTop:
                                                                                    '-20px',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'Not chosen'
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                </div>
                                            )
                                        )}
                                </div>
                            );
                        })}
                </div>
            </AppModule>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxResultsView);
