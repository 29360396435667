// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import Loader from '../Loader';
import classes from './AppModule.module.scss';

type Props = {
    loading?: boolean,
    children?: any,
    hasTabs?: boolean,
    prepend: any
};


const AppModule = (props: Props) => {
    const { loading, children, prepend, className } = props;
    return (
        <div style={{paddingTop: props.hasTabs ? '109px' : '64px'}}>
            <div className={classes.prepend}>{prepend}</div>
            <div className={classes.root} >
                <Loader className={loading ? classes.loader : ''} size={100} loading={loading}>
                    <div className={classes.wrapper + ' ' + className}>{children}</div>
                </Loader>
            </div>
        </div>
    );
};

export default (AppModule);
