// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { Link } from 'react-router-dom'
import MovieIcon from '@mui/icons-material/PlayCircleFilled';
import classes from './VideoBlockPreview.module.scss'

type Props = {
    block: any,
    classes: any
};


const VideoBlockPreview = (props: Props) => {
    const { block, format } = props;

    const isNormalYoutubeLink = block.url && block.url.toLowerCase().includes("youtu") && block.url.toLowerCase().includes("?v=");
    const isMobileYoutubeLink = block.url && block.url.toLowerCase().includes("youtu") && block.url.toLowerCase().includes(".be/");
    const isShortLink = block.url && block.url.toLowerCase().includes("shorts");


    let src = isNormalYoutubeLink ? "https://www.youtube.com/embed/" + block.url.split('?v=')[1] :
        isMobileYoutubeLink ? "https://www.youtube.com/embed/" + block.url.split('.be/')[1] : (isShortLink ? "https://www.youtube.com/embed/" + block.url.split('youtube.com/shorts/')[1] : null);

    if (src !== null) {
        // A short link looks like this: https://youtube.com/shorts/7kdfdVswIDI?si=dRRAegY2RlmiQpjp
        // Strip ?si=* from the src to keep only: https://youtube.com/shorts/7kdfdVswIDI
        src = src.replace(/\?si=.*$/,"");
        src = src.replace(/&t=.*$/,"");
    }

    return (
        <div className={classes.root} style={{maxWidth: window.innerWidth}}>
            {block.url && (
                isNormalYoutubeLink || isMobileYoutubeLink ?
                    <iframe id="ytplayer" title="ytplayer"
                            type="text/html"
                            width={window.innerWidth < 768 ? window.innerWidth - 50 : 640}
                            height={window.innerWidth < 768 ? (((window.innerWidth - 50) / 16) * 9) : 360}
                            src={src}
                            frameBorder="0"
                            allowFullScreen={true}
                            mozallowfullscreen="true"
                            msallowfullscreen="true"
                            oallowfullscreen="true"
                            webkitallowfullscreen="true"
                            style={(format && format) === 'mobile' ? {width: '300px', height: '169px'} : {}}/> :
                    <Link target="_blank" to={block.url} className={classes.player}>
                        <MovieIcon className={classes.playerIcon}/>
                    </Link>
            )}
        </div>
    );
}

export default (VideoBlockPreview);
