import { createSelector } from 'reselect'
import {
    V2_TOOLBOX_ASSIGN_OVERVIEW_API,
    V2_TOOLBOX_OVERVIEW_API,
    V2_TOOLBOX_SCHEDULED_OVERVIEW_API
} from "../lib/apiTypes";

const getApiInProgress = store => store.api.inProgress;

const inProgress = createSelector(
    [getApiInProgress],
    (apiInProgress) => {
        return apiInProgress.length === 0;
    }
)

export const toolboxOverviewInProgress = createSelector(
    [getApiInProgress],
    (apiInProgress) => {
        return apiInProgress.some(action => action.payload.type === V2_TOOLBOX_OVERVIEW_API)
    }
)

export const toolboxAssignOverviewInProgress = createSelector(
    [getApiInProgress],
    (apiInProgress) => {
        return apiInProgress.some(action => action.payload.type === V2_TOOLBOX_ASSIGN_OVERVIEW_API)
    }
)

export const toolboxScheduledOverviewInProgress = createSelector(
    [getApiInProgress],
    (apiInProgress) => {
        return apiInProgress.some(action => action.payload.type === V2_TOOLBOX_SCHEDULED_OVERVIEW_API)
    }
)

export default inProgress;
