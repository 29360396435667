import { createSelector } from 'reselect';
import moment from 'moment';
import { getCurrentFilterSet } from '../reducers/filterReducer';

const getInspectionAssigns = store => store.entities.inspectionAssigns;
const getCurrentFilters = store => getCurrentFilterSet(store.filters);

const getFilteredInspectionAssigns = createSelector([getInspectionAssigns, getCurrentFilters], (inspectionAssigns, filters) => {
    let ias = [];
    if (inspectionAssigns && inspectionAssigns.allIds) {
        ias = inspectionAssigns.allIds.map(id => inspectionAssigns.byId[id]);
    }

    if (filters.periodType === 'year') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).year() === filters.periodYear);
    }

    if (filters.periodType === 'month') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).month() === filters.periodMonth);
    }

    if (filters.periodType === 'week') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).week() === filters.periodWeek);
    }

    if (filters.periodType === 'fromtill') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed) >= moment(filters.periodFrom) && moment(ia.deployed) <= moment(filters.periodTill));
    }

    return ias.length > 0 ? ias : [];
});

export default getFilteredInspectionAssigns;
