import { createSelector } from 'reselect';

const getCurrentUser = store => store.drafts.employees.currentUser;

const getSubscription = createSelector([getCurrentUser], currentUser => {
    if (currentUser) {
        return currentUser.subscription;
    }
});

export default getSubscription;
