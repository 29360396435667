// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Input,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import {
    processForm,
    processFormFailure,
    closeDialog,
    createInspection,
    showSnackbar,
} from '../../actions';
import { getForm } from '../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './DialogInspectionCreate.module.scss'

type Props = {
    open: boolean,
    isLoading: boolean,
    inspections: any,
    error: any,
};

type State = {
    form: any,
};

const mapStateToProps = (store) => {
    const inspections = store.entities.inspections;

    return {
        ...getForm('inspectionCreate')(store),
        open: store.ui.dialogs.inspectionCreate.open,
        inspections: inspections.allIds.map((id) => inspections.byId[id]),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (data) => {
            dispatch(
                processForm('inspectionCreate', createInspection(data, true))
            );
        },
        onClose: () => {
            dispatch(closeDialog('inspectionCreate'));
        },
        onError: (message) => {
            dispatch(processFormFailure('inspectionCreate', { message }));
        },
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class DialogInspectionCreate extends React.Component {
    props: Props;
    state: State = {
        form: {
            title: '',
        },
    };

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    handleCreateClick = (event) => {
        const { t } = this.props;
        let unique = true;
        this.props.inspections.forEach((inspection) => {
            if (
                inspection.title.toLowerCase() ===
                this.state.form.title.toLowerCase()
            ) {
                unique = false;
                this.props.onError(
                    t(
                        'Name can not be the same as existing workplace inspection'
                    )
                );
            }
        });
        if (unique) {
            this.props.onSubmit(this.state.form);
        }
    };

    handleChange = (inputId) => (event) => {
        const { t } = this.props;
        if (inputId === 'title' && event.target.value.length > 105) {
            this.props.showSnackbar(
                t('Text cannot be longer than 105 characters')
            );
            return;
        }

        const formState = { ...this.state.form };
        this.setState({
            form: { ...formState, [inputId]: event.target.value },
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.form);
    };

    componentWillMount() {}

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
            this.setState({
                form: {
                    title: '',
                },
            });
        }
    }

    render() {
        const { open, error, processing, t } = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('New workplace inspection')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <ErrorMessage
                        className={classes.errorMessage}
                        error={error}
                    />
                    <Loader size={100} loading={processing}>
                        <form onSubmit={this.handleSubmit}>
                            <FormControl>
                                <InputLabel htmlFor="DialogInspectionCreate-title">
                                    {t('Name')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="DialogInspectionCreate-title"
                                    onChange={this.handleChange('title')}
                                    label={t('Name')}
                                    required
                                    value={this.state.form.title}
                                />
                            </FormControl>
                        </form>
                    </Loader>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={processing}
                        onClick={this.handleCancelClick}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={
                            processing || this.state.form.title.length === 0
                        }
                        onClick={this.handleCreateClick}
                    >
                        {t('Create')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
/*
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(DialogInspectionCreate);
