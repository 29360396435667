// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Input,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import Select from 'react-select';


import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import {
    processForm,
    processFormFailure,
    closeDialog,
    fetchCategories,
    fetchSectors,
    createToolbox,
    getCurrentEmployee,
    createSector,
    showSnackbar,
} from '../../actions';
import { getForm } from '../../selectors';
import getRoles from '../../selectors/getRoles';
import {
    isUndefinedOrNullOrNaN,
} from '../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './DialogToolboxCreate.module.scss';
import colors from "../../lib/themes/theme.scss";

type Props = {
    open: boolean,
    sectors: Array<any>,
    categories: Array<any>,
    isLoading: boolean,
    error: any,
    toolboxes: any,
    roles: Array<any>,
    employee: Array<any>,
};

type State = {
    form: any,
};


// For react-select
const customStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => {
        return {
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? colors.primaryColor : "white",
            "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};


const mapStateToProps = (store) => {
    const sectors = store.entities.sectors;
    const categories = store.entities.categories;
    const toolboxes = store.entities.toolboxes;
    const employee = store.drafts.employees.currentUser;

    return {
        ...getForm('toolboxCreate')(store),
        open: store.ui.dialogs.toolboxCreate.open,
        sectors: sectors.allIds.map((id) => sectors.byId[id]),
        toolboxes: toolboxes.allIds.map((id) => toolboxes.byId[id]),
        categories: categories.allIds.map((id) => categories.byId[id]),
        roles: getRoles(store),
        employee: employee,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSectors: () => {
            dispatch(fetchSectors());
        },
        fetchCategories: () => {
            dispatch(fetchCategories());
        },
        onSubmit: (data) => {
            dispatch(processForm('toolboxCreate', createToolbox(data, true)));
        },
        onClose: () => {
            dispatch(closeDialog('toolboxCreate'));
        },
        onError: (message) => {
            dispatch(processFormFailure('toolboxCreate', { message }));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        createSector: (data, callback) => {
            dispatch(createSector(data, callback));
        },
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class DialogToolboxCreate extends React.Component {
    props: Props;
    state: State = {
        form: {
            title: '',
            category: '',
            sectors: [],
        },
        multi: null,
    };

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    handleCreateClick = (event) => {
        const { t } = this.props;
        if (this.state.form.category) {
            let unique = true;
            this.props.toolboxes.forEach((toolbox) => {
                if (
                    toolbox.title.toLowerCase() ===
                    this.state.form.title.toLowerCase()
                ) {
                    unique = false;
                    this.props.onError(
                        t('Name can not be the same as existing toolbox')
                    );
                }
            });
            if (unique) {
                if (this.state.form.sectors.length > 0) {
                    this.setState(
                        (prevState) => ({
                            form: {
                                ...prevState.form,
                                sectors: prevState.form.sectors.filter(
                                    (sector) => !isUndefinedOrNullOrNaN(sector)
                                ),
                            },
                        }),
                        () => {
                            this.props.onSubmit(this.state.form);
                        }
                    );
                } else {
                    this.props.onSubmit(this.state.form);
                }
            }
        } else {
            this.props.onError(t('Category is required'));
        }
    };

    handleChange = (inputId) => (event) => {
        const { t } = this.props;
        if (inputId === 'title' && event.target.value.length > 105) {
            this.props.showSnackbar(
                t('Text cannot be longer than 105 characters')
            );
            return;
        }

        const formState = { ...this.state.form };
        this.setState({
            form: { ...formState, [inputId]: event.target.value },
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    componentWillMount() {
        this.props.fetchCurrentUser();
        this.props.fetchSectors();
        this.props.fetchCategories();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
            this.setState({
                form: {
                    title: '',
                    category: '',
                    sectors: this.props.roles.includes('CUMELA')
                        ? [
                              nextProps.sectors.find(
                                  (s) => s.name === 'Cumela sector'
                              ).id,
                          ]
                        : [],
                },
            });
        }
    }

    handleChangeMulti = (multi) => {
        if (multi) {
            const formState = { ...this.state.form };
            this.setState({
                form: {
                    ...formState,
                    sectors: multi.map((select) => parseInt(select.value,10)),
                },
            });
        }
    };

    handleChangeSingle = (single) => {
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, category: single.value } });
    };

    handleCreateSector = (sector) => {
        this.props.createSector(sector, (savedSector) => {
            let sectors = this.state.form.sectors;
            sectors.push(savedSector.id);
            this.setState({
                form: {
                    title: this.state.form.title,
                    category: this.state.form.category,
                    sectors: sectors,
                },
            });
        });
    };

    render() {
        const {
            open,
            error,
            sectors,
            categories,
            processing,
            t,
        } = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('New toolbox')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <ErrorMessage
                        className={classes.errorMessage}
                        error={error}
                    />
                    <Loader size={100} loading={processing}>
                        <form onSubmit={this.handleSubmit}>
                            <FormControl>
                                <InputLabel htmlFor="DialogToolboxCreate-title">
                                    {t('Name')}
                                </InputLabel>
                                <Input
                                    fullWidth={true}
                                    id="DialogToolboxCreate-title"
                                    onChange={this.handleChange('title')}
                                    label={t('Name')}
                                    required
                                    value={this.state.form.title}
                                />
                            </FormControl>

                            <FormControl className={classes.sectorSelection}>
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={customStyles}
                                    isMulti
                                    placeholder={t('Sector')}
                                    onChange={this.handleChangeMulti}
                                    options={ sectors.filter(
                                                (sector) =>
                                                    (sector.subscriptionHolder ===
                                                        null &&
                                                        sector.name !==
                                                            'Cumela sector') ||
                                                    (this.props.employee &&
                                                        sector.subscriptionHolder &&
                                                        sector
                                                            .subscriptionHolder
                                                            .companyname ===
                                                            this.props.employee
                                                                .companyname) ||
                                                    (this.props.roles.includes(
                                                        'CUMELA'
                                                    ) &&
                                                        sector.name ===
                                                            'Cumela sector')
                                            )
                                            .map((sector) => ({
                                                value: sector.id,
                                                label: t(sector.name),
                                            }))
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <Select
                                    styles={customStyles}
                                    menuPortalTarget={document.body}
                                    placeholder={t('Category')}
                                    isMulti={false}
                                    fullWidth
                                    onChange={this.handleChangeSingle}
                                    options={ categories.map((category) => ({
                                            value: category.id,
                                            label: t(category.locKey || category.name),
                                        }))
                                    }
                                />
                            </FormControl>
                        </form>
                    </Loader>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={processing}
                        onClick={this.handleCancelClick}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={
                            processing || this.state.form.title.length === 0
                        }
                        onClick={this.handleCreateClick}
                    >
                        {t('Create')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
/*
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(DialogToolboxCreate);
