// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import InspectionItem from './InspectionItem';
import { Table, TableHead, TableCell, TableRow } from '@mui/material';
import './InspectionCategory.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    inspectionCategory: any,
};

type State = {};

class InspectionCategory extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { inspectionCategory, t } = this.props;

        return (
            <div style={{ paddingBottom: '60px' }}>
                <Table style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow style={{ height: '48px' }}>
                            <TableCell>
                                <div className="category-title">
                                    {inspectionCategory.title}
                                </div>
                            </TableCell>
                            <TableCell
                                className="category-table-header"
                                style={{ width: '20%', paddingRight: '10px' }}
                            >
                                {t('Result')}
                            </TableCell>
                            <TableCell
                                className="category-table-header"
                                style={{ width: '10%', padding: '20px' }}
                            >
                                {t('Comment')}
                            </TableCell>
                            <TableCell
                                style={{ width: '40px', padding: '0' }}
                            />
                        </TableRow>
                    </TableHead>

                    {inspectionCategory.items.map((item, index) => (
                        <InspectionItem key={index} item={item} />
                    ))}
                </Table>
            </div>
        );
    }
}

export default compose(withTranslation())(InspectionCategory);
