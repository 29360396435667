// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowInspection from '../../StatisticsView/StatisticsRow/StatisticsRowInspection';
import { connect } from 'react-redux';
import type { TStateStatisticsListData } from '../../../reducers/StatisticsReducer';
import { getFilteredAndGroupedInspectionAssigns } from '../../../selectors';
import { fetchInspectionAssignsPerYear } from '../../../actions/inspectionActions';
import getRoles from '../../../selectors/getRoles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { getCurrentEmployee } from '../../../actions';

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
    inspectionAssigns: Array<any>,
    fetchInspectionAssignsPerYear: () => mixed,
};

const mapStateToProps = (state) => {
    return {
        inspectionAssigns: getFilteredAndGroupedInspectionAssigns(
            state
        ).filter((inspection) =>
            getRoles(state).includes('CUMELA') ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.cumela ===
                    true) ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.caoLeo === true)
                ? inspection
                : inspection.inspection.source !== 'cumela'
        ),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        fetchInspectionAssignsPerYear: (year) => {
            dispatch(fetchInspectionAssignsPerYear(year));
        },
    };
};

class StatisticsListInspections extends React.PureComponent<Props, void> {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentWillMount() {
        this.props.fetchCurrentUser();
        if (
            this.props.inspectionAssigns === undefined ||
            this.props.inspectionAssigns === null ||
            this.props.inspectionAssigns.length < 1
        ) {
            this.props.fetchInspectionAssignsPerYear(this.props.year);
        }
    }
    componentWillReceiveProps(newProps: Props) {
        if (this.props.year !== newProps.year) {
            this.props.fetchInspectionAssignsPerYear(newProps.year);
        }
    }

    rowProvider = (inspectionAssign) => {
        if (!inspectionAssign || !inspectionAssign.inspection) {
            return undefined;
        }

        return (
            <StatisticsRowInspection
                key={inspectionAssign.id}
                handleRowClick={() => this.onRowClick(inspectionAssign)}
                title={inspectionAssign.inspection.title}
                latestVersion={inspectionAssign.latestVersion}
                totalSend={
                    inspectionAssign.assignList
                        ? inspectionAssign.assignList.length
                        : 0
                }
                totalEmployees={inspectionAssign.employeeCount}
                totalCompleted={inspectionAssign.finished}
                allOk={inspectionAssign.result ? inspectionAssign.result.allCorrect && !inspectionAssign.result.unsafe : false }
                logo={
                    inspectionAssign.inspection.source === 'cumela'
                        ? '/assets/images/cumela-logo.png'
                        : inspectionAssign.inspection.source === 'global'
                        ? '/assets/images/vvw-admin-logo.svg'
                        : inspectionAssign.inspection.rootInspectionSource ===
                              'global' ||
                          inspectionAssign.inspection.rootInspectionSource ===
                              'cumela'
                        ? '/assets/images/aangepast.svg'
                        : '/assets/images/vvw-logo.svg'
                }
            />
        );
    };

    headerProvider = () => {
        const { t } = this.props;
        return {
            logo: '',
            title: t('Title'),
            latestVersion: t('Latest version'),
            totalSend: t('Total send'),
            // totalEmployees: 'Tot. medewerkers',
            totalCompleted: t('Total completed'),
        };
    };

    onRowClick = (inspectionAssign) => {
        this.props.onItemClick(inspectionAssign);
    };

    render() {
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set(
            'title',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.inspection.title &&
                    inspectionAssignB.inspection.title
                ) {
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() >
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() <
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return -1;
                    }
                    return 0;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'totalCompleted',
            (inspectionAssignA, inspectionAssignB) => {
                if (inspectionAssignA.finished > inspectionAssignB.finished) {
                    return 1;
                }
                if (inspectionAssignA.finished < inspectionAssignB.finished) {
                    return -1;
                }
                if (inspectionAssignA.finished === inspectionAssignB.finished) {
                    if (
                        inspectionAssignA.totalEmployees >
                        inspectionAssignB.totalEmployees
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.totalEmployees <
                        inspectionAssignB.totalEmployees
                    ) {
                        return -1;
                    }
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'logo',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.inspection.source >
                    inspectionAssignB.inspection.source
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.inspection.source <
                    inspectionAssignB.inspection.source
                ) {
                    return -1;
                }
                return 0;
            }
        );

        let filteredinspectionAssigns = [];

        this.props.inspectionAssigns.forEach((inspection) => {
            let add = false;
            inspection.assignList.forEach((assign) => {
                let dontAdd = false;

                if (this.props.situatie && this.props.situatie !== 0) {
                    if (this.props.situatie === 1 && assign.result) {
                        if (assign.result.unsafe) {
                            dontAdd = true;
                        }
                    } else if (this.props.situatie === 2 && assign.result) {
                        dontAdd = true;
                        if (assign.result.unsafe) {
                            dontAdd = false;
                        }
                    } else if (!assign.result) {
                        dontAdd = true;
                    }
                }
                if (this.props.project && this.props.project !== 'all') {
                    if (
                        assign.projectId !== this.props.project
                    ) {
                        dontAdd = true;
                    }
                }
                if (this.props.uitvoerder && this.props.uitvoerder !== 0) {
                    if (assign.user.id !== this.props.uitvoerder) {
                        dontAdd = true;
                    }
                }
                if (this.props.afgerond && this.props.afgerond !== 0) {
                    if (
                        this.props.afgerond === 1 &&
                        (!assign.result || !assign.result.complete)
                    ) {
                        dontAdd = true;
                    } else if (
                        this.props.afgerond === 2 &&
                        assign.result &&
                        assign.result.complete
                    ) {
                        dontAdd = true;
                    }
                }
                if (this.props.resultaat && this.props.resultaat !== 0) {
                    if (
                        this.props.resultaat === 1 &&
                        (!assign.result || !assign.result.allCorrect)
                    ) {
                        dontAdd = true;
                    } else if (
                        this.props.resultaat === 2 &&
                        (!assign.result || assign.result.allCorrect)
                    ) {
                        dontAdd = true;
                    }
                }
                if (!dontAdd) {
                    add = true;
                }
            });

            if (add) {
                filteredinspectionAssigns.push(inspection);
            }
        });

        const data = List(
            filteredinspectionAssigns
                .filter((inspection) =>
                    this.props.searchtext
                        ? inspection.inspection.title
                              .toLowerCase()
                              .indexOf(this.props.searchtext.toLowerCase()) > -1
                        : true
                )
                .map((item) =>
                    Object.assign(item, {
                        totalSend: item.assignList.length,
                        totalEmployees: item.employeeCount,
                        totalCompleted: item.finished,
                    })
                )
        );

        return (
            <PaginatedSortFilterView
                data={data}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="title"
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListInspections);
