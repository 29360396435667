import React from 'react';
import {
	IconButton,
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import moment from "../../../lib/moment";
import i18n from "i18next";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
moment.locale(i18n.t("LOCALE"));

const Header = props => {
    const {speakup, onClose} = props;
    return (
		<div>
			<p
				style={{
					fontSize: "20px",
					marginLeft: "24px",
					float: "left",
				}}
			>
				{speakup
					? moment(speakup.date).format("LLL") +
					  ", " +
					  speakup.user.fullname 
					: "-"}
			</p>
			<IconButton
				style={{
					float: "right",
					marginTop: "10px",
				}}
				onClick={onClose}
			>
				<CloseIcon />
			</IconButton>
		</div>
	);
}

export default compose(withTranslation())(Header);