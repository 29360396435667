import { combineReducers } from 'redux';
import toolboxDraftsReducer from './toolboxDraftsReducer';
import contentBlockDraftsReducer from './contentBlockDraftsReducer';
import questionBlockDraftsReducer from './questionBlockDraftsReducer';
import employeesReducer from './employeesReducer';
import projectReducer from './projectsReducer';
import inspectionDraftsReducer from './inspectionDraftsReducer';
import subscriptionReducer from './subscriptionReducer';

const draftsReducer = combineReducers({
    toolboxes: toolboxDraftsReducer,
    inspections: inspectionDraftsReducer,
    contentBlocks: contentBlockDraftsReducer,
    questionBlocks: questionBlockDraftsReducer,
    employees: employeesReducer,
    projects: projectReducer,
    abonnee:subscriptionReducer
});

export default draftsReducer;
