// @flow
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    InputAdornment,
    Button, TextField,
} from '@mui/material';

import { connect } from 'react-redux';
import AppModule from '../AppModule';
import { loginWebclient, processFormWebclient, resetForm } from '../../actions';
import { LinkList, LinkListItem } from '../LinkList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { push } from 'connected-react-router';
import { getForm } from '../../selectors';
import ErrorMessage from '../ErrorMessage';
import LanguageDropdown from '../LanguageDropdown';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './login.module.scss'

type Props = {
    error: any,
    onSubmit: Function,
    onReset: Function,
    onNavigate: Function,
};

type State = {
    form: any,
    showPassword: boolean,
};

const mapStateToProps = (store, props) => {
    return getForm('login')(store);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSubmit: (username, password, redirect) => {
            dispatch(
                processFormWebclient(
                    'login',
                    loginWebclient(username.toLowerCase(), password, redirect)
                )
            );
        },
        onNavigate: (url) => {
            dispatch(push(url));
        },
        onReset: () => {
            dispatch(resetForm('login'));
        },
    };
};


class LoginClient extends React.Component<Props, State> {
    props: Props;
    state: State = {
        form: {
            username: '',
            password: '',
        },
        showPassword: false,
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    handleChange = (event) => {
        let text = event.target.value
        text = text.trim()
        if (event.target.id === 'username') {
            this.setState({
                form: {
                    ...this.state.form,
                    [event.target.id]: text,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [event.target.id]: event.target.value,
                },
            });
        }
    };

    backToIntro = () => {
        this.props.onNavigate('/web/intro');
    };

    handleLinkItemClick = (event) => {
        event.preventDefault();
        this.reset();
        this.props.onNavigate(event.target.href.replace(/^.*\/\/[^\/]+/, '')); // eslint-disable-line no-useless-escape
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPasssword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    submit() {
        const params = new URLSearchParams(this.props.location.search);
        this.props.onSubmit(
            this.state.form.username,
            this.state.form.password,
            params.get('redirect')
        );
    }

    reset() {
        this.setState({
            form: { username: '', password: '' },
            showPassword: false,
        });
        this.props.onReset();
    }

    componentWillMount() {}

    componentDidMount() {}

    render() {
        const { location, t } = this.props;
        let { error } = this.props;

        const messageParam = new URLSearchParams(location.search).get(
            'message'
        );
        if (error && error.message === 'Authentication Failed: Bad credentials') {
            error.message = t('Authentication Failed: The email address or password is invalid');
        }

        // window.$crisp.push(['do', 'chat:hide']);

        const buttonDisabled = !(
            this.state.form.username && this.state.form.password
        );

        return (
            <div
                className="App"
                style={{ backgroundColor: 'white', height: '100vh' }}
            >
                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        <Typography
                            className={classes.toolbarFontStyle}
                            type="title"
                            id="headerTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {process.env.REACT_APP_TITLE || 'Veilig Vakwerk'}
                        </Typography>
                        <LanguageDropdown
                            className={classes.dropdown}
                            labelColor={classes.labelColor}
                            selectRootColor={classes.selectRootColor}
                        />
                        {window.innerWidth > 768 && (
                            <Button
                                className={classes.toolbarFontStyle}
                                onClick={this.backToIntro}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    top: '15px',
                                }}
                                id="expandbutton"
                            >
                                {t('Back to introduction')}
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>

                <AppModule>
                    <form
                        onSubmit={this.handleSubmit}
                        id="form"
                        className={classes.loginform}
                    >
                        <h2 className={classes.title}>
                            {t('Login')}
                        </h2>
                        <p style={{ textAlign: 'center', opacity: '0.6' }}>
                            {t('Enter the information below to log in')}
                        </p>
                        <ErrorMessage error={error} />
                        {messageParam && (
                            <div className={classes.message}>
                                {this.renderMessageParam(messageParam)}
                            </div>
                        )}
                        <FormControl>
                            <TextField
                                id="username"
                                label={t('Emailadres')}
                                variant="standard"
                                onChange={this.handleChange}
                                value={this.state.form.username}
                                defaultValue={t('Emailadres')}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="password"
                                label={t('Password')}
                                variant="standard"
                                type={ this.state.showPassword ? 'text' : 'password'}
                                onChange={this.handleChange}
                                value={this.state.form.password}
                                defaultValue={t('Password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.handleClickShowPasssword}
                                                        onMouseDown={this.handleMouseDownPassword}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-10px',
                                                            right: '0px',
                                                        }}
                                                        size="large">
                                                {this.state.showPassword ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                        <br />
                        <Button
                            variant="contained"
                            disabled={buttonDisabled}
                            color="primary"
                            type="submit"
                            className={classes.button}
                        >
                            {t('Login')}
                        </Button>
                        <LinkList>
                            <LinkListItem
                                onClick={this.handleLinkItemClick}
                                href="/web/login/help"
                            >
                                {t('Help with logging in')}
                            </LinkListItem>
                        </LinkList>
                    </form>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(LoginClient);
