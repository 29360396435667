// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import {
    setCrumbPath,
    inviteEmployee,
    editEmployee,
    fetchUsergroups,
} from '../../../actions';
import { inProgress, getPermissions } from '../../../selectors';
import moment from '../../../lib/moment';

import TitleDescription from '../../TitleDescription';
import EmployeeEdit from '../EmployeeEdit';

import './EmployeeInformation.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './EmployeeInformation.module.scss';

type Props = {
    loading: boolean,
    toolboxes: Array<any>,
    sectors: Array<any>,
    categories: Array<any>,
    inviteEmployee: (employeeId: number) => void,
    startEditEmployee: (employee: TEmployee) => void,
};

type State = {};


const mapDispatchToProps = (dispatch) => {
    return {
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        setCrumbPath: (employeeName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Medewerker',
                    crumbs: [
                        { name: employeeName, link: '/medewerker#informatie' },
                    ],
                })
            );
        },
        startEditEmployee: bindActionCreators(editEmployee, dispatch),
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
    };
};

const mapStateToProps = (store, props) => {
    let usergroups = store.entities.usergroups;

    return {
        allUsergroups: usergroups,
        usergroups: usergroups.allIds.map((id) => usergroups.byId[id]),
        loading: !inProgress(store),
        sectorEntities: store.entities.sectors,
        categoryEntities: store.entities.categories,
        permissions: getPermissions(store),
        showDetailDialog: window.location.href.endsWith('dialog'),
    };
};

class EmployeeInformation extends React.Component<Props, State> {
    state: State = {
        showDetailDialog: true,
    };

    sendActivation = () => {
        this.props.inviteEmployee(this.props.employee.id);
    };

    getGroupnamesFromUser = (employeeId) => {
        let groupnames = [];
        this.props.usergroups.forEach((usergroup) => {
            usergroup.users.forEach((user) => {
                if (employeeId === user.id) {
                    groupnames.push(usergroup.name);
                }
            });
        });
        return groupnames;
    };

    render() {
        const { style, employee, editemployee, t } = this.props;

        if (!employee) {
            return <div />;
        }
        const groupnames = this.getGroupnamesFromUser(employee.id);
        let groups = groupnames
            ? groupnames.map((group) => group).join(', ')
            : t('Not a member of a group yet');

        return (
            <div>
                <div style={style} className={classes.root}>
                    <h2>{t('Personal data')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('First name')}
                        itemDescription={employee.firstname}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Last name')}
                        itemDescription={
                            employee.middlename
                                ? employee.middlename + ' ' + employee.lastname
                                : '' + employee.lastname
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Employee number')}
                        itemDescription={employee.externalId}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Emailadres')}
                        itemDescription={employee.username}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Member of groups')}
                        itemDescription={groups}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Phone number')}
                        itemDescription={
                            employee.phonenumber ? employee.phonenumber : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Added on')}
                        itemDescription={
                            employee.addedDate
                                ? moment(employee.addedDate).format('L HH:mm')
                                : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('App version')}
                        itemDescription={
                            employee.appVersion ? employee.appVersion : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Language')}
                        itemDescription={
                            employee.language ? employee.language : '-'
                        }
                    />

                    <TitleDescription className={classes.item} itemTitle={'Medewerkernummer'} itemDescription={employee.externalId} />
                    <TitleDescription className={classes.item} itemTitle={'E-mailadres'} itemDescription={employee.username} />
                    <TitleDescription className={classes.item} itemTitle={'Bedrijfsnaam'} itemDescription={employee.companyname} />
                    <TitleDescription className={classes.item} itemTitle={'Lid van groepen'} itemDescription={groups} />
                    <TitleDescription className={classes.item} itemTitle={'Telefoonnummer'} itemDescription={employee.phonenumber ? employee.phonenumber : '-'} />
                    <TitleDescription className={classes.item} itemTitle={'Toegevoegd op'} itemDescription={employee.addedDate ? moment(employee.addedDate).format('L HH:mm') : '-'} />
                    <TitleDescription className={classes.item} itemTitle={'App versie'} itemDescription={employee.appVersion ? employee.appVersion  : '-'} />

                </div>
                {employee.state === 'ACTIVE' && (
                    <div style={style} className={classes.root}>
                        <h2>{t('Account settings')}</h2>
                        <TitleDescription
                            itemTitle={t(
                                'Send new activation mail to employee'
                            )}
                            itemDescription={
                                <Button
                                    className="employee-resend-activation-button"
                                    onClick={this.sendActivation}
                                    
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('Send')}
                                </Button>
                            }
                        />
                    </div>
                )}
                <EmployeeEdit
                    open={this.props.showDetailDialog}
                    editemployee={editemployee}
                    pageAfterSave={'/medewerker/details/' + employee.id}
                />
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(EmployeeInformation);
