import { createSelector } from 'reselect'

const getForms = state => state.ui.forms;

const getForm = formName => {
    return createSelector(
        [getForms],
        (forms) => {
            return forms[formName] || { processing: false, error: null, result: null }
        }
    );
}

export default getForm;
