// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Paper } from '@mui/material';

import classes from './DashboardTotal.module.scss';

type Props = {
    text: string,
    value: mixed,
    units: string,
};

type State = {};


class DashboardTotal extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { text, units, value,image, small } = this.props;

        return (
            <div className={classes.root} style={small ? {width: 'calc(20% - 16px)'} : {}}>
                <Paper elevation={4} className={classes.paper}>
                    <div className={classes.title}>{text}</div>
                    <div>
                        <div className={classes.value}>{value}</div>
                        <div className={classes.unit}>{units}</div>
                        <div className={classes.image}>{image}</div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default (DashboardTotal);
