import _ from 'lodash';
import * as api from '../lib/api';
import { push } from 'connected-react-router';
import { openDialog } from './uiActions';

export const FETCH_ALL_REQUEST = 'FETCH_ALL_REQUEST';
export const FETCH_ALL_SUCCESS = 'FETCH_ALL_SUCCESS';
export const FETCH_ALL_FAILURE = 'FETCH_ALL_FAILURE';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAILURE = 'CREATE_FAILURE';

export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';

export function apiError(error) {
    return (dispatch) => {
        if (error.status === 401) {
            dispatch(push('/auth/login'));
        } else if (error.status >= 500) {
            dispatch(openDialog('error'));
        }
    };
}

export function fetchAll(type, query = {}) {
    return (dispatch) => { 
        dispatch(fetchAllRequest(type));

        return api
            .fetchAll(type, query)
            .then((result) => {
                dispatch(fetchAllSuccess(type, result));
                return result;
            })
            .catch((error) => {
                dispatch(fetchAllFailure(type, error));
                dispatch(apiError(error));
                throw error;
            });
    };
}

export function create(type, data) {
    return (dispatch) => {
        dispatch(createRequest(type, data));
        return api
            .create(type, data)
            .then((result) => {
                dispatch(createSuccess(type, result));
                return result;
            })
            .catch((error) => {
                dispatch(createFailure(type, error));
                dispatch(apiError(error));
                throw error;
            });
    };
}

export function del(type, data) {
    return (dispatch) => {
        dispatch(deleteRequest(type, data));
        return api
            .del(type, data)
            .then((result) => {
                dispatch(deleteSuccess(type, result));
                return result;
            })
            .catch((error) => {
                dispatch(deleteFailure(type, error));
                dispatch(apiError(error));
                throw error;
            });
    };
}

const upload = _.curry((type, file) => {
    return (dispatch) => {
        dispatch(uploadRequest(type));
        return api
            .upload(type, file)
            .then((result) => {
                dispatch(uploadSuccess(type, result));
                return result;
            })
            .catch((error) => {
                dispatch(uploadFailure(type, error));
                throw error;
            });
    };
});

const uploadpdf = _.curry((type, file, id) => {
    return (dispatch) => {
        dispatch(uploadRequest(type));
        return api
            .upload(type, file)
            .then((result) => {
                dispatch(uploadSuccess(type, result));
                result[0].blockId = id;
                return result;
            })
            .catch((error) => {
                dispatch(uploadFailure(type, error));
                throw error;
            });
    };
});
export const uploadPhoto = upload('photo');
export const uploadPdf = uploadpdf('pdf');
export const uploadDocument = upload("document");

export function fetchAllRequest(type) {
    return {
        type: FETCH_ALL_REQUEST,
        payload: { type },
    };
}

export function fetchAllSuccess(type, entities) {
    return {
        type: FETCH_ALL_SUCCESS,
        payload: { type, entities },
    };
}

export function fetchAllFailure(type, error) {
    return {
        type: FETCH_ALL_FAILURE,
        payload: { type, error },
    };
}

export function createRequest(type, data) {
    return {
        type: CREATE_REQUEST,
        payload: { type, data },
    };
}

export function createSuccess(type, entity) {
    return {
        type: CREATE_SUCCESS,
        payload: { type, entity },
    };
}

export function createFailure(type, error) {
    return {
        type: CREATE_FAILURE,
        payload: { type, error },
    };
}

export function deleteRequest(type, data) {
    return {
        type: DELETE_REQUEST,
        payload: { type, data },
    };
}

export function deleteSuccess(type, entity) {
    return {
        type: DELETE_SUCCESS,
        payload: { type, entity },
    };
}

export function deleteFailure(type, error) {
    return {
        type: DELETE_FAILURE,
        payload: { type, error },
    };
}

export function uploadRequest(type) {
    return {
        type: UPLOAD_REQUEST,
        payload: { type },
    };
}

export function uploadSuccess(type, data) {
    return {
        type: UPLOAD_SUCCESS,
        payload: { type, data },
    };
}

export function uploadFailure(type, error) {
    return {
        type: UPLOAD_FAILURE,
        payload: { type, error },
    };
}
