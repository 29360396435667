import React from 'react';
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import TitleDescription from "../../TitleDescription";
import Attachments from './Attachments';
import Block from './Block';

const View = props => {
    const {
		t,
		onAttachmentClick,
		classes,
		speakup = {},
		speakupcompany,
		speakupContacts,
	} = props;

    return (
		<React.Fragment>
			<Block title={t("Speak-Up! details")}>
				{speakupContacts &&
					speakupContacts.byId &&
					speakup &&
					speakup.contact && (
						<TitleDescription
							className={classes.item2}
							itemTitle={t("Contact person")}
							itemDescription={t(speakupContacts.byId[speakup.contact].locKey)}
						/>
					)}
				{speakupcompany &&
					speakupcompany.byId &&
					speakup &&
					speakup.company && (
						<TitleDescription
							className={classes.item2}
							itemTitle={t("Company")}
							itemDescription={
								speakupcompany.byId[speakup.company].name
							}
						/>
					)}
				<TitleDescription
					className={classes.item2}
					itemTitle={t("Description")}
					itemDescription={
						speakup && speakup.remark.text
							? speakup.remark.text
							: "-"
					}
				/>
			</Block>
			<Block title={t("Attachments")}>
				<TitleDescription
					className={classes.item2}
					itemTitle={t("Images")}
					itemDescription={
						speakup && speakup.remark.photos.length > 0 ? "" : "-"
					}
				/>
				{speakup && speakup.remark.photos.length > 0 && (
					<Attachments
						photos={speakup.remark.photos}
						onClick={onAttachmentClick}
					/>
				)}
			</Block>
		</React.Fragment>
	);
}

export default compose(
    withTranslation()
)(View);