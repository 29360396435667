// @flow
import { List } from 'immutable';
import TypedRecord from '../components/Util/IRecord';
import type { TProjectAction } from '../actions/ProjectActions';

export type TProject = {
    id?: number,
    externalId?: string,
    omschrijving?: string,
    opdrachtgever?: string,
    active?: boolean,
    importType: 'MANUAL' | 'IMPORT',
    subscriptionHolder?: TProject
};

export class TStateProject extends TypedRecord({
    didInvalidate: true,
    lastUpdated: 0,
    error: null,
    projects: List(),
    editproject: null,
    diff: null,
    inProgress: false
}) {
    didInvalidate: boolean;
    lastUpdated: number;
    error: ?mixed;
    projects: List<TProject>;
    editproject: TProject;
    inProgress: boolean;
    diff: { removed: List<TProject>, added: List<TProject> };
}

const initialState = new TStateProject();

export default function ProjectsReducer(state: TStateProject = initialState, action: TProjectAction) {
    switch (action.type) {
        case 'PROJECT_IMPORT_SUCCESS': {
            state = state.set('inProgress', false);
            return state.set('diff', action.diff);
        }
        case 'PROJECT_CLEAR_DIFF': {
            return state.set('diff', null);
        }
        case 'PROJECT_IMPORT_ERROR': {
            return state.set('inProgress', false);
        }
        case 'PROJECT_IMPORT_START': {
            return state.set('inProgress', true);
        }
        case 'PROJECT_EDIT': {
            return state.set('editproject', action.project);
        }
        default:
            return state;
    }
}
