import { fetchAll, create } from './apiActions';
import { setEntities, setEntity, removeEntity } from './entityActions';
import { push } from 'connected-react-router';
import { closeDialog, showSnackbar } from './uiActions';
import * as api from '../lib/api';
import i18n from 'i18next';

export function fetchUsergroups() {
    return (dispatch) => {
        return fetchAll('usergroup')(dispatch).then((entities) => {
            dispatch(setEntities('usergroups', entities));
        });
    };
}

export function createUsergroup(usergroupData, redirect = false) {
    return (dispatch) => {
        return create(
            'usergroup',
            usergroupData
        )(dispatch).then((entity) => {
            dispatch(closeDialog('usergroupCreate'));
            dispatch(setEntity('usergroups', { ...usergroupData, ...entity }));
            if (redirect) {
                dispatch(push('/groep/details/' + entity.id));
            }
            return { ...usergroupData, ...entity };
        });
    };
}

export function deleteUsergroup(usergroupData) {
    return (dispatch) => {
        api.deleteUsergroup('usergroup', usergroupData)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Group')} ` +
                            usergroupData.name +
                            ` ${i18n.t('Is deleted')}`
                    )
                );
                dispatch(removeEntity('usergroups', { ...usergroupData }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Group can not be deleted')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function addUserToUsergroup(usergroupId, employeeId) {
    return (dispatch) => {
        api.addUserToUsergroup('usergroup', usergroupId, employeeId)
            .then((entity) => {
                dispatch(setEntity('usergroups', entity.body)); // add new version
                dispatch(showSnackbar(i18n.t('Employee has been added')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not add employee')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function removeUserFromUsergroup(usergroupId, employeeId) {
    return (dispatch) => {
        api.removeUserFromUsergroup('usergroup', usergroupId, employeeId)
            .then((entity) => {
                dispatch(setEntity('usergroups', entity.body)); // add new version
                dispatch(showSnackbar(i18n.t('Employee deleted')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not delete employee')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}
