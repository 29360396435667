//@flow
import * as api from '../lib/api';
import type { PlainDispatch } from 'redux';

type TDAFetchDashboard = {
    type: 'FETCH_DASHBOARD_SUCCESS',
    payload: any,
};

type TDAFetchStart = {
    type: 'FETCH_DASHBOARD_START',
};

export type TDashboardActions = TDAFetchDashboard | TDAFetchStart;

export function fetchDashboard() {
    return (dispatch: PlainDispatch<TDashboardActions>) => {
        dispatch({
            type: 'FETCH_DASHBOARD_START',
        });

        return api.fetchDashboard().then((response) => {
            dispatch({
                payload: response.body,
                type: 'FETCH_DASHBOARD_SUCCESS',
            });
        });
    };
}
