// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import ConfirmRegistrationForm from './ConfirmRegistrationForm';
import RetrievePinPage from './RetrievePinPage';
import LanguageDropdown from '../LanguageDropdown';
import classes from './AuthPage.module.scss';


type Props = {};

const AuthPage = (props: Props) => {
    return (
        <div className={classes.AuthPage}>
            <LanguageDropdown
                className={classes.filter}
                labelColor={classes.labelColor}
                selectRootColor={classes.selectRootColor}
            />
            <Switch>
                <Route path="/auth/login" component={LoginForm} />
                <Route path="/auth/forgot" component={ForgotPasswordForm} />
                <Route path="/auth/reset" component={ResetPasswordForm} />
                <Route
                    path="/auth/confirm"
                    component={ConfirmRegistrationForm}
                />
                <Route path="/auth/pin" component={RetrievePinPage} />
            </Switch>
            <a href="https://veiligvakwerk.nl/">
                <img
                    className={classes.AuthPage__logo}
                    src="/assets/images/vvw-inverse.png"
                    alt="Veilig Vakwerk"
                />
            </a>
        </div>
    );
};

export default compose()(AuthPage);
