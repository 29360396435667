import { FETCH_ALL_REQUEST, FETCH_ALL_SUCCESS, FETCH_ALL_FAILURE } from '../actions';

const initialState = {
    inProgress: [],
    errors: []
}

const apiReducer = (state = initialState, action= {}) => {
    switch (action.type) {
        case FETCH_ALL_REQUEST: {
            return {
                ...state,
                inProgress: [...state.inProgress, action]
            };
        } case FETCH_ALL_SUCCESS: {
            return {
                ...state,
                inProgress: state.inProgress.filter(loadingAction => loadingAction.type !== FETCH_ALL_REQUEST || loadingAction.payload.type !== action.payload.type)
            };
        } case FETCH_ALL_FAILURE: {
            return {
                ...state,
                inProgress: state.inProgress.filter(loadingAction => loadingAction.type !== FETCH_ALL_REQUEST || loadingAction.payload.type !== action.payload.type),
                errors: [ ...state.errors, action.payload.error ]
            }
        } default:
            return state;
    }
}

export default apiReducer;
