import { createSelector } from 'reselect';
import getRoles from './getRoles';

const ROLE_CUMELA = 'CUMELA';

const getPermissions = createSelector([getRoles], roles => ({
    toolboxes: {
        editTemplate: roles.includes(ROLE_CUMELA),
        view: true,
        create: true,
        deploy: true,
        edit: true,
        delete: true
    },
    employees: {
        view: true
    },
    projecten: {
        view: true
    },
    settings: {
        view: false
    },
    notifications: {
        view: false
    },
    information: {
        view: false
    },
    inspections: {
        create: true,
        deploy: true,
        edit: true,
        view: true,
        delete: true
    },
    subscription: {
        view: roles.includes('SUBSCRIPTIONHOLDER') && !roles.includes('ADMIN') && !roles.includes('CUMELA')
    },

    abonnees: {
        view: roles.includes('ADMIN')
    },
    abonneesCUMELA: {
        view: roles.includes('CUMELA')
    },
    publish: {
        toolbox: roles.includes('CUMELA') || roles.includes('JANSSENDEJONG'),
        inspection: roles.includes('CUMELA') || roles.includes('JANSSENDEJONG')
    }
}));

export default getPermissions;
