// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
    InputAdornment,
    IconButton,
    Button,
    Input,
    Paper,
    Typography,
    InputLabel,
    FormControl,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { processForm, resetForm, resetPassword, login } from '../../actions';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import { getForm } from '../../selectors';
import { compose } from 'recompose';
import Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import classes from './ResetPasswordForm.module.scss';

type Props = {
    processing: boolean,
    result: any,
    error: any,
    onSubmit: Function,
    onReset: Function,
    onLogin: Function,
    showPassword: boolean,
    showPasswordRepeat: boolean,
};

type State = {
    form: any,
};

const mapStateToProps = (store) => {
    return getForm('resetPassword')(store);
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (newPassword, token, userId) => {
            dispatch(
                processForm(
                    'resetPassword',
                    resetPassword(newPassword, token, userId)
                )
            );
        },
        onLogin: (username, password) => {
            dispatch(processForm('resetPassword', login(username, password)));
        },
        onReset: () => {
            dispatch(resetForm('resetPassword'));
        },
    };
};

class ResetPasswordForm extends React.Component {
    props: Props;
    state: State = {
        form: {
            newPassword: '',
            newPasswordRepeat: '',
        },
        showPasswordRepeat: false,
        showPassword: false,
    };

    componentWillMount() {
        const { i18n } = this.props;
        const lang = new URLSearchParams(window.location.search).get(
            'language'
        );

        if (lang) {
            i18n.changeLanguage(lang);
            Cookies.set('lang', lang, { expires: 365 });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    handleChange = (event) => {
        this.setState({
            form: { ...this.state.form, [event.target.id]: event.target.value },
        });
    };

    handleLoginClick = (event) => {
        event.preventDefault();
        this.props.onLogin(
            this.props.result.username,
            this.state.form.newPassword
        );
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPasssword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPasswordRepeat = (event) => {
        event.preventDefault();
    };

    handleClickShowPassswordRepeat = () => {
        this.setState({ showPasswordRepeat: !this.state.showPasswordRepeat });
    };

    submit() {
        const params = new URLSearchParams(this.props.location.search);
        this.props.onSubmit(
            this.state.form.newPassword,
            params.get('resetToken'),
            params.get('userId')
        );
    }

    render() {
        const { result, error, processing, t } = this.props;
        const success = result && !error;
        const message = success ? t('Password has been changed') : '';

        return (
            <div>
                <Paper elevation={4} className={classes.paper}>
                    <Typography
                        variant="h1"
                        type="display1"
                        gutterBottom={true}
                        align="center"
                    >
                        {t('Reset password')}
                    </Typography>
                    <Loader size={100} loading={processing}>
                        <ErrorMessage error={error} />
                        <div className={classes.message}>{message}</div>
                        {!success && (
                            <form onSubmit={this.handleSubmit}>
                                <FormControl>
                                    <InputLabel htmlFor="newPassword">
                                        {t('Password')}
                                    </InputLabel>
                                    <Input
                                        fullWidth={true}
                                        id="newPassword"
                                        inputProps={{ autoComplete: 'off' }}
                                        onChange={this.handleChange}
                                        placeholder={t('Password')}
                                        required
                                        type={
                                            this.state.showPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={this.state.form.newPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        this
                                                            .handleClickShowPasssword
                                                    }
                                                    onMouseDown={
                                                        this
                                                            .handleMouseDownPassword
                                                    }
                                                    size="large">
                                                    {this.state.showPassword ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl>
                                    <InputLabel htmlFor="newPasswordRepeat">
                                        {t('Password again')}
                                    </InputLabel>
                                    <Input
                                        fullWidth={true}
                                        id="newPasswordRepeat"
                                        inputProps={{ autoComplete: 'off' }}
                                        onChange={this.handleChange}
                                        placeholder={t('Password again')}
                                        required
                                        type={
                                            this.state.showPasswordRepeat
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={
                                            this.state.form.newPasswordRepeat
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        this
                                                            .handleClickShowPassswordRepeat
                                                    }
                                                    onMouseDown={
                                                        this
                                                            .handleMouseDownPasswordRepeat
                                                    }
                                                    size="large">
                                                    {this.state
                                                        .showPasswordRepeat ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.button}
                                >
                                    {t('Send')}
                                </Button>
                            </form>
                        )}
                        {success && (
                            <Button
                                
                                variant="contained"
                                onClick={this.handleLoginClick}
                                color="primary"
                                className={classes.button}
                            >
                                {t('Login now')}
                            </Button>
                        )}
                    </Loader>
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ResetPasswordForm);
