import { createSelector } from 'reselect'

const getContentBlockTypeEntities = store => store.entities.contentBlockTypes;

export const getContentBlockTypes = createSelector(
    [getContentBlockTypeEntities],
    (contentBlockTypeEntities) => {
        return contentBlockTypeEntities.allIds.map(id => contentBlockTypeEntities.byId[id]);
    }
)

export const getContentBlockTypesForToolbox = createSelector(
    [getContentBlockTypes],
    (contentBlockTypes) => {
        return contentBlockTypes.filter(contentBlockType => contentBlockType.for.includes('toolbox'));
    }
)

export const getContentBlockTypesForInspection = createSelector(
    [getContentBlockTypes],
    (contentBlockTypes) => {
        return contentBlockTypes.filter(contentBlockType => contentBlockType.for.includes('inspection'));
    }
)
