import React from 'react';

const Attachments = props => {
    const {photos = [], onClick} = props;
    return (
		<div
			style={{
				maxWidth: "550px",
				marginTop: "16px",
			}}
		>
			{photos.map(photo => (
                <img
                    src={
                        photo.url
                    }
                    alt=""
                    style={{
                        width: "64px",
                        height: "64px",
                        marginRight:
                            "8px",
                        marginBottom:
                            "8px",
                        borderRadius:
                            "8px",
                    }}
                    onClick={() => onClick(photo.id)}
                />  
            ))}
		</div>
	);
}

export default Attachments