// @flow
import React from 'react';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import { fetchWebclientHelp, setCrumbPath } from '../../../actions';
import classes from './Help.module.scss';
import { push } from 'connected-react-router';
import { inProgress } from '../../../selectors';
import TableActionHeader from '../../TableActionHeader';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    toolboxId: string,
    toolbox: Array<any>,
};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        help: store.entities.help.byId.undefined,
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        goTo: (path) => {
            dispatch(push(path));
        },
        getHelptext: (t) => {
            dispatch(fetchWebclientHelp(t));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Help' }));
        },
    };
};

class Help extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentWillMount() {
        const { t } = this.props;
        this.props.setCrumbPath();
        this.props.getHelptext(t);
    }

    componentDidMount() {}

    render() {
        const { loading, help, t } = this.props;

        // window.$crisp.push(['do', 'chat:hide']);

        const tableActionHeader = (
            <TableActionHeader title={t('Help using web app')} />
        );

        return (
            <div className="App">
                <AppModule
                    className="appModule"
                    loading={loading}
                    prepend={tableActionHeader}
                >
                    <div
                        className={classes.paper}
                        id="tbExternPaperMain"
                        style={{
                            minWidth: '128px',
                            maxWidth: '768px',
                            margin: '0 auto',
                        }}
                    >
                        <h1 style={{ wordWrap: 'break-word' }}>
                            {help && help.faq[1].title}
                        </h1>
                        {help &&
                            help.faq[1].items.map((question) => {
                                return (
                                    <div style={{ marginBottom: '50px' }}>
                                        <div>
                                            <b>{question.question}</b>
                                        </div>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: question.answer,
                                            }}
                                        ></div>
                                    </div>
                                );
                            })}
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(Help);
