// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import Icon from '@mui/material/Icon';
import './BlockTypeCard.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    blockType: any,
};

type State = {};

class BlockTypeCard extends React.Component {
    props: Props;
    state: State = {};

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.blockType !== this.props.blockType) {
            return true;
        }
        return false;
    }

    render() {
        let { blockType, isDragging, onClick, style, t } = this.props;
        let classes = classnames({
            BlockTypeCard: true,
            'BlockTypeCard-isDragging': isDragging,
        });

        return (
            <div className={classes} style={style} onClick={onClick}>
                <div className="block-content">
                    <span className="BlockTypeCard-icon">
                        <Icon className="material-icons">{blockType.icon}</Icon>
                    </span>
                    <span className="BlockTypeCard-name">{t(blockType.name)}</span>
                    <span className="BlockTypeCard-hover-text">
                        {t('Click or drag to add')}
                    </span>
                </div>
            </div>
        );
    }
}

export default compose(withTranslation())(BlockTypeCard);
