import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode';

const getToken = state => state.auth.token;

const getDecodedToken = createSelector(
    [getToken],
    (token) => {
        try {
            return jwtDecode(token);
        } catch(error) {
            return {};
        }
    }
);

export default getDecodedToken;
