// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { muiThemeLegacy } from '../../lib/theme';
import PropTypes from 'prop-types';

type Props = {
};

type State = {};

class LegacyThemeProvider extends React.Component {
    props: Props;
    state: State = {};

    getChildContext() {
        return { muiTheme: muiThemeLegacy }
    }

    render() {
        return this.props.children;
    }
}

LegacyThemeProvider.childContextTypes = {
    muiTheme: PropTypes.object
}

export default LegacyThemeProvider;
