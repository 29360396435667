// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import PhotosBlockPreview from './PhotosBlockPreview';
import TextBlockPreview from './TextBlockPreview';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './QuestionPreview.module.scss';

type Props = {
    classes: any,
    index: number,
    question: any,
    format: any,
};


const QuestionPreview = (props: Props) => {
    const { index, question, format, t, contentBlockDrafts } = props;
    let textBlock;
    let photosBlock;

    question.blocks.forEach((blockId) => {
        const block = contentBlockDrafts[blockId];
        if (block) {
            if (block.textBlock) textBlock = block.textBlock;
            else if (block.photosBlock) photosBlock = block.photosBlock;
        } else {
            if (question.blocks[blockId].textBlock)
                textBlock = question.blocks[blockId].textBlock;
            else if (question.blocks[blockId].photosBlock)
                photosBlock = question.blocks[blockId].photosBlock;
        }
    });

    return (
        <div className={classes.root}>
            <h3>
                {t('Question')} {index + 1}
            </h3>
            <div className={classes.questionQuestion}>{question.title && question.title}</div>

            {textBlock && <TextBlockPreview block={textBlock} />}
            {photosBlock && (
                <PhotosBlockPreview block={photosBlock} format={format} />
            )}
            <ol>
                {question.possibleAnswers.map((answer, i) => (
                    <li
                        key={answer.id}
                        style={{
                            listStyleType: 'upper-alpha',
                            fontWeight: 'bolder',
                        }}
                    >
                        <span style={{ fontWeight: 'normal' }}>
                            {answer.text}
                        </span>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default compose(withTranslation(), )(QuestionPreview);
