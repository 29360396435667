/* @flow */

import * as Immutable from 'immutable';

/**
 * This is a restricted typed interface to `immutable.Record`.
 *
 * Notably, the `get` and `set` methods are missing, because they expose
 * holes in the type system.
 *
 * @private
 */
declare class IRecord<T: Object> {
    /**
     * Create a new typed immutable record with the values given.
     *
     * @memberof TypedRecord
     * @param {$Shape<T>} values The values to initialize this record with.
     * @returns {$Subtype<IRecord<T>>} The record instance.
     */
    constructor(values: $Shape<T>): void;

    /**
     * @memberof TypedRecord
     * @returns {string} A string representation of this record.
     */
    inspect(): string;

    /**
     * Set the given key to the given value.
     *
     * @memberof TypedRecord
     * @returns {$Subtype<IRecord<T>>} The updated record instance.
     */
    set<A>(key: $Keys<T>, value: A): $Subtype<IRecord<T>>;

    /**
     * Update the given key to the value returned by the given function.
     *
     * @memberof TypedRecord
     * @returns {$Subtype<IRecord<T>>} The updated record instance.
     */
    update<A>(key: $Keys<T>, updater: (value: A) => A): $Subtype<IRecord<T>>;

    /**
     * @memberof TypedRecord
     * @param {$Shape<T>} values
     *   Values to replace in the current record.
     * @returns {$Subtype<Record<T>>}
     *   A new record with the given values.
     */
    merge(values: $Shape<T>): $Subtype<IRecord<T>>;

    /**
     * Apply a batch of mutations to this record. This may be more efficient
     * than calling `merge` multiple times.
     *
     * @memberof TypedRecord
     * @param mutator
     *   A mutator function that accepts the current record and returns a
     *   new one.
     * @returns {$Subtype<IRecord<T>>} The new record.
     */
    withMutations(
        mutator: (mutable: $Subtype<IRecord<T>>) => $Subtype<IRecord<T>>,
    ): $Subtype<IRecord<T>>;

    /**
     * Convert this record to a normal Javascript object.
     *
     * @memberof TypedRecord
     * @return {Object} A plain Javascript object of this record.
     */
    toJS(): Object;
}

/**
 * Converts an `immutable.Record` specification to a class constructor for
 * typed immutable records. The return value of this function is a **class**
 * and not an instance of a class. The instance methods of the class returned
 * are documented on this function.
 *
 * The parameter doubles as both the type specification for this record and
 * as the initial default values used whenever a record is constructed.
 *
 * **Example usage:**
 *
 * ```
 * class Foo extends TypedRecord({x: 0, y: 'foo'}) {
 *   x: number;
 *   y: string;
 * }
 *
 * const foo1 = new Foo({x: 5});
 * expect(foo1.x).toEqual(5);
 * expect(foo1.y).toEqual('foo');
 *
 * // This won't type check.
 * const foo2 = foo1.merge({x: 'not a number'});
 * // Neither will this.
 * const foo3 = new Foo({y: 5});
 * ```
 *
 * @function
 */
export default function TypedRecord<T: Object>(spec: T): Class<IRecord<T>> {
  return Immutable.Record(spec);
}
