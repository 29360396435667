import { PROCESS_FORM_REQUEST, PROCESS_FORM_SUCCESS, PROCESS_FORM_FAILURE, RESET_FORM } from '../actions';

const initialState = {};

function formsReducer(state = initialState, action = {}) {
    switch(action.type) {
        case PROCESS_FORM_REQUEST: {
            const newState = { ...state };
            newState[action.payload.name] = { processing: true, error: null, result: null };
            return newState;
        } case PROCESS_FORM_SUCCESS: {
            const newState = { ...state };
            newState[action.payload.name] = { processing: false, error: null, result: { ...action.payload.result } };
            return newState;
        } case PROCESS_FORM_FAILURE: {
            const newState = { ...state };
            newState[action.payload.name] = { processing: false, error: { ...action.payload.error }, result: null };
            return newState;
        } case RESET_FORM: {
            const newState = { ...state };
            newState[action.payload.name] = { ...initialState[action.payload.name] };
            return newState;
        } default: {
            return state;
        }
    }
}

export default formsReducer;
