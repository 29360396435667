import moment from "../../lib/moment";
import i18n from "i18next";
moment.locale(i18n.t("LOCALE"));

export const filter = (obj) => {
	let arr = [...obj.array];

	arr = arr.filter((i) =>
		obj.employees && obj.employees !== 0
			? i.user.id === obj.employees
			: true
	);

	if (obj.periodType === "year") {
		arr = arr.filter(
			(i) => moment(i.date).year() === obj.periodYear
		);
	} else if (obj.periodType === "month") {
		arr = arr
			.filter((i) => moment(i.date).year() === obj.periodYear)
			.filter((i) => moment(i.date).month() === obj.periodMonth);
	} else if (obj.periodType === "week") {
		arr = arr
			.filter((i) => moment(i.date).year() === obj.periodYear)
			.filter((i) => moment(i.date).week() === obj.periodWeek);
	} else if (obj.periodType === "fromtill") {
		arr = arr.filter(
			(i) =>
				moment(i.date) > moment(obj.periodFrom) &&
				moment(i.date) < obj.periodTill
		);
	}

	return arr;
};


export const speakupSorter = () => {
	return {
		deployed: (a, b) => {
			a = a.date;
			b = b.date;

			if (a == null) {
				return -1;
			}
			if (b == null) {
				return 1;
			}

			a = moment(a).valueOf();
			b = moment(b).valueOf();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		title: (a, b) => {
			a = a.remark.text.toLowerCase();
			b = b.remark.text.toLowerCase();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		from: (a, b) => {
			a = a.user.fullname.toLowerCase();
			b = b.user.fullname.toLowerCase();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		attachments: (a, b) => {
			a = a.remark.photos.length;
			b = b.remark.photos.length;

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
	};
};