// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import classes from './ListBlockPreview.module.scss';

type Props = {
    block: any,
    classes: any,
};


const ListBlockPreview = (props: Props) => {
    const { block } = props;
    return (
        <div className={ classes.root }>
            { block.items.length && (
                <div>
                    { (block.type === 'bullet' || block.type === 'check') && (<ul className={ classes[block.type + 'List'] }>
                        {block.items.map((item, i) =>
                            (<li className={ classes[block.type + 'ListItem'] } key={ i }>{item.text ? (item.text) : (typeof item === "string" && item)}</li>)
                        )}
                    </ul>)}
                    { block.type === 'ordered' && (<ol className={classes.numberedList}>
                        { block.items.map((item, i) => 
                            (<li className={classes.numberedListItem} key={ i }>{item.text ? (item.text) : (typeof item === "string" && item)}</li>)) }
                    </ol>)}
                </div>
            )}
        </div>
    )
};

export default (ListBlockPreview);
