import {
    SET_ENTITIES,
    SET_ENTITY,
    REMOVE_ENTITY
} from '../actions';
import {
    V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX, V2_TOOLBOX_ASSIGN_REDUX,
    V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX, V2_TOOLBOX_INFORMATION_REDUX,
    V2_TOOLBOX_OVERVIEW_REDUX, V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX
} from "../lib/apiTypes";

const createInitialEntitiesState = () => ({ byId: {}, allIds: [] });

const initialState = {
    contentBlockTypes: {
        byId: {
            textBlock: {
                id: 'textBlock',
                name: 'Tekstblok',
                icon: 'format_align_left',
                initialData: {
                    type: 'textBlock',
                    textBlock: { text: '' },
                },
                for: ['toolbox'],
            },
            listBlock: {
                id: 'listBlock',
                name: 'Opsomming',
                icon: 'list',
                types: ['bullet', 'check', 'ordered'],
                initialData: {
                    type: 'listBlock',
                    listBlock: { type: 'bullet', items: [] },
                },
                for: ['toolbox'],
            },
            checkListBlock: {
                id: 'checkListBlock',
                name: 'Checklist',
                icon: 'list',
                initialData: {
                    type: 'checkListBlock',
                    listBlock: { items: [] },
                },
                for: ['inspection'],
            },
            videoBlock: {
                id: 'videoBlock',
                name: 'Youtube link',
                icon: 'videocam',
                initialData: {
                    type: 'videoBlock',
                    videoBlock: { url: '' },
                },
                for: ['toolbox'],
            },
            photosBlock: {
                id: 'photosBlock',
                name: 'Afbeelding & PDF',
                icon: 'image',
                initialData: {
                    type: 'photosBlock',
                    photosBlock: { photos: [] },
                },
                for: ['toolbox'],
            },
        },
        allIds: [
            'textBlock',
            'listBlock',
            'videoBlock',
            'photosBlock',
            'checkListBlock',
        ],
    },
    questionBlockTypes: {
        byId: {
            singleResponse: {
                id: 'singleResponse',
                name: 'Single choice',
                icon: 'check',
                initialData: {
                    type: 'singleResponse',
                    possibleAnswers: [],
                    correctAnswers: [],
                    blocks: [
                        {
                            type: 'photosBlock',
                            photosBlock: {
                                photos: [],
                            },
                        },
                    ],
                    title: '',
                },
            },
            multiResponse: {
                id: 'multiResponse',
                name: 'Multiple choice',
                icon: 'playlist_add_check',
                initialData: {
                    type: 'multiResponse',
                    possibleAnswers: [],
                    correctAnswers: [],
                    blocks: [
                        {
                            type: 'photosBlock',
                            photosBlock: {
                                photos: [],
                            },
                        },
                    ],
                    title: '',
                },
            },
        },
        allIds: ['singleResponse', 'multiResponse'],
    },
    toolbox: createInitialEntitiesState(),
    toolboxes: createInitialEntitiesState(),
    [V2_TOOLBOX_OVERVIEW_REDUX]: createInitialEntitiesState(),
    [V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX]: createInitialEntitiesState(),
    [V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX]: createInitialEntitiesState(),
    [V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX]: createInitialEntitiesState(),
    [V2_TOOLBOX_ASSIGN_REDUX]: createInitialEntitiesState(),
    [V2_TOOLBOX_INFORMATION_REDUX]: createInitialEntitiesState(),
    toolboxAssign: createInitialEntitiesState(),
    toolboxAssigns: createInitialEntitiesState(),
    inspections: createInitialEntitiesState(),
    inspectionAssigns: createInitialEntitiesState(),
    inspectionAssign: createInitialEntitiesState(),
    sectors: createInitialEntitiesState(),
    categories: createInitialEntitiesState(),
    employees: createInitialEntitiesState(),
    projects: createInitialEntitiesState(),
    usergroups: createInitialEntitiesState(),
    employeesAll: createInitialEntitiesState(),
    help: createInitialEntitiesState(),
};

export default function entitiesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ENTITIES: {
            const newEntitiesState = action.payload.entities.reduce(
                (entities, entity) => {
                    if (entity.toolbox && entity.assignId) {
                        entities.byId[entity.assignId] = entity;
                        entities.allIds.push(entity.assignId);
                    } else {
                        entities.byId[entity.id] = entity;
                        entities.allIds.push(entity.id);
                    }
                    return entities;
                },
                { ...state[action.payload.type], byId: {}, allIds: [] }
            );
            return { ...state, [action.payload.type]: newEntitiesState };
        }
        case SET_ENTITY: {
            const { type, entity } = action.payload;

            const allIds = [...state[type].allIds];
            if (allIds.indexOf(entity.id) === -1) {
                allIds.push(entity.id);
            }
            const byId = { ...state[type].byId, [entity.id]: entity };
            return { ...state, [type]: { allIds: allIds, byId: byId } };
        }
        case REMOVE_ENTITY: {
            const { type, entity } = action.payload;
            const allIds = [...state[type].allIds];
            const byId = { ...state[type].byId };
            delete byId[entity.id];

            const idIndex = allIds.indexOf(entity.id);
            if (idIndex >= 0) {
                allIds.splice(idIndex, 1);
            }
            return { ...state, [type]: { allIds: allIds, byId: byId } };
        }
        default:
            return state;
    }
}
