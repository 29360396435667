// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircle, Done } from '@mui/icons-material';
import _ from 'lodash';

import {
    inProgress,
    getVisibleInspectionVersions,
    getPermissions,
} from '../../../selectors';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import {
    openDialog,
    setCrumbPath,
    deleteUsergroup,
    addUserToUsergroup,
    removeUserFromUsergroup,
    fetchEmployees,
} from '../../../actions';
import AppModule from '../../AppModule';
import EnhancedTable from '../../EnhancedTable';
import TableActionHeaderUsergroup from '../TableActionHeaderUsergroup';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import './UsergroupDetailView.css';
import classes from './UsergroupDetailView.module.scss';

type Props = {
    loading: boolean,
    permissions: any,
    setCrumbPath: () => void,
};

type State = {
    columns: Array<any>,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean
    }>,
    searchtext: string
};


const mapStateToProps = (store, props) => {
    let inspections = getVisibleInspectionVersions(store);
    const allEmployees = store.entities.employees;
    const allUsergroups = store.entities.usergroups;

    return {
        page: Number,
        rowsPerPage: Number,
        allUsergroups: allUsergroups,
        usergroups: allUsergroups.allIds.map((id) => allUsergroups.byId[id]),
        usergroup: allUsergroups.byId[props.match.params.id],
        allEmployees: allEmployees,
        employees: allEmployees.allIds.map((id) => allEmployees.byId[id]),
        loading: !inProgress(store),
        inspections: inspections,
        permissions: getPermissions(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const page = props.match.params.page
        ? parseInt(props.match.params.page, 10)
        : undefined;

    const rowsPerPage = props.match.params.rowsPerPage
        ? parseInt(props.match.params.rowsPerPage, 10)
        : undefined;

    return {
        deleteUsergoup: (usergroup, t) => {
            dispatch(deleteUsergroup(usergroup, t));
        },
        createUsergroup: () => {
            dispatch(openDialog('usergroupCreate'));
        },
        setCrumbPath: (usergroupName: string, t) => {
            dispatch(
                setCrumbPath({
                    title: 'Medewerkers',
                    crumbs: [
                        {
                            name: `${t('Group')} ` + usergroupName,
                            link: '/medewerkers#groepen?rows=' + rowsPerPage + '&page='+page,
                        },
                    ],
                })
            );
        },
        addUserToUsergroup: (usergroupId, employeeId) => {
            dispatch(addUserToUsergroup(usergroupId, employeeId));
        },
        removeUserFromUsergroup: (usergroupId, employeeId) => {
            dispatch(removeUserFromUsergroup(usergroupId, employeeId));
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
    };
};

class UsergroupDetailView extends React.Component<Props, State> {
    props: Props;
    state: State;

    mapUsergroupToRow = (user) => {
        const { t } = this.props;

        return {
            ...user,
            number: user.externalId,
            lastname: user.middlename
                ? user.middlename + ' ' + user.lastname
                : user.lastname,
            email: user.username ? user.username : user.externalusername,
            phonenumber: user.phonenumber ? user.phonenumber : '-',
            state: this.userStateToText(user.state, user.roles),
            icon: this.isMember(user) ? (
                <div className={classes.buttonContainer}>
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '-40px', top: '15px' }}
                        >
                            {t('Remove from group')}
                        </span>
                        <IconButton
                            className="button-remove-from-group"
                            onClick={() => this.handleRemoveUser(user)}
                        >
                            <Done className="icon-added-to-group" />
                            <RemoveCircle className="icon-remove-from-group" />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <div className={classes.buttonContainer}>
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '-40px', top: '15px' }}
                        >
                            {t('Add to group')}
                        </span>
                        <IconButton onClick={() => this.handleAddUser(user)}>
                            <AddCircleOutline />
                        </IconButton>
                    </div>
                </div>
            ),
        };
    };

    setPage = (page) => {
        console.log("INFO: ToolboxOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ToolboxOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }


    isMember = (user) => {
        if (this.props.usergroup.users) {
            return this.props.usergroup.users.find((usergroup) => {
                return usergroup.id === user.id;
            });
        }
    };

    handleAddUser = (row) => {
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;
        if (usergroupId && row.id) {
            this.props.addUserToUsergroup(usergroupId, row.id);
        }
    };

    handleRemoveUser = (row) => {
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;
        if (usergroupId && row.id) {
            this.props.removeUserFromUsergroup(usergroupId, row.id);
        }
    };

    userStateToText = (state, roles) => {
        const { t } = this.props;
        let text = '';

        switch (state) {
            case 'ADDED':
                text = t('Inactive');
                break;
            case 'ACTIVE':
                text = t('active');
                break;
            case 'INACTIVE':
                text = t('Inactive');
                break;
            default:
        }

        if (
            roles.find((element) => {
                return element === 'EXTERN';
            })
        ) {
            text = 'Extern';
            state = 'EXTERN';
        }

        return <div className={'border-text ' + state}>{text}</div>;
    };

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.usergroup &&
            _.get(this, 'props.usergroup.id', null) !==
                _.get(nextProps, 'usergroup.id', null)
        ) {
            // this.props.setCrumbPath(nextProps.usergroup.name, t);
        }
    }

    componentWillMount() {
        this.props.fetchEmployees();
        this.setState({
            columns: [
                { id: 'number', label: 'Nr.', size: '8%' },
                { id: 'firstname', label: 'Naam', size: '15%' },
                { id: 'lastname', label: 'Achternaam', size: '15%' },
                { id: 'email', label: 'Email', size: '20%' },
                { id: 'phonenumber', label: 'Telefoonnummer', size: '10%' },
                { id: 'state', numeric: false, size: '150px', label: 'Status', classes: 'status' },
                { id: 'icon', label: 'Lid van groep', size: '10%', noHoverBackground:true }
            ],
            searchtext: '',
            page: 0,
            rowsPerPage: 10
        });

        let actions = [];

        actions.push({
            id: 'delete',
            label: 'Verwijderen',
            isVisible: true,
        });

        this.setState({ actions });
    }

    componentDidMount() {
        const { t } = this.props;
        if (this.props.usergroup) {
            this.props.setCrumbPath(this.props.usergroup.name, t);
        }
    }
    handleSearch = searchtext => {
        this.setState({
            searchtext: searchtext
        })
    };

    groupSorter = () => {
        return {
            number: (a, b) => {
                a = a.externalId.toLowerCase();
                b = b.externalId.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            icon: (a, b) => {
                const amember = this.isMember(a);
                const bmember = this.isMember(b);

                if (amember && !bmember) {
                    return 1;
                }
                if (!amember && bmember) {
                    return -1;
                }
                return null;
            },
            firstname: (a, b) => {
                a = a.firstname.toLowerCase();
                b = b.firstname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            lastname: (a, b) => {
                a = a.lastname.toLowerCase();
                b = b.lastname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            email: null,
            phonenumber: null,
        };
    };

    render() {
        const { loading, allUsergroups, employees, t } = this.props;
        const { columns } = this.state;

        if (!allUsergroups) {
            return <div />;
        }

        if (allUsergroups.length === 0 || allUsergroups.allIds.length === 0) {
            return <div />;
        }
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;

        const usergroup = allUsergroups.byId[usergroupId];

        const tableActionHeader = <TableActionHeader searchPlaceholder={t('Search in employees')} onSearchChange={this.handleSearch} groupsmode={false} title={usergroup.name ? <TableActionHeaderUsergroup headerItem={usergroup} /> : 'Naam onbekend'} />;

        return (
            <AppModule loading={loading} prepend={tableActionHeader}>
                <EnhancedTable
                    defaultOrderBy={'title'}
                    defaultOrder="desc"
                    columns={columns}
                    rows={employees.filter(row => (row.fullname.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1) || (row.username && row.username.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1) || (row.externalId && row.externalId.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1))}
                    formatter={this.mapUsergroupToRow}
                    actions={[]}
                    onAction={this.handleAction}
                    sorter={this.groupSorter()}
                    emptyState="Geen Medewerkers Gevonden"

                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={this.state.startRowsPerPage}

                />
            </AppModule>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(UsergroupDetailView);
