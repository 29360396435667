// @flow
import React from 'react';

import './StatisticsToolboxCard.css';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import $ from 'jquery';
import 'materialize-tabs';
import { connect } from 'react-redux';
import type { StoreState } from '../../../reducers/index';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    filterBar: mixed, // Filter bar component
    toolboxBarData?: Array<any>, // Chart data
    list: mixed, // List component
    totalToolboxesCorrect: number,
    totalToolboxesIncorrect: number,
    onDownloadCSV: () => mixed,
    loading: boolean,
};

const mapStateToProps = (state: StoreState) => {
    return {
        loading: state.statistics.loading,
    };
};

type State = {};

class StatisticsCard extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        $('ul.tabs').tabs();
    }

    getToolboxTooltip = (chartBar) => {
        const { t } = this.props;
        if (chartBar.payload && chartBar.payload.length) {
            let rows = [];
            let values = 0;
            for (let i = chartBar.payload.length - 1; i >= 0; i--) {
                let item = chartBar.payload[i];
                if (item.value) {
                    values++;
                    rows.push(
                        this.toolTipRow(item.color, item.name, item.value)
                    );
                }
            }

            return values ? (
                <div className="chart-tooltip">
                    <div className="chart-tooltip-title">
                        # {t('Toolboxes')} {chartBar.label}
                    </div>

                    {rows}
                </div>
            ) : undefined;
        }
    };

    toolTipRow = (color: string, key: string, value: string) => {
        return (
            <div key={color} className="chart-tooltip-row">
                <div
                    className="chart-tooltip-color"
                    style={{ backgroundColor: color }}
                />
                <div className="chart-tooltip-key">{key}</div>
                <div className="chart-tooltip-value">{value}</div>
            </div>
        );
    };

    getToolboxChart = () => {
        return null; //Hier kan nog evt toolbox titel worden neergezet

        /*<div className="chart-label-block">
                <ChartLabel label="# Uitgestuurde toolboxen" vertical />
                <ChartLabel label="Maand" horizontal />
                <ResponsiveContainer width="100%" aspect={110 / 33}>
                    <BarChart
                        data={!this.props.toolboxBarData || !this.props.toolboxBarData.length ? [] : this.props.toolboxBarData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        barCategoryGap="25"
                    >
                        <XAxis dataKey="barName" axisLine={false} tickLine={false} />
                        <YAxis axisLine={false} allowDecimals={false} tickLine={false} />
                        <CartesianGrid vertical={false} />
                        <Tooltip cursor={false} isAnimationActive={false} content={this.getToolboxTooltip} offset={-110} />
                        <Legend verticalAlign="top" align="right" iconType="circle" iconSize={10} />
                        <Bar name="In orde" dataKey="correct" stackId="a" fill="#4CCC32" barSize={48} animationBegin={0} animationDuration={200} animationEasing="linear" />
                        <Bar name="Niet in orde" dataKey="incorrect" stackId="a" fill="#E22513" barSize={48} animationBegin={0} animationDuration={200} animationEasing="linear" />
                    </BarChart>
                </ResponsiveContainer>
            </div>*/
    };

    getStatisticsSummary = () => {
        let components = [];
        components.push(
            <div
                className="statistics-summary-item therapy-count"
                key={1}
            ></div>
        );
        components.push(
            <div className="statistics-summary-item duration-sum" key={2}></div>
        );
        return components;
    };

    render() {
        return (
            <div>
                <div className="card-content">
                    <LinearProgress
                        className={
                            this.props.loading
                                ? 'statistics-loader visible'
                                : 'statistics-loader'
                        }
                        mode="indeterminate"
                    />
                    <div className="statistics-summary">
                        {this.getStatisticsSummary()}
                    </div>
                    <div className="statistics-graphs-active-graph">
                        {this.getToolboxChart()}
                    </div>
                    <div className="statistics-list">{this.props.list}</div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(StatisticsCard);
