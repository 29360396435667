import { fetchAll } from './apiActions';
import { setEntities } from './entityActions';

export function fetchCategories() {
    return dispatch => {
        return (fetchAll('category')(dispatch))
            .then(entities => {
                dispatch(setEntities('categories', entities));
            });
    }
}
