// @flow

import { connect } from 'react-redux';
import React from 'react';
import { setCrumbPath } from '../../../actions';
import InspectionDetailView from '../../Inspection/InspectionDetailView';

type Props = {};

const mapStateToProps = (state, props) => {
    const inspectionId = props.match.params.inspectionId;
    const inspectionAssign = state.entities.inspectionAssigns.byId[inspectionId];
    return { inspectionAssign, inspection: inspectionAssign ? inspectionAssign.inspection : null };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: inspectionAssign => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        { name: 'Werkplekinspectie: ' + inspectionAssign.inspection.title, link: '/rapportages#werkplekinspecties' },
                        { name: 'Versie: ' + inspectionAssign.inspection.version, link: '/rapportages/werkplekinspectie/' + inspectionAssign.inspection.id }
                    ]
                })
            );
        }
    };
};

class StatisticsInspectionDetailView extends React.Component<Props, void> {
    props: Props;

    componentDidMount() {
        if (this.props.inspectionAssign && this.props.inspectionAssign.inspection) {
            setCrumbPath(this.props.inspectionAssign);
        }
    }

    componentWillReceiveProps(newProps: Props) {
        if (this.props.inspectionAssign !== newProps.inspectionAssign && newProps.inspectionAssign) {
            this.props.setCrumbPath(newProps.inspectionAssign);
        }
    }

    render() {
        return <InspectionDetailView {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsInspectionDetailView);
