// @flow
import TypedRecord from '../components/Util/IRecord';
import { List } from 'immutable';
import type { TStatisticsAction } from '../actions/StatisticsActions';

export type TInspectionBarData = {
    barName: string,
    cumela: number,
    cumelaEdited: number,
    own: number,
    admin: number,
    days: number
};

export type TToolboxBarData = {
    barName: string,
    cumela: number,
    cumelaEdited: number,
    own: number,
    admin: number,
    days: number
};

export type TStateStatisticsChartData = {
    startDate: Date,
    endDate: Date,
    totalInspectionsSend: number,
    totalInspectionsCompleted: number,
    totalInspectionsNotCompleted: number,
    totalToolboxesSend: number,
    totalToolboxCompleted: number,
    totalToolboxNotCompleted: number
};

export type TStateStatisticsListData = {
    serial?: string,
    userId?: number,
    dateTime?: any
};

export class TStateStatisticsData extends TypedRecord({
    chartData: {
        startDate: undefined,
        endDate: undefined
    },
    listData: List(),
    loading: false,
    latestTimestamp: 0,
    employeeChartData: List()
}) {
    chartData: TStateStatisticsChartData;
    listData: List<TStateStatisticsListData>;
    loading: boolean;
    latestTimestamp: number;
}

const initialstate = new TStateStatisticsData();

const StatisticsReducer = (state: TStateStatisticsData = initialstate, action: TStatisticsAction) => {
    switch (action.type) {
        case 'LOAD_STATISTICS_DATA_START':
            return state
                .set('loading', true)
                .set('latestTimestamp', action.timestamp)
                .set('chartData', List())
                .set('listData', List())
                .set('employeeChartData', List());
        case 'LOAD_CHART_DATA_SUCCESS':
            return action.timestamp === state.latestTimestamp ? state.set('chartData', action.chartData) : state;
        case 'LOAD_EMPLOYEE_CHART_DATA_SUCCESS':
            return action.timestamp === state.latestTimestamp ? state.set('employeeChartData', action.employeeChartData) : state;
        case 'LOAD_STATISTICS_LIST_DATA_SUCCESS':
            return action.timestamp === state.latestTimestamp ? state.set('listData', action.listData) : state;
        case 'LOAD_CHART_DATA_ERROR':
        case 'LOAD_STATISTICS_LIST_DATA_ERROR':
        case 'LOAD_STATISTICS_DATA_SUCCESS':
            return action.timestamp === state.latestTimestamp ? state.set('loading', false) : state;
        default:
            return state;
    }
};

export default StatisticsReducer;
