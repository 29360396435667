// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {Fab } from '@mui/material';
import AppModule from '../AppModule';
import {
    getAppInspections,
    getAppToolboxes,
    getCurrentEmployee,
    setCrumbPath,
} from '../../actions';
import { getPermissions, inProgress } from '../../selectors';
import { push } from 'connected-react-router';
import { openDialog } from '../../actions/uiActions';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import { TEmployee } from '../../reducers/employeesReducer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import classnames from 'classnames';
import OverviewIcon from '@mui/icons-material/ViewList';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {getModules} from "../lib";
import i18n from 'i18next';
import classes from './opdrachten.module.scss';

moment.locale(i18n.t('LOCALE'));

type Props = {
    setCrumbPath: () => void,
    permissions: any,
    classes: any,
    loading: boolean,
    getCurrentEmployee: () => TEmployee,
    getAppToolboxes: () => any,
};

type State = {
    currentEmpl: TEmployee,
};


const categoryToImageMap = {
        bedrijfAlgemeen: "cat_jajo_icon_1.svg",
        gevaarlijkeStoffen: "cat_jajo_icon_1.svg",
        grondwerk: "cat_jajo_icon_1.svg",
        kinderen: "cat_jajo_icon_1.svg",
        overig: "cat_jajo_icon_1.svg",
        personeelEnArbeid: "cat_jajo_icon_2.svg",
        persoonlijk: "cat_jajo_icon_2.svg",
        voertuigen: "cat_jajo_icon_2.svg",
        voorkomen: "cat_jajo_icon_2.svg",
        weersomstandigheden: "cat_jajo_icon_2.svg",
        werkplaats: "cat_jajo_icon_3.svg",
        werktuigen: "cat_jajo_icon_3.svg",
        werkplekInspectie: "cat_jajo_icon_3.svg",
        loonwerk: "cat_jajo_icon_3.svg",
        werkzaamhedenAlgemeen: "cat_jajo_icon_3.svg"};

const mapStateToProps = (store) => {
    return {
        permissions: getPermissions(store),
        loading: !inProgress(store),
        currentEmpl: store.drafts.employees.currentUser,
        appToolboxes: store.entities.appToolboxes,
        appInspections: store.entities.appInspections,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: i18n.t('Assignments') }));
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        getCurrentEmployee: () => {
            dispatch(getCurrentEmployee());
        },
        getAppToolboxes: () => {
            dispatch(getAppToolboxes());
        },
        getAppInspections: () => {
            dispatch(getAppInspections());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class Opdrachten extends React.Component<Props, State> {
    props: Props;
    state: State = {};



    componentWillMount() {
        this.props.setCrumbPath();
        this.props.getCurrentEmployee();
        this.props.getAppToolboxes();
        this.props.getAppInspections();

        moment.locale(i18n.t('LOCALE'));
    }

    gotoOpdracht = (row) => {
        if (row.inspection) {
            this.props.goTo('inspection/' + row.id);
        } else {
            this.props.goTo('toolbox/' + row.assignId);
        }
    };

    gotoPublicInspections = () => {
        this.props.goTo('nieuweinspectie');
    };

    render() {
        const {
            loading,
            currentEmpl,
            appToolboxes,
            appInspections,
            t,
        } = this.props;

        const modules = getModules();

        // window.$crisp.push(['do', 'chat:hide']);

        let opdrachten = [];
        if (appToolboxes && opdrachten.length === 0) {
            appToolboxes.allIds.forEach((id) => {
                if (
                    appToolboxes.byId[id].toolbox.results === null ||
                    (appToolboxes.byId[id].toolbox.results &&
                        appToolboxes.byId[id].toolbox.results.complete ===
                            false)
                ) {
                    opdrachten.push(appToolboxes.byId[id]);
                }
            });
        }
        if (appInspections) {
            appInspections.allIds.forEach((id) => {
                if (
                    appInspections.byId[id].inspection.results === null ||
                    (appInspections.byId[id].inspection.results &&
                        appInspections.byId[id].inspection.results.complete ===
                        false)
                ) {
                    opdrachten.push(appInspections.byId[id]);
                }
                return null
            });
        }

        if (opdrachten[0] !== null) {
            opdrachten.sort(function (a, b) {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return 1;
                }
                if (b == null) {
                    return -1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        const tableActionHeader = (
            <TableActionHeader
                title={
                    currentEmpl && currentEmpl.fullname
                        ? t('Welcome') + ' ' + currentEmpl.fullname
                        : ''
                }
            />
        );

        return (
            <div className={classes.root}>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    {modules.inspections && (
                        <div
                            className={classnames({
                                [classes.button]: true,
                                'mui-fixed': true,
                            })}
                        >
                            <Fab
                                 
                                onClick={this.gotoPublicInspections}
                            >
                                <AddIcon />
                            </Fab>
                        </div>
                    )}
                    <div
                        className={classes.content}
                        id="container"
                    >
                        {opdrachten.map((row, index) => {
                            let meetingDate = null;
                            if (row.toolbox) {
                                if (row.toolbox.blocks[0]) {
                                    if (row.toolbox.blocks[0].meetingBlock) {
                                        if (
                                            row.toolbox.blocks[0].meetingBlock
                                                .meetingDate
                                        ) {
                                            meetingDate = new Date(
                                                row.toolbox.blocks[0].meetingBlock.meetingDate
                                            );
                                        }
                                    }
                                }
                            }
                            let categoryImage = categoryToImageMap[row.category];
                            let janssen = false;
                            if (process.env.REACT_APP_PROJECT === 'janssen') {
                                janssen = true;
                            }

                            return (
                                <div
                                    className={classes.card}
                                    id="block"
                                >
                                    <div
                                        className={classes.cardbg}
                                        style={
                                            janssen ? {
                                                backgroundImage:
                                                    'url(/assets/images/' + categoryImage + ')',
                                                backgroundRepeat:
                                                    'no-repeat',
                                                backgroundPosition:
                                                    'center bottom',

                                            }
                                            :
                                            row.category === 'werkplekInspectie'
                                                ? {
                                                      backgroundImage:
                                                          'url(/assets/images/cat_werkplekinspectie.png)',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center bottom',
                                                  }
                                                : {
                                                      backgroundImage:
                                                          'url(/assets/images/cat_' +
                                                          (row.isToolboxMeeting
                                                              ? 'toolboxMeeting'
                                                              : row.category
                                                              ? row.category
                                                              : 'werkplekinspectie') +
                                                          '.png)',
                                                      backgroundRepeat:
                                                          'no-repeat',
                                                      backgroundPosition:
                                                          'center bottom',
                                                  }
                                        }
                                    >
                                        <div
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                backgroundColor: 'white',
                                                float: 'right',
                                                textAlign: 'center',
                                                lineHeight: '50px',
                                                color: '#727272',
                                                fontWeight: '300',
                                            }}
                                        >
                                            {row.category ===
                                            'werkplekInspectie'
                                                ? 'W'
                                                : 'T'}
                                        </div>
                                        <div
                                            style={{
                                                paddingLeft: '14px',
                                                paddingTop: '0px',
                                                lineHeight: '50px',
                                                color: '#727272',
                                                backgroundColor: '#DDDDDDA0',
                                                fontWeight: '300',
                                            }}
                                        >
                                            {row.author}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '165px',
                                            textAlign: 'center',
                                            width: '90%',
                                            marginLeft: '15px',
                                            bottom: '100px',
                                        }}
                                    >
                                        {row.toolbox !== undefined && (
                                            <div>
                                                {row.toolbox.blocks[0] !==
                                                undefined ? (
                                                    row.toolbox.blocks[0]
                                                        .type ===
                                                    'meetingBlock' ? (
                                                        <div>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                {t(
                                                                    'Toolboxmeeting'
                                                                )}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            {row.title
                                                                ? row.title
                                                                : t(
                                                                      '(No title)'
                                                                  )}
                                                        </span>
                                                    )
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {row.title
                                                            ? row.title
                                                            : t('(No title)')}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {row.inspection !== undefined && (
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {row.title
                                                    ? row.title
                                                    : t('(No title)')}
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '210px',
                                            bottom: '115px',
                                            textAlign: 'center',
                                            width: '100%',
                                            color: '#727272',
                                            fontWeight: '300',
                                            fontSize: '13px',
                                        }}
                                    >
                                        {row.toolbox !== undefined ? (
                                            <div>
                                                {row.toolbox.blocks[0] !==
                                                undefined
                                                    ? row.toolbox.blocks[0]
                                                          .type ===
                                                      'meetingBlock'
                                                        ? t('Planned') +
                                                          ': ' +
                                                          moment(
                                                              meetingDate
                                                          ).format(
                                                              t('DD-MM-YYYY')
                                                          )
                                                        : Math.abs(
                                                              moment(
                                                                  row.date
                                                              ).diff(
                                                                  moment(),
                                                                  'days'
                                                              )
                                                          ) > 7
                                                        ? moment(
                                                              row.date
                                                          ).format(
                                                              t('dateTime')
                                                          )
                                                        : moment().to(
                                                              moment(row.date)
                                                          )
                                                    : Math.abs(
                                                          moment(row.date).diff(
                                                              moment(),
                                                              'days'
                                                          )
                                                      ) > 7
                                                    ? moment(row.date).format(
                                                          t('dateTime')
                                                      )
                                                    : moment().to(
                                                          moment(row.date)
                                                      )}
                                            </div>
                                        ) : Math.abs(
                                              moment(row.date).diff(
                                                  moment(),
                                                  'days'
                                              )
                                          ) > 7 ? (
                                            moment(row.date).format(
                                                t('dateTime')
                                            )
                                        ) : (
                                            moment().to(moment(row.date))
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '232px',
                                            textAlign: 'center',
                                            width: '90%',
                                            marginLeft: '15px',
                                            bottom: '65px',
                                            fontWeight: '300',
                                        }}
                                    >
                                        {row.toolbox !== undefined && (
                                            <div>
                                                {row.toolbox.blocks[0] !==
                                                undefined ? (
                                                    row.toolbox.blocks[0]
                                                        .type ===
                                                    'meetingBlock' ? (
                                                        <span>
                                                            {
                                                                row.toolbox
                                                                    .blocks[0]
                                                                    .meetingBlock
                                                                    .location
                                                            }
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {row.description}
                                                        </span>
                                                    )
                                                ) : (
                                                    <span>
                                                        {row.description}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {row.inspection !== undefined && (
                                            <span>{row.description}</span>
                                        )}
                                    </div>
                                    <div
                                        className={classes.cardbutton}
                                        onClick={() => this.gotoOpdracht(row)}
                                    >
                                        {meetingDate != null &&
                                        meetingDate.getTime() >=
                                            new Date().getTime()
                                            ? t('MORE INFORMATION')
                                            : t('START NOW')}
                                        <ArrowForwardIcon
                                            style={{
                                                position: 'absolute',
                                                right: '13px',
                                                top: '13px',
                                                width: '20px',
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {opdrachten[0] == null && (
                            <div style={{ marginTop: '30vh' }}>
                                <OverviewIcon
                                    style={{
                                        margin: 'auto',
                                        width: '100%',
                                        height: '64px',
                                        opacity: '0.4',
                                    }}
                                />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        opacity: '0.4',
                                    }}
                                >
                                    {t(
                                        'There are currently no assignments available'
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </AppModule>
                <div style={{ visibility: 'hidden' }}>
                    {
                        (document.getElementById('html').style.backgroundColor =
                            '#f5f5f5')
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(Opdrachten);
