import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { getCurrentFilterSet } from '../reducers/filterReducer';
import getFilteredInspectionAssigns from './getFilteredInspectionAssigns';

const getCurrentFilters = store => getCurrentFilterSet(store.filters);
const getFilteredIas = store => getFilteredInspectionAssigns(store);
const getInspections = store => (store.entities.inspections);

const getFilteredAndGroupedInspectionAssigns = createSelector([getFilteredIas, getCurrentFilters, getInspections], (ias, filters, inspections) => {
    let inspectionAssignGrouped = new Map();

    ias.forEach(ia => {
        const inspectionAssign = ia;
        inspectionAssign.inspection = { ...inspections.byId[inspectionAssign.inspectionId || 0] }

        let title_version = inspectionAssign.inspection.title + '-' + inspectionAssign.inspection.version;

        if (!inspectionAssignGrouped.get(title_version)) {
            inspectionAssignGrouped = inspectionAssignGrouped.set(title_version, Object.assign({}, inspectionAssign));
        }

        const inspectionAssignGroup = inspectionAssignGrouped.get(title_version);

        if (!inspectionAssignGroup.assignList) {
            inspectionAssignGroup.assignList = [];
            inspectionAssignGroup.employeeCount = 0;
            inspectionAssignGroup.finished = 0;
        }
        inspectionAssignGroup.assignList.push(inspectionAssign);

        if (!inspectionAssignGroup.latestVersion) {
            inspectionAssignGroup.latestVersion = inspectionAssign.inspection.version;
        } else if (inspectionAssign.inspection.version.toString().split('.')[0] > inspectionAssignGroup.latestVersion.toString().split('.')[0] && inspectionAssign.inspection.version.toString().split('.')[1] > inspectionAssignGroup.latestVersion.toString().split('.')[1]) {
            inspectionAssignGroup.latestVersion = inspectionAssign.inspection.version;
        }

        inspectionAssignGroup.employeeCount++;

        if (inspectionAssign.result && inspectionAssign.result.complete) inspectionAssignGroup.finished++;
    });

    return inspectionAssignGrouped.size > 0 ? inspectionAssignGrouped.toList().toJS() : [];
});

export default getFilteredAndGroupedInspectionAssigns;
