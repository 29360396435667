// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowEmployeeAssign from '../../StatisticsView/StatisticsRow/StatisticsRowEmployeeAssign';
import { connect } from 'react-redux';
import type { TStateStatisticsListData } from '../../../reducers/StatisticsReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from "moment";

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
    toolboxAssigns: any,
};

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
    };
};

class StatisticsListDistributors extends React.PureComponent<Props, void> {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentWillMount() {
    }

    rowProvider = (item) => {
        if (!item) {
            return undefined;
        }

        return (
            <StatisticsRowEmployeeAssign
                key={item.id}
                handleRowClick={() => this.onRowClick(item)}
                inspectionAssign={item}
            />
        );
    };

    headerProvider = () => {
        const { t } = this.props;

        return {
            type: t('Type'),
            title: t('Title'),
            version: t('Version'),
            datesend: t('Date sent'),
            finished: t('Completed'),
            allOk: t('Result'),
        };
    };

    onRowClick = (inspectionAssign) => {
        this.props.onItemClick(inspectionAssign);
    };

    render() {
        const { inspectionAssigns, toolboxAssigns, searchtext } = this.props;
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set(
            'type',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.toolbox !== undefined &&
                    inspectionAssignB.inspection !== undefined
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.inspection !== undefined &&
                    inspectionAssignB.toolbox !== undefined
                ) {
                    return -1;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'title',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.toolbox === undefined &&
                    inspectionAssignB.toolbox === undefined
                ) {
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() >
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() <
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() ===
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.inspection === undefined &&
                    inspectionAssignB.inspection === undefined
                ) {
                    if (
                        inspectionAssignA.toolbox.title.toLowerCase() >
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.toolbox.title.toLowerCase() <
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.toolbox.title.toLowerCase() ===
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.toolbox === undefined &&
                    inspectionAssignB.inspection === undefined
                ) {
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() >
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() <
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.inspection.title.toLowerCase() ===
                        inspectionAssignB.toolbox.title.toLowerCase()
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.inspection === undefined &&
                    inspectionAssignB.toolbox === undefined
                ) {
                    if (
                        inspectionAssignA.toolbox.title.toLowerCase() >
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.toolbox.title.toLowerCase() <
                        inspectionAssignB.inspection.title.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'version',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.toolbox === undefined &&
                    inspectionAssignB.toolbox === undefined
                ) {
                    if (
                        inspectionAssignA.inspection.version >
                        inspectionAssignB.inspection.version
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection.version <
                        inspectionAssignB.inspection.version
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.inspection.version ===
                        inspectionAssignB.inspection.version
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.inspection === undefined &&
                    inspectionAssignB.inspection === undefined
                ) {
                    if (
                        inspectionAssignA.toolbox.version >
                        inspectionAssignB.toolbox.version
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.toolbox.version <
                        inspectionAssignB.toolbox.version
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.toolbox.version ===
                        inspectionAssignB.toolbox.version
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.toolbox === undefined &&
                    inspectionAssignB.inspection === undefined
                ) {
                    if (
                        inspectionAssignA.inspection.version >
                        inspectionAssignB.toolbox.version
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection.version <
                        inspectionAssignB.toolbox.version
                    ) {
                        return -1;
                    }
                    if (
                        inspectionAssignA.inspection.version ===
                        inspectionAssignB.toolbox.version
                    ) {
                        return 0;
                    }
                }
                if (
                    inspectionAssignA.inspection === undefined &&
                    inspectionAssignB.toolbox === undefined
                ) {
                    if (
                        inspectionAssignA.toolbox.version >
                        inspectionAssignB.inspection.version
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.toolbox.version <
                        inspectionAssignB.inspection.version
                    ) {
                        return -1;
                    }
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'datesend',
            (inspectionAssignA, inspectionAssignB) => {
                let result = moment(inspectionAssignB.deployed).valueOf() - moment(inspectionAssignA.deployed).valueOf()
                return result;
            }
        );
        sorters = sorters.set(
            'finished',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result === null
                ) {
                    return 0;
                }
                if (
                    inspectionAssignA.result !== null &&
                    inspectionAssignB.result === null
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result !== null
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.result !== null &&
                    inspectionAssignB.result !== null
                ) {
                    if (
                        inspectionAssignA.result.date >
                        inspectionAssignB.result.date
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.result.date <
                        inspectionAssignB.result.date
                    ) {
                        return -1;
                    }
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'allOk',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result === null
                ) {
                    return 0;
                }
                if (
                    inspectionAssignA.result !== null &&
                    inspectionAssignB.result === null
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result !== null
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.result !== null &&
                    inspectionAssignB.result !== null
                ) {
                    if (
                        inspectionAssignA.toolbox !== undefined &&
                        inspectionAssignB.toolbox === undefined
                    ) {
                        //A == toolbox B == inspection
                        if (inspectionAssignB.result.allCorrect) {
                            return -1;
                        }
                        return 1;
                    }
                    if (
                        inspectionAssignA.inspection !== undefined &&
                        inspectionAssignB.inspection === undefined
                    ) {
                        //A == inspection B == toolbox
                        if (inspectionAssignA.result.allCorrect) {
                            return 1;
                        }
                        return -1;
                    }
                    if (
                        inspectionAssignA.inspection !== undefined &&
                        inspectionAssignB.inspection !== undefined
                    ) {
                        //A == inspection B == inspection
                        if (inspectionAssignA.result.allCorrect) {
                            return 1;
                        }
                        if (inspectionAssignB.result.allCorrect) {
                            return -1;
                        }
                        return 0;
                    }
                    if (
                        inspectionAssignA.toolbox !== undefined &&
                        inspectionAssignB.toolbox !== undefined
                    ) {
                        let employeeA = 0;
                        let employeeB = 0;
                        //A == toolbox B == toolbox
                        this.props.toolboxAssigns.forEach((row) => {
                            if (
                                row.user.fullname
                                    .toLowerCase()
                                    .indexOf(
                                        inspectionAssignA.user.fullname.toLowerCase()
                                    ) > -1
                            ) {
                                if (row.result !== null) {
                                    employeeA++;
                                }
                            }
                            if (
                                row.user.fullname
                                    .toLowerCase()
                                    .indexOf(
                                        inspectionAssignB.user.fullname.toLowerCase()
                                    ) > -1
                            ) {
                                if (row.result !== null) {
                                    employeeB++;
                                }
                            }
                        });
                        if (employeeA > employeeB) {
                            return 1;
                        }
                        if (employeeA < employeeB) {
                            return -1;
                        }
                        return 0;
                    }
                }
                return 0;
            }
        );

        const findBySearch = (value, search) =>
            value.toLowerCase().indexOf(search) > -1;

        let data = [];
        if (inspectionAssigns && toolboxAssigns) {
            data = inspectionAssigns
                .filter((employee) =>
                    searchtext
                        ? findBySearch(employee.inspection.title, searchtext)
                        : true
                )
                .concat(
                    toolboxAssigns.filter((employee) =>
                        searchtext
                            ? findBySearch(employee.toolbox.title, searchtext)
                            : true
                    )
                );
        } else if (inspectionAssigns) {
            data = inspectionAssigns.filter((employee) =>
                searchtext
                    ? findBySearch(employee.inspection.title, searchtext)
                    : true
            );
        } else if (toolboxAssigns) {
            data = toolboxAssigns.filter((employee) =>
                searchtext
                    ? findBySearch(employee.toolbox.title, searchtext)
                    : true
            );
        }

        return (
            <PaginatedSortFilterView
                data={List(data)}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="datesend"
                asc={false}
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListDistributors);
