// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Fab} from '@mui/material';


import { goBack, push } from 'connected-react-router';
import { bindActionCreators } from 'redux';

import {
    fetchAbonnees,
    editAbonnee,
    changeSubscriptionHolder,
    disableAbonnee,
    enableAbonnee,
    makeTrialAbonnee,
    makeNotTrialAbonnee,
    impersonate,
} from '../../actions/subscriptionActions';
import { connect } from 'react-redux';
import EnhancedTable from '../EnhancedTable';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import AppModule from '../AppModule';
import { TSubscription } from '../../reducers/subscriptionReducer';

import AbonneeEditCard from '../Subscriptions/AbonneeEditCard';
import { Dialog, DialogTitle } from '@mui/material';
import Slide from '@mui/material/Slide';
import { disableEmployee, setCrumbPath, enableEmployee } from '../../actions';
import moment from '../../lib/moment';
import type { TEmployee } from '../../reducers/employeesReducer';
import DescIcon from '@mui/icons-material/Description';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

type Props = {
    setCrumbPath: () => void,
    abonnees: Array<any>,
    employees: Array<any>,
    open: boolean,
    goBack: () => mixed,
    onEdit: (abonnee: TSubscription) => mixed,
    editAbonnee: (abonnee: TSubscription) => void,
    currentAbonnee: TSubscription,
    loading: boolean,
    setEditAbonnee: (abonnee: TSubscription) => void,
    abonnee?: TSubscription,
    pageAfterSave?: string,
    disableAbonnee: (abonnee: TSubscription) => void,
    disableEmployee: (employee: TEmployee) => void,
    enableAbonnee: (abonnee: TSubscription) => void,
    enableEmployee: (employee: TEmployee) => void,
    makeTrialAbonnee: (abonnee: TSubscription) => void,
    impersonate: (abonnee: TSubscription) => void,
    makeNotTrialAbonnee: (abonnee: TSubscription) => void,
};

type State = {
    searchtext: string,
    fetched: boolean,
};

const mapStateToProps = (store) => {
    const employees = store.entities.employees;
    const abonnees = store.subscription.abonnees;
    const currentUser = store.drafts.employees.currentUser;

    let abonneesByIdMap = new Map();
    if (abonnees) {
        abonnees.forEach((abonee) => {
            abonneesByIdMap.set(abonee.id, abonee);
        });
    }
    return {
        abonnees: abonnees,
        employees: employees.allIds.map((id) => employees.byId[id]),
        currentAbonnee: store.subscription.editabonnee,
        abonneesByIdMap: abonneesByIdMap,
        currentUser: currentUser,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        disableAbonnee: bindActionCreators(disableAbonnee, dispatch),
        enableAbonnee: bindActionCreators(enableAbonnee, dispatch),
        disableEmployee: bindActionCreators(disableEmployee, dispatch),
        enableEmployee: bindActionCreators(enableEmployee, dispatch),
        makeTrialAbonnee: bindActionCreators(makeTrialAbonnee, dispatch),
        impersonate: bindActionCreators(impersonate, dispatch),
        makeNotTrialAbonnee: bindActionCreators(makeNotTrialAbonnee, dispatch),
        fetchAbonnees: () => {
            dispatch(fetchAbonnees());
        },
        onSave: (newOb) => {
            changeSubscriptionHolder(newOb)(dispatch);

            dispatch(
                push(props.pageAfterSave ? props.pageAfterSave : '/abonnees')
            );
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: i18n.t('Subscribers') }));
        },
        setEditAbonnee: bindActionCreators(editAbonnee, dispatch),
        goBack: () => {
            dispatch(goBack());
        },
        editAbonnee: (id) => {
            if (Number.isInteger(id)) {
                dispatch(push('/abonnees/' + id));
            } else {
                dispatch(push('/abonnees/' + id.id));
                // bindActionCreators(upsertAbonnee, dispatch);
            }
        },
        goToDetailView: (abonneeId) => {
            dispatch(push('/abonnee/details/' + abonneeId));
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SubscriptionsOverview extends React.Component<Props, State> {
    state: State = {
        searchtext: '',
        fetched: false,
        openDialog: false,
        actions: [
            {
                id: 'edit',
                label: 'Wijzigen',
                isVisible: (id) => this.isVisible(id),
            },
            {
                id: 'enable',
                label: 'Activeer',
                isVisible: (id) => {
                    return this.isDisabled(id) && !this.isTrial(id);
                },
            },
            {
                id: 'makeTrial',
                label: 'Maak proefabonnement',
                isVisible: (id) => {
                    return !this.isTrial(id) && this.isActive(id);
                },
            },
            {
                id: 'impersonate',
                label: 'Login als abonneehouder',
                isVisible: true,
            },
            {
                id: 'makeNotTrial',
                label: 'Sluit proefabonnement',
                isVisible: (id) => {
                    return this.isTrial(id);
                },
            },
        ],
        columns: [
            {
                id: 'companyName',
                numeric: false,
                size: '20%',
                label: 'Bedrijfsnaam',
            },
            //{ id: 'nrEmployees', numeric: true, size: '14%', label: 'Medewerkers' },
            {
                id: 'activeEmployees',
                numeric: true,
                size: '18%',
                label: 'Actieve medewerkers',
            },
            {
                id: 'maxEmployees',
                numeric: true,
                size: '17%',
                label: 'Max Medewerkers',
            },
            {
                id: 'typeSubscription',
                numeric: false,
                size: '18%',
                label: 'Soort abonnement',
            },
            {
                id: 'contactPerson',
                numeric: false,
                size: '20%',
                label: 'Contact persoon',
            },
            {
                id: 'createdOn',
                numeric: false,
                size: '13%',
                label: 'Lid sinds',
            },
            { id: 'incasso', numeric: false, size: '10%', label: 'Incasso' },
            {
                id: 'state',
                numeric: false,
                size: '120px',
                label: 'Status',
                classes: 'Status',
            },
        ],
    };

    goBack = () => {
        this.props.setEditAbonnee(undefined);
        this.props.goBack();
    }
    onSave = (newObj) => {
        this.props.onSave(newObj);
        this.props.setEditAbonnee(undefined);
    }

    processProps = (props: Props) => {
        const paramId = props.match.params.id
            ? parseInt(props.match.params.id, 10)
            : undefined;

        if (!props.currentAbonnee || props.currentAbonnee.id !== paramId) {
            if (props.abonneesByIdMap) {
                props.setEditAbonnee(props.abonneesByIdMap.get(paramId));
            }
        }
    };


    getAbonnee = (abonneeId: number): ?TSubscription => {
        if (this.props.abonneesByIdMap) {
            return this.props.abonneesByIdMap.get(abonneeId)
        }
    };

    isVisible = (rowId) => {
        const abon = this.getAbonnee(rowId);
        if (abon) {
            return true;
        }
        return false;
    };

    isActive = (rowId) => {
        const abon = this.getAbonnee(rowId);
        return abon.state === 'CONFIRMED';
    };

    isDisabled = (rowId) => {
        const abon = this.getAbonnee(rowId);
        return abon.state === 'DISABLED';
    };

    isTrial = (rowId) => {
        const abon = this.getAbonnee(rowId);
        return abon.trial === true;
    };

    handleAction = (event, action) => {
        const { t } = this.props;
        const abon = this.getAbonnee(action.rowId);

        if (action.id === 'edit') {
            this.props.setEditAbonnee(this.getAbonnee(action.rowId));
            this.props.editAbonnee(action.rowId);
            this.showDialog();
        } else if (action.id === 'disable') {
            if (abon) {
                //disable the subscription
                this.props.disableAbonnee(abon);

                this.props.employees.forEach((e) => {
                    if (e.idSubscriptionHolder === abon.idSubscriptionHolder) {
                        this.props.disableEmployee(e, t);
                    }
                });
            }
        } else if (action.id === 'enable') {
            if (abon) {
                //activate the subscription
                this.props.enableAbonnee(abon);


                //this will activate all users 

                // this.props.employees.forEach((e) => {
                //     if (e.idSubscriptionHolder === abon.idSubscriptionHolder) {
                //         this.props.enableEmployee(e);
                //     }
                // });
            }
        } else if (action.id === 'makeTrial') {
            if (abon) {
                //make the subscription => trial
                this.props.makeTrialAbonnee(abon);
            }
        } else if (action.id === 'makeNotTrial') {
            if (abon) {
                //activate the subscription
                this.props.makeNotTrialAbonnee(abon);
            }
        } else if (action.id === 'impersonate') {
            impersonate(abon.idSubscriptionHolder);
        }
    };

    setPage = (page) => {
        console.log("INFO: ExternOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ExternOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    showDialog = () => {
        this.setState({
            openDialog: true
        })
    }


    cancelEdit = () => {
        this.setState({
            openDialog: false
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fetched === false) {
            this.setState( { fetched: true });

            this.props.fetchAbonnees();
        }
    }

    componentDidMount() {
        this.props.setEditAbonnee(undefined);

        this.props.setCrumbPath();

        this.setState({
            fetched: false,
            actions: [
                {
                    id: 'edit',
                    label: 'Change',
                    isVisible: (id) => this.isVisible(id),
                },
                // {
                //     id: 'disable',
                //     label: 'Deactiveer',
                //     isVisible: id => {
                //         return this.isActive(id) && !this.isTrial(id) ;
                //     }
                // },
                {
                    id: 'enable',
                    label: 'Activate',
                    isVisible: (id) => {
                        return this.isDisabled(id) && !this.isTrial(id);
                    },
                },
                {
                    id: 'makeTrial',
                    label: 'Create trial subscription',
                    isVisible: (id) => {
                        return !this.isTrial(id) && this.isActive(id);
                    },
                },
                {
                    id: 'impersonate',
                    label: 'Login as a subscriber',
                    isVisible: true,
                },
                {
                    id: 'makeNotTrial',
                    label: 'Close trial subscription',
                    isVisible: (id) => {
                        return this.isTrial(id);
                    },
                },
            ],
            columns: [
                {
                    id: 'companyName',
                    numeric: false,
                    size: '20%',
                    label: 'Company name',
                },
                //{ id: 'nrEmployees', numeric: true, size: '14%', label: 'Medewerkers' },
                {
                    id: 'activeEmployees',
                    numeric: true,
                    size: '18%',
                    label: 'active employees',
                },
                {
                    id: 'maxEmployees',
                    numeric: true,
                    size: '17%',
                    label: 'Max employees',
                },
                {
                    id: 'typeSubscription',
                    numeric: false,
                    size: '18%',
                    label: 'Subscription type',
                },
                {
                    id: 'contactPerson',
                    numeric: false,
                    size: '20%',
                    label: 'Contact person',
                },
                {
                    id: 'createdOn',
                    numeric: false,
                    size: '13%',
                    label: 'Member since',
                },
                {
                    id: 'incasso',
                    numeric: false,
                    size: '10%',
                    label: 'Collection',
                },
                {
                    id: 'state',
                    numeric: false,
                    size: '120px',
                    label: 'State',
                    classes: 'Status',
                },
            ],
        });
    }

    componentWillReceiveProps(newProps: Props) {
        this.processProps(newProps);
    }

    abonneeStateToText = (state, trial, expiryDate) => {
        const { t } = this.props;
        let text = '';
        let class_type = '';

        switch (state) {
            case 'REQUESTED':
                text = t('Inactive');
                class_type = 'INACTIVE';
                break;
            case 'CONFIRMED':
                text = t('Active');
                class_type = 'ACTIVE';
                break;
            case 'DISABLED':
                if (expiryDate != null) {
                    let date = new Date(expiryDate);
                    text =
                        date.getDate() +
                        '/' +
                        (date.getMonth() + 1) +
                        '/' +
                        date.getFullYear();
                    class_type = 'INACTIVE';
                } else {
                    text = t('Inactive');
                    class_type = 'INACTIVE';
                }
                break;
            default:
        }
        //check if the subscription is just a trial
        if (text === t('Active') && trial === true) {
            text = t('Trial');
            class_type = 'TRIAL';
        }

        return <div className={'border-text ' + class_type}>{text}</div>;
    };

    mapAbonneeToRow = (abonnee, index) => {
        const { t } = this.props;
        return {
            ...abonnee,
            id: abonnee.id,
            companyName: abonnee.companyName,
            state: this.abonneeStateToText(
                abonnee.state,
                abonnee.trial,
                abonnee.expiryDate
            ),
            maxEmployees:
                abonnee.maxEmployees !== -1
                    ? t('max') + abonnee.maxEmployees
                    : t('Infinite'),
            incasso: abonnee.stripeId ? t('Yes') : t('No'),
            contactPerson: abonnee.fullNameSubscriptionHolder,
            nrEmployees: abonnee.totalEmployees,
            activeEmployees:
                abonnee.activeEmployees + '/' + abonnee.totalEmployees,
            createdOn: moment(abonnee.dateCreatedOn).format(t('DD-MM-YYYY')),
            // typeSubscription: abonnee.type === 'CUMELA' ? 'Cumela' : ( (abonnee.credits_setup && !abonnee.vv_functions) ? 'Sneldelen only' : 'Veilig Vakwerk'),
            typeSubscription:
                abonnee.credits_setup && !abonnee.vv_functions
                    ? t('Sneldelen only')
                    : abonnee.cumela === true
                    ? abonnee.credits_setup
                        ? t('Cumela (+ Express sharing)')
                        : t('Cumela')
                    : abonnee.caoLeo === true
                    ? abonnee.credits_setup
                        ? t('cao Groen, Grond en Infrastructuur met Sneldelen')
                        : t('cao Groen, Grond en Infrastructuur')
                    : abonnee.credits_setup
                    ? t('Standard (+ Express sharing)')
                    : t('Standard'),
        };
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    onRowClick = (row, event) => {
        if (row.id) {
            this.props.goToDetailView(row.id);
        }
    };

    employeeSorter = () => {
        return {
            companyName: (a, b) => {
                if (a && b) {
                    if (
                        a.companyName.toLowerCase() >
                        b.companyName.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.companyName.toLowerCase() <
                        b.companyName.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            },
            createdOn: (a, b) => {
                if (a && b) {
                    if (a.dateCreatedOn > b.dateCreatedOn) {
                        return 1;
                    }
                    if (a.dateCreatedOn < b.dateCreatedOn) {
                        return -1;
                    }
                }
                return 0;
            },
            maxEmployees: (a, b) => {
                if (a && b) {
                    if (a.maxEmployees === -1 && b.maxEmployees === -1) {
                        return 0;
                    }
                    if (a.maxEmployees === -1) {
                        return 1;
                    }
                    if (b.maxEmployees === -1) {
                        return -1;
                    }
                    if (a.maxEmployees > b.maxEmployees) {
                        return 1;
                    }
                    if (a.maxEmployees < b.maxEmployees) {
                        return -1;
                    }
                }
                return 0;
            },
            activeEmployees: (a, b) => {
                if (a && b) {
                    if (a.activeEmployees > b.activeEmployees) {
                        return 1;
                    }
                    if (a.activeEmployees < b.activeEmployees) {
                        return -1;
                    }
                }
                return 0;
            },
            typeSubscription: (a, b) => {
                if (a && b) {
                    if (a.type > b.type) {
                        return 1;
                    }
                    if (a.type < b.type) {
                        return -1;
                    }
                }
                return 0;
            },
            contactPerson: (a, b) => {
                if (a && b) {
                    if (
                        a.fullNameSubscriptionHolder.toLowerCase() >
                        b.fullNameSubscriptionHolder.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.fullNameSubscriptionHolder.toLowerCase() <
                        b.fullNameSubscriptionHolder.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            },
            state: (a, b) => {
                if (a && b) {
                    if (a.state === 'CONFIRMED') {
                        if (b.state === 'CONFIRMED') {
                            if (a.trial === false) {
                                if (b.trial === false) return 0;
                                else return 1;
                            } else if (b.trial === false) {
                                return -1;
                            } else return 0;
                        } else if (a.trial === false) {
                            return 1;
                        } else return -1;
                    } else if (b.state === 'CONFIRMED') {
                        if (b.trial === false) {
                            return -1;
                        } else return 1;
                    } else if (a.trial === false) {
                        if (b.trial === false) return 0;
                        else return 1;
                    } else if (b.trial === false) return -1;
                    else return 0;
                }
                return 0;
            },
        };
    };

    downloadCSV = () => {
        const { t } = this.props;
        var dataCSV = this.convertArrayOfObjectsToCSV(this.props.abonnees);
        var data, filename, link;
        var csv = 'data:text/csv;charset=utf-8,' + dataCSV;
        if (csv == null) return;

        filename = t('subscribersCsv');

        data = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click(); //
        document.body.removeChild(link);
    };

    convertArrayOfObjectsToCSV = (data) => {
        const { t } = this.props;
        var result, ctr, keys, columnDelimiter, lineDelimiter;

        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        //all columns
        // keys = Object.keys(data[0]);

        //just few columns
        keys = [
            'id',
            'dateCreatedOn',
            'companyName',
            'fullNameSubscriptionHolder',
            'phoneNumberSubscriptionHolder',
            'type',
            'maxEmployees',
            'price',
            'state',
        ];

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (key === 'state') {
                    switch (item[key]) {
                        case 'REQUESTED':
                            result += 'Inactief';
                            break;
                        case 'CONFIRMED':
                            if (item['trial'] === true) {
                                result += 'Proef';
                            } else {
                                result += 'Actief';
                            }
                            break;
                        case 'DISABLED':
                            result += 'Inactief';
                            break;
                        default:
                    }
                } else if (key === 'type') {
                    let subscriptionType =
                        item['credits_setup'] && !item['vv_functions']
                            ? t('Sneldelen only')
                            : item['cumela'] === true
                            ? item['credits_setup']
                                ? t('Cumela (+ Express sharing)')
                                : t('Cumela')
                            : item['caoLeo'] === true
                            ? item['credits_setup']
                                ? t(
                                      'Collective Labor Agreement for Green, Land and Infrastructure (+ Fast sharing)'
                                  )
                                : t('cao Groen, Grond en Infrastructuur')
                            : item['credits_setup']
                            ? t('Standard (+ Express sharing)')
                            : t('Standard');
                    result += subscriptionType;
                } else if (key === 'dateCreatedOn') {
                    result += moment(item['dateCreatedOn']).format(
                        t('DD-MM-YYYY')
                    );
                } else {
                    result += item[key] ? item[key] : '-';
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    render() {
        const { t } = this.props;
        if (
            this.props.currentUser &&
            this.props.currentUser.roles.includes('ADMIN')
        ) {
            const { abonnees, currentAbonnee } = this.props;
            const { actions, columns } = this.state;

            let totalAbonnees = 0;
            let activeAbonnees = 0;
            let searchresults = [];

            if (this.props.abonnees) {
                totalAbonnees =
                    this.props.abonnees.length +
                    ' ' +
                    t('subscriber') +
                    (this.props.abonnees.length === 1 ? '' : 's');
                activeAbonnees =
                    '(' +
                    abonnees.filter(
                        (a) => a.state === 'CONFIRMED' && a.trial === false
                    ).length +
                    ` ${t('active')})`;

                searchresults = abonnees.filter((row) => {
                    return (
                        (row.companyName &&
                            row.companyName
                                .toLowerCase()
                                .indexOf(this.state.searchtext.toLowerCase()) >
                                -1) ||
                        (row.contactPerson &&
                            row.contactPerson
                                .toLowerCase()
                                .indexOf(this.state.searchtext.toLowerCase()) >
                                -1)
                    );
                });
            }

            const tableActionHeader = (
                <TableActionHeader
                    searchPlaceholder={t('Search in subscribers')}
                    onSearchChange={this.handleSearch}
                    title={
                        totalAbonnees +
                        ' ' +
                        activeAbonnees +
                        (this.state.searchtext.length > 0
                            ? (searchresults.length === 1
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresult')
                                  : '') +
                              (searchresults.length > 1 ||
                              searchresults.length === 0
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresults')
                                  : '')
                            : '')
                    }
                />
            );

            return (
                <div>
                    <AppModule prepend={tableActionHeader}>
                        <EnhancedTable
                            hover
                            columns={columns}
                            onClick={this.onRowClick}
                            rows={searchresults} // rows={abonnees || []}
                            formatter={this.mapAbonneeToRow}
                            actions={actions} //actions={[]}
                            onAction={this.handleAction}
                            sorter={this.employeeSorter()}
                            emptyState={
                                this.state.searchtext.length > 0
                                    ? t('No searchresults')
                                    : t('There are no subscribers yet.')
                            }
                            onPageChange={this.setPage}
                            onRowsPerPageChange={this.setRowsPerPage}
                            startPage={this.state.startPage}
                            startRowsPerPage={this.state.startRowsPerPage}

                        />

                        <div
                            style={{
                                position: 'fixed',
                                top: '84px',
                                right: '36px',
                                zIndex: 1500,
                            }}
                        >
                            <div className={'tooltip'}>
                                <span
                                    className={'tooltiptext'}
                                    style={{ top: '60px' }}
                                >
                                    {t('Download subscribers as CSV')}
                                </span>
                                <Fab
                                     
                                    onClick={this.downloadCSV}
                                >
                                    <DescIcon />
                                </Fab>
                            </div>
                        </div>

                        <Dialog
                            open={this.state.openDialog}
                            onClose={this.cancelEdit}
                            TransitionComponent={Transition}
                            scroll={'body'}
                        >
                            <DialogTitle className="title">
                                {t('Change subscriber')}
                            </DialogTitle>
                            <AbonneeEditCard
                                hideParents={true}
                                abonnee={currentAbonnee}
                                onEdit={this.props.setEditAbonnee}
                                onSave={this.onSave}
                                goBack={this.goBack}
                            />
                        </Dialog>
                    </AppModule>
                </div>
            );
        } else {
            return <div />;
        }
    }
}
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(SubscriptionsOverview);
