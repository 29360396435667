// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';

import { TableRow } from '@mui/material';
import classes from './EnhancedTableRow.module.scss';

type Props = {};


const EnhancedTableRow = (props: Props) => {
    const { className, children } = props;
    let rootClasses;
    if (props.hover) {
        if (props.notify){
            rootClasses = classnames({
                [classes.root]: true,
                [className]: true,
                [classes.hover]: true,
                [classes.notify]: true
            })
        } else {
            rootClasses = classnames({
                [classes.root]: true,
                [className]: true,
                [classes.hover]: true
            })
        }

    } else { 
        rootClasses = classnames({
        [classes.root]: true,
        [className]: true
        })
    }


    return (
        <TableRow onClick={props.onClick} hover={props.hover} className={ rootClasses }>
            { children }
        </TableRow>
    );
}

export default (EnhancedTableRow);