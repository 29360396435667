// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import './TitleSwitch.css';
import {FormControl, FormControlLabel, Switch} from '@mui/material';

type Props = {
	itemTitle: string,
	onSwitch: (value: any) => mixed,
	children: any,
	disabled: boolean
};

type State = {};

class TitleSwitch extends React.Component<Props, State> {
	props: Props;
	state: State = {
		value: false
	};

	componentDidMount() {
		const {initialValue} = this.props;
		this.setState({
			value: initialValue
		});
	}

	handleSwitchChange = event => {
		const {onSwitch} = this.props;
		const value = event.target.checked;

		this.setState({
			value: value
		}, () => {
			onSwitch(value);
		})
	}

	componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
		const {initialValue} = this.props;
		if (prevProps.initialValue !== initialValue) {
			this.setState({
				value: initialValue
			});
		}
	}

	render() {
		const {disabled, itemTitle, children} = this.props;
		const {value} = this.state;

		return (
			<div className="titleSwitch">
				<div className="titleSwitchTitle">{itemTitle}</div>
				<div className="titleSwitchAction">
					<FormControl disabled={disabled}>
						<FormControlLabel label="" control={<Switch checked={value} onChange={this.handleSwitchChange}/>}/>
					</FormControl>
				</div>
				<div>
					{children}
				</div>
			</div>
		);
	}
}

export default (TitleSwitch);
