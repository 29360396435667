import { createTheme } from '@mui/material/styles';

let theme = require('./themes/veiligvakwerk').theme;
switch (process.env.REACT_APP_PROJECT) {
    case 'janssen':
        theme = require('./themes/janssen').theme;
        break;
    default:
        theme = require('./themes/veiligvakwerk').theme;
}

const muiTheme = createTheme(theme);
export const muiThemeLegacy = muiTheme;

export default muiTheme;
