import uuidv4 from 'uuid/v4';
import { CREATE_QUESTION_BLOCK_DRAFT, DELETE_QUESTION_BLOCK_DRAFT, EDIT_ANSWER_LIST_ITEM_BLOCK_DRAFT, EDIT_QUESTION_BLOCK_DRAFT, MOVE_ANSWER_LIST_ITEM_BLOCK_DRAFT,DELETE_ANSWER_LIST_ITEM_BLOCK_DRAFT, ADD_ANSWER_LIST_ITEM_BLOCK_DRAFT } from '../actions';

const initialState = {};

export default function questionBlockDraft(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_QUESTION_BLOCK_DRAFT: {
            const { data } = action.payload;
            const draftBlock = { ...data };
            return { ...state, [data.id]: draftBlock };
        } case DELETE_QUESTION_BLOCK_DRAFT: {
            const { id } = action.payload;
            const newState = {...state};
            delete(newState[id]);
            return newState;
        } case EDIT_QUESTION_BLOCK_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: { ...state[id], [key]: value } };
        } case EDIT_ANSWER_LIST_ITEM_BLOCK_DRAFT: {
            const { id, index, key, value } = action.payload;
            let items = [...state[id].possibleAnswers];
            items[index] = { ...items[index], [key]: value };
            return { ...state, [id]: { ...state[id], possibleAnswers: items } };
        }  case MOVE_ANSWER_LIST_ITEM_BLOCK_DRAFT: {
            const { id, fromIndex, toIndex } = action.payload;
            const questionBlock = state[id];
            const newItems = [...questionBlock.possibleAnswers];
            newItems.splice(toIndex, 0, newItems.splice(fromIndex, 1)[0]);
            return { ...state, [id]: { ...state[id], possibleAnswers: newItems } };
        } case DELETE_ANSWER_LIST_ITEM_BLOCK_DRAFT: {
            const { id, index } = action.payload;
            const questionBlock = state[id];
            const newItems = [...questionBlock.possibleAnswers];
            newItems.splice(index, 1);
            return { ...state, [id]: { ...state[id], possibleAnswers: newItems } };
        } case ADD_ANSWER_LIST_ITEM_BLOCK_DRAFT: {
            const { id } = action.payload;
            const questionBlock = state[id];
            const newItems = [...questionBlock.possibleAnswers];
            newItems.push({ id: uuidv4(), text: ''});
            return { ...state, [id]: { ...state[id], possibleAnswers: newItems } };
        }  default:
            return state;
    }
}
