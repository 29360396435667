// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'lodash';

import Collapse from '@mui/material/Collapse';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ErrorMessage.module.scss';

type Props = {
    error: any,
};

const ErrorMessage = (props: Props) => {
    const { t } = props;
    let message = _.get(props, 'error.message', '');

    message = message.replace('Authentication Failed: ', '');

    return (
        <Collapse in={message !== ''}>
            <div className={classes.root}>{t(message)}</div>
        </Collapse>
    );
};

export default compose(withTranslation(), )(ErrorMessage);
