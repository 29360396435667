// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import Spinner from '../Spinner'
import classes from './Loader.module.scss';

type Props = {
    size: ?number,
    loading?: boolean,
    className?: mixed,
    children?: mixed
};


const Loader = (props: Props) => {
    const { loading, size, children } = props;

    const style = {
        icon: {
            width: (size || 200) + 'px',
            display: loading ? 'block' : 'none'
        },
        children: {
            visibility: loading ? 'hidden' : 'visible'
        }
    };

    return (
        <div className={classes.root}>
            <div style={style.children}>{children}</div>
            <div className={classes.iconWrapper}>
                <div className={classes.icon} style={style.icon}>
                    {loading && <Spinner />}
                </div>
            </div>
        </div>
    );
};

export default (Loader);
