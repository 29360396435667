// @flow

import { connect } from 'react-redux';
import React from 'react';
import StatisticsToolboxDetailCard from '../StatisticsCard/StatisticsToolboxDetailCard';
import { push } from 'connected-react-router';
import {
    setViewkey,
    setCrumbPath,
    fetchToolboxes,
    pushSubjectOrComment,
    removeSubjectOrComment,
    updateChairman,
    fetchToolboxAssigns,
    getToolboxByIdV2,
} from '../../../actions';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListToolboxMeeting from '../StatisticsList/StatisticsListToolboxMeeting';
import getFilteredToolboxAssigns from '../../../selectors/getFilteredToolboxAssigns';
import { fetchCSV, fetchPDF } from '../../../lib/api';
import DescIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Divider,
    FormControl,
    Typography,
    Input,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField, Fab,
} from '@mui/material';

import TitleDescription from '../../TitleDescription';
import CloseIcon from '@mui/icons-material/Close';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/GetApp';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

//type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {};

const mapStateToProps = (state, props) => {
    const toolboxMeetingId = props.match.params.id;

    let toolboxAssign = getFilteredToolboxAssigns(state)
        ? getFilteredToolboxAssigns(state).filter(
              (ta) => ta.id.toString() === toolboxMeetingId
          )
        : null;
    let toolboxAssigns = toolboxAssign[0]
        ? toolboxAssign[0].listParticipants
        : null;
    let toolboxAssigned = toolboxAssign && toolboxAssign[0] ? toolboxAssign[0] : null;
    let toolboxId = toolboxAssign && toolboxAssign[0] ? toolboxAssign[0].toolbox.id : null;

    let toolbox = state.entities.toolbox.byId[toolboxId];

    return {
        toolboxAssignsReal: state.entities.toolboxAssigns,
        toolboxAssigns: toolboxAssigns,
        toolboxAssigned: toolboxAssigned,
        toolboxMeetingId: toolboxMeetingId,
        allSectors: state.entities.sectors,
        allCategories: state.entities.categories,
        toolboxId: toolboxId,
        toolbox: toolbox,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (title, id) => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        {
                            name: 'Toolbox: ' + title,
                            link: '/rapportages#toolboxen',
                        },
                        {
                            name: 'Toolboxmeeting',
                            link: '/rapportages/toolbox/' + id,
                        },
                    ],
                })
            );
        },
        goToDetailView: (toolboxAssign) => {
            dispatch(
                push(
                    '/toolbox/details/' +
                        toolboxAssign.toolbox.id +
                        '/' +
                        toolboxAssign.deploymentId +
                        '/results/' +
                        toolboxAssign.user.id +
                        '?from=rapportagesTMgroups'
                )
            );
        },
        fetchToolboxes: () => {
            dispatch(fetchToolboxes());
        },
        pushSubjectOrComment: (type, toolboxAssignId, date, payload) => {
            dispatch(
                pushSubjectOrComment(type, toolboxAssignId, date, payload)
            );
        },
        removeSubjectOrComment: (type, subjectId, date, payload) => {
            dispatch(removeSubjectOrComment(type, subjectId, date, payload));
        },
        updateChairman: (assignId, date, payload) => {
            dispatch(updateChairman(assignId, date, payload));
        },
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
        getToolboxByIdV2: (toolboxId) => {
            dispatch(getToolboxByIdV2(toolboxId));
        },
    };
};

class StatisticsToolboxMeetingView extends React.Component<Props, void> {
    props: Props;
    state: State = {
        subjectValue: '',
        commentValue: '',
        chairmanValue: '',
        dialogOpen: true,
        list: null,
        listParticipants: null,
    };

    componentDidMount() {
        // this.props.setViewkey();
        if (this.props.toolboxAssigns && this.props.toolboxAssigns[0]) {
            this.props.setCrumbPath(
                this.props.toolboxAssigns[0].toolbox.title,
                this.props.toolboxAssigns[0].toolbox.id
            );
        }
        if (this.props.toolboxId) {
            this.props.getToolboxByIdV2(this.props.toolboxId);
        }
        // this.props.setCrumbPath(this.props.toolboxAssigns && this.props.toolboxAssigns[0] ? this.props.toolboxAssigns[0].toolbox.title : this.props.toolboxAssigned ? this.props.toolboxAssigned.toolbox.title : 'Titel', this.props.toolboxAssigns && this.props.toolboxAssigns[0] ? this.props.toolboxAssigns[0].toolbox.id : this.props.toolboxAssigned ? this.props.toolboxAssigned.toolbox.id : 0);
    }

    componentWillMount() {
        // this.props.fetchToolboxAssigns();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevProps.toolboxAssigns === undefined &&
            this.props.toolboxAssigns !== undefined &&
            this.props.toolboxAssigns[0] !== undefined &&
            this.props.toolboxAssigns[0] !== null &&
            this.props.toolboxAssigns[0] !== undefined
        ) {
            this.props.setCrumbPath(
                this.props.toolboxAssigns[0].toolbox.title,
                this.props.toolboxAssigns[0].toolbox.id
            );
        }
    }

    onItemClicked = (clickedToolboxAssign) => {
        this.props.goToDetailView(clickedToolboxAssign);
    };

    getList = () => {
        const selected = this.props.toolboxAssignsReal.byId[
            this.props.toolboxMeetingId
        ];

        if (selected) {
            //Legacy toolboxmeetings
            let list = Object.values(this.props.toolboxAssignsReal.byId).filter(
                (e) =>
                    e.toolboxMeetingDate === selected.toolboxMeetingDate &&
                    e.toolboxMeetingLocation ===
                        selected.toolboxMeetingLocation &&
                    e.toolbox.id === selected.toolbox.id
            );
            return (
                <StatisticsListToolboxMeeting
                    toolbox={this.props.toolbox}
                    toolboxAssigns={list}
                    onItemClick={this.onItemClicked}
                />
            );
        } else {
            return (
                <StatisticsListToolboxMeeting
                    toolboxAssigns={[]}
                    onItemClick={this.onItemClicked}
                />
            );
        }

        /*if(selected && selected.meetingid) {
            //New way of getting toolboxmeetings
                let list = Object.values(this.props.toolboxAssignsReal.byId).filter(e =>
                    e.meetingid === selected.meetingid);
            return <StatisticsListToolboxMeeting toolboxAssigns={list} onItemClick={this.onItemClicked} />;
        }
        else if(selected) {
            //Legacy toolboxmeetings
            let list = Object.values(this.props.toolboxAssignsReal.byId).filter(e =>
                e.toolboxMeetingDate === selected.toolboxMeetingDate &&
                e.toolboxMeetingLocation === selected.toolboxMeetingLocation &&
                e.toolbox.id === selected.toolbox.id);
            return <StatisticsListToolboxMeeting toolboxAssigns={list} onItemClick={this.onItemClicked} />;
        } else {
            return <StatisticsListToolboxMeeting toolboxAssigns={[]} onItemClick={this.onItemClicked} />;
        }*/
    };

    getTMPDF = (toolboxAssign) => {
        if (toolboxAssign.toolboxMeeting) {
            let data = [];
            if (toolboxAssign.listParticipants) {
                this.setState({
                    listParticipants: toolboxAssign.listParticipants,
                });
                toolboxAssign.listParticipants.map((p) => data.push(p));
                data.map((p) => (p.listParticipants = []));
                data.map((p) => (p.assignList = []));
            } else if (this.state.listParticipants) {
                this.state.listParticipants.map((p) => data.push(p));
                data.map((p) => (p.listParticipants = []));
                data.map((p) => (p.assignList = []));
            }

            data[0].toolbox.sectors = data[0].toolbox.sectors.map(
                (id, index) => {
                    return this.props.allSectors.byId[id]
                        ? this.props.allSectors.byId[id].name
                              .charAt(0)
                              .toUpperCase() +
                              this.props.allSectors.byId[id].name.slice(1)
                        : id;
                }
            );
            data[0].toolbox.category = this.props.allCategories.byId[
                data[0].toolbox.category
            ]
                ? this.props.allCategories.byId[data[0].toolbox.category].name
                : data[0].toolbox.category;

            let subjects = '';
            let oldSubjects = data[0].toolboxMeetingSubjects;
            data[0].toolboxMeetingSubjects.map(
                (s) => (subjects = subjects + '\n' + s.subject.toString())
            );
            data[0].toolboxMeetingSubjects = subjects;

            // Set the full toolbox
            data[0].toolbox = this.props.toolbox;

            fetchPDF({
                platform: 'VV',
                customer: 'VV',
                template: 'TOOLBOXMEETING_RAPPORTAGE',
                options: {},
                data: data,
                info: {
                    periodYear: this.props.periodYear,
                    periodMonth: this.props.periodMonth,
                    periodWeek: this.props.periodWeek,
                },
            })
                .then((res) => {
                    var encodedUri = window.URL.createObjectURL(res.body);
                    var link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute(
                        'download',
                        'toolboxMeetingRapportage_' +
                            toolboxAssign.toolbox.title +
                            '.pdf'
                    );
                    document.body.appendChild(link);
                    link.click(); //
                })
                .catch((err) => console.log(err));

            data[0].toolboxMeetingSubjects = oldSubjects;
        } else {
            const { toolbox, assignId } = this.props;
            let { toolboxAssigns } = this.props;

            let pdfToolbox = Object.assign({}, toolbox);
            pdfToolbox.sectors = pdfToolbox.sectors.map((id, index) => {
                return this.props.allSectors.byId[id]
                    ? this.props.allSectors.byId[id].name
                    : id;
            });
            pdfToolbox.category = this.props.allCategories.byId[
                pdfToolbox.category
            ]
                ? this.props.allCategories.byId[pdfToolbox.category].name
                : pdfToolbox.category;

            toolboxAssigns = toolboxAssigns.filter(
                (ta) => ta.id.toString() === assignId.toString()
            );

            pdfToolbox.showResults = true;

            fetchPDF({
                platform: 'VV',
                customer: 'VV',
                template: 'TOOLBOX',
                options: {},
                data: [],
                info: { toolbox: pdfToolbox, toolboxAssigns },
            })
                .then((res) => {
                    var encodedUri = window.URL.createObjectURL(res.body);
                    var link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute(
                        'download',
                        this.props.toolbox.title +
                            ' v' +
                            this.props.toolbox.version +
                            '.pdf'
                    );
                    document.body.appendChild(link);
                    link.click(); //
                })
                .catch((err) => console.log(err));
        }
    };

    getTMCSV = (toolboxAssign) => {
        if (toolboxAssign.toolboxMeeting) {
            let data = [];
            if (toolboxAssign.listParticipants) {
                this.setState({
                    listParticipants: toolboxAssign.listParticipants,
                });
                toolboxAssign.listParticipants.map((p) => data.push(p));
                data.map((p) => (p.listParticipants = []));
                data.map((p) => (p.assignList = []));
            } else if (this.state.listParticipants) {
                this.state.listParticipants.map((p) => data.push(p));
                data.map((p) => (p.listParticipants = []));
                data.map((p) => (p.assignList = []));
            }

            let formattedData = [];

            data.forEach((i) => {
                let score = 0;

                if (i.result) {
                    i.result.answers.forEach((a) => {
                        if (a.correct) {
                            score++;
                        }
                    });
                }

                formattedData.push({
                    deployed: moment(i.deployed).format('LLL'),
                    employee: i.user.fullname,
                    finished: i.finished
                        ? moment(i.finished).format('LLL')
                        : '',
                    score: i.result ? score : '',
                    vragen: i.result ? i.result.answers.length : '',
                });
            });

            fetchCSV({
                platform: 'VV',
                customer: 'VV',
                template: 'TOOLBOXMEETING_RAPPORTAGE',
                options: {},
                data: formattedData,
                info: {
                    periodYear: this.props.periodYear,
                    periodMonth: this.props.periodMonth,
                    periodWeek: this.props.periodWeek,
                },
            })
                .then((res) => {
                    var encodedUri = window.URL.createObjectURL(res.body);
                    var link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute(
                        'download',
                        'toolboxMeetingRapportage_' +
                            toolboxAssign.toolbox.title +
                            '.csv'
                    );
                    document.body.appendChild(link);
                    link.click(); //
                })
                .catch((err) => console.log(err));
        }
    };

    handleSubjectChange = (event) => {
        if (event.target.value.length < 105) {
            this.setState({ subjectValue: event.target.value });
        }
    };

    handleAddSubject = (event) => {
        const toolboxAssigned = this.props.toolboxAssigned;

        if (this.state.subjectValue) {
            // Remove added fields to prevent 500 on API
            let assignedTb = toolboxAssigned;
            delete assignedTb.expanded;
            delete assignedTb.finished;
            delete assignedTb.listParticipants;

            this.props.pushSubjectOrComment(
                'subject',
                toolboxAssigned.toolbox.id,
                moment(toolboxAssigned.toolboxMeetingDate).format(
                    'YYYY-MM-DDTHH:mm:ss-SSS'
                ),
                {
                    toolboxAssign: assignedTb,
                    subject: this.state.subjectValue,
                }
            );

            this.setState({
                subjectValue: '',
            });
        }
    };

    handleCommentChange = (event) => {
        if (event.target.value.length < 105) {
            this.setState({ commentValue: event.target.value });
        }
    };

    handleAddComment = (event) => {
        const toolboxAssigned = this.props.toolboxAssigned;

        if (this.state.commentValue) {
            // Remove added fields to prevent 500 on API
            let assignedTb = toolboxAssigned;
            delete assignedTb.expanded;
            delete assignedTb.finished;
            delete assignedTb.listParticipants;

            this.props.pushSubjectOrComment(
                'comment',
                toolboxAssigned.toolbox.id,
                moment(toolboxAssigned.toolboxMeetingDate).format(
                    'YYYY-MM-DDTHH:mm:ss-SSS'
                ),
                {
                    toolboxAssign: assignedTb,
                    comment: this.state.commentValue,
                }
            );

            this.setState({
                commentValue: '',
            });
        }
    };

    removeSelectedSubject = (subject) => {
        const toolboxAssigned = this.props.toolboxAssigned;

        this.props.removeSubjectOrComment(
            'subject',
            toolboxAssigned.toolbox.id,
            moment(toolboxAssigned.toolboxMeetingDate).format(
                'YYYY-MM-DDTHH:mm:ss-SSS'
            ),
            subject
        );
    };

    removeSelectedComment = (comment) => {
        const toolboxAssigned = this.props.toolboxAssigned;

        this.props.removeSubjectOrComment(
            'comment',
            toolboxAssigned.toolbox.id,
            moment(toolboxAssigned.toolboxMeetingDate).format(
                'YYYY-MM-DDTHH:mm:ss-SSS'
            ),
            comment
        );
    };

    handleChangeChairman = (event) => {
        this.setState({
            chairmanValue: this.props.toolboxAssigned.toolboxMeetingChairman,
            openDialog: true,
        });
    };

    handleChairmanChange = (event) => {
        if (event.target.value.length < 105) {
            this.setState({
                chairmanValue: event.target.value,
            });
        }
    };

    handleClose = (event) => {
        this.setState({
            openDialog: false,
        });
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    handleSaveChairman = (event) => {
        const toolboxAssigned = this.props.toolboxAssigned;

        this.props.updateChairman(
            toolboxAssigned.toolbox.id,
            moment(toolboxAssigned.toolboxMeetingDate).format(
                'YYYY-MM-DDTHH:mm:ss-SSS'
            ),
            {
                chairman: this.state.chairmanValue,
            }
        );

        this.setState({
            openDialog: false,
        });
    };

    render() {
        const { classes, toolboxAssigned, t } = this.props;
        const { subjectValue, commentValue } = this.state;

        const tableActionHeader = <TableActionHeader />;

        let comments = [];
        let subjects = [];
        if (toolboxAssigned) {
            if (
                toolboxAssigned.toolboxMeetingComments != null &&
                toolboxAssigned.toolboxMeetingComments.length > 0
            ) {
                toolboxAssigned.toolboxMeetingComments
                    .sort((a, b) => a.id > b.id)
                    .forEach((e) => {
                        comments.push(
                            <div>
                                <div className={classes.itemLeft}>
                                    <TitleDescription
                                        className={classes.toolboxMeetingLeft}
                                        itemDescription={e.comment}
                                        width={'250px'}
                                    />
                                </div>
                                <Button
                                    className={classes.buttonDelete}
                                    onClick={() =>
                                        this.removeSelectedComment(e)
                                    }
                                >
                                    <DeleteIcon />
                                </Button>
                                <Divider className={classes.divider} />
                            </div>
                        );
                    });
            }

            if (toolboxAssigned.toolboxMeetingSubjects.length > 0) {
                toolboxAssigned.toolboxMeetingSubjects
                    .sort((a, b) => a.id > b.id)
                    .forEach((e) => {
                        subjects.push(
                            <div>
                                <div className={classes.itemLeft}>
                                    <TitleDescription
                                        className={classes.toolboxMeetingLeft}
                                        itemDescription={e.subject}
                                        width={'250px'}
                                    />
                                </div>
                                <Button
                                    className={classes.buttonDelete}
                                    onClick={() =>
                                        this.removeSelectedSubject(e)
                                    }
                                >
                                    <DeleteIcon />
                                </Button>
                                <Divider className={classes.divider} />
                            </div>
                        );
                    });
            }
        }

        return (
            <div className="Statistics">
                <AppModule loading={false} prepend={tableActionHeader}>
                    {this.props.toolboxAssigned ? (
                        <div className={classes.toolboxMeetingBox}>
                            <Dialog
                                open={this.state.openDialog}
                                keepMounted
                                onClose={this.handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle>{t('Change chairman')}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        {t('Name of the new chairman')}
                                        <TextField
                                            id="firstname"
                                            value={this.state.chairmanValue}
                                            required
                                            onChange={this.handleChairmanChange}
                                        />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                        color="secondary"
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        onClick={this.handleSaveChairman}
                                        color="secondary"
                                    >
                                        {t('Save')}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Typography className={classes.toolboxMeetingBoxH3}>
                                {t('Toolboxmeeting details')}
                            </Typography>
                            <div className={classes.itemLeft}>
                                <TitleDescription
                                    className={classes.toolboxMeetingLeft}
                                    itemTitle={t('Date')}
                                    itemDescription={moment
                                        .utc(
                                            this.props.toolboxAssigned
                                                .toolboxMeetingDate
                                        )
                                        .local()
                                        .format(t('DD-MM-YYYY'))}
                                    width={'250px'}
                                />
                                <TitleDescription
                                    className={classes.toolboxMeetingRight}
                                    itemTitle={t('Time of the day')}
                                    itemDescription={moment
                                        .utc(
                                            this.props.toolboxAssigned
                                                .toolboxMeetingDate
                                        )
                                        .local()
                                        .format(t('hourMinute'))}
                                    width={'250px'}
                                />
                            </div>
                            <div className={classes.itemLeft}>
                                <TitleDescription
                                    className={classes.toolboxMeetingLeft}
                                    itemTitle={t('Location')}
                                    itemDescription={
                                        toolboxAssigned.toolboxMeetingLocation
                                    }
                                    width={'250px'}
                                />
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.itemLeft}>
                                <TitleDescription
                                    className={classes.toolboxMeetingLeft}
                                    itemTitle={t('Chairman')}
                                    itemDescription={
                                        toolboxAssigned.toolboxMeetingChairman
                                            ? toolboxAssigned.toolboxMeetingChairman
                                            : '-'
                                    }
                                    width={'250px'}
                                />
                            </div>
                            <Button
                                style={{ marginTop: '12px' }}
                                onClick={this.handleChangeChairman}
                            >
                                <EditIcon />
                            </Button>
                            <Divider className={classes.divider} />

                            <TitleDescription
                                className={classes.toolboxMeetingLeft}
                                style={{ float: 'none' }}
                                itemTitle={t('Topics covered')}
                            />

                            {subjects}

                            <FormControl>
                                <div
                                    style={{
                                        display: 'flex',
                                        background: 'rgba(220,220, 220, 0.3)',
                                    }}
                                >
                                    <Input
                                        style={{
                                            backgroundColor:
                                                'rgba(220,220, 220, 0.3)',
                                            height: '40px',
                                            paddingLeft: '25px',
                                            paddingTop: '10px',
                                        }}
                                        fullWidth={true}
                                        disableUnderline={true}
                                        
                                        id="addSubject"
                                        value={subjectValue}
                                        onChange={this.handleSubjectChange}
                                        placeholder={t('Add subject')}
                                    />
                                    <Button
                                        onClick={() => this.handleAddSubject()}
                                    >
                                        <AddIcon />
                                    </Button>
                                </div>
                            </FormControl>

                            <Divider className={classes.divider} />
                            <TitleDescription
                                className={classes.toolboxMeetingLeft}
                                style={{ float: 'none' }}
                                itemTitle={t('comments')}
                            />

                            {comments}

                            <FormControl>
                                <div
                                    style={{
                                        display: 'flex',
                                        background: 'rgba(220,220, 220, 0.3)',
                                    }}
                                >
                                    <Input
                                        style={{
                                            backgroundColor:
                                                'rgba(220,220, 220, 0.3)',
                                            height: '40px',
                                            paddingLeft: '25px',
                                            paddingTop: '10px',
                                        }}
                                        fullWidth={true}
                                        disableUnderline={true}
                                        
                                        id="addSubject"
                                        value={commentValue}
                                        onChange={this.handleCommentChange}
                                        placeholder={t('Add subject')}
                                    />
                                    <Button
                                        onClick={() => this.handleAddComment()}
                                    >
                                        <AddIcon />
                                    </Button>
                                </div>
                            </FormControl>
                        </div>
                    ) : (
                        ''
                    )}

                    <div
                        className={classes.tabelDiv}
                        style={{ textAlign: '', backgroundColor: '#FFF' }}
                    >
                        <Typography className={classes.toolboxMeetingBoxH3}>
                            Presentielijst en uitslagen
                        </Typography>
                        <StatisticsToolboxDetailCard
                            toolboxBarData={
                                this.props.chartData
                                    ? this.props.chartData.correctnessBarData
                                    : []
                            }
                            // onDownloadCSV={this.downloadCsv}
                            list={this.getList()}
                            totalToolboxesIncorrect={
                                this.props.chartData
                                    ? this.props.chartData
                                          .totalToolboxesIncorrect
                                    : 0
                            }
                            totalToolboxesCorrect={
                                this.props.chartData
                                    ? this.props.chartData.totalToolboxesCorrect
                                    : 0
                            }
                        />
                    </div>
                </AppModule>
                <div
                    style={{
                        position: 'fixed',
                        top: '32px',
                        right: '36px',
                        zIndex: 1500,
                    }}
                >
                    <div>
                        <Fab   onClick={this.toggleExport}>
                            {this.state && this.state.exportOpen ? (
                                <CloseIcon />
                            ) : (
                                <DownloadIcon />
                            )}
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '20px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '87px', right: '60px' }}
                        >
                            Download PDF bestand
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={() =>
                                this.getTMPDF(
                                    this.props.toolboxAssignsReal.byId[
                                        toolboxAssigned.id
                                    ]
                                )
                            }
                        >
                            <InsertIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '145px', right: '60px' }}
                        >
                            Download CSV bestand
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={() =>
                                this.getTMCSV(
                                    this.props.toolboxAssignsReal.byId[
                                        toolboxAssigned.id
                                    ]
                                )
                            }
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(StatisticsToolboxMeetingView);
