// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowEmployees from '../../StatisticsView/StatisticsRow/StatisticsRowEmployees';
import { connect } from 'react-redux';
import type { TStateStatisticsListData } from '../../../reducers/StatisticsReducer';

import {
    fetchEmployees,
    fetchEmployeesAll,
    getCurrentEmployee,
    fetchInspectionAssignsPerYear,
} from '../../../actions';
import {
    getFilteredToolboxAssigns,
    getFilteredInspectionAssigns,
} from '../../../selectors';
import { fetchToolboxAssigns } from '../../../actions/toolboxActions';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
    employees: Array<any>,
    usergroups: Array<any>,
    group: any,
    toolboxAssigns: any,
    inspectionAssigns: any,
};

const mapStateToProps = (state) => {
    return {
        employees: state.entities.employeesAll.allIds.map(
            (id) => state.entities.employeesAll.byId[id]
        ),
        usergroups: state.entities.usergroups.allIds.map(
            (id) => state.entities.usergroups.byId[id]
        ),
        toolboxAssigns: getFilteredToolboxAssigns(state),
        inspectionAssigns: getFilteredInspectionAssigns(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        fetchEmployeesAll: () => {
            dispatch(fetchEmployeesAll('employeesAll'));
        },
        fetchExternEmployeesAll: () => {
            dispatch(fetchEmployeesAll('extern'));
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
        fetchExternEmployees: () => {
            dispatch(fetchEmployees('extern'));
        },
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
        fetchInspectionAssignsPerYear: (year) => {
            dispatch(fetchInspectionAssignsPerYear(year));
        },
    };
};

class StatisticsListEmployees extends React.Component<Props, void> {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentWillMount() {
        if (this.props.vv_functions) {
            this.props.fetchEmployeesAll();
            this.props.fetchEmployees();
        } else {
            this.props.fetchExternEmployeesAll();
            this.props.fetchExternEmployees();
        }

        this.props.fetchCurrentUser();
        this.props.fetchToolboxAssigns();
        if (
            this.props.inspectionAssigns === undefined ||
            this.props.inspectionAssigns === null ||
            this.props.inspectionAssigns.length < 1
        ) {
            this.props.fetchInspectionAssignsPerYear(this.props.periodYear);
        }
    }
    componentDidUpdate(prevProps,prevState) {
        if (prevProps.periodYear !== this.props.periodYear) {
            this.props.fetchInspectionAssignsPerYear(this.props.periodYear);

        }
    }

    shouldComponentUpdate(newProps) {
        if (
            !_.isEqual(this.props.employees, newProps.employees) ||
            !_.isEqual(this.props.searchtext, newProps.searchtext)
        || !_.isEqual(this.props.group, newProps.group)) {
            return true;
        }

        if (_.isEqual(this.props.toolboxAssigns, newProps.toolboxAssigns)) {
            return false;
        }

        return true;
    }

    rowProvider = (employees) => {
        if (!employees || !employees.fullname || !employees.id) {
            return undefined;
        }

        let toolboxAmount = 0;
        let inspectionAmount = 0;
        let toolboxMeetingAmount = 0;
        let toolboxCompleted = 0;
        let inspectionCompleted = 0;
        let groups = '';

        this.props.toolboxAssigns.forEach((row) => {
            if (row.user.id === employees.id) {
                toolboxAmount++;
                if (row.result !== null) {
                    toolboxCompleted++;
                }
                if (row.toolboxMeeting === true) {
                    toolboxMeetingAmount++;
                }
            }
        });

        this.props.inspectionAssigns.forEach((row) => {
            if (row.user.id === employees.id) {
                inspectionAmount++;
                if (row.result !== null) {
                    inspectionCompleted++;
                }
            }
        });

        this.props.usergroups.forEach((row) => {
            row.users.forEach((user) => {
                if (user.id === employees.id) {
                    if (groups.length === 0) {
                        groups += row.name;
                    } else {
                        groups += ', ';
                        groups += row.name;
                    }
                }
            });
        });

        let totalCompleted = inspectionCompleted + toolboxCompleted;

        if (groups.length === 0) {
            groups = '-';
        }

        return (
            <StatisticsRowEmployees
                key={employees.id}
                handleRowClick={() => this.onRowClick(employees)}
                title={employees.fullname}
                groups={groups}
                toolboxAmount={toolboxAmount}
                toolboxMeetingAmount={toolboxMeetingAmount}
                inspectionAmount={inspectionAmount}
                inspectionCompleted={inspectionCompleted}
                toolboxCompleted={toolboxCompleted}
                totalCompleted={totalCompleted}
            />
        );
    };

    headerProvider = () => {
        const { t } = this.props;
        return {
            title: t('Name'),
            groups: t('In group(s)'),
            toolboxAmount: '#' + t('Toolboxes'),
            toolboxMeetingAmount: t('Of which Toolbox meetings'),
            inspectionAmount: '#' + t('Workplace inspections'),
            inspectionCompleted: t('WPI Completed'),
            toolboxCompleted: t('Toolbox completed'),
            totalCompleted: t('Total completed'),
        };
    };

    onRowClick = (employees) => {
        this.props.onItemClick(employees);
    };

    render() {
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();

        sorters = sorters.set(
            'toolboxAmount',
            (toolboxAssignA, toolboxAssignB) => {
                let employeeA = 0;
                let employeeB = 0;
                this.props.toolboxAssigns.forEach((row) => {
                    if (row.user.id === toolboxAssignA.id) {
                        employeeA++;
                        /*if (row.result !== null){
                        toolboxCompleted++;
                    }*/
                    }
                    if (row.user.id === toolboxAssignB.id) {
                        employeeB++;
                        /*if (row.result !== null){
                        toolboxCompleted++;
                    }*/
                    }
                });
                if (employeeA > employeeB) {
                    return 1;
                }
                if (employeeA < employeeB) {
                    return -1;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'toolboxMeetingAmount',
            (toolboxAssignA, toolboxAssignB) => {
                let employeeA = 0;
                let employeeB = 0;
                this.props.toolboxAssigns.forEach((row) => {
                    if (row.toolboxMeeting === true) {
                        if (row.user.id === toolboxAssignA.id) {
                            employeeA++;
                        }
                        if (row.user.id === toolboxAssignB.id) {
                            employeeB++;
                        }
                    }
                });
                if (employeeA > employeeB) {
                    return 1;
                }
                if (employeeA < employeeB) {
                    return -1;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'inspectionAmount',
            (inspectionAssignA, inspectionAssignB) => {
                let employeeA = 0;
                let employeeB = 0;
                this.props.inspectionAssigns.forEach((row) => {
                    if (row.user.id === inspectionAssignA.id) {
                        employeeA++;
                    }
                    if (row.user.id === inspectionAssignB.id) {
                        employeeB++;
                    }
                });
                if (employeeA > employeeB) {
                    return 1;
                }
                if (employeeA < employeeB) {
                    return -1;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'totalCompleted',
            (totalCompletedA, totalCompletedB) => {
                let employeeA = 0;
                let employeeB = 0;
                let employeeATotal = 0;
                let employeeBTotal = 0;
                this.props.inspectionAssigns.forEach((row) => {
                    if (row.user.id === totalCompletedA.id) {
                        employeeATotal++;
                        if (row.result !== null) {
                            employeeA++;
                        }
                    }
                    if (row.user.id === totalCompletedB.id) {
                        employeeBTotal++;
                        if (row.result !== null) {
                            employeeB++;
                        }
                    }
                });
                this.props.toolboxAssigns.forEach((row) => {
                    if (row.user.id === totalCompletedA.id) {
                        employeeATotal++;
                        if (row.result !== null) {
                            employeeA++;
                        }
                    }
                    if (row.user.id === totalCompletedB.id) {
                        employeeBTotal++;
                        if (row.result !== null) {
                            employeeB++;
                        }
                    }
                });
                if (employeeA > employeeB) {
                    return 1;
                }
                if (employeeA < employeeB) {
                    return -1;
                }
                if (employeeA === employeeB) {
                    if (employeeATotal > employeeBTotal) {
                        return 1;
                    }
                    if (employeeATotal < employeeBTotal) {
                        return -1;
                    }
                }
                return 0;
            }
        );
        sorters = sorters.set('groups', (groupMemberA, groupMemberB) => {
            let employeeA = 0;
            let employeeB = 0;
            this.props.usergroups.forEach((row) => {
                row.users.forEach((user) => {
                    if (user.id === groupMemberA.id) {
                        employeeA++;
                    }
                });
            });
            this.props.usergroups.forEach((row) => {
                row.users.forEach((user) => {
                    if (user.id === groupMemberB.id) {
                        employeeB++;
                    }
                });
            });
            if (employeeA > employeeB) {
                return 1;
            }
            if (employeeA < employeeB) {
                return -1;
            }
            return 0;
        });

        let filteredEmployees = [];

        this.props.employees.forEach((employee) => {
            let toolboxAmount = 0;
            let inspectionAmount = 0;

            this.props.toolboxAssigns.forEach((row) => {
                if (row.user.id === employee.id) {
                    toolboxAmount++;
                }
            });

            this.props.inspectionAssigns.forEach((row) => {
                if (row.user.id === employee.id) {
                    inspectionAmount++;
                }
            });

            if (toolboxAmount > 0 || inspectionAmount > 0) {
                filteredEmployees.push(employee);
            }
        });

        let usergroupUsers = this.props.group !== 0 ? this.props.usergroups.filter(usergroup => usergroup.id === this.props.group)[0].users : []
        const data = List(
            filteredEmployees
                .filter((employee) =>
                    this.props.searchtext
                        ? employee.fullname
                              .replace('  ', ' ')
                              .toLowerCase()
                              .indexOf(this.props.searchtext.toLowerCase()) > -1
                            ? true
                            : this.props.usergroups.filter(
                                  (row) =>
                                      row.name
                                          .toLowerCase()
                                          .indexOf(
                                              this.props.searchtext.toLowerCase()
                                          ) > -1 &&
                                      row.users.filter(
                                          (user) => user.id === employee.id
                                      ).length > 0
                              ).length > 0 && true
                        : true
                )
                .filter((employee) =>
                    this.props.group !== 0
                        ? this.props.usergroups.filter(
                              (row) =>
                                  row.id === this.props.group &&
                                  row.users.filter(
                                      (user) => user.id === employee.id
                                  ).length > 0
                          ).length > 0
                        : true
                )
                .filter(user => this.props.group === 0 || usergroupUsers.filter(usergroup => usergroup.id === user.id).length > 0).map((item) =>
                    Object.assign(item, {
                        title: item.fullname,
                    })
                )
        );
        return (
            <PaginatedSortFilterView
                data={data}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="title"
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListEmployees);
