import { createSelector } from 'reselect'
import getDecodedToken from './getDecodedToken';

const getRoles = createSelector(
    [getDecodedToken],
    (decodedToken) => {
        return [].concat(decodedToken.roles || 'I_DONT_HAVE_A_ROLE');
    }
)

export default getRoles;
