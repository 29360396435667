// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import App from '../App';
import history from '../../lib/history';
import AuthPage from '../AuthPage';
import PrivateRoute from '../PrivateRoute';
import './Root.css';
import ToolboxExtern from '../Toolbox/ToolboxExtern';
import introduction from '../Webclient/introduction';
import login from '../Webclient/login';
import LoginHelp from '../Webclient/Help/LoginHelp';

type Props = {
    store: any,
    theme: any,
};

const Root = (props: Props) => {
    console.log('process.env', process.env);
    return (
        <React.Fragment>
            <Helmet>
                {process.env.REACT_APP_TITLE && (
                    <title>{process.env.REACT_APP_TITLE}</title>
                )}
            </Helmet>
            <Provider store={props.store}>
                <MuiThemeProvider theme={props.theme}>
                    <BrowserRouter>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route path="/auth" component={AuthPage} />
                                <Route
                                    path="/web/login/help"
                                    component={LoginHelp}
                                />
                                <Route path="/web/login" component={login} />
                                <Route
                                    path="/web/intro"
                                    component={introduction}
                                />
                                <Route
                                    exact
                                    path="/web"
                                    component={introduction}
                                />
                                <Route
                                    path="/extern/toolbox/:toolboxId?"
                                    component={ToolboxExtern}
                                />
                                <PrivateRoute path="/" component={App} />
                            </Switch>
                        </ConnectedRouter>
                    </BrowserRouter>
                </MuiThemeProvider>
            </Provider>
        </React.Fragment>
    );
};

export default Root;
