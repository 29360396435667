// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Card,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Tabs, Tab,
} from '@mui/material';
import { initInspectionDraft, openDialog, setCrumbPath } from '../../actions';
import {
    inProgress,
    getPermissions,
    getVisibleInspectionVersions,
} from '../../selectors';
import getRoles from '../../selectors/getRoles';

import CheckListBlockPreview from './CheckListBlockPreview';

import InspectionTrendView from '../Inspection/InspectionTrendView';

import './InspectionPreview.css';
import TableActionHeader from '../TableActionHeader';
import { fetchPDF } from '../../lib/api';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './InspectionOverviewPreview.module.scss';
import colors from "../../lib/themes/theme.scss";

type Props = {
    loading: boolean,
    toolboxes: Array<any>,
    sectors: Array<any>,
    categories: Array<any>,
    roles: Array<any>,
};

type State = {
    inspectionErrorDialogOpen: boolean,
    inspectionErrorDialogText: string,
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        editInspection: (id) => {
            dispatch(push('/werkplekinspecties/' + id));
        },
        deployInspection: (id) => {
            dispatch(openDialog('inspectionDeploy', { id }));
        },
        setCrumbPath: (inspectionName: string) => {
            const hash = '?' + ownProps.location.hash.split('?')[1];
            let page = new URLSearchParams(hash).get("page");
            let rowsPerPage = new URLSearchParams(hash).get("rowsPerPage");
            console.log("INFO calling setCrumbPath in InspectionOverviewPreview: hash = " + hash + " page="+page + "rowsPerPage="+rowsPerPage);

            let activeTab = '#overzicht';
            if (ownProps.location.hash.includes("#ingepland")) { activeTab = '#ingepland' };
            if (ownProps.location.hash.includes("#uitgestuurd")) { activeTab = '#uitgestuurd' };

            dispatch(
                setCrumbPath({
                    title: 'Werkplekinspecties',
                    crumbs: [
                        { name: inspectionName,
                            link: '/werkplekinspecties' + activeTab + '?page=' + page + '&rowsPerPage=' + rowsPerPage,
                        },
                    ],
                })
            );
        },
        initDraft: (inspection) => {
            dispatch(initInspectionDraft(inspection));
        },
    };
};

const mapStateToProps = (store, props) => {
    let inspections = getVisibleInspectionVersions(store);
    let inspection = store.entities.inspections;
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;
    let currentInspection = props.match.params.inspectionId;
    const allEmployees = store.entities.employees;


    return {
        loading: !inProgress(store),
        inspection: inspection.byId[currentInspection],
        inspections: inspections,
        sectorEntities: store.entities.sectors,
        categoryEntities: store.entities.categories,
        contentBlockDrafts: store.drafts.contentBlocks,
        questionBlockDrafts: store.drafts.questionBlocks,
        permissions: getPermissions(store),
        roles: getRoles(store),
        subscription: subscription,
        allEmployees: allEmployees,
    };
};

let initialized = false;

class InspectionOverviewPreview extends React.Component<Props, State> {
    state: State = {
        tab: 0,
        inspectionErrorDialogOpen: false,
        inspectionErrorDialogText: '',
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentWillMount() {}

    componentDidMount() {
        if (this.props.inspection) {
            this.props.initDraft(this.props.inspection);
            this.props.setCrumbPath(this.props.inspection.title);
            initialized = true;
        }

        const tabs = ['#preview', '#analyses'];

        if (this.props.location.hash) {
            let index = tabs.indexOf(this.props.location.hash.split("?")[0]);
            if (index === -1) index = 0;

            this.setState({
                tab: index,
            });
        }
    }

    editInspection = () => {
        // window.$crisp.push(['do', 'chat:hide']);
        this.props.editInspection(this.props.inspection.id);
    };

    sendInspection = () => {
        this.checkValidInspectionAndPerformAction(this.props.inspection, () =>
            this.props.deployInspection(this.props.inspection.id)
        );
    };

    showInspectioncategoryCountError = () => {
        const { t } = this.props;
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText:
                t('Workplace inspection must have at least one checklist'),
        });
    };

    showInspectionCategoriesItemsError = (emptyCategories) => {
        const { t } = this.props;
        let text =
            t('All workplace inspection checklists must have at least one item to be inspected. At checklist number') +
            emptyCategories
                .map((c, i) => {
                    let count = emptyCategories.length;
                    if (i === count - 1) {
                        return c;
                    } else if (i === count - 2) {
                        return c + t('and');
                    } else {
                        return c + ', ';
                    }
                })
                .join('') +
            t('not enough inspection parts have been added');
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText: text,
        });
    };
    checkValidInspectionAndPerformAction = (
        inspection,
        action: () => mixed
    ) => {
        // Check eerst of er wel categorien zijn
        if (!inspection.inspection.categories.length) {
            this.showInspectioncategoryCountError();
        } else {
            // Check of alle vragen ook mogelijke antwoorden hebben
            let categoriesWithoutAnswers = inspection.inspection.categories
                .map((c, i) => {
                    return { index: i, items: c.items };
                })
                .filter((c) => c.items.length < 1)
                .map((c) => c.index + 1);

            if (categoriesWithoutAnswers.length) {
                this.showInspectionCategoriesItemsError(
                    categoriesWithoutAnswers
                );
            } else {
                action();
            }
        }
    };

    handlePDFClickNoResults = () => {
        const { inspection } = this.props;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTIONPREVIEW',
            options: {},
            data: inspection.inspection.categories,
            info: {
                inspection,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    this.props.inspection.title +
                        ' v' +
                        this.props.inspection.version +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    render() {
        const { style, contentBlockDrafts, t, allEmployees, inspection} = this.props;
        const {
            inspectionErrorDialogOpen,
            inspectionErrorDialogText,
        } = this.state;

        let tableActionHeader = <TableActionHeader />;
        const showActionButtons = true;

        if (this.props.inspection && initialized === false) {
            this.props.initDraft(this.props.inspection);
            this.props.setCrumbPath(this.props.inspection.title);
            initialized = true;
        }

        if (!inspection) {
            return <div />;
        }

        inspection.inspection.blocks = inspection.inspection.categories;

        const { tab } = this.state;

        return (

            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor={colors.mainTabsColor}
                    sx={{color: colors.mainTabsColor, '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}

                >
                    <Tab label={t('Inspection')} href="#preview" />
                    <Tab label={t('Trend')} href="#analyse" />
                </Tabs>

                {tab === 1 && (
                    <div
                        style={style}
                        className={classes.trend}
                    >

                    <InspectionTrendView
                        inspectionId={inspection.id}
                        allEmployees={allEmployees}
                    />
                    </div>
                )}

                {tab === 0 && (
                    <div>
                    <Dialog
                        open={inspectionErrorDialogOpen}
                        onClose={() => {
                            this.setState({ inspectionErrorDialogOpen: false });
                        }}
                    >
                        <DialogTitle>
                            {t('Workplace inspection cannot be send out yet')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {inspectionErrorDialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        inspectionErrorDialogOpen: false,
                                    });
                                }}
                                 
                            >
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                <div
                    style={style}
                    className={classes.cols}
                    prepend={tableActionHeader}
                >
                    <div className="previewView" style={{ textAlign: '' }}>
                        <div className={'wpiPreview'}>
                            <Card className={'ietsAnders'}>
                                <div
                                    style={{ margin: '16px', padding: '16px' }}
                                >
                                    <div
                                        style={{
                                            margin: 'auto',
                                            width: '300px',
                                        }}
                                    >
                                        {inspection.title && (
                                            <h2
                                                style={{
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                {inspection.title}
                                            </h2>
                                        )}
                                        {inspection.inspection.blocks.map(
                                            (block, index) => {
                                                const blockId = block.id
                                                    ? block.id
                                                    : block;
                                                let draftBlock =
                                                    contentBlockDrafts[blockId];
                                                if (!draftBlock) {
                                                    return (
                                                        <div
                                                            key={
                                                                blockId || index
                                                            }
                                                        />
                                                    );
                                                }
                                                return (
                                                    <div key={blockId}>
                                                        <h3
                                                            className={
                                                                'actiePuntTitle'
                                                            }
                                                            style={{
                                                                wordBreak:
                                                                    'break-all',
                                                            }}
                                                        >
                                                            {draftBlock.title}
                                                        </h3>
                                                        <CheckListBlockPreview
                                                            block={draftBlock}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="info">
                            <div className="fixedContainer">
                                <div className="check">
                                    <h3
                                        className="myNetjes"
                                        style={{ wordBreak: 'break-all' }}
                                    >
                                        {inspection.title}
                                    </h3>

                                    <div className="myNetjes">
                                        {t('View an example of the workplace inspection on the left.')}
                                    </div>

                                    {showActionButtons && (
                                        <div>
                                            <h3 className="myNetjes">
                                                {t('CHECKLIST SENDING A WORKPLACE INSPECTION')}
                                            </h3>
                                            <div className="myNetjes">
                                                {t('1. Choose the correct workplace inspection and check the contents')}
                                            </div>
                                            <div className="myNetjes">
                                                {t('2. Fill in the relevant details for the workplace inspection')}
                                            </div>
                                            <div className="myNetjes">
                                                {t('3. Select your correct workplace inspection recipient')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {inspection.source === 'cumela' ? (
                                    (this.props.roles.includes('CUMELA') ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                true) ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                false &&
                                            this.props.subscription.caoLeo ===
                                                true)) && (
                                        <div>
                                            {showActionButtons && (
                                                <Button
                                                    className={classes.button}
                                                    onClick={
                                                        this.editInspection
                                                    }
                                                    
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('Change workplace inspection')}
                                                </Button>
                                            )}
                                            {showActionButtons &&
                                                inspection.published ===
                                                    'PUBLISHED' && (
                                                    <Button
                                                        className="buttonNetjes"
                                                        onClick={
                                                            this.sendInspection
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('Sent out workplace inspection')}
                                                    </Button>
                                                )}
                                            {showActionButtons && (
                                                <Button
                                                    className="buttonNetjes"
                                                    onClick={
                                                        this
                                                            .handlePDFClickNoResults
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('PDF')}
                                                </Button>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <div>
                                        {showActionButtons && (
                                            <Button
                                                className="buttonNetjes"
                                                onClick={this.editInspection}
                                                variant="contained"
                                                
                                                color="primary"
                                            >
                                                {t('Change workplace inspection')}
                                            </Button>
                                        )}
                                        {showActionButtons &&
                                            inspection.published ===
                                                'PUBLISHED' && (
                                                <Button
                                                    className="buttonNetjes"
                                                    onClick={
                                                        this.sendInspection
                                                    }
                                                    variant="contained"
                                                    
                                                    color="primary"
                                                >
                                                    {t('Sent out workplace inspection')}
                                                </Button>
                                            )}
                                        {showActionButtons && (
                                            <Button
                                                className="buttonNetjes"
                                                onClick={
                                                    this.handlePDFClickNoResults
                                                }
                                                variant="contained"
                                                
                                                color="primary"
                                            >
                                                {t('PDF')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                    </div>



                    )}


            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(InspectionOverviewPreview);
