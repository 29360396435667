// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TEmployee } from '../../../reducers/employeesReducer';
import Button from '@mui/material/Button';
import { getCurrentEmployee, upgradeCredits } from '../../../actions';
import CreditsDialog from '../../Abonnement/CreditsDialog/CreditsDialog';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    noOfExtern: number,
    currentEmpl: TEmployee,
};
type State = {
    showCreditsDialog: boolean,
};

const mapDispatchToProps = (dispatch) => {
    return {
        upgradeCredits: bindActionCreators(upgradeCredits, dispatch),
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
    };
};

class CreditsBlock extends React.Component<Props, State> {
    props: Props;
    state: State = {
        showCreditsDialog: false,
    };

    componentWillMount() {
        this.props.fetchCurrentUser();
    }

    componentDidMount() {}

    closeCreditsDialog = () => {
        this.setState({
            showCreditsDialog: false,
        });
    };

    creditsBuy = () => {
        this.props.upgradeCredits((err) => {
            if (!err) {
                this.props.fetchCurrentUser();
                this.setState({
                    showCreditsDialog: false,
                });
            }
        });
    };

    render() {
        const { noOfExtern, currentEmpl, t } = this.props;
        const { showCreditsDialog } = this.state;

        const credits = currentEmpl.subscription.credits
            ? currentEmpl.subscription.credits
            : 0;

        return (
            <div className="App">
                <CreditsDialog
                    open={showCreditsDialog}
                    handleClose={this.closeCreditsDialog}
                    onCreditsBuy={this.creditsBuy}
                    creditsSetup={currentEmpl.subscription.credits_setup}
                />
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                {!noOfExtern ? '0' : noOfExtern}{' '}
                                {noOfExtern === 1 ? 'externe' : 'externen'}{' '}
                                {t('selected')}
                            </td>
                            <td style={{ textAlign: 'right', color: '#AAA' }}>
                                {t('Coins')}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: '#AAA' }}>
                                {noOfExtern}{' '}
                                {noOfExtern === 1 ? t('credit') : t('credits')}{' '}
                                {t('necessary')}
                            </td>
                            <td style={{ textAlign: 'right', color: '#AAA' }}>
                                {credits}{' '}
                                {credits === 1 ? t('credit') : t('credits')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {noOfExtern > credits && (
                    <div>
                        <p style={{ fontWeight: 'bold' }}>
                            {t('Not enough credits')}
                        </p>
                        <Button
                            
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({ showCreditsDialog: true });
                            }}
                        >
                            {t('Upgrade credits')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default compose(
    connect(() => {}, mapDispatchToProps),
    withTranslation()
)(CreditsBlock);
