// @flow
import React from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import './FilterHeader.scss';
import ButtonBase from '@mui/material/ButtonBase';

type Props = {
    filterTitle: string,
    filterValue: string
};

const FilterHeader = (props: Props) => {
    return (
        <ButtonBase className="filterheader">
            <span className="filtertitle grey-text capitalizeFirstLetter">{props.filterTitle}:</span>
            <span className="filtertext blue-text">{props.filterValue}</span>
            <ArrowDropDown />
        </ButtonBase>
    );
};

export default FilterHeader;
