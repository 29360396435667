import { setEntity } from "./entityActions";
export const PROCESS_FORM_REQUEST = "PROCESS_FORM_REQUEST";
export const PROCESS_FORM_SUCCESS = "PROCESS_FORM_SUCCESS";
export const PROCESS_FORM_FAILURE = "PROCESS_FORM_FAILURE";
export const RESET_FORM = "RESET_FORM";

export function processEntityForm(formName, formAction) {
	return (dispatch) => {
		dispatch(processFormRequest(formName));
		return formAction(dispatch)
			.then((result) => {
				dispatch(setEntity("document", result));
				return result;
			})
			.catch((error) => {
				dispatch(processFormFailure(formName, error));
				throw error;
			});
	};
}

export function processForm(formName, formAction) {
	return (dispatch) => {
		dispatch(processFormRequest(formName));
		return formAction(dispatch)
			.then((result) => {
				dispatch(processFormSuccess(formName, result));
				return result;
			})
			.catch((error) => {
				dispatch(processFormFailure(formName, error));
				throw error;
			});
	};
}

export function processFormWebclient(formName, formAction) {
	return (dispatch) => {
		dispatch(processFormRequest(formName));
		return formAction(dispatch)
			.then((result) => {
				dispatch(processFormSuccess(formName, result));
				return result;
			})
			.catch((error) => {
				dispatch(processFormFailure(formName, error));
				throw error;
			});
	};
}

export function processFormRequest(name) {
	return {
		type: PROCESS_FORM_REQUEST,
		payload: {
			name,
		},
	};
}

export function processFormSuccess(name, result) {
	return {
		type: PROCESS_FORM_SUCCESS,
		payload: {
			name,
			result,
		},
	};
}

export function processFormFailure(name, error) {
	return {
		type: PROCESS_FORM_FAILURE,
		payload: {
			name,
			error,
		},
	};
}

export function resetForm(name) {
	return {
		type: RESET_FORM,
		payload: {
			name,
		},
	};
}
