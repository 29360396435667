//@flow
import * as api from '../lib/api';
import { List } from 'immutable';
import type { PlainDispatch } from 'redux';
import type {
    TSubscription,
    TSubscriptionStub,
    TChangeSubscriptionHolder,
    THistory,
    TPrice,
    TSubscriptionFunctionality,
    TSubscriptionFunctionalityWhitelabel,
} from '../reducers/subscriptionReducer';
import { showSnackbar } from './uiActions';
import * as storage from '../lib/storage';
import i18n from 'i18next';

type TSAFetchSubscription = {
    type: 'FETCH_SUBSCRIPTION',
    subscription: TSubscription,
};

type TSAFetchSubscriptions = {
    type: 'FETCH_SUBSCRIPTIONS',
    subscriptionStubs: List<TSubscriptionStub>,
};

type TEAUpsertStart = {
    type: 'ABONNEE_UPSERT_START',
};

type TEAEditAbonnee = {
    type: 'ABONNEE_EDIT',
    abonnee?: TSubscription,
};

// type TEAClearDiff = {
//     type: 'ABONNEE_CLEAR_DIFF'
// };

//---------------------------------
type TSAFetchAbonnees = {
    type: 'FETCH_ABONNEES',
    abonnees: List<TSubscription>,
};

//---------------------------------
type TSAFetchAbonneesCUMELA = {
    type: 'FETCH_ABONNEES_CUMELA',
    abonnees: List<TSubscription>,
};

type TSAFetchHistory = {
    type: 'FETCH_HISTORY',
    history: List<THistory>,
};

type TSASubscriptionCategory = {
    type: 'FETCH_SUBSCRIPTION_CATEGORIES',
    categories: List<TPrice>,
};
//--------------------------------------

type TSAChangeSubscriptionHolder = {
    type: 'CHANGE_SUBSCRIPTION_HOLDER',
    object: TChangeSubscriptionHolder,
};

type TSASubscriptionFunctionality = {
    type: 'FETCH_SUBSCRIPTION_FUNCTIONALITY',
    object: TSubscriptionFunctionality,
};

type TSAChangeSubscriptionFunctionality = {
    type: 'CHANGE_SUBSCRIPTION_FUNCTIONALITY',
    object: TSubscriptionFunctionality,
};

type TSASubscriptionFunctionalityWhitelabel = {
    type: 'FETCH_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL',
    object: TSubscriptionFunctionalityWhitelabel,
};

type TSAChangeSubscriptionFunctionalityWhitelabel = {
    type: 'CHANGE_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL',
    object: TSubscriptionFunctionalityWhitelabel,
};


export type TSubscriptionAction =
    | TSAFetchSubscription
    | TSAFetchSubscriptions
    | TSAFetchAbonnees
    | TEAUpsertStart
    | TEAEditAbonnee
    | TSAChangeSubscriptionHolder
    | TSAFetchAbonneesCUMELA
    | TSAFetchHistory
    | TSASubscriptionCategory
    | TSASubscriptionFunctionality
    | TSAChangeSubscriptionFunctionality
    | TSASubscriptionFunctionalityWhitelabel
    | TSAChangeSubscriptionFunctionalityWhitelabel;

export function upgradeCredits(callback) {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.upgradeCredits().then((res) => {
            callback();

            dispatch({
                type: 'FETCH_SUBSCRIPTION',
                subscription: res.body,
            });
        });
    };
}

export function upgradeSubscription(callback) {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.upgradeSubscription().then((res) => {
            callback();

            dispatch({
                type: 'FETCH_SUBSCRIPTION',
                subscription: res.body,
            });
        });
    };
}

export function impersonate(id) {
    api.impersonateUser(id)
        .then((response) => {
            storage.storeSecondToken(storage.fetchLogin().token);
            storage.storeLogin({ token: response.body.access_token });
            window.location.replace('/');
        })
        .catch((error) => {
            throw error;
        });
}

export function fetchSubscriptions() {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getSubscriptions().then((res) => {
            dispatch({
                type: 'FETCH_SUBSCRIPTIONS',
                subscriptionStubs: res.body,
            });
        });
    };
}

export function fetchAbonnees() {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getAbonnees().then((res) => {
            dispatch({
                type: 'FETCH_ABONNEES',
                abonnees: res.body._embedded.abonnees,
            });
        });
    };
}

export function fetchAbonneesCUMELA() {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getAbonneesCUMELA().then((res) => {
            dispatch({
                type: 'FETCH_ABONNEES_CUMELA',
                abonneesCUMELA: res.body._embedded.abonneesCUMELA,
            });
        });
    };
}

export function fetchHistory(id: Number) {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getHistory(id).then((res) => {
            dispatch({
                type: 'FETCH_HISTORY',
                history: res.body._embedded.history,
            });
        });
    };
}

export function fetchSubscriptionCategories(id: Number) {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getSubscriptionCategories(id).then((res) => {
            dispatch({
                type: 'FETCH_SUBSCRIPTION_CATEGORIES',
                categories: res.body._embedded.category,
            });
        });
    };
}

export function changeSubscriptionHolder(
    newObj: TChangeSubscriptionHolder
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const newObject = Object.assign({}, newObj);

        api.upsert('changeabonnee', newObject)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Subscriber')} "` +
                            entity.companyName +
                            `" ${i18n.t('Has been updated')}`
                    )
                );
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error disabling subscriber')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function editPaymentInfo(newObj: TChangeSubscriptionHolder): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const newObject = Object.assign({}, newObj);

        api.upsert('changepayment', newObject)
            .then((entity) => {
                console.log(entity);
                if (entity[0]['message'].includes('Incorrect IBAN nummer')) {
                    dispatch(showSnackbar(entity[0]['message']));
                } else {
                    // window.$crisp.push(['do', 'chat:show']);
                    window.location.assign('/');
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(i18n.t('Error changing payment options'))
                );
                throw error;
            });
    };
}

export function changeSubscriptionComment(
    newObj: TChangeSubscriptionHolder
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const newObject = Object.assign({}, newObj);

        api.upsert('changeabonneecomment', newObject)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Comment from')} "` +
                            entity.companyName +
                            `" ${i18n.t('Has been changed')}`
                    )
                );
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error disabling subscriber')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function disableAbonnee(abonnee: TSubscription): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const abonneeToSave = Object.assign({}, abonnee, { state: 'DISABLED' });

        api.upsert('abonneestate', abonneeToSave)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Subscriber')} "` +
                            entity.companyName +
                            `" ${i18n.t('Is turned off')}`
                    )
                );
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error disabling subscriber')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function upgradeAbonnee(abonnee: TSubscription): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const abonneeToSave = Object.assign({}, abonnee);

        api.upsert('abonneeupgrade', abonneeToSave)
            .then((entity) => {
                //
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function enableAbonnee(abonnee: TSubscription): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const abonneeToSave = Object.assign({}, abonnee, {
            state: 'CONFIRMED',
        });

        api.upsert('abonneestate', abonneeToSave)
            .then((entity) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Subscriber')} "` +
                            entity.companyName +
                            '" ' +
                            i18n.t('is activated')
                    )
                );
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error activating subscriber')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function makeTrialAbonnee(abonnee: TSubscription): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const abonneeToSave = Object.assign({}, abonnee, { trial: true });

        api.upsert('abonneetrial', abonneeToSave)
            .then((entity) => {
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function makeNotTrialAbonnee(abonnee: TSubscription): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        const abonneeToSave = Object.assign({}, abonnee, { trial: false });

        api.upsert('abonneetrial', abonneeToSave)
            .then((entity) => {
                fetchAbonnees()(dispatch);
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function editAbonnee(abonnee?: TSubscription) {
    return {
        type: 'ABONNEE_EDIT',
        abonnee: abonnee,
    };
}

export function fetchSubscriptionFunctionality(
    subscriptionId: number
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getSubscriptionFunctionality(subscriptionId).then((res) => {
            dispatch({
                type: 'FETCH_SUBSCRIPTION_FUNCTIONALITY',
                functionality: res.body,
            });
        });
    };
}

export function changeSubscriptionFunctionality(
    subscriptionId: number,
    subscriptionFunctionality: TSubscriptionFunctionality
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api
            .changeSubscriptionFunctionality(
                subscriptionId,
                subscriptionFunctionality
            )
            .then((res) => {
                dispatch(showSnackbar(i18n.t('Changes saved')));
                fetchSubscriptionFunctionality(subscriptionId)(dispatch);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        i18n.t('Failed to turn functionality on / off')
                    )
                );
                throw error;
            });
    };
}

export function fetchSubscriptionFunctionalityWhitelabel(
    subscriptionId: number
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api
            .getSubscriptionFunctionalityWhitelabel(subscriptionId)
            .then((res) => {
                dispatch({
                    type: 'FETCH_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL',
                    functionalityWhitelabel: res.body,
                });
            });
    };
}

export function changeSubscriptionFunctionalityWhitelabel(
    subscriptionId: number,
    subscriptionFunctionalityWhitelabel: TSubscriptionFunctionalityWhitelabel,
    onSuccess: () => mixed
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api
            .changeSubscriptionFunctionalityWhitelabel(
                subscriptionId,
                subscriptionFunctionalityWhitelabel
            )
            .then((res) => {
                dispatch(showSnackbar(i18n.t('Changes saved')));
                fetchSubscriptionFunctionalityWhitelabel(subscriptionId)(
                    dispatch
                );
                if (onSuccess) onSuccess();
            })
            .catch((error) => {
                dispatch(showSnackbar(i18n.t('Failed to change whitelabel')));
                throw error;
            });
    };
}

export function fetchSubscriptionFunctionalityWhitelabelAppStoreCodes(
    subscriptionId: number
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api.getWhitelabelAppStoreCodes(subscriptionId).then((res) => {
            dispatch({
                type:
                    'FETCH_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL_APP_STORE_CODES',
                functionalityWhitelabelAppStoreCodes: res.body,
            });
        });
    };
}

export function parseSubscriptionFunctionalityWhitelabelAppStoreCodes(
    subscriptionId: number,
    file: any,
    onSuccess: () => mixed
): Function {
    return (dispatch: PlainDispatch<TSubscriptionAction>) => {
        return api
            .parseWhitelabelAppStoreCodes(subscriptionId, file)
            .then((res) => {
                dispatch(
                    showSnackbar(
                        i18n.t('App store codes successfully implemented')
                    )
                );
                fetchSubscriptionFunctionalityWhitelabelAppStoreCodes(
                    subscriptionId
                )(dispatch);
                if (onSuccess) onSuccess();
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(i18n.t('Failed to save app store codes'))
                );
                throw error;
            });
    };
}
