// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { IconButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleImageInput from '../SimpleImageInput';

type Props = {
    classes: any,
    item: any,
    onChange: Function,
    onDelete: Function,
    connectDragPreview: Function,
    connectDragSource: Function
};

type State = {};


class PhotosBlockItem extends React.Component {
    props: Props;
    state: State = {};

    getInputId() {
        return this.props.item.id + '-photosBlockItem';
    }

    render() {
        const { item, onDelete, onChange, connectDragPreview, connectDragSource } = this.props;

        return (
            <div>
                {connectDragPreview(
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        {connectDragSource(
                            <div style={{
                                marginLeft: '8px',
                                marginRight: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: 196,
                            }}>
                                <DragHandleIcon />
                            </div>
                        )}
                        <SimpleImageInput id={this.getInputId()} onChange={onChange} url={item.url} />
                        <IconButton onClick={onDelete} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
            </div>
        );
    }
}

export default PhotosBlockItem;
