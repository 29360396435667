// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Tab, Tabs } from '@mui/material'; 
import AppModule from '../AppModule';
import {
    getAppInspections,
    getAppToolboxes,
    setCrumbPath,
} from '../../actions';
import { getPermissions, inProgress } from '../../selectors';
import { push } from 'connected-react-router';
import { openDialog } from '../../actions/uiActions';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import { TEmployee } from '../../reducers/employeesReducer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classes from './opdrachtenCompleted.module.scss';
import moment from 'moment';
import DeployIcon from '@mui/icons-material/Send';
import { isUndefinedOrNull } from '../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {getModules} from '../lib';

moment.locale(i18n.t('LOCALE'));

type Props = {
    setCrumbPath: () => void,
    permissions: any,
    classes: any,
    loading: boolean,
    getAppToolboxes: () => any,
    getAppInspections: () => any,
};

type State = {
    currentEmpl: TEmployee,
    tab: number,
};

const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        appToolboxes: store.entities.appToolboxes,
        appInspections: store.entities.appInspections,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: i18n.t('Completed assignments') }));
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        getAppToolboxes: () => {
            dispatch(getAppToolboxes());
        },
        getAppInspections: () => {
            dispatch(getAppInspections());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class OpdrachtenCompleted extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: this.props.location.hash === '#werkplekinspecties' ? 1 : 0,
        searchQuery:
            new URLSearchParams(window.location.search).get('searchQuery') ||
            '',
    };
    searchTimeout = null;

    componentWillMount() {
        this.props.setCrumbPath();
        this.props.getAppToolboxes();
        this.props.getAppInspections();
        moment.locale(i18n.t('LOCALE'));
    }

    gotoToolboxResult = (id) => {
        const { searchQuery } = this.state;
        const query =
            searchQuery.length > 0 ? `?searchQuery=${searchQuery}` : '';
        this.props.goTo(`resultaten/toolbox/${id}${query}`);
    };

    gotoInspectionResult = (id) => {
        const { searchQuery } = this.state;
        const query =
            searchQuery.length > 0 ? `?searchQuery=${searchQuery}` : '';
        this.props.goTo(`resultaten/inspection/${id}${query}`);
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    handleSearch = (query) => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.setState({
                searchQuery: query,
            });
        }, 400);
    };

    render() {
        const modules = getModules()

        const {
            loading,
            appToolboxes,
            appInspections,
            t,
        } = this.props;
        const { searchQuery, tab } = this.state;
        // window.$crisp.push(['do', 'chat:hide']);


        let toolboxes = [];
        if (appToolboxes && toolboxes.length === 0) {
            appToolboxes.allIds.map(
                (id) =>
                    appToolboxes.byId[id].toolbox.results !== null &&
                    toolboxes.push(appToolboxes.byId[id])
            );

            toolboxes.sort((a, b) => {
                a = a.toolbox.results.date;
                b = b.toolbox.results.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        let inspections = [];
        if (appInspections && inspections.length === 0) {
            appInspections.allIds.map(
                (id) =>
                    appInspections.byId[id].inspection.results !== null &&
                    inspections.push(appInspections.byId[id])
            );

            inspections.sort((a, b) => {
                a = a.inspection.results.date;
                b = b.inspection.results.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        let searchResults = tab === 0 ? toolboxes : inspections;

        if (searchQuery.length > 0 && tab === 0) {
            searchResults = searchResults.filter((toolbox) => {
                const queryInText = (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                let searchQueryFound = false; // Using return in a forEach only returns the forEach, not the parent method, therefore we need a manual check

                if (
                    queryInText(toolbox.title) ||
                    queryInText(toolbox.description) ||
                    queryInText(toolbox.author) ||
                    queryInText(toolbox.category)
                )
                    return true;

                toolbox.toolbox.blocks.forEach((block) => {
                    if (queryInText(block.title) || queryInText(block.subtitle))
                        searchQueryFound = true;

                    if (
                        !isUndefinedOrNull(block.textBlock) &&
                        queryInText(block.textBlock.text)
                    )
                        searchQueryFound = true;

                    if (block.photosBlock && block.photosBlock.photos) {
                        block.photosBlock.photos.forEach((photoBlock) => {
                            if (!isUndefinedOrNull(photoBlock) && queryInText(photoBlock.title)) {
                                searchQueryFound = true;
                            }
                        });
                    }

                    if (block.listBlock && block.listBlock.items) {
                        block.listBlock.items.forEach((item) => {
                            if (queryInText(item)) searchQueryFound = true;
                        });
                    }
                });

                toolbox.toolbox.questions.forEach((question) => {
                    if (queryInText(question.title)) searchQueryFound = true;

                    question.possibleAnswers.forEach((possibleAnswer) => {
                        if (possibleAnswer && queryInText(possibleAnswer.text))
                            searchQueryFound = true;
                    });
                });

                return searchQueryFound;
            });
        } else if (searchQuery.length > 0 && tab === 1) {
            searchResults = searchResults.filter((inspection) => {
                const queryInText = (value) =>
                    typeof value === 'string' &&
                    value.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                let searchQueryFound = false; // Using return in a forEach only returns the forEach, not the parent method, therefore we need a manual check

                if (
                    queryInText(inspection.title) ||
                    queryInText(inspection.description)
                )
                    return true;

                if (inspection.inspection) {
                    if (
                        queryInText(inspection.inspection.projectName) ||
                        queryInText(inspection.inspection.projectNumber) ||
                        queryInText(inspection.inspection.customer) ||
                        queryInText(inspection.inspection.activity)
                    )
                        return true;

                    if (inspection.inspection.categories) {

                        inspection.inspection.categories.forEach((category) => {
                            if (category.items) {
                                category.items.forEach((item) => {
                                    if (queryInText(item.title))
                                        searchQueryFound = true;
                                });
                            }
                            return null
                        });
                    }

                    if (inspection.inspection.results) {
                        const results = inspection.inspection.results;
                        if (
                            (results.remarks &&
                                queryInText(results.remarks.text)) ||
                            queryInText(results.weatherType) ||
                            queryInText(results.externalEmployees)
                        )
                            return true;

                        if (results.results) {
                            results.results.forEach((result) => {
                                if (
                                    result.remark &&
                                    queryInText(result.remark.text)
                                )
                                    searchQueryFound = true;
                            })
                        }
                    }

                    if (inspection.inspection.location) {
                        if (
                            queryInText(inspection.inspection.location.name) ||
                            queryInText(
                                inspection.inspection.location.street
                            ) ||
                            queryInText(
                                inspection.inspection.location.zipCode
                            ) ||
                            queryInText(inspection.inspection.location.city) ||
                            queryInText(inspection.inspection.location.country)
                        )
                            return true;
                    }
                }

                return searchQueryFound;
            });
        }

        const tableActionHeader = (
            <TableActionHeader
                searchAlwaysOpen={searchQuery.length > 0}
                onSearchChange={this.handleSearch}
                defaultSearchText={searchQuery}
                searchPlaceholder={
                    tab === 0
                        ? t('Search within toolboxes')
                        : t('Search workplace inspections')
                }
            />
        );

        // Janssen De Jong Images
        const categoryToImageMap = {
            bedrijfAlgemeen: "cat_jajo_icon_1.svg",
            gevaarlijkeStoffen: "cat_jajo_icon_1.svg",
            grondwerk: "cat_jajo_icon_1.svg",
            kinderen: "cat_jajo_icon_1.svg",
            overig: "cat_jajo_icon_1.svg",
            personeelEnArbeid: "cat_jajo_icon_2.svg",
            persoonlijk: "cat_jajo_icon_2.svg",
            voertuigen: "cat_jajo_icon_2.svg",
            voorkomen: "cat_jajo_icon_2.svg",
            weersomstandigheden: "cat_jajo_icon_2.svg",
            werkplaats: "cat_jajo_icon_3.svg",
            werktuigen: "cat_jajo_icon_3.svg",
            werkplekInspectie: "cat_jajo_icon_3.svg",
            loonwerk: "cat_jajo_icon_3.svg",
            werkzaamhedenAlgemeen: "cat_jajo_icon_3.svg"};

        let janssen = false;
        if (process.env.REACT_APP_PROJECT === 'janssen') {
            janssen = true;
        }

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                >
                    <Tab label={t('Toolboxes')} href="#toolboxen" style={{
                                                        color: '#000'
                                                    }}/>
                    { modules.inspections && (
                        <Tab
                            label={t('Workplace inspections')}
                            href="#werkplekinspecties"
                            style={{
                                color: '#000'
                            }}/>
                    )}
                </Tabs>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    {tab === 0 && (
                        <div className={classes.content}>
                            {searchResults.length > 0 ? (
                                searchResults.map((row, index) => {
                                    let answersCorrect = 0;
                                    row.toolbox.results.answers.forEach(
                                        (answer) =>
                                            answer.correct && answersCorrect++
                                    );

                                    return (
                                        <div
                                            key={index}
                                            className={classes.listItem}
                                            id="toolboxBlocks"
                                            onClick={() =>
                                                this.gotoToolboxResult(
                                                    row.assignId
                                                )
                                            }
                                        >
                                            {janssen ? (
                                                <div
                                                    className={classes.listItemIcon}
                                                    style={{
                                                        backgroundImage: 'url(/assets/images/' + categoryToImageMap[row.category] + ')',
                                                    }}
                                                />

                                            ) : (
                                                <div
                                                    className={classes.listItemIcon}
                                                    style={{
                                                        backgroundImage:
                                                            'url(/assets/images/cat_' +
                                                            row.category +
                                                            '.png)',
                                                    }}
                                                />

                                            )
                                            }

                                            <ArrowForwardIcon
                                                className={
                                                    classes.listItemArrow
                                                }
                                            />
                                            <div
                                                className={
                                                    classes.listItemContent
                                                }
                                            >
                                                <p
                                                    className={
                                                        classes.listItemTitle
                                                    }
                                                    id="toolboxText"
                                                >
                                                    <b>{row.title}</b>
                                                </p>
                                                <p
                                                    className={
                                                        classes.listItemQuestions
                                                    }
                                                >
                                                    {answersCorrect +
                                                        t(' of ') +
                                                        row.toolbox.results
                                                            .answers.length +
                                                        ' ' +
                                                        t('ask correctly')}
                                                </p>
                                                <p
                                                    className={
                                                        classes.listItemDate
                                                    }
                                                >
                                                    {Math.abs(
                                                        moment(
                                                            row.toolbox.results
                                                                .date
                                                        ).diff(moment(), 'days')
                                                    ) > 7
                                                        ? moment(
                                                              row.toolbox
                                                                  .results.date
                                                          ).format(
                                                              t('dateTime')
                                                          )
                                                        : moment().to(
                                                              moment(
                                                                  row.toolbox
                                                                      .results
                                                                      .date
                                                              )
                                                          )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={classes.noItemsFound}>
                                    <DeployIcon
                                        className={classes.deployIcon}
                                    />
                                    <div className={classes.noItemsFoundTitle}>
                                        {t('There are no completed toolboxes')}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {tab === 1 && (
                        <div className={classes.content}>
                            {searchResults.length > 0 ? (
                                searchResults.map((row, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classes.listItem}
                                            id="toolboxBlocks"
                                            onClick={() =>
                                                this.gotoInspectionResult(
                                                    row.id
                                                )
                                            }
                                        >
                                            <div
                                                className={classes.listItemIcon}
                                                style={{
                                                    backgroundImage:
                                                        'url(/assets/images/cat_werkplekinspectie.png)',
                                                }}
                                            />
                                            <ArrowForwardIcon
                                                className={
                                                    classes.listItemArrow
                                                }
                                            />
                                            <div
                                                className={
                                                    classes.listItemContent
                                                }
                                            >
                                                <p
                                                    className={
                                                        classes.listItemTitle
                                                    }
                                                    id="toolboxText"
                                                >
                                                    <b>{row.title}</b>
                                                </p>
                                                <p
                                                    className={
                                                        classes.listItemQuestions
                                                    }
                                                >
                                                    {row.inspection
                                                        .projectNumber +
                                                        ' - ' +
                                                        row.inspection
                                                            .projectName}
                                                </p>
                                                <p
                                                    className={
                                                        classes.listItemDate
                                                    }
                                                >
                                                    {Math.abs(
                                                        moment(
                                                            row.inspection
                                                                .results.date
                                                        ).diff(moment(), 'days')
                                                    ) > 7
                                                        ? moment(
                                                              row.inspection
                                                                  .results.date
                                                          ).format(
                                                              t('dateTime')
                                                          )
                                                        : moment().to(
                                                              moment(
                                                                  row.inspection
                                                                      .results
                                                                      .date
                                                              )
                                                          )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className={classes.noItemsFound}>
                                    <DeployIcon
                                        className={classes.deployIcon}
                                    />
                                    <div className={classes.noItemsFoundTitle}>
                                        {t(
                                            'There are no completed workplace inspections'
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </AppModule>
                <div style={{ visibility: 'hidden' }}>
                    {
                        (document.getElementById('html').style.backgroundColor =
                            '#f5f5f5')
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(OpdrachtenCompleted);
