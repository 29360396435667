import {createSelector} from 'reselect';
import {V2_TOOLBOX_OVERVIEW_REDUX} from "../lib/apiTypes";

const getToolboxOverviewEntities = store => store.entities[V2_TOOLBOX_OVERVIEW_REDUX];

export const getToolboxesOverviewV2 = createSelector([getToolboxOverviewEntities], toolboxEntities => {
    return toolboxEntities.allIds.map(id => toolboxEntities.byId[id]);
});

export const getToolboxOverviewV2 = createSelector(
    [getToolboxesOverviewV2],
    (toolboxes) => {
        return toolboxes;
    }
);
