import React, { Component } from 'react';
import LocationPicker from 'react-location-picker';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
//https://github.com/ubilabs/react-geosuggest
import Geosuggest from 'react-geosuggest';
import './LocationPicker.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

/* Default position */
const defaultPosition = {
    lat: 52,
    lng: 5,
};

class LocationPickerPanel extends Component {
    constructor(props) {
        super(props);
        if (this.props.address) {
            this.state = {
                address: this.props.address,
            };
        } else {
            this.state = {
                address: '',
            };
        }
    }

    handleLocationChange = ({ position, address }) => {
        if (this.props.onChange) {
            this.props.onChange(position, address);
        }
        // Set new location
        this.setState({ location: position, address });

        if (this.geosuggest) {
            this.geosuggest.update(address);
        }
    };

    handleAddressChange = (event: any) => {
        const address = event.target.value;
        this.geocodeAddress(address);

        this.setState({ address });

        if (this.props.onChange) {
            this.props.onChange(this.state.location, address);
        }
    };

    onSuggestSelect = (suggest) => {
        console.log(suggest);

        if (suggest) {
            this.handleLocationChange({
                position: suggest.location,
                address: suggest.label,
            });

            this.loctionPicker.setState({
                position: new window.google.maps.LatLng(
                    suggest.location.lat,
                    suggest.location.lng
                ),
            });
        }
    };

    geocodeAddress = (address) => {
        var geocoder = new window.google.maps.Geocoder();

        const self = this;
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK') {
                const location = results[0].geometry.location;

                self.loctionPicker.setState({ position: location });
                self.setState({ location: location });

                if (self.props.onChange) {
                    self.props.onChange(location, results[0].formatted_address);
                }
            }
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <FormControl>
                    <InputLabel
                        htmlFor="DialogInspectionDeploy-address-input"
                        shrink={this.state.address !== '' ? true : undefined}
                    >
                        {t('Address')}
                    </InputLabel>
                    <Geosuggest
                        id="DialogInspectionDeploy-address-input"
                        ref={(ref) => (this.geosuggest = ref)}
                        placeholder=""
                        country="nl"
                        initialValue={
                            this.state.address ? this.state.address : ''
                        }
                        types={['address']}
                        onChange={(address) => this.setState({ address })}
                        onSuggestSelect={this.onSuggestSelect}
                        autoActivateFirstSuggest
                    />
                    <FormHelperText>
                        {t('Address where the inspection will take place')}
                    </FormHelperText>
                </FormControl>
                <div>
                    <LocationPicker
                        ref={(ref) => (this.loctionPicker = ref)}
                        containerElement={
                            <div style={{ width: '100%', height: '400px' }} />
                        }
                        mapElement={<div style={{ height: '400px' }} />}
                        defaultPosition={this.props.location || defaultPosition}
                        zoom={5}
                        onChange={this.handleLocationChange}
                    />
                </div>
            </div>
        );
    }
}

export default compose(withTranslation())(LocationPickerPanel);
