import React from 'react';

const Block = props => {
    const {children, title} = props;

    return (
		<div
			style={{
				marginLeft: "24px",
				marginTop: "44px",
			}}
		>
			<p style={{ marginBottom: "-4px" }}>
				<b>{title || ""}</b>
			</p>
            {children}
		</div>
	);
}

export default Block