// @flow

import { connect } from 'react-redux';
import React from 'react';
import type { StoreState } from '../../../reducers/index';
import StatisticsToolboxDetailCard from '../StatisticsCard/StatisticsToolboxDetailCard';
import type { Dispatch as ReduxDispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
    clearAllFilters,
    filterPeriodMonth,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    filterIsToolboxMeetingChecked,
    filterIsNoToolboxMeetingChecked,
    setViewkey,
    setCrumbPath,
    fetchToolboxes,
    filterPeriodFrom,
    filterPeriodTill,
    filterAfgerond,
} from '../../../actions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import { getCurrentFilterSet } from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListToolboxAssign from '../StatisticsList/StatisticsListToolboxAssign';
import getFilteredToolboxAssigns from '../../../selectors/getFilteredToolboxAssigns';
import { fetchCSV, fetchPDF } from '../../../lib/api';
import DescIcon from '@mui/icons-material/Description';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/GetApp';
import { MenuItem, Menu, Fab} from '@mui/material';
import FilterHeader from '../../FilterHeader';
import $ from 'jquery';
import CloseIcon from '@mui/icons-material/Close';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {
    filterAfgerond: Function,
    afgerondFilter: number,
};

type State = {
    openAfgerond: boolean,
    openMeeting: boolean,
    toolboxFilter: any,
    anchorEl?: any,
};

const mapStateToProps = (state, props) => {
    let filters = getCurrentFilterSet(state.filters);

    const toolboxId = props.match.params.id;
    const toolbox = state.entities.toolboxes.byId[toolboxId];

    let toolboxAssigns = getFilteredToolboxAssigns(state);

    if (toolboxAssigns && toolbox) {
        toolboxAssigns = toolboxAssigns.filter(
            (ia) => ia.toolbox.toolboxId === toolbox.toolboxId
        );
    } else {
        toolboxAssigns = null;
    }

    return {
        toolbox: toolbox,
        toolBoxes: state.entities.toolboxes,
        toolboxAssigns: toolboxAssigns,
        loading: toolboxAssigns && toolbox ? false : true,
        newestTitle:
            toolboxAssigns && toolboxAssigns[0] && toolboxAssigns[0].toolbox
                ? toolboxAssigns[0].toolbox.title
                : '',
        listData: state.statistics.listData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        afgerondFilter: filters.afgerond,
        isToolboxMeetingChecked: filters.isToolboxMeetingChecked,
        isNoToolboxMeetingChecked: filters.isNoToolboxMeetingChecked,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        filterAfgerond: bindActionCreators(filterAfgerond, dispatch),
        filterIsToolboxMeetingChecked: bindActionCreators(
            filterIsToolboxMeetingChecked,
            dispatch
        ),
        filterIsNoToolboxMeetingChecked: bindActionCreators(
            filterIsNoToolboxMeetingChecked,
            dispatch
        ),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (title) => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        {
                            name: 'Toolbox: ' + title,
                            link: '/rapportages#toolboxen',
                        },
                    ],
                })
            );
        },
        goToDetailView: (toolboxAssign) => {
            dispatch(
                push(
                    '/toolbox/details/' +
                        toolboxAssign.toolbox.id +
                        '/' +
                        toolboxAssign.deploymentId +
                        '/results/' +
                        toolboxAssign.user.id +
                        '?from=rapportages'
                )
            );
        },
        fetchToolboxes: () => {
            dispatch(fetchToolboxes());
        },
    };
};

class StatisticsToolboxView extends React.Component<Props, State> {
    props: Props;
    state: {
        openAfgerond: false,
        openMeeting: false,
        toolboxFilter: 0,
        anchorEl: 0,
    };

    componentDidMount() {
        //set view key
        this.props.setViewkey();

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        this.props.setCrumbPath(
            this.props.newestTitle ? this.props.newestTitle : 'Titel'
        );
    }

    componentWillReceiveProps(newProps: Props) {
        if (this.props.toolbox !== newProps.toolbox) {
            this.props.setCrumbPath(
                newProps.newestTitle ? newProps.newestTitle : 'Titel'
            );
        }
    }

    componentWillMount() {
        this.props.fetchToolboxes();
    }

    handleToolboxFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ toolboxFilter: event.target.value });

        if (event.target.value === 1) {
            this.setState({ isToolboxMeetingChecked: true });
            this.setState({ isNoToolboxMeetingChecked: false });
            this.props.filterIsNoToolboxMeetingChecked(false);
            this.props.filterIsToolboxMeetingChecked(true);
        } else if (event.target.value === 2) {
            this.setState({ isNoToolboxMeetingChecked: true });
            this.setState({ isToolboxMeetingChecked: false });
            this.props.filterIsToolboxMeetingChecked(false);
            this.props.filterIsNoToolboxMeetingChecked(true);
        } else {
            this.setState({ isNoToolboxMeetingChecked: false });
            this.setState({ isToolboxMeetingChecked: false });
            this.props.filterIsToolboxMeetingChecked(false);
            this.props.filterIsNoToolboxMeetingChecked(false);
        }
    };

    onItemClicked = (clickedToolboxAssign) => {
        this.props.goToDetailView(clickedToolboxAssign);
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadCsv = () => {
        let ta = this.props.toolboxAssigns
            .filter((toolbox) =>
                this.props.isToolboxMeetingChecked
                    ? toolbox.toolboxMeeting === true
                    : true
            )
            .filter((toolbox) =>
                this.props.isNoToolboxMeetingChecked
                    ? toolbox.toolboxMeeting === false
                    : true
            )
            .filter((toolbox) =>
                this.state && this.state.searchtext
                    ? toolbox.fullnameUser
                          .replace('  ', ' ')
                          .toLowerCase()
                          .indexOf(this.state.searchtext.toLowerCase()) > -1
                    : true
            )
            .filter((toolbox) =>
                this.props.afgerondFilter !== 0
                    ? this.props.afgerondFilter === 1
                        ? toolbox.result && toolbox.result.complete
                        : toolbox.result == null || !toolbox.result.complete
                    : true
            );

        let data = [];

        ta.forEach((item) => {
            let score = 0;

            if (item.result) {
                item.result.answers.forEach((i) => {
                    if (i.correct) {
                        score++;
                    }
                });
            }

            data.push({
                deployed: moment(item.deployed).format('LLL'),
                version: item.version,
                result: item.result
                    ? {
                          date: moment(item.result.date).format('LLL'),
                          score: score,
                          questions: item.result.answers.length,
                      }
                    : null,
                toolboxMeeting: item.toolboxMeeting ? 'Ja' : 'Nee',
                user: {
                    fullname: item.user.fullname,
                },
            });
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOXASSIGN_RAPPORTAGE',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', this.props.newestTitle + '.csv');
                link.click();
            })
            .catch((err) => console.log(err));
    };

    getList = (toolbox, toolBoxes) => {
        return (
            <StatisticsListToolboxAssign
                isToolboxMeetingChecked={
                    this.props ? this.props.isToolboxMeetingChecked : false
                }
                isNoToolboxMeetingChecked={
                    this.props ? this.props.isNoToolboxMeetingChecked : false
                }
                toolboxAssigns={this.props.toolboxAssigns || []}
                toolbox={toolbox || {}}
                toolBoxes={toolBoxes}
                onItemClick={this.onItemClicked}
                searchtext={this.state ? this.state.searchtext : ''}
                afgerond={this.props.afgerondFilter}
            />
        );
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openMeeting: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openAfgerond: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleToolboxAfgerondFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ toolboxFilter: event.target.value });

        this.setState({ afgerondFilter: event.target.value });
        this.props.filterAfgerond(event.target.value);
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openAfgerond: false,
            openMeeting: false,
        });
        this.setState(newState);
    };

    getFilterBar = () => {
        const { t } = this.props;
        return (
            <div className="filterbar" style={{ paddingRight: '500px' }}>
                <PeriodFilterBlock />

                <div>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Toon'}
                            filterValue={
                                this.props.isToolboxMeetingChecked
                                    ? t('Toolboxmeeting')
                                    : this.props.isNoToolboxMeetingChecked
                                    ? t('Toolbox')
                                    : t('Everything')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openMeeting : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.props.isToolboxMeetingChecked
                            ? 1
                            : this.props.isNoToolboxMeetingChecked
                            ? 2
                            : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={1}
                    >
                        {t('Toolboxmeeting')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={2}
                    >
                        {t('Toolbox')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={'Afgerond'}
                            filterValue={
                                this.props.afgerondFilter === 0
                                    ? t('Everything')
                                    : this.props.afgerondFilter === 1
                                    ? t('Yes')
                                    : t('No')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openAfgerond : false}
                    onClose={this.handleRequestClose}
                    value={this.props.afgerondFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        style={{ width: '130px' }}
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={1}
                    >
                        {t('Yes')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={2}
                    >
                        {t('No')}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    getPDF = () => {
        let ta = this.props.toolboxAssigns
            .filter((toolbox) =>
                this.props.isToolboxMeetingChecked
                    ? toolbox.toolboxMeeting === true
                    : true
            )
            .filter((toolbox) =>
                this.props.isNoToolboxMeetingChecked
                    ? toolbox.toolboxMeeting === false
                    : true
            )
            .filter((toolbox) =>
                this.state && this.state.searchtext
                    ? toolbox.fullnameUser
                          .replace('  ', ' ')
                          .toLowerCase()
                          .indexOf(this.state.searchtext.toLowerCase()) > -1
                    : true
            )
            .filter((toolbox) =>
                this.props.afgerondFilter !== 0
                    ? this.props.afgerondFilter === 1
                        ? toolbox.result && toolbox.result.complete
                        : toolbox.result == null || !toolbox.result.complete
                    : true
            );

        let data = [];
        for (let i in (0, ta.length)) {
            if (ta[i].listParticipants) {
                ta[i].listParticipants.map((p) => data[i].push(p));
            }
        }
        ta.map((t) => (t.listParticipants = []));

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOXASSIGN_RAPPORTAGE',
            options: {},
            data: ta,
            info: {
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodType: this.props.periodType,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
                title: this.props.isToolboxMeetingChecked
                    ? i18n.t('Toolboxmeeting report')
                    : i18n.t('Toolbox report'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    'toolboxrapportage_' +
                        this.props.toolboxAssigns[0].toolbox.title +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));

        for (let i in (0, ta.length)) {
            ta[i].listParticipants = data[i];
        }
    };

    render() {
        const {
            loading,
            toolbox = {},
            toolBoxes,
            t,
        } = this.props;
        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                widemode={true}
                searchPlaceholder="Zoek binnen toolboxen"
            />
        );

        return (
            <div className="Statistics">
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <StatisticsToolboxDetailCard
                        toolboxBarData={
                            this.props.chartData
                                ? this.props.chartData.correctnessBarData
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList(toolbox, toolBoxes)}
                        totalToolboxesIncorrect={
                            this.props.chartData
                                ? this.props.chartData.totalToolboxesIncorrect
                                : 0
                        }
                        totalToolboxesCorrect={
                            this.props.chartData
                                ? this.props.chartData.totalToolboxesCorrect
                                : 0
                        }
                    />
                </AppModule>
                <div
                    style={{
                        position: 'fixed',
                        top: '32px',
                        right: '36px',
                        zIndex: 1500,
                    }}
                >
                    <div>
                        <Fab   onClick={this.toggleExport}>
                            {this.state && this.state.exportOpen ? (
                                <CloseIcon />
                            ) : (
                                <DownloadIcon />
                            )}
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '20px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '87px', right: '60px' }}
                        >
                            {t('Download PDF bestand')}
                        </span>
                        <Fab color="primary"  onClick={this.getPDF}>
                            <InsertIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '145px', right: '60px' }}
                        >
                            {t('Download CSV bestand')}
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={this.downloadCsv}
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsToolboxView);
