// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowToolboxMeeting from '../../StatisticsView/StatisticsRow/StatisticsRowToolboxMeeting';
import { connect } from 'react-redux';
import { fetchToolboxAssigns } from '../../../actions/toolboxActions';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
    toolbox: any,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
    };
};

class StatisticsListDistributors extends React.PureComponent<Props, void> {
    static contextTypes = {
        router: PropTypes.object,
    };

    rowProvider = (toolboxAssign) => {
        if (!toolboxAssign || !toolboxAssign.toolbox) {
            return undefined;
        }

        return (
            <StatisticsRowToolboxMeeting
                toolbox={this.props.toolbox}
                key={toolboxAssign.id}
                handleRowClick={() => this.onRowClick(toolboxAssign)}
                toolboxAssign={toolboxAssign}
            />
        );
    };

    headerProvider = () => {
        const { t } = this.props;
        return {
            deployed: t('Date sent'),
            fullnameUser: t('Employee'),
            finished: t('Completed on'),
            Score: t('Score'),
        };
    };

    onRowClick = (toolboxAssign) => {
        this.props.onItemClick(toolboxAssign);
    };

    countParticipantsToolboxMeeting = (nta) => {
        let listParticipants = [];

        this.props.toolboxAssigns.forEach((ta) => {
            let before = moment(ta.deployed).add(5, 'second');
            let after = moment(ta.deployed).subtract(5, 'second');
            if (
                ta.toolboxMeetingDate === nta.toolboxMeetingDate &&
                moment(nta.deployed).isBefore(before) &&
                moment(nta.deployed).isAfter(after)
            ) {
                listParticipants.push(ta);
            }
        });

        /*if (nta && nta.meetingid){
            //New way of getting toolboxmeetings
            this.props.toolboxAssigns.forEach(ta=> {
                if (ta.meetingid === nta.meetingid) {
                    listParticipants.push(ta);
                }
            });
        } else {
            //Legacy toolboxmeetings
            this.props.toolboxAssigns.forEach(ta=> {
                let before = moment(ta.deployed).add(5, 'second');
                let after = moment(ta.deployed).subtract(5, 'second');
                if (ta.toolboxMeetingDate === nta.toolboxMeetingDate && moment(nta.deployed).isBefore(before) && moment(nta.deployed).isAfter(after)) {
                    listParticipants.push(ta);
                }
            });
        }*/

        return listParticipants;
    };

    render() {
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set('Score', (toolboxAssignA, toolboxAssignB) => {
            if (
                toolboxAssignA.result === null &&
                toolboxAssignB.result === null
            ) {
                return 0;
            }
            if (toolboxAssignA.result === null) {
                return -1;
            }
            if (toolboxAssignB.result === null) {
                return 1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length >
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                return 1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length <
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                return -1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length ===
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                if (
                    toolboxAssignA.result.answers.length >
                    toolboxAssignB.result.answers.length
                ) {
                    return 1;
                }
                if (
                    toolboxAssignA.result.answers.length <
                    toolboxAssignB.result.answers.length
                ) {
                    return -1;
                }
            }
            return 0;
        });

        let data = List(
            this.props.toolboxAssigns.map((item) =>
                Object.assign(item, {
                    finished: item.result ? item.result.date : null,
                    listParticipants:
                        item.toolboxMeeting === true
                            ? this.countParticipantsToolboxMeeting(item)
                            : 0,
                    expanded: false,
                })
            )
        );

        return (
            <PaginatedSortFilterView
                data={this.props.toolboxAssigns.length === 0 ? List([]) : data}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="deployed"
                asc={false}
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListDistributors);
