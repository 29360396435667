//@flow
import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'lodash';
import { DragSource } from 'react-dnd';

type Props = {
    children: any,
    connectDragSource: Function,
};

type State = {};

class DragDropSource extends React.Component {
    props: Props;
    state: State = {};

    render() {
        const { children, connectDragSource } = this.props;
        return connectDragSource(<div>{ children }</div>);
    }
}

export default _.flowRight([
    DragSource(
        (props) => props.transfers,
        {
            beginDrag: function (props) {
                return props.data;
            }
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource()
        })
    )
])(DragDropSource);
