// @flow
import React from 'react'; // eslint-disable-line no-unused-vars


import moment from '../../../lib/moment';
import TitleDescription from '../../TitleDescription/TitleDescription';

import './TableActionHeaderUsergroup.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    toolboxAssignRow?: any,
    loading: boolean,
};

type State = {};


class TableActionHeaderUsergroup extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { headerItem, t } = this.props;

        return (
            <div className="headerChildrenContainer">
                <h3>{headerItem.name}</h3>
                <TitleDescription
                    className={'headerItem'}
                    itemTitle={t('Created on')}
                    itemDescription={moment(headerItem.createdDate).format(
                        t('dateTime')
                    )}
                />
                <TitleDescription
                    className={'headerItem'}
                    itemTitle={t('by')}
                    itemDescription={headerItem.createdBy}
                />
                <TitleDescription
                    className={'headerItem'}
                    itemTitle={'#' + t('employees member in group')}
                    itemDescription={
                        headerItem.users ? headerItem.users.length : 0
                    }
                />
            </div>
        );
    }
}

export default compose(
    
    withTranslation()
)(TableActionHeaderUsergroup);
