// @flow
import React from 'react';
import type { TEmployee } from '../../../../reducers/employeesReducer';
import { TableRow, TableCell } from '@mui/material';

type Props = {
    employee: TEmployee
}

const EmployeeDiffRow = (props: Props) => {
    const { employee, conflictRow, ...childProps } = props;

    return (
        <TableRow {...childProps}>
            <TableCell style={{width: '22%',color: conflictRow === "externalId"? 'red': ''}}>{conflictRow === "externalId" ? /*<Tooltip placement="top" title={'Dit nummer wordt al gebruikt door een bestaande al gebruiker. Verander het nummer en probeer het opnieuw'}>*/<div>{employee.externalId}</div>/*</Tooltip>*/ : <div>{employee.externalId}</div>}</TableCell>
            <TableCell style={{width: '22%'}}>{employee.firstname}</TableCell>
            <TableCell style={{width: '22%'}}>{employee.lastname + (employee.middlename ? ' ' + employee.middlename : '')}</TableCell>
            <TableCell style={{width: '34%', position: 'relative', color: conflictRow === "email"? 'red': ''}}>{conflictRow === "email" ? /*<Tooltip placement="top" title={'Dit e-mail adres wordt al gebruikt door een al bestaande gebruiker. Verander het email adres of wijzig het email adres van de bestaande gebruiker en probeer het opnieuw'}>*/<div>{employee.username || '-'}</div>/*</Tooltip>*/ : <div>{employee.username || '-'}</div>}</TableCell>
        </TableRow>
    );
};

export default EmployeeDiffRow;
