// @flow
import React from 'react';
import { Map } from 'immutable';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';

import { fetchProjects } from '../../../actions';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ProjectEditCard.module.scss';


type Props = {
    project?: TProject,
    onEdit: (project?: TProject) => mixed,
    onSave: (project: TProject) => mixed,
    goBack: () => mixed,
    projects: any,
};

type State = {
    errors: Map<string, string>,
};


const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        projects: store.entities.projects,
    };
};

class ProjectEditCard extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        errors: new Map(),
    };

    componentWillMount() {
        this.props.fetchProjects();
    }
    handleOmschrijvingChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.props.onEdit(
            Object.assign({}, this.props.project, {
                omschrijving: event.target.value,
            })
        );

        this.setState({
            errors: this.state.errors.remove('omschrijving'),
        });
    };

    handleOpdrachtgeverChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.props.onEdit(
            Object.assign({}, this.props.project, {
                opdrachtgever: event.target.value,
            })
        );

        this.setState({
            errors: this.state.errors.remove('opdrachtgever'),
        });
    };

    cancelEdit = () => {
        this.props.onEdit(undefined);
        this.props.goBack();
    };

    saveProject = () => {
        let errors: Map<string, string> = new Map();
        const { t } = this.props;

        var errorTexts = {
            'omschrijving.required': t('This field is required'),
            'opdrachtgever.required': t('This field is required'),
        };

        if (!this.props.project) {
            //TODO error?
            return;
        }

        let project = this.props.project;

        if (!project.omschrijving || project.omschrijving === '') {
            errors = errors.set(
                'omschrijving',
                errorTexts['omschrijving.required']
            );
        }

        if (!project.opdrachtgever || project.opdrachtgever === '') {
            errors = errors.set(
                'opdrachtgever',
                errorTexts['opdrachtgever.required']
            );
        }

        //No errors
        if (errors.isEmpty()) {
            let unique = true;

            if (this.props.projects) {
                this.props.projects.allIds
                    .map((id) => this.props.projects.byId[id])
                    .forEach((pr) => {
                        if (
                            pr.omschrijving.toLowerCase() ===
                                project.omschrijving.toLowerCase() &&
                            pr.opdrachtgever.toLowerCase() ===
                                project.opdrachtgever.toLowerCase()
                        ) {
                            unique = false;
                        }
                    });
                if (unique === false) {
                    alert(t('The project already exists'));
                }

                if (unique === true) {
                    this.setState(
                        Object.assign({}, this.state, { errors: errors })
                    );
                    this.props.onSave(project, t);
                    this.props.onEdit(undefined);
                }
            }
        } else {
            this.setState({
                errors,
            });
        }
    };

    render() {
        if (!this.props.project) {
            return <div />;
        }
        const project = this.props.project;

        const { t } = this.props;

        const errors = this.state.errors;

        return (
            <div style={{ height: '100%' }}>
                <DialogContent style={{ height: 'calc(100% - 100px)' }}>
                    <DialogContentText className={classes.text}>
                        {t('Enter the details of the project / work area.')}
                    </DialogContentText>
                    <TextField
                        variant="standard"
                        id="omschrijving"
                        label={t('Name')}
                        value={
                            project.omschrijving
                                ? project.omschrijving
                                : undefined
                        }
                        error={errors.get('omschrijving') != null}
                        helperText={errors.get('omschrijving')}
                        onChange={this.handleOmschrijvingChange}
                    />

                    <TextField
                        id="opdrachtgever"
                        variant="standard"
                        label={t('Customer')}
                        error={errors.get('opdrachtgever') != null}
                        helperText={errors.get('opdrachtgever')}
                        value={
                            project.opdrachtgever
                                ? project.opdrachtgever
                                : undefined
                        }
                        onChange={this.handleOpdrachtgeverChange}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        className="secondaryButton"
                        onClick={this.cancelEdit}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        className="secondaryButton"
                        onClick={this.saveProject}>
                        {project.id ? t('Save') : t('Add')}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ProjectEditCard);
