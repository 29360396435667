import {
    CREATE_TOOLBOX_DRAFT,
    MOVE_TOOLBOX_DRAFT_CONTENT_BLOCK,
    DELETE_TOOLBOX_DRAFT_CONTENT_BLOCK,
    INSERT_TOOLBOX_DRAFT_CONTENT_BLOCK,
    EDIT_TOOLBOX_DRAFT,
    MOVE_TOOLBOX_DRAFT_QUESTION_BLOCK,
    DELETE_TOOLBOX_DRAFT_QUESTION_BLOCK,
    INSERT_TOOLBOX_DRAFT_QUESTION_BLOCK,
} from '../actions';

const initialState = {};

export default function toolboxDraftsReducer(
    state = initialState,
    action = {}
) {
    switch (action.type) {
        case CREATE_TOOLBOX_DRAFT: {
            const newDraft = {
                title: '',
                description: '',
                ...action.payload.data,
            };
            newDraft.description = newDraft.description || '';
            newDraft.title = newDraft.title || '';
            return { ...state, [action.payload.data.id]: newDraft };
        }
        case MOVE_TOOLBOX_DRAFT_CONTENT_BLOCK: {
            const { id, fromIndex, toIndex } = action.payload;
            const toolbox = state[id];
            const newBlocks = [...toolbox.toolbox.blocks];
            newBlocks.splice(toIndex, 0, newBlocks.splice(fromIndex, 1)[0]);
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, blocks: newBlocks },
                },
            };
        }
        case DELETE_TOOLBOX_DRAFT_CONTENT_BLOCK: {
            const { id, contentBlockId } = action.payload;
            const toolbox = state[id];
            const newBlocks = [...toolbox.toolbox.blocks];
            newBlocks.splice(
                newBlocks.findIndex((b) => b === contentBlockId),
                1
            );
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, blocks: newBlocks },
                },
            };
        }
        case INSERT_TOOLBOX_DRAFT_CONTENT_BLOCK: {
            const { id, contentBlockId, index } = action.payload;
            const toolbox = state[id];
            const newBlocks = [...toolbox.toolbox.blocks];
            newBlocks.splice(index, 0, contentBlockId);
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, blocks: newBlocks },
                },
            };
        }
        case MOVE_TOOLBOX_DRAFT_QUESTION_BLOCK: {
            const { id, fromIndex, toIndex } = action.payload;
            const toolbox = state[id];
            const newQuestions = [...toolbox.toolbox.questions];
            newQuestions.splice(
                toIndex,
                0,
                newQuestions.splice(fromIndex, 1)[0]
            );
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, questions: newQuestions },
                },
            };
        }
        case DELETE_TOOLBOX_DRAFT_QUESTION_BLOCK: {
            const { id, questionBlockId } = action.payload;
            const toolbox = state[id];
            const newQuestions = [...toolbox.toolbox.questions];
            newQuestions.splice(
                newQuestions.findIndex((b) => b === questionBlockId),
                1
            );
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, questions: newQuestions },
                },
            };
        }
        case INSERT_TOOLBOX_DRAFT_QUESTION_BLOCK: {
            const { id, questionBlockId, index } = action.payload;
            const toolbox = state[id];
            const newQuestions = [...toolbox.toolbox.questions];
            newQuestions.splice(index, 0, questionBlockId);
            return {
                ...state,
                [id]: {
                    ...state[id],
                    toolbox: { ...state[id].toolbox, questions: newQuestions },
                },
            };
        }
        case EDIT_TOOLBOX_DRAFT: {
            const { id, key, value } = action.payload;

            console.log('payload', action.payload);
            return { ...state, [id]: { ...state[id], [key]: value } };
        }
        default:
            return state;
    }
}
