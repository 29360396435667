import React from 'react';
import {
	Divider,
	Drawer,
} from '@mui/material';
import {Header, View} from './DrawerComponents';

const DrawerComponent = props => {
    const {
		open,
		onClose,
		speakup,
		classes,
		onAttachmentClick,
		speakupcompany,
		speakupContacts,
	} = props;

    return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			style={{ zIndex: 1102 }}
		>
			<div tabIndex={0} role="button">
                <div style={{ width: '600px' }}>
				<Header speakup={speakup} onClose={onClose} />
				<Divider style={{ width: "100%" }} />
				<View
					speakup={speakup}
					classes={classes}
					onAttachmentClick={onAttachmentClick}
					speakupcompany={speakupcompany}
					speakupContacts={speakupContacts}
				/>
                </div>
			</div>
		</Drawer>
	);
}

export default DrawerComponent