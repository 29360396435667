import { createSelector } from 'reselect';
import moment from 'moment';
import { getCurrentFilterSet } from '../reducers/filterReducer';

const getToolboxAssigns = store => store.entities.toolboxAssigns;
const getCurrentFilters = store => getCurrentFilterSet(store.filters);

const getFilteredToolboxAssigns = createSelector([getToolboxAssigns, getCurrentFilters], (toolboxAssigns, filters) => {
    let ias = [];
    if (toolboxAssigns && toolboxAssigns.allIds) {
        ias = toolboxAssigns.allIds.map(id => toolboxAssigns.byId[id]);
    }

    if (filters.periodType === 'year' || filters.periodType === 'month' || filters.periodType === 'week') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).year() === filters.periodYear);
    }

    if (filters.periodType === 'month') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).month() === filters.periodMonth);
    }

    if (filters.periodType === 'week') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed).week() === filters.periodWeek);
    }

    if (filters.periodType === 'fromtill') {
        ias = ias.filter(ia => ia.deployed && moment(ia.deployed) >= moment(filters.periodFrom) && moment(ia.deployed) <= moment(filters.periodTill));
    }

    return ias.length > 0 ? ias : [];
});

export default getFilteredToolboxAssigns;
