// @flow
import TypedRecord from '../components/Util/IRecord';
import type { TDashboardActions } from '../actions/dashboardActions';

export type TDashboard = {
    fullName: string,
    totalToolboxes: number,
    totalInspections: number,
    finishedToolboxes: number,
    finishedInspections: number,
    deployedToolboxes: array,
    deployedInspections: array,
};

export class TStateDashboard extends TypedRecord({
    inProgress: false,
    dashboard: {},
}) {
    inProgress: boolean;
    dashboard: TDashboard;
}

const initialState = new TStateDashboard();

export default function dashboardReducer(
    state: TStateDashboard = initialState,
    action: TDashboardActions
) {
    switch (action.type) {
        case 'FETCH_DASHBOARD_START': {
            return { ...state, inProgress: true };
        }
        case 'FETCH_DASHBOARD_SUCCESS': {
            return { ...state, dashboard: action.payload, inProgress: false };
        }
        default:
            return state;
    }
}
