// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StatisticsInspectionOverview from './StatisticsInspection/StatisticsInspectionOverview';
import StatisticsToolboxOverview from './StatisticsToolbox/StatisticsToolboxOverview';
import StatisticsEmployeeOverview from './StatisticsEmployee/StatisticsEmployeeOverview';
import { TEmployee } from '../../reducers/employeesReducer';
import { getCurrentEmployee } from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from '../../lib/moment';
import 'moment/min/locales';
import i18n from 'i18next';
import {getModules} from '../lib';
import classes from './StatisticsView.module.scss'
import colors from "../../lib/themes/theme.scss";

moment.locale(i18n.t('LOCALE'));

type Props = {
    getCurrentEmployee: () => TEmployee,
};

type State = {
    tab: number,
    currentEmpl: TEmployee,
};

const mapStateToProps = (store, props) => {
    return {
        currentEmpl: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCurrentEmployee: () => {
            dispatch(getCurrentEmployee());
        },
    };
};


class StatisticsView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    componentWillMount() {
        this.props.getCurrentEmployee();
    }

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        const tabs = ['#toolboxen', '#werkplekinspecties', '#medewerkers'];

        if (this.props.location.hash) {
            const hash = this.props.location.hash;

            this.setState({
                tab: tabs.indexOf(hash),
            });
        }
    }

    render() {
        const modules = getModules();
        const { currentEmpl, t } = this.props;
        const { tab } = this.state;

        let vv_functions;
        if (currentEmpl) vv_functions = currentEmpl.subscription.vv_functions;

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.headerTabs}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{ '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('TOOLBOXES')} href="#toolboxen" sx={{ color: colors.mainTabsColor }}/>
                    {modules.inspections && vv_functions && (
                        <Tab
                            label={t('WORK PLACE INSPECTIONS')}
                            href="#werkplekinspecties"
                            sx={{ color: colors.mainTabsColor }}
                        />
                    )}
                    {vv_functions && (
                        <Tab label={t('EMPLOYEES')} href="#medewerkers" sx={{ color: colors.mainTabsColor }}/>
                    )}
                </Tabs>
                {vv_functions && tab === 0 && (
                    <StatisticsToolboxOverview {...this.props} />
                )}
                {vv_functions && tab === 1 && modules.inspections && (
                    <StatisticsInspectionOverview {...this.props} />
                )}
                {vv_functions && tab === 1 && !modules.inspections && (
                    <StatisticsEmployeeOverview
                        vv_functions={vv_functions}
                        {...this.props}
                    />
                )}
                {vv_functions && tab === 2 && (
                    <StatisticsEmployeeOverview
                        vv_functions={vv_functions}
                        {...this.props}
                    />
                )}

                {!vv_functions && tab === 0 && (
                    <StatisticsToolboxOverview {...this.props} />
                )}
                {/*!vv_functions && tab === 1 && <StatisticsEmployeeOverview vv_functions={vv_functions} {...this.props} />*/}
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsView);
