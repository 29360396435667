import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { getCurrentFilterSet } from '../reducers/filterReducer';
import getFilteredToolboxAssigns from './getFilteredToolboxAssigns';

const getCurrentFilters = store => getCurrentFilterSet(store.filters);
const getFilteredIas = store => getFilteredToolboxAssigns(store);

const getFilteredAndGroupedToolboxAssigns = createSelector([getFilteredIas, getCurrentFilters], (ias, filters) => {
    let toolboxAssignGrouped = new Map();

    ias.forEach(toolboxAssign => {
        if (!toolboxAssignGrouped.get(toolboxAssign.toolbox.toolboxId)) {
            toolboxAssignGrouped = toolboxAssignGrouped.set(toolboxAssign.toolbox.toolboxId, Object.assign({}, toolboxAssign));
        }

        const toolboxAssignGroup = toolboxAssignGrouped.get(toolboxAssign.toolbox.toolboxId);

        if (!toolboxAssignGroup.assignList) {
            toolboxAssignGroup.assignList = [];
            toolboxAssignGroup.employeeCount = 0;
            toolboxAssignGroup.finished = 0;
            toolboxAssignGroup.answersCorrect = 0;
            toolboxAssignGroup.answersIncorrect = 0;

        }
        toolboxAssignGroup.employeeCount++;
        toolboxAssignGroup.assignList.push(toolboxAssign);

        if (!toolboxAssignGroup.latestVersion) {
            toolboxAssignGroup.latestVersion = toolboxAssign.version;
        } else if (toolboxAssign.version.toString().split('.')[0] > toolboxAssignGroup.latestVersion.toString().split('.')[0] && toolboxAssign.version.toString().split('.')[1] > toolboxAssignGroup.latestVersion.toString().split('.')[1]) {
            toolboxAssignGroup.latestVersion = toolboxAssign.version;
        }



        if (toolboxAssign.result && toolboxAssign.result.complete) {
            toolboxAssignGroup.finished++;
            // Loop the result set
            if (toolboxAssign.result.answers) {
                toolboxAssign.result.answers.forEach(answer => {
                   if (answer.correct === true) {
                       toolboxAssignGroup.answersCorrect++;
                   } else {
                       toolboxAssignGroup.answersIncorrect++;
                   }
                });
            }
        }
    });

    return toolboxAssignGrouped.size > 0 ? toolboxAssignGrouped.toList().toJS() : [];
});

export default getFilteredAndGroupedToolboxAssigns;
