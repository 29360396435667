// @flow
import React from 'react';
import type { TProject } from '../../../../reducers/projectsReducer';
import { TableRow, TableCell } from '@mui/material';

type Props = {
    project: TProject
};

const ProjectDiffRow = (props: Props) => {
    const { project, ...childProps } = props;

    return (
        <TableRow {...childProps}>
            <TableCell>{project.externalId}</TableCell>
            <TableCell>{project.omschrijving}</TableCell>
            <TableCell>{project.opdrachtgever}</TableCell>
        </TableRow>
    );
};

export default ProjectDiffRow;
