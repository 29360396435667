// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {Fab} from '@mui/material';


import AppModule from '../../AppModule';
import {
    getAppSpeakups,
    setCrumbPath,
    fetchSpeakupContacts,
} from '../../../actions';
import { getPermissions, inProgress } from '../../../selectors';
import { push } from 'connected-react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import classnames from 'classnames';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import classes from "./WebAppSpeakup.module.scss"

type Props = {
    setCrumbPath: () => void,
    permissions: any,
    classes: any,
    loading: boolean,
    getAppSpeakups: () => any,
    fetchSpeakupContacts: () => any,

};

type State = {};


const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        speakups: store.entities.speakups,
        speakupcontact: store.entities.speakupcontact,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: (title = i18n.t('speakUpMenuItem')) => {
            dispatch(setCrumbPath({ title: title }));
        },
        fetchSpeakupContacts: () => {
            dispatch(fetchSpeakupContacts());
        },
        getAppSpeakups: () => {
            dispatch(getAppSpeakups());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class WebAppSpeakup extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentWillMount() {
        this.props.setCrumbPath();
        this.props.getAppSpeakups();
        this.props.fetchSpeakupContacts();
    }

    gotoSpeakupResult = (id) => {
        this.props.goTo('speakup/create/' + id);
    };

    createSpeakup = () => {
        this.props.goTo('speakup/create');
    };

    componentDidMount() {}

    componentDidUpdate() {
        this.props.setCrumbPath();
    }

    render() {
        const { loading, speakups, t } = this.props;

        // window.$crisp.push(['do', 'chat:hide']);

        let sortedIncidents = [];
        if (speakups && sortedIncidents.length === 0) {
            speakups.allIds.map(
                (id) =>
                    speakups.byId[id] !== null &&
                    sortedIncidents.push(speakups.byId[id])
            );

            sortedIncidents.sort((a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        const tableActionHeader = <TableActionHeader />;
        return (
            <div>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <Fab   onClick={this.createSpeakup} >
                            <AddIcon/>
                        </Fab>
                    </div>
                    <div className={classes.content}>
                        {sortedIncidents.length > 0 ? (
                            sortedIncidents.map((row, index) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '8px',
                                            border: '2px solid #ECECEC',
                                            width: '610px',
                                            height: '92px',
                                            margin: 'auto',
                                            marginBottom: '16px',
                                            cursor: 'pointer',
                                        }}
                                        id="toolboxBlocks"
                                        onClick={() => this.gotoSpeakupResult(row.id) }
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                height: '100%',
                                                backgroundImage:
                                                    'url(/assets/images/cat_werkplekinspectie.png)',
                                                backgroundRepeat: 'no-repeat',
                                                float: 'left',
                                                backgroundPosition: 'center',
                                                backgroundSize: '56px 56px',
                                            }}
                                        />
                                        <ArrowForwardIcon
                                            style={{
                                                float: 'right',
                                                marginRight: '15px',
                                                marginTop: '15px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                float: 'left',
                                                width: '150px',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: '450px',
                                                }}
                                                id="toolboxText"
                                            >
                                                <b>
                                                    {this.props.speakupcontact &&
                                                    this.props.speakupcontact
                                                        .byId &&
                                                    row.contact
                                                        ? t(
                                                              this.props.speakupcontact.byId[row.contact].locKey ||
                                                                  this.props.speakupcontact.byId[row.locKey].name
                                                          )
                                                        : t('No type selected')}
                                                </b>
                                            </p>
                                            <p
                                                style={{
                                                    opacity: '0.5',
                                                    lineHeight: '2px',
                                                    width: '450px',
                                                }}
                                            >
                                                {`${t('Status')} ${t('Received')}`}
                                            </p>
                                            <p
                                                style={{
                                                    opacity: '0.5',
                                                    lineHeight: '6px',
                                                    width: '450px',
                                                }}
                                            >
                                                {moment(row.date).format(t('dateTime'))}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ marginTop: '30vh' }}>
                                <AnnouncementIcon
                                    style={{
                                        margin: 'auto',
                                        width: '100%',
                                        height: '64px',
                                        opacity: '0.4',
                                    }}
                                />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        opacity: '0.4',
                                    }}
                                >
                                    {t('There are no SpeakUp Reports')}
                                </div>
                            </div>
                        )}
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(WebAppSpeakup);
