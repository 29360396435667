// @flow
import React from 'react'; // eslint-disable-line no-unused-vars


import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {getToolboxTrend} from "../../../lib/api";
import {showSnackbar} from "../../../actions";
import i18n from "i18next";
import {connect} from "react-redux";
import ToolboxTrendQuestionList from "./ToolboxTrendQuestionList";

type Props = {
    toolbox?: any,

};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        projects: store.entities.projects,
    };
};

class ToolboxTrendView extends React.Component<Props, State> {
    props: Props;
    state: State = {};


    componentDidMount() {
        // Fetch Toolbox trend

        getToolboxTrend(this.props.toolbox.id)
            .then((entities) => {
                this.setState({
                    answerTrend: entities.body
                });
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching toolboxtrend'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    render() {
        const { toolbox  } = this.props;
        const { answerTrend } = this.state;

        var totalCorrectCnt = 0;
        var totalIncorrectCnt = 0;
        if (answerTrend) {
            answerTrend.forEach(trend => {
                totalCorrectCnt+=trend.correctCnt;
                totalIncorrectCnt+=trend.incorrectCnt;
            })
        }
        var percentage = 0;
        if (totalIncorrectCnt + totalCorrectCnt > 0) {
            percentage = (100 * totalCorrectCnt / (totalCorrectCnt + totalIncorrectCnt)).toFixed(2);
        }


        return (
            <div>
                <div>
                    <h4>{i18n.t('Toolbox Score') + totalCorrectCnt + '/' + (totalIncorrectCnt+totalCorrectCnt) + ' (' + percentage + '%)'}</h4>
                    <h3>{i18n.t('Based on the toolbox results from Toolbox') + toolbox.title + ' (' + i18n.t('Version') + ': ' + toolbox.version + ')' }</h3>
                </div>
                <ToolboxTrendQuestionList
                    answerTrend={answerTrend}
                />
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    
    withTranslation()
)(ToolboxTrendView);
