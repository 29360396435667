// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCrumbPath, inviteEmployee } from '../../actions/index';
import { inProgress } from '../../selectors/index';
import moment from '../../lib/moment';
import TitleDescription from '../TitleDescription/TitleDescription';
import Slide from '@mui/material/Slide';
import classes from './SubscriptionAbonnement.module.scss';
import AppModule from '../AppModule';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import { Divider, Dialog, DialogTitle, Button } from '@mui/material';
import {
    editAbonnee,
    changeSubscriptionHolder,
    changeSubscriptionComment,
} from '../../actions/subscriptionActions';
import { TSubscription } from '../../reducers/subscriptionReducer';
import AbonneeEditCard from '../Subscriptions/AbonneeEditCard';
import { goBack, push } from 'connected-react-router';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

type Props = {
    loading: boolean,
    cumela?: boolean,
    columns?: any,
    editAbonnee: (abonnee: TSubscription) => void,
    editabonnee: TSubscription,
    goBack: () => mixed,
    setEditAbonnee: (abonnee: TSubscription) => void,
};

type State = {
    comment: string,
};


const mapDispatchToProps = (dispatch, props) => {
    return {
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: i18n.t('Subscriber'),
                    crumbs: [{ name: abonneeCompanyName, link: '/abonnee' }],
                })
            );
        },
        setEditAbonnee: bindActionCreators(editAbonnee, dispatch),
        editAbonnee: (id) => {
            if (Number.isInteger(id)) {
                dispatch(push('/abonnees/' + id));
            } else {
                dispatch(push('/abonnees/details/' + id.id));
                // bindActionCreators(upsertAbonnee, dispatch);
            }
        },
        goBack: () => {
            dispatch(goBack());
        },
        onSave: (newOb) => {
            changeSubscriptionHolder(newOb)(dispatch);
            dispatch(push('/abonnee/details/' + newOb.id + '#abonnement'));
        },
        onSaveComment: (abonnee) => {
            changeSubscriptionComment(abonnee)(dispatch);
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
        showDetailDialog: window.location.href.endsWith('dialog'),
        editabonnee: store.subscription.editabonnee,
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SubscriptionAbonnement extends React.Component<Props, State> {
    state: State = {
        showDetailDialog: true,
        comment: this.props.abonnee.comments,
    };

    cancelEdit = () => {
        this.props.goBack();
    };

    saveComment = () => {
        let sub = this.props.abonnee;
        sub.comments = this.state.comment;
        this.props.onSaveComment(sub);
    };

    handleCommentsChange = (event: any) => {
        this.setState({
            comment: event.target.value,
        });
    };

    render() {
        const { style, abonnee, t } = this.props;

        if (!abonnee) {
            return <div />;
        }

        const creditsShow = abonnee.credits_setup
            ? abonnee.credits
            : t('Subscription holder doesnt have Quick share');

        const abonneeType =
            abonnee.credits_setup && !abonnee.vv_functions
                ? t('Sneldelen only')
                : abonnee.cumela === true
                ? abonnee.credits_setup
                    ? 'Cumela (+ Sneldelen)'
                    : 'Cumela'
                : abonnee.caoLeo === true
                ? abonnee.credits_setup
                    ? 'cao Groen, Grond en Infrastructuur (+ Sneldelen)'
                    : 'cao Groen, Grond en Infrastructuur'
                : abonnee.credits_setup
                ? 'Standaard (+ Sneldelen)'
                : 'Standaard';


        const tableActionHeader = (
            <TableActionHeader
                title={
                    <span style={{ paddingLeft: '20px' }}>
                        {t('Subscription information')}
                    </span>
                }
            />
        );

        return (
            <div>
                <AppModule hasTabs prepend={tableActionHeader}>
                    <div style={style} className={classes.root}>
                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Subscription')}
                        </div>
                        <Divider className={classes.divider} />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Member since')}
                            itemDescription={moment(
                                abonnee.dateCreatedOn
                            ).format(t('DD-MM-YYYY'))}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Number of employees')}
                            itemDescription={
                                abonnee.totalEmployees +
                                ' (' +
                                abonnee.activeEmployees +
                                ' actief)'
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Subscription')}
                            itemDescription={abonneeType}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Subscription size')}
                            itemDescription={
                                abonnee.maxEmployees !== -1
                                    ? 'Tot ' +
                                      abonnee.maxEmployees +
                                      ' medewerkers'
                                    : t('Infinite')
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Yearly costs')}
                            itemDescription={'€ ' + abonnee.price}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Fast-share credits available')}
                            itemDescription={creditsShow}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Subscription expiration date')}
                            itemDescription={
                                abonnee.expiryDate === null
                                    ? '-'
                                    : moment(abonnee.expiryDate).format(
                                          t('DD-MM-YYYY')
                                      )
                            }
                        />

                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Cumela information')}
                        </div>
                        <Divider className={classes.divider} />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={'CUMELA'}
                            itemDescription={
                                abonnee.cumela === true ? t('Yes') : t('No')
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('cao Groen, Grond en Infrastructuur')}
                            itemDescription={
                                abonnee.caoLeo === true ? t('Yes') : t('No')
                            }
                        />

                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Comments')}
                        </div>
                        <Divider className={classes.divider} />
                        <br />
                        <textarea
                            id="comments"
                            rows="5"
                            cols="100"
                            value={this.state.comment ?? ""}
                            onChange={this.handleCommentsChange}
                            style={{ resize: 'none' }}
                        />
                        <br />
                        <Button
                             
                            onClick={this.saveComment}
                            style={{ marginLeft: '-15px' }}
                        >
                            {t('Save comment')}
                        </Button>
                    </div>

                    <Dialog
                        open={this.state.showEditDialog ?? false}
                        onClose={this.cancelEdit}
                        TransitionComponent={Transition}
                        className="victor"
                    >
                        <DialogTitle style={{ marginBottom: '-15px' }}>
                            {t('Change subscriber')}
                        </DialogTitle>
                        <AbonneeEditCard
                            hideParents={true}
                            abonnee={this.props.editabonnee}
                            onEdit={this.props.setEditAbonnee}
                            onSave={this.props.onSave}
                            goBack={this.props.goBack}
                        />
                    </Dialog>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(SubscriptionAbonnement);
