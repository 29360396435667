// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import {
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    Input,
    Tabs,
    Tab,
    MenuItem,
    Toolbar,
    Typography,
    AppBar,
    Button,
    IconButton,
    Paper,
    DialogTitle,
    DialogContentText,
    DialogActions,
    TextField,
    Menu,
    Switch,
    FormControlLabel,
    InputAdornment,
    Select,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Loader from '../Loader';
import InspectionContentEditor from '../InspectionContentEditor';
import InspectionPreview from '../InspectionPreview';
import {
    initInspectionDraft,
    editInspectionDraft,
    saveInspection,
} from '../../actions';
import { getContentBlockTypesForInspection, inProgress } from '../../selectors';
import DropdownButton from '../DropdownButton/DropdownButton';
import getRoles from '../../selectors/getRoles';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { getLanguages } from '../../lib/utils';
import classes from './InspectionEdit.module.scss';
import colors from "../../lib/themes/theme.scss";


type Props = {
    inspection: any,
    inspections: any,
    contentBlockTypes: Array<any>,
    loading: boolean,
    contentBlocks: any,
};

type State = {
    activeTab: number,
    changed: boolean,
    warningDialogOpen: boolean,
    dropdownAnchorElement: any,
    saveAsNewDialogOpen: boolean,
    newWerkplekinspectieName: string,
    newWerkplekinspectieNameError: ?string,
    submitting: boolean,
    askNewWerkplekinspectie: boolean,
};

const mapStateToProps = (store, props) => {
    const inspectionDraft = props.inspection
        ? store.drafts.inspections[props.inspection.id]
        : null;
    const inspections = store.entities.inspections;
    const contentBlocksDraft = props.inspection
        ? store.drafts.contentBlocks
        : null;

    return {
        contentBlockTypes: getContentBlockTypesForInspection(store),
        inspections: inspections.allIds.map((id) => inspections.byId[id]),
        inspectionDraft: inspectionDraft,
        loading: !inProgress(store),
        roles: getRoles(store),
        contentBlocks: contentBlocksDraft,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onChange: (key, value) => {
            dispatch(editInspectionDraft(props.inspection.id, key, value));
        },
        onSave: (inspectionDraft, showNewWerkplekinspectie) => {
            dispatch(saveInspection(inspectionDraft, showNewWerkplekinspectie));
        },
        onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);

            dispatch(push('/werkplekinspecties'));
        },
        initDraft: (inspection) => {
            dispatch(initInspectionDraft(inspection));
        },
    };
};


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class InspectionEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        activeTab: 0,
        changed: false,
        warningDialogOpen: false,
        dropdownAnchorElement: null,
        saveAsNewDialogOpen: false,
        newWerkplekinspectieName: '',
        newWerkplekinspectieNameError: null,
        submitting: false,
        askNewWerkplekinspectie: false,
        language: 'nl',
    };

    handleChangeLanguage = (event) => {
        this.props.onChange('language', event.target.value);
        this.setState({
            language: event.target.value,
            changed: true,
        });
    };

    handleChange = (key) => (event) => {
        if (key === 'title' && event.target.value.length > 105) {
            return;
        }

        if (event) {
            this.props.onChange(key, event.target.value);
        }
        this.setState({
            changed: true,
            submitting: false,
        });

        let enableSwitch = false;
        let blocks = this.props.contentBlocks;
        Object.keys(blocks).forEach((blockId) => {
            const block = blocks[blockId];
            if (
                block.title &&
                block.title !== '' &&
                block &&
                block.listBlock &&
                block.listBlock.items &&
                block.listBlock.items.length > 0
            ) {
                enableSwitch = true;
            }
        });
        this.setState({
            switchEnabled: enableSwitch,
        });
    };

    handleTabsChange = (event, value) => {
        this.setState({ activeTab: value });
    };

    handleClose = (event) => {
        if (this.state.changed) {
            this.setState({
                warningDialogOpen: true,
            });
        } else {
            this.props.onClose();
        }
    };

    handleSave = (event) => {
        const { t } = this.props;
        if (this.props.inspection.title !== this.props.inspectionDraft.title) {
            let unique = true;

            if (!this.props.loading) {
                this.props.inspections.forEach((inspection) => {
                    if (
                        inspection.title.toLowerCase() ===
                            this.props.inspectionDraft.title.toLowerCase() &&
                        inspection.title !== this.props.inspection.title
                    ) {
                        unique = false;
                        this.setState({
                            submitting: false,
                        });
                    }
                });

                if (unique === true) {
                    this.props.onSave(
                        this.props.inspectionDraft,
                        event != null
                    );
                } else {
                    alert(
                        t(
                            'The name may not be the same as the existing inspection'
                        )
                    );
                }
            }

            if (unique === true) {
                this.setState({
                    changed: false,
                    submitting: true,
                });
            }
        } else {
            if (!this.props.loading) {
                this.props.onSave(this.props.inspectionDraft, event != null);
            }
            this.setState({
                changed: false,
                submitting: true,
            });
        }

        this.requestAskNewWerkplekinspectieClose();
    };

    handleSavePublicChange = (event) => {
        if (!this.props.loading) {
            this.props.onSave(this.props.inspectionDraft, event != null);
        }
        this.setState({
            changed: false,
            submitting: true,
        });

        this.requestAskNewWerkplekinspectieClose();
    };

    checkSaveType = (event) => {
        if (this.props.inspection.title !== this.props.inspectionDraft.title) {
            this.setState({
                askNewWerkplekinspectie: true,
            });
        } else {
            this.handleSave();
        }
    };

    requestAskNewWerkplekinspectieClose = () => {
        this.setState({
            askNewWerkplekinspectie: false,
        });
    };

    requestWarningDialogClose = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    handleWarningDontSave = () => {
        this.setState({
            warningDialogOpen: false,
            changed: false,
        });
        this.props.onClose();
    };

    handleWarningSave = () => {
        this.setState({
            warningDialogOpen: false,
            changed: false,
            submitting: true,
        });
        this.handleSave();
        this.props.onClose();
    };

    requestSaveAsNewDialogClose = () => {
        this.setState({
            saveAsNewDialogOpen: false,
        });
    };

    handleDropdownClick = (event) => {
        this.setState({
            dropdownAnchorElement: event.currentTarget,
        });
    };

    requestCloseDropdown = () => {
        this.setState({
            dropdownAnchorElement: null,
        });
    };

    handleSaveAsNewStarted = (event) => {
        this.requestCloseDropdown();

        this.setState({
            saveAsNewDialogOpen: true,
            newWerkplekinspectieName: this.props.inspectionDraft.title,
        });
    };

    handlePublicChange = (event) => {
        // this.props.onChange('selfAssignable', event.target.checked);
        this.props.inspectionDraft.selfAssignable = event.target.checked;
        this.handleSavePublicChange();
    };

    handleSaveAsNewClick = (event) => {
        const { t } = this.props;
        let x;
        if (this.state.saveAsNewDialogOpen === false) {
            x = this.props.inspectionDraft.title;
            this.setState({
                newWerkplekinspectieName: this.props.inspectionDraft.title,
            });
        } else {
            x = this.state.newWerkplekinspectieName;
        }

        this.setState({
            submitting: true,
        });

        let unique = true;

        const isCumela = this.props.roles.includes('CUMELA');
        const isAdmin = this.props.roles.includes('ADMIN');
        const extendedCumelaInspection =
            !isCumela && this.props.inspectionDraft.source === 'cumela';
        const extendedAdminInspection =
            !isAdmin && this.props.inspectionDraft.source === 'global';

        this.props.inspections.forEach((inspection) => {
            //Wether to check in the Cumela collection or not
            //const rightCollection = isCumela ? inspection.source === 'cumela' : inspection.source !== 'cumela';

            if (inspection.title.toLowerCase() === x.toLowerCase()) {
                unique = false;
                this.setState({
                    submitting: false,
                    askNewWerkplekinspectie: false,
                });
            }
        });

        if (unique === false) {
            alert(
                t(
                    'The name may not be the same as the existing Workplace Inspection'
                )
            );
        }

        if (unique) {
            const newDraft = {};

            //The API automaticaly increases version by 1 so we send 0 to get version 1
            const newVersion = isCumela ? '0.-1' : '1.-1';

            Object.assign(newDraft, this.props.inspectionDraft, {
                id:
                    extendedCumelaInspection || extendedAdminInspection
                        ? this.props.inspectionDraft.id
                        : null,
                title: x || this.props.inspectionDraft.title,
                version: extendedCumelaInspection
                    ? this.props.inspectionDraft.version
                    : newVersion,
            });
            this.setState({
                changed: false,
                submiting: false,
            });
            this.props.onSave(newDraft, false);
            this.requestSaveAsNewDialogClose();

            //Use onClose instead of handleClose to skip warning popup
            //this.props.onClose();
        }
    };

    handleNewWerkplekinspectieNameChanged = (event) => {
        this.setState({
            newWerkplekinspectieNameError: null,
            newWerkplekinspectieName: event.target.value,
        });
    };

    componentWillMount() {
        if (this.props.inspection) {
            this.props.initDraft(this.props.inspection);
        }

        this.requestAskNewWerkplekinspectieClose();
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.inspection &&
            _.get(this, 'props.inspection.id', null) !==
                _.get(nextProps, 'inspection.id', null)
        ) {
            this.props.initDraft(nextProps.inspection);
            this.setState({
                activeTab: 0,
                submitting: false,
            });
        }

        let enableSwitch = false;
        let blocks = nextProps.contentBlocks;
        if (blocks) {
            let empty = false;
            Object.keys(blocks).forEach((blockId) => {
                const block = blocks[blockId];
                if (
                    block.title &&
                    block.title !== '' &&
                    block &&
                    block.listBlock &&
                    block.listBlock.items &&
                    block.listBlock.items.length > 0
                ) {
                    block.listBlock.items.forEach((block) => {
                        if (block.text.length < 1) empty = true;
                    });

                    if (!empty) {
                        enableSwitch = true;
                    }
                }
            });

            this.setState({
                switchEnabled: enableSwitch,
            });
        }

        this.requestAskNewWerkplekinspectieClose();
    }

    render() {
        const {
            contentBlockTypes,
            inspectionDraft,
            open,
            loading,
            t,
        } = this.props;
        const { warningDialogOpen, saveAsNewDialogOpen } = this.state;
        const languages = getLanguages(true);

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <Dialog
                    open={warningDialogOpen}
                    onClose={this.requestWarningDialogClose}
                >
                    <DialogTitle>{t('Save Changes')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('You have made changes to the workplace inspection. Do you want to save it?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.requestWarningDialogClose}>
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.handleWarningDontSave}>
                            {t('Dont save')}
                        </Button>
                        <Button onClick={this.handleWarningSave}
                            disabled={ this.state.submitting || (inspectionDraft && inspectionDraft.title.length === 0)}>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.askNewWerkplekinspectie}
                    onClose={this.requestAskNewWerkplekinspectieClose}
                >
                    <DialogTitle>{t('Save or overwrite?')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Do you want to overwrite or save the workplace inspection as a new workplace inspection?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.requestAskNewWerkplekinspectieClose}>
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.handleSave}
                            disabled={this.state.submitting || (inspectionDraft && inspectionDraft.title.length === 0)}
                        >
                            {t('Overwrite')}
                        </Button>
                        <Button
                             
                            onClick={this.handleSaveAsNewClick}
                            disabled={
                                this.state.submitting ||
                                (inspectionDraft &&
                                    inspectionDraft.title.length === 0)
                            }
                        >
                            {t('Save as new')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={saveAsNewDialogOpen}
                    onClose={this.requestSaveAsNewDialogClose}
                >
                    <DialogTitle>
                        {t('Save as new workplace inspection')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t(
                                'Enter a new name for the new workplace inspection.'
                            )}
                        </DialogContentText>
                        <TextField
                            fullWidth
                            error={!!this.state.newWerkplekinspectieNameError}
                            helperText={
                                this.state.newWerkplekinspectieNameError
                            }
                            onChange={
                                this.handleNewWerkplekinspectieNameChanged
                            }
                            defaultValue={
                                inspectionDraft ? inspectionDraft.title : ''
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                             
                            onClick={this.requestSaveAsNewDialogClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                             
                            onClick={this.handleSaveAsNewClick}
                            disabled={
                                this.state.submitting ||
                                this.state.newWerkplekinspectieName.length === 0
                            }
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppBar
                     
                    className={classes.appBar}
                    style={{ boxShadow: 'none' }}
                >
                    {inspectionDraft && (
                        <Toolbar disableGutters>
                            <IconButton
                                className={classes.appBarButton}
                                color="inherit"
                                onClick={this.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                type="title"
                                color="inherit"
                                className={classes.flex}
                            >
                                {inspectionDraft.title}
                            </Typography>
                            {(inspectionDraft.source === 'cumela' &&
                                !this.props.roles.includes('CUMELA')) ||
                            (inspectionDraft.source === 'global' &&
                                !this.props.roles.includes('ADMIN')) ? (
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSaveAsNewStarted}
                                >
                                    {t('Save as new workplace inspection')}
                                </Button>
                            ) : (
                                <DropdownButton
                                    
                                    variant="contained"
                                    color="primary"
                                    onClick={this.checkSaveType}
                                    onDropdownClick={this.handleDropdownClick}
                                    disabled={
                                        this.state.submitting ||
                                        inspectionDraft.title.length === 0
                                    }
                                >
                                    {t('Save')}
                                </DropdownButton>
                            )}
                        </Toolbar>
                    )}
                </AppBar>

                <Menu
                    anchorEl={this.state.dropdownAnchorElement}
                    open={Boolean(this.state.dropdownAnchorElement)}
                    onClose={this.requestCloseDropdown}
                >
                    <MenuItem
                        key="saveas"
                        onClick={this.handleSaveAsNewStarted}
                    >
                        {t('Save as new workplace inspection')}
                    </MenuItem>
                </Menu>

                {inspectionDraft && (
                    <DialogContent className={classes.content}>
                        <Loader loading={loading}>
                            <div className={classes.inspectionInfo}>
                                <div className={classes.inspectionInfoContent}>
                                    <div className={classes.inspectionInfoIcon}>
                                        {inspectionDraft.source === 'cumela' ? (
                                            <img
                                                height="50"
                                                width="50"
                                                src="/assets/images/cumela-logo.png"
                                                alt={t('Cumela logo')}
                                            />
                                        ) : inspectionDraft.source ===
                                          'global' ? (
                                            <img
                                                height="50"
                                                width="50"
                                                src="/assets/images/vvw-admin-logo.svg"
                                                alt=""
                                            />
                                        ) : inspectionDraft.rootInspectionSource ===
                                              'global' ||
                                          inspectionDraft.rootInspectionSource ===
                                              'cumela' ? (
                                            <img
                                                height="20"
                                                width="20"
                                                src="/assets/images/aangepast.svg"
                                                alt={t('Custom logo')}
                                            />
                                        ) : (
                                            <img
                                                height="20"
                                                width="20"
                                                src="/assets/images/vvw-logo.svg"
                                                alt={t('Own logo')}
                                            />
                                        )}
                                        <div>
                                            {t('Version')}{' '}
                                            {inspectionDraft.version}
                                        </div>
                                    </div>
                                    <form
                                        className={classes.inspectionInfoForm}
                                    >
                                        <FormControl>
                                            <InputLabel htmlFor="inspection-titel">
                                                {t('Title')}
                                            </InputLabel>
                                            <Input
                                                fullWidth={true}
                                                
                                                id="inspection-title"
                                                error={
                                                    inspectionDraft.title
                                                        .length === 0
                                                }
                                                placeholder={
                                                    inspectionDraft.title
                                                        .length === 0
                                                        ? t(
                                                              'Title cant be empty'
                                                          )
                                                        : ''
                                                }
                                                required
                                                value={inspectionDraft.title}
                                                onChange={this.handleChange(
                                                    'title'
                                                )}
                                                endAdornment={
                                                    <InputAdornment
                                                        position="end"
                                                        style={{
                                                            fontSize: '14px',
                                                            opacity: '0.2',
                                                        }}
                                                    >
                                                        {105 -
                                                            inspectionDraft
                                                                .title.length}
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {languages.length > 1 && (
                                            <FormControl>
                                            <InputLabel id="labelLanguage">
                                                {t('Language')}
                                            </InputLabel>
                                            <Select
                                                labelId='labelLanguage'
                                                id="language-single-select"
                                                value={this.state.language}
                                                onChange={this.handleChangeLanguage}
                                                fullWidth
                                                label="Age"
                                            >
                                                {languages.map((lang) => (
                                                        <MenuItem value={lang}>{t(`translated-${lang}`)}</MenuItem>
                                                    ))}
                                                </Select>    
                                            </FormControl>
                                        )}
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={inspectionDraft.selfAssignable}
                                                    disabled={!this.state.switchEnabled || inspectionDraft.inspection.blocks.length < 1}
                                                    onChange={this.handlePublicChange}
                                                    id="ispublic"
                                                />
                                            }
                                            label={t('This workplace inspection is available to everyone')}
                                        />
                                    </form>
                                </div>
                            </div>

                            <Paper elevation={4} className={classes.editors}>
                                <AppBar
                                    position="static"
                                     
                                    style={{ boxShadow: 'none' }}
                                >
                                    <Tabs
                                        value={this.state.activeTab}
                                        onChange={this.handleTabsChange}
                                        className={classes.appBar}
                                        sx={{'& .Mui-selected': { color: colors.primaryColor } }}
                                    >
                                        <Tab label={t('Workplace inspection content')} sx={{color: colors.whiteTextColor }}/>
                                        <Tab label={t('Example')} sx={{color: colors.whiteTextColor }}/>
                                    </Tabs>
                                </AppBar>
                                <div>
                                    {this.state.activeTab === 0 && (
                                        <InspectionContentEditor
                                            style={{
                                                display:
                                                    this.state.activeTab === 0
                                                        ? 'flex'
                                                        : 'none',
                                            }}
                                            inspection={inspectionDraft}
                                            contentBlockTypes={
                                                contentBlockTypes
                                            }
                                            onChange={this.handleChange()}
                                        />
                                    )}
                                    {this.state.activeTab === 1 && (
                                        <InspectionPreview
                                            style={{
                                                display:
                                                    this.state.activeTab === 1
                                                        ? 'flex'
                                                        : 'none',
                                            }}
                                            inspection={inspectionDraft}
                                            contentBlockTypes={
                                                contentBlockTypes
                                            }
                                            onChange={this.handleChange()}
                                        />
                                    )}
                                </div>
                            </Paper>
                        </Loader>
                    </DialogContent>
                )}
            </Dialog>
        );
    }
}

/*

            */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(InspectionEdit);
