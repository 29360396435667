import { createSelector } from 'reselect'

const getCategoryEntities = store => store.entities.categories;

const getCategories = createSelector(
    [getCategoryEntities],
    (categoryEntities) => {
        return categoryEntities.allIds.map(id => categoryEntities.byId[id]);
    }
)

export default getCategories;

