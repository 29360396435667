// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { Dialog, DialogTitle } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { List } from 'immutable';
import './ProjectImportDialog.css';

import ProjectEditCard from '../Project/ProjectEditCard';
import UploadDropdownDialog from '../Util/UploadDropdownDialog';
import type { TProject } from '../../reducers/projectsReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    handleClose: () => mixed,
    diff: { removed: List<TProject>, added: List<TProject> },
    mapToDiffRow: (list: List<mixed>) => mixed,
    editproject: TProject,
    onUpload: (file: any) => mixed,
    acknowledgeImport: (file: any) => mixed,
    inProgress: boolean,
    mapToDiffRow: Function,
    editProjectFunction: (project?: TProject) => mixed,
    onSave: (project?: TProject) => mixed,
};

type State = {
    tab: number,
};

class ProjectImportDialog extends React.PureComponent<Props, State> {
    state: State = {
        tab: 0,
    };

    handleTabChange = (event: mixed, value: number) => {
        this.setState({ tab: value });
    };

    handleChangeIndex = (index: number) => {
        this.setState({ tab: index });
    };

    componentDidMount() {
        this.processProps(this.props);
    }

    componentWillReceiveProps(newProps: Props) {
        this.processProps(newProps);
    }

    cleanEditProject = (props: Props) => {
        props.editProjectFunction({
            omschrijving: '',
            opdrachtgever: '',
            importType: 'MANUAL',
        });
    };

    processProps = (props: Props) => {
        if (props.open && !props.editproject) {
            this.cleanEditProject(props);
        }
    };

    render() {
        const { t } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogTitle className="title">
                    {t('Add projects / work area')}
                </DialogTitle>
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleTabChange}
                    className="tab-bar"
                >
                    <Tab label={t('Manual')} />
                    <Tab label={t('Import')} />
                </Tabs>
                <SwipeableViews
                    axis={'x'}
                    index={this.state.tab}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <ProjectEditCard
                        project={this.props.editproject}
                        onEdit={this.props.editProjectFunction}
                        onSave={this.props.onSave}
                        goBack={this.props.handleClose}
                    />
                    <UploadDropdownDialog
                        onUpload={this.props.onUpload}
                        diff={this.props.diff}
                        acknowledgeImport={this.props.acknowledgeImport}
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.props.mapToDiffRow}
                        handleClose={this.props.handleClose}
                        uploadType={'project'}
                        projects={this.props.projects}
                    />
                </SwipeableViews>
            </Dialog>
        );
    }
}

export default compose(withTranslation())(ProjectImportDialog);
