import { CREATE_INSPECTION_DRAFT, MOVE_INSPECTION_DRAFT_CONTENT_BLOCK, DELETE_INSPECTION_DRAFT_CONTENT_BLOCK, INSERT_INSPECTION_DRAFT_CONTENT_BLOCK, EDIT_INSPECTION_DRAFT } from '../actions';

const initialState = {};

export default function inspectionDraftsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE_INSPECTION_DRAFT: {
            const newDraft = { title: '', ...action.payload.data };
            newDraft.title = newDraft.title || '';
            return { ...state, [action.payload.data.id]: newDraft };
        } case MOVE_INSPECTION_DRAFT_CONTENT_BLOCK: {
            const { id, fromIndex, toIndex } = action.payload;
            const inspection = state[id];
            const newBlocks = [...inspection.inspection.blocks];
            newBlocks.splice(toIndex, 0, newBlocks.splice(fromIndex, 1)[0]);
            return { ...state, [id]: { ...state[id], inspection: {...state[id].inspection, blocks: newBlocks } } };
        } case DELETE_INSPECTION_DRAFT_CONTENT_BLOCK: {
            const { id, contentBlockId } = action.payload;
            const inspection = state[id];
            const newBlocks = [...inspection.inspection.blocks];
            newBlocks.splice(newBlocks.findIndex(b => b === contentBlockId), 1);
            return { ...state, [id]: { ...state[id], inspection: {...state[id].inspection, blocks: newBlocks } } };
        } case INSERT_INSPECTION_DRAFT_CONTENT_BLOCK: {
            const { id, contentBlockId, index } = action.payload;
            const inspection = state[id];
            const newBlocks= [...inspection.inspection.blocks];
            newBlocks.splice(index, 0, contentBlockId);
            return { ...state, [id]: {...state[id], inspection: {...state[id].inspection, blocks: newBlocks } } };
        } case EDIT_INSPECTION_DRAFT: {
            const { id, key, value } = action.payload;
            return { ...state, [id]: {...state[id], [key]: value }};
        } default:
            return state;
    }
}
