/**
 * Created by MarkBuikema on 6/29/2017.
 */
import { apiStatisticsCall } from '../lib/api';
import type { StoreState } from '../reducers';
import { List } from 'immutable';
import type { Dispatch } from 'redux';
import type { TStateStatisticsChartData } from '../reducers/StatisticsReducer';
import moment from 'moment';

type TSALoadInspectionDataStart = {
    type: 'LOAD_INSPECTION_DATA_START',
    timestamp: number
};

type TSALoadChartDataSuccess = {
    type: 'LOAD_CHART_DATA_SUCCESS',
    chartData: TStateStatisticsChartData,
    timestamp: number
};

type TSALoadChartDataError = {
    type: 'LOAD_CHART_DATA_ERROR',
    timestamp: number
};

type TSALoadListDataSuccess = {
    type: 'LOAD_STATISTICS_LIST_DATA_SUCCESS',
    timestamp: number
};

type TSALoadListDataError = {
    type: 'LOAD_STATISTICS_LIST_DATA_ERROR',
    timestamp: number
};

type TSALoadInspectionDataSuccess = {
    type: 'LOAD_INSPECTION_DATA_SUCCESS',
    timestamp: number
};

export type TStatisticsAction = TSALoadInspectionDataStart | TSALoadChartDataSuccess | TSALoadChartDataError | TSALoadListDataSuccess | TSALoadListDataError | TSALoadInspectionDataSuccess;

export function loadInspectionData(periodType: string, period: string, periodTill: string, inspectionId: string, project: String, uitvoerder: number, afgerond: number, resultaat: number, situatie: number) {
    return (dispatch: Dispatch<StoreState, TStatisticsAction>) => {
        let timestamp = moment().valueOf();

        dispatch({
            type: 'LOAD_STATISTICS_DATA_START',
            timestamp: timestamp
        });

        let callsFinished: number = 0;
        const totalCalls: number = 2;

        apiStatisticsCall('chartData', periodType, period, periodTill, false,false, 0, null, afgerond, project, uitvoerder, resultaat, situatie, inspectionId, 0, dispatch, (err, res) => {
            if (err) {
                dispatch({
                    type: 'LOAD_CHART_DATA_ERROR',
                    timestamp: timestamp
                });
            } else {
                if (res.body) {
                    dispatch({
                        type: 'LOAD_CHART_DATA_SUCCESS',
                        chartData: Object.assign(res.body, {
                            therapyBarData: List(res.body.therapyBarData),
                            top10ProgramsBarData: List(res.body.top10ProgramsBarData),
                            durationBarData: List(res.body.durationBarData)
                        }),
                        timestamp: timestamp
                    });
                }

                if (++callsFinished === totalCalls) {
                    dispatch({
                        type: 'LOAD_INSPECTION_DATA_SUCCESS',
                        timestamp: timestamp
                    });
                }
            }
        });

        // apiStatisticsCall(
        //     'listData',
        //     distributorScope,
        //     customerScope,
        //     locationScope,
        //     deviceScope,
        //     periodType,
        //     period,
        //     distributorFilter,
        //     customerFilter,
        //     locationFilter,
        //     deviceFilter,
        //     therapyTypes,
        //     programIds,
        //     minDuration,
        //     maxDuration,
        //     dispatch,
        //     (err, res) => {
        //         if (err) {
        //             dispatch({
        //                 type: 'LOAD_STATISTICS_LIST_DATA_ERROR',
        //                 timestamp: timestamp
        //             });
        //         } else {
        //             if (res.body) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_LIST_DATA_SUCCESS',
        //                     listData: List(res.body),
        //                     timestamp: timestamp
        //                 });
        //             }

        //             if (++callsFinished === totalCalls) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_DATA_SUCCESS',
        //                     timestamp: timestamp
        //                 });
        //             }
        //         }
        //     }
        // );
    };
}

export function loadToolboxData(periodType: string, period: string, periodTill: string, toolboxId: string, isToolboxMeeting:boolean, isNoToolboxMeeting:boolean, sector: number, category: String, afgerond: number) {
    return (dispatch: Dispatch<StoreState, TStatisticsAction>) => {
        let timestamp = moment().valueOf();

        dispatch({
            type: 'LOAD_STATISTICS_DATA_START',
            timestamp: timestamp
        });

        let callsFinished: number = 0;
        const totalCalls: number = 2;

        isToolboxMeeting=isToolboxMeeting ? isToolboxMeeting : false;
        isNoToolboxMeeting=isNoToolboxMeeting ? isNoToolboxMeeting : false;

        apiStatisticsCall('chartData', periodType, period, periodTill, isToolboxMeeting, isNoToolboxMeeting, sector, category, afgerond, 0, 0, 0, 0, toolboxId,0, dispatch, (err, res) => {
            if (err) {
                dispatch({
                    type: 'LOAD_CHART_DATA_ERROR',
                    timestamp: timestamp
                });
            } else {
                if (res.body) {
                    dispatch({
                        type: 'LOAD_CHART_DATA_SUCCESS',
                        chartData: Object.assign(res.body, {
                            therapyBarData: List(res.body.therapyBarData),
                            top10ProgramsBarData: List(res.body.top10ProgramsBarData),
                            durationBarData: List(res.body.durationBarData)
                        }),
                        timestamp: timestamp
                    });
                }

                if (++callsFinished === totalCalls) {
                    dispatch({
                        type: 'LOAD_TOOLBOX_DATA_SUCCESS',
                        timestamp: timestamp
                    });
                }
            }
        });

        // apiStatisticsCall(
        //     'listData',
        //     distributorScope,
        //     customerScope,
        //     locationScope,
        //     deviceScope,
        //     periodType,
        //     period,
        //     distributorFilter,
        //     customerFilter,
        //     locationFilter,
        //     deviceFilter,
        //     therapyTypes,
        //     programIds,
        //     minDuration,
        //     maxDuration,
        //     dispatch,
        //     (err, res) => {
        //         if (err) {
        //             dispatch({
        //                 type: 'LOAD_STATISTICS_LIST_DATA_ERROR',
        //                 timestamp: timestamp
        //             });
        //         } else {
        //             if (res.body) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_LIST_DATA_SUCCESS',
        //                     listData: List(res.body),
        //                     timestamp: timestamp
        //                 });
        //             }

        //             if (++callsFinished === totalCalls) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_DATA_SUCCESS',
        //                     timestamp: timestamp
        //                 });
        //             }
        //         }
        //     }
        // );
    };
}

export function loadEmployeeData(periodType: string, period: string, periodTill: string, toolboxId: string, groupFilter : number) {
    return (dispatch: Dispatch<StoreState, TStatisticsAction>) => {
        let timestamp = moment().valueOf();

        dispatch({
            type: 'LOAD_STATISTICS_DATA_START',
            timestamp: timestamp
        });

        let callsFinished: number = 0;
        const totalCalls: number = 2;

        apiStatisticsCall('chartInfo', periodType, period, periodTill, false,false, 0, null, 0, 0, 0, 0, 0, toolboxId, groupFilter, dispatch, (err, res) => {
            if (err) {
                dispatch({
                    type: 'LOAD_CHART_DATA_ERROR',
                    timestamp: timestamp
                });
            } else {
                if (res.body) {
                    dispatch({
                        type: 'LOAD_EMPLOYEE_CHART_DATA_SUCCESS',
                        employeeChartData: res.body,
                        timestamp: timestamp
                    });
                }

                if (++callsFinished === totalCalls) {
                    dispatch({
                        type: 'LOAD_TOOLBOX_DATA_SUCCESS',
                        timestamp: timestamp
                    });
                }
            }
        });

        // apiStatisticsCall(
        //     'listData',
        //     distributorScope,
        //     customerScope,
        //     locationScope,
        //     deviceScope,
        //     periodType,
        //     period,
        //     distributorFilter,
        //     customerFilter,
        //     locationFilter,
        //     deviceFilter,
        //     therapyTypes,
        //     programIds,
        //     minDuration,
        //     maxDuration,
        //     dispatch,
        //     (err, res) => {
        //         if (err) {
        //             dispatch({
        //                 type: 'LOAD_STATISTICS_LIST_DATA_ERROR',
        //                 timestamp: timestamp
        //             });
        //         } else {
        //             if (res.body) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_LIST_DATA_SUCCESS',
        //                     listData: List(res.body),
        //                     timestamp: timestamp
        //                 });
        //             }

        //             if (++callsFinished === totalCalls) {
        //                 dispatch({
        //                     type: 'LOAD_STATISTICS_DATA_SUCCESS',
        //                     timestamp: timestamp
        //                 });
        //             }
        //         }
        //     }
        // );
    };
}
