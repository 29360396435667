import moment from "../../lib/moment";
import i18n from "i18next";
moment.locale(i18n.t("LOCALE"));

export const bytesToMB = (bytes) => {
    if(!bytes) return 0;
    return (bytes / (1024 * 1024)).toFixed(3);
}

export const sorter = () => {
	return {
		deployed: (a, b) => {
			a = a.date;
			b = b.date;

			if (a == null) {
				return -1;
			}
			if (b == null) {
				return 1;
			}

			a = moment(a).valueOf();
			b = moment(b).valueOf();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		title: (a, b) => {
			a = a.remark.text.toLowerCase();
			b = b.remark.text.toLowerCase();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		from: (a, b) => {
			a = a.user.fullname.toLowerCase();
			b = b.user.fullname.toLowerCase();

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
		attachments: (a, b) => {
			a = a.remark.photos.length;
			b = b.remark.photos.length;

			if (a > b) {
				return 1;
			} else if (a < b) {
				return -1;
			} else {
				return 0;
			}
		},
	};
};