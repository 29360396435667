import uuidv4 from 'uuid/v4';
import { createContentBlockDraft, deleteContentBlockDraft } from './contentBlockDraftActions';
import { initQuestionBlockDraft, deleteQuestionBlockDraft } from './questionBlockDraftActions';
import {isUndefinedOrNull} from "../lib/utils";
export const CREATE_TOOLBOX_DRAFT = 'CREATE_TOOLBOX_DRAFT';
export const MOVE_TOOLBOX_DRAFT_CONTENT_BLOCK = 'MOVE_TOOLBOX_DRAFT_CONTENT_BLOCK';
export const DELETE_TOOLBOX_DRAFT_CONTENT_BLOCK = 'DELETE_TOOLBOX_DRAFT_CONTENT_BLOCK';
export const INSERT_TOOLBOX_DRAFT_CONTENT_BLOCK = 'INSERT_TOOLBOX_DRAFT_CONTENT_BLOCK';
export const INSERT_TOOLBOX_DRAFT_QUESTION_BLOCK = 'INSERT_TOOLBOX_DRAFT_QUESTION_BLOCK';
export const DELETE_TOOLBOX_DRAFT_QUESTION_BLOCK = 'DELETE_TOOLBOX_DRAFT_QUESTION_BLOCK';
export const MOVE_TOOLBOX_DRAFT_QUESTION_BLOCK = 'MOVE_TOOLBOX_DRAFT_QUESTION_BLOCK';
export const EDIT_TOOLBOX_DRAFT = 'EDIT_TOOLBOX_DRAFT';

export function initToolboxDraft(data) {
    console.log("data", data)
    const draftData = {
        ...data,
        toolbox: {
            ...data.toolbox,
            blocks: data.toolbox.blocks.filter(block => !isUndefinedOrNull(block)).map(block => block.id),
            questions: data.toolbox.questions.filter(question => !isUndefinedOrNull(question)).map(question => question.id)
        }
    };

    return (dispatch, getState) => {
        data.toolbox.blocks.forEach(block => {
            if (typeof block === "object") {
                const draftBlock = {title: '', subtitle: '', ...block};
                block.highlight = block.highlighted;
                if (draftBlock.type === 'photosBlock') {
                    if (!draftBlock.photosBlock) {
                        draftBlock.photosBlock = {photos: []};
                    }
                }

                dispatch(createContentBlockDraft(draftBlock));
            }
        });
        data.toolbox.questions.forEach(question => {
            if (typeof question === "object") {
                dispatch(initQuestionBlockDraft({type: 'singleResponse', ...question}));
            }
        });
        dispatch(createToolboxDraft(draftData));
    };
}

export function insertToolboxDraftQuestionBlockType(id, data, index) {
    const questionBlockDraft = { id: uuidv4(), ...data.initialData };
    const action = {
        type: INSERT_TOOLBOX_DRAFT_QUESTION_BLOCK,
        payload: { id, questionBlockId: questionBlockDraft.id, index }
    };

    return (dispatch, getState) => {
        dispatch(initQuestionBlockDraft(questionBlockDraft));
        dispatch(action);
    };
}

export function deleteToolboxDraftQuestionBlock(id, questionBlockId) {
    const action = {
        type: DELETE_TOOLBOX_DRAFT_QUESTION_BLOCK,
        payload: { id, questionBlockId }
    };

    return (dispatch, getState) => {
        dispatch(action);
        dispatch(deleteQuestionBlockDraft(questionBlockId));
    };
}

export function insertToolboxDraftContentBlockType(id, data, index) {
    const contentBlockDraft = { id: uuidv4(), title: '', subtitle: '', ...data.initialData };
    const action = {
        type: INSERT_TOOLBOX_DRAFT_CONTENT_BLOCK,
        payload: { id, contentBlockId: contentBlockDraft.id, index }
    };

    return (dispatch, getState) => {
        dispatch(createContentBlockDraft(contentBlockDraft));
        dispatch(action);
    };
}

export function deleteToolboxDraftContentBlock(id, contentBlockId) {
    const action = {
        type: DELETE_TOOLBOX_DRAFT_CONTENT_BLOCK,
        payload: { id, contentBlockId }
    };

    return (dispatch, getState) => {
        dispatch(action);
        dispatch(deleteContentBlockDraft(contentBlockId));
    };
}

export function editToolboxDraft(id, key, value) {
    return {
        type: EDIT_TOOLBOX_DRAFT,
        payload: { id, key, value }
    };
}

export function createToolboxDraft(data) {
    return {
        type: CREATE_TOOLBOX_DRAFT,
        payload: { data }
    };
}

export function moveToolboxDraftContentBlock(id, fromIndex, toIndex) {
    return {
        type: MOVE_TOOLBOX_DRAFT_CONTENT_BLOCK,
        payload: { id, fromIndex, toIndex }
    };
}

export function moveToolboxDraftQuestionBlock(id, fromIndex, toIndex) {
    return {
        type: MOVE_TOOLBOX_DRAFT_QUESTION_BLOCK,
        payload: { id, fromIndex, toIndex }
    };
}
