import { combineReducers } from 'redux';
import authReducer from './authReducer';
import entitiesReducer from './entitiesReducer';
import uiReducer from './uiReducer';
import apiReducer from './apiReducer';
import draftsReducer from './draftsReducer';
import dashboardReducer from './dashboardReducer';
import subscriptionReducer from './subscriptionReducer';
import filterreducer from './filterReducer';
import statisticsreducer from './statisticsReducer';
import externalReducer from './externalReducer';
import { connectRouter } from 'connected-react-router';

const createRootReducer = (history) => combineReducers( {
    api: apiReducer,
    auth: authReducer,
    router: connectRouter(history),
    entities: entitiesReducer,
    drafts: draftsReducer,
    dashboard: dashboardReducer,
    subscription: subscriptionReducer,
    statistics: statisticsreducer,
    ui: uiReducer,
    filters: filterreducer,
    external: externalReducer
});

export default createRootReducer;
