import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const mapStateToProps = (store, props) => {
    return {
        authenticated: store.auth.token !== null
    };
};

const PrivateRoute = ({ component, authenticated, ...otherProps }) => {
    if (authenticated) {
        return (<Route { ...otherProps } component={ component } />);
    }

    let loginParams = otherProps.location.pathname ? (otherProps.location.search && otherProps.location.search.includes("upgrade")) ? '?redirect=' + otherProps.location.pathname + otherProps.location.search : '?redirect=' + otherProps.location.pathname : '';

    if (otherProps.location.pathname.includes("/web/")){
        return (
            <Redirect to={ '/web/login' + loginParams } />
        );
    }

    return (
        <Redirect to={ '/auth/login' + loginParams } />
    );
}

export default connect(mapStateToProps)(PrivateRoute);
