// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {IconButton, Input, InputAdornment, FormControl, MenuItem, ListItemText, Select} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import './ListBlockItem.css';

type Props = {
    classes: any,
    item: any,
    onChange: Function,
    onDelete: Function,
    connectDragPreview: Function,
    connectDragSource: Function,
};

type State = {};


class ListBlockItem extends React.Component {
    props: Props;
    state: State = {
        value: this.props.item.text,
    };
    questionType = 'check'; // Default questionType is check item

    timer = null;

    // Return the questionType during render()
    getQuestionType = (item) => {
        if (item.openQuestion === true) {
            return 'open';
        } else if (item.photoQuestion === true) {
            return 'photo';
        }
        return 'check';
    }

    handleFormChange = (event) => {
        event.persist();
        const value = event.target.value;

        this.setState({ value });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) this.props.onChange(event);
        }, 400);
    };

    handleQuestionType = (key) => (event) => {
        this.questionType = event.target.value;

        this.props.onQuestionType(event);

    }


    getInputId(path) {
        return this.props.item.id + '-listBlockitem';
    }

    render() {
        const {
            item,
            onDelete,
            connectDragPreview,
            connectDragSource,
            t,
        } = this.props;

        return (
            <div >
                {connectDragPreview(
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center', }}>
                        {connectDragSource(
                            <div style={{
                                marginLeft: '8px',
                                marginRight: '8px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: 80,
                            }} >
                                <DragHandleIcon  />
                            </div>
                        )}
                        <Input
                            fullWidth={true}
                            id={'input-' + item.id}
                            
                            multiline
                            maxRows="4"
                            onChange={this.handleFormChange}
                            label={t('List part text')}
                            value={this.state.value}
                            endAdornment={
                                <InputAdornment
                                    position="end"
                                    style={{ fontSize: '14px', opacity: '0.2' }}
                                >
                                    {500 - item.text.length}
                                </InputAdornment>
                            }
                        />

                        <div style={{ display: 'block'}}>
                            <FormControl

                                style={{ width: '120px', marginLeft: '16px' }}
                            >
                                {/*<InputLabel>{t('Question Type')}</InputLabel>*/}
                                <Select
                                    variant="standard"
                                    label={t('Question Type')}
                                    value={this.getQuestionType(item)}
                                    onChange={this.handleQuestionType('type')}
                                >
                                    <MenuItem key="check" value="check">
                                        <ListItemText primary={t('Check Item')}/>
                                    </MenuItem>
                                    <MenuItem key="open" value="open">
                                        <ListItemText primary={t('Open Question')}/>
                                    </MenuItem>
                                    <MenuItem key="photo" value="photo">
                                        <ListItemText primary={t('Photo Request')}/>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <IconButton onClick={onDelete} size="large">
                            <DeleteIcon />
                        </IconButton>

                    </div>
                )}
            </div>
        );
    }
}

export default compose(withTranslation())(ListBlockItem);
