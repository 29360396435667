import React from 'react';
import ReactBodymovin from 'react-bodymovin';
import animationData from './loaderAnimationData.json';

const Spinner = () => {
    const bodymovinOptions = {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: animationData
    };

    return <ReactBodymovin options={bodymovinOptions} />
}

export default Spinner