import { createSelector } from 'reselect';
import { List } from 'immutable';
import moment from 'moment';
import i18n from 'i18next';
moment.locale(i18n.t('LOCALE'));


const getToolboxAssigns = store => store.entities.toolboxAssigns;

const getScheduledAssignedToolboxes = createSelector([getToolboxAssigns], allAssigns => {
    let toolboxAssigns = [];
    let toolboxAssignList = List();
    if (allAssigns && allAssigns.allIds) {
        toolboxAssigns = allAssigns.allIds.map(id => allAssigns.byId[id]);

        // Scheduled Asigned Toolboxes are grouped by scheduled date
        const groups = groupBy(toolboxAssigns, function(item) {
            const deployed = moment(item.scheduled).format('L HH:mm');
            return [deployed, item.toolboxName, item.version];
        });

        groups.forEach(group => {
            const toolboxAssignRow = Object.assign({}, group[0], { employees: 0, finished: 0, success: 0 });

            group.forEach(item => {
                toolboxAssignRow.employees = toolboxAssignRow.employees + 1;

                if (item.result) {
                    toolboxAssignRow.finished = toolboxAssignRow.finished + 1;

                    //user has finished more dan 60% of the questions
                    if (item.result.answers) {
                        const correct = item.result.answers.reduce((total, answer) => total + (answer.correct ? 1 : 0), 0);

                        if (correct / item.result.answers.length >= 0.6) {
                            toolboxAssignRow.success = toolboxAssignRow.success + 1;
                        }
                    }
                }

                if (!toolboxAssignRow.assignList) {
                    toolboxAssignRow.assignList = [];
                }

                if (!toolboxAssignRow.employeeList) {
                    toolboxAssignRow.employeeList = [];
                }


                toolboxAssignRow.employeeList.push(item.user.fullname);
                toolboxAssignRow.assignList.push(item);
            });
            toolboxAssignList = toolboxAssignList.push(toolboxAssignRow);
        });

        // toolboxAssigns.forEach(function(toolboxAssign) {

        //     if()
        //     toolboxAssignList = toolboxAssignList.push({
        //         deployed: toolboxAssign.deployed,
        //         version: toolboxAssign.version,
        //         toolboxName: toolboxAssign.toolboxName
        //     })

        // });
    }

    return toolboxAssignList.toJS();
});

function groupBy(array, f) {
    var groups = {};
    array.forEach(function(o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function(group) {
        return groups[group];
    });
}

export default getScheduledAssignedToolboxes;
