import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from './history';

const middlewares = [
    thunk,
    createDebounce(),
    routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ predicate: process.env.NODE_ENV === `development` }));
}

export default middlewares;
