// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Fab} from '@mui/material';
import AppModule from '../../AppModule';
import {
    fetchIncidentTypes,
    getAppIncidents,
    setCrumbPath,
} from '../../../actions';
import { getPermissions, inProgress } from '../../../selectors';
import { push } from 'connected-react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classes from './Incident.module.scss';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import classnames from 'classnames';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

type Props = {
    setCrumbPath: () => void,
    permissions: any,
    classes: any,
    loading: boolean,
    getAppIncidents: () => any,
};

type State = {};


const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        incidents: store.entities.incidents,
        incidenttype: store.entities.incidenttype,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: (title = i18n.t('Report registration')) => {
            dispatch(setCrumbPath({ title: title }));
        },
        getAppIncidents: () => {
            dispatch(getAppIncidents());
        },
        fetchIncidentTypes: () => {
            dispatch(fetchIncidentTypes());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class OpdrachtenCompleted extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentWillMount() {
        this.props.setCrumbPath();
        this.props.getAppIncidents();
        this.props.fetchIncidentTypes();
    }

    gotoIncidentResult = (id) => {
        this.props.goTo('incident/create/' + id);
    };

    createIncident = () => {
        this.props.goTo('incident/create');
    };

    componentDidMount() {}

    componentDidUpdate() {
        this.props.setCrumbPath();
    }

    render() {
        const { loading, incidents, t } = this.props;

        // window.$crisp.push(['do', 'chat:hide']);

        let sortedIncidents = [];
        if (incidents && sortedIncidents.length === 0) {
            incidents.allIds.map(
                (id) =>
                    incidents.byId[id] !== null &&
                    sortedIncidents.push(incidents.byId[id])
            );

            sortedIncidents.sort((a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        const tableActionHeader = <TableActionHeader />;
        return (
            <div>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <div
                        className={classnames({
                            [classes.button]: true,
                            [classes.muiFixed]: true,
                        })}
                    >
                        <Fab
                            onClick={this.createIncident}
                        >
                            <AddIcon />
                        </Fab>
                    </div>
                    <div className={classes.content}>
                        {sortedIncidents.length > 0 ? (
                            sortedIncidents.map((row, index) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '8px',
                                            border: '2px solid #ECECEC',
                                            width: '610px',
                                            height: '92px',
                                            margin: 'auto',
                                            marginBottom: '16px',
                                            cursor: 'pointer',
                                        }}
                                        id="toolboxBlocks"
                                        onClick={() =>
                                            this.gotoIncidentResult(row.id)
                                        }
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                height: '100%',
                                                backgroundImage:
                                                    'url(/assets/images/cat_werkplekinspectie.png)',
                                                backgroundRepeat: 'no-repeat',
                                                float: 'left',
                                                backgroundPosition: 'center',
                                                backgroundSize: '56px 56px',
                                            }}
                                        />
                                        <ArrowForwardIcon
                                            style={{
                                                float: 'right',
                                                marginRight: '15px',
                                                marginTop: '15px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                float: 'left',
                                                width: '150px',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: '450px',
                                                }}
                                                id="toolboxText"
                                            >
                                                <b>
                                                    {this.props.incidenttype &&
                                                    this.props.incidenttype
                                                        .byId &&
                                                    row.type
                                                        ? t(
                                                              this.props
                                                                  .incidenttype
                                                                  .byId[
                                                                  row.type
                                                              ].locKey ||
                                                                  this.props
                                                                      .incidenttype
                                                                      .byId[
                                                                      row.type
                                                                  ].name
                                                          )
                                                        : t('No type selected')}
                                                </b>
                                            </p>
                                            <p
                                                style={{
                                                    opacity: '0.5',
                                                    lineHeight: '2px',
                                                    width: '450px',
                                                }}
                                            >
                                                {`${t('Status')} ${t(
                                                    'Received'
                                                )}`}
                                            </p>
                                            <p
                                                style={{
                                                    opacity: '0.5',
                                                    lineHeight: '6px',
                                                    width: '450px',
                                                }}
                                            >
                                                {moment(row.date).format(
                                                    t('dateTime')
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ marginTop: '30vh' }}>
                                <AnnouncementIcon
                                    style={{
                                        margin: 'auto',
                                        width: '100%',
                                        height: '64px',
                                        opacity: '0.4',
                                    }}
                                />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        opacity: '0.4',
                                    }}
                                >
                                    {t(
                                        'There are no registration notifications'
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(OpdrachtenCompleted);
