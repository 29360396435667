// @flow
import React from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
} from '@mui/material';

import type {
    TSubscription,
    TSubscriptionFunctionalityWhitelabel,
} from '../../reducers/subscriptionReducer';
import type { TEmployee } from '../../reducers/employeesReducer';
import {
    changeSubscriptionFunctionalityWhitelabel,
    showSnackbar,
} from '../../actions';
import { connect } from 'react-redux';
import DialogInputField from './DialogInputField';
import { Grid } from '@mui/material';
import SimpleImageInput from '../SimpleImageInput';
import DeleteIcon from '@mui/icons-material/Delete';
import TitleSwitch from '../TitleSwitch';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './WhitelabelEditCard.module.scss'

type Props = {
    abonnee?: TSubscription,
    onSave: (user: TEmployee, onSucces: () => mixed) => mixed,
    functionalityWhitelabel: TSubscriptionFunctionalityWhitelabel,
};

type State = {};


const mapStateToProps = (store) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
        changeSubscriptionFunctionalityWhitelabel: (
            subscriptionId: number,
            subscriptionFunctionalityWhitelabel: TSubscriptionFunctionalityWhitelabel,
            onSuccess: () => mixed
        ) => {
            dispatch(
                changeSubscriptionFunctionalityWhitelabel(
                    subscriptionId,
                    subscriptionFunctionalityWhitelabel,
                    onSuccess
                )
            );
        },
    };
};

class WhiteLabelEditCard extends React.Component<Props, State> {
    props: Props;
    state: State = {
        saving: false,
        form: {
            id: '',
            url: '',
            packageNameIos: '',
            packageNameAndroid: '',
            storeLinkIos: '',
            appName: '',
            supportMailAddress: '',
            teamName: '',
            organisationName: '',
            separateBackend: false,
            mailMainColor: '',
            mailFontColor: '',
            logo: {},
            href: '',
        },
    };

    componentDidMount(): * {
        const { functionalityWhitelabel } = this.props;
        this.setState({
            form: {
                ...functionalityWhitelabel,
            },
        });
    }

    cancelEdit = () => {
        const { cancelEdit } = this.props;
        cancelEdit();
    };

    handleSave = () => {
        const {
            abonnee,
            onSave,
            functionalityWhitelabel,
            changeSubscriptionFunctionalityWhitelabel,
        } = this.props;
        const { form } = this.state;
        changeSubscriptionFunctionalityWhitelabel(
            abonnee.id,
            {
                ...functionalityWhitelabel,
                ...form,
            },
            () => {
                onSave();
            }
        );
    };

    handleChange = (key, value) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [key]: value,
            },
        }));
    };

    handleSeparateBackendSwitch = (value) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                separateBackend: value,
            },
        }));
    };

    handleRemoveImage = () => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                logo: {},
            },
        }));
    };

    render() {
        const { abonnee, onCancel, t } = this.props;
        const { saving, form } = this.state;
        if (!abonnee) return <div />;

        return (
            <div>
                <DialogContent>
                    <DialogContentText className={classes.dialogSubtitle}>
                        {t('Enter the changes to your white label below')}
                    </DialogContentText>
                    <Grid>
                        <Grid item xs={12}>
                            <DialogInputField
                                title={t('Url webversion')}
                                id="url"
                                value={form.url}
                                onChange={this.handleChange}
                                suffix=".veiligvakwerk.nl/web"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Package name iOS')}
                                id="packageNameIos"
                                value={form.packageNameIos}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Package name android')}
                                id="packageNameAndroid"
                                value={form.packageNameAndroid}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DialogInputField
                                title={t('App Store link iOS')}
                                id="storeLinkIos"
                                value={form.storeLinkIos}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DialogContentText
                                className={classes.text}
                                style={{ marginTop: '20px' }}
                            >
                                {t('Settings e-mail template')}
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('App name')}
                                id="appName"
                                value={form.appName}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Support e-mail adres')}
                                id="supportMailAddress"
                                value={form.supportMailAddress}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Team name')}
                                id="teamName"
                                value={form.teamName}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Organisation name')}
                                id="organisationName"
                                value={form.organisationName}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={classes.separateBackendSwitch}
                        >
                            <TitleSwitch
                                itemTitle={t('Seperate backend')}
                                initialValue={form.separateBackend}
                                onSwitch={(value) =>
                                    this.handleSeparateBackendSwitch(value)
                                }
                            ></TitleSwitch>
                        </Grid>
                        <Grid item xs={6}>
                            {/* Fill up space to keep colors on next line */}
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Main color')}
                                id="mailMainColor"
                                value={form.mailMainColor}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DialogInputField
                                title={t('Font color')}
                                id="mailFontColor"
                                value={form.mailFontColor}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.imageInput}>
                                <SimpleImageInput
                                    id="image"
                                    onChange={(value) =>
                                        this.handleChange('logo', value)
                                    }
                                    url={form.logo && form.logo.url}
                                />
                                <IconButton
                                     
                                    className="drawer-button"
                                    onClick={() => this.handleRemoveImage()}
                                >
                                    <DeleteIcon className="drawer-icon" />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                         
                        className="button cancel"
                        onClick={onCancel}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                         
                        className="button add"
                        onClick={this.handleSave}
                        disabled={saving}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(WhiteLabelEditCard);
