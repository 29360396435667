import uuidv4 from 'uuid/v4';
import { fetchAll, create } from './apiActions';
import { setEntities, setEntity, removeEntity } from './entityActions';
import { push } from 'connected-react-router';
import { closeDialog, showSnackbar } from './uiActions';
import { editInspectionDraft } from './inspectionDraftActions';
import moment from 'moment';
import * as api from '../lib/api';
import { fetchDashboard } from './dashboardActions';
import { fetchSpeakups} from "./toolboxActions";
import { PlainDispatch } from 'redux';
import i18n from 'i18next';

export function deployInspection(
    inspection,
    project,
    employee,
    deadline,
    note,
    activity,
    projectLocation,
    projectAddress,
    scheduledDate,
    groups,
    deployedBy
) {
    return (dispatch) => {
        const formattedDeadline = moment(deadline)
            .hours(23)
            .minutes(59)
            .format('YYYY-MM-DDTHH:mm:ssZ');
        const scheduledMillies = scheduledDate
            ? '/' + scheduledDate.getTime()
            : '';

        let loc = undefined;

        if (projectLocation) {
            loc = { lat: projectLocation.lat, lon: projectLocation.lng };
        }

        return create(
            'inspection/' + inspection + '/assignment' + scheduledMillies,
            {
                project,
                employee,
                deadline: formattedDeadline,
                note,
                activity,
                projectLocation: loc,
                projectAddress,
                groups,
                deployedBy,
            }
        )(dispatch)
            .then((result) => {
                dispatch(closeDialog('inspectionDeploy'));
                dispatch(
                    showSnackbar(i18n.t('Workplace inspection has been sent'))
                );
                dispatch(fetchInspectionAssigns());
                dispatch(fetchDashboard());
                dispatch(fetchInspections());
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error when sending workplace inspection'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function fetchInspections() {
    return (dispatch) => {
        return fetchAll('inspection')(dispatch).then((entities) => {
            dispatch(setEntities('inspections', entities));
        });
    };
}

export function fetchInspectionAssignsPerYear(year) {
    return (dispatch) => {
        api.fetchInspectionAssignPerYear(year)
            .then((entities) => {
                dispatch(
                    setEntities(
                        'inspectionAssigns',
                        entities.body._embedded.inspectionAssigns
                    )
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error when fetching InspectionAssignsPerYear'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function fetchDetailInspectionAssign(inspectionAssignId) {
    return (dispatch) => {
        api.fetchInspectionDetail(inspectionAssignId)
            .then((entities) => {
                dispatch(setEntity('inspectionAssign', entities.body._embedded.inspectionAssign));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while fetching fetchDetailInspectionAssign'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function fetchInspectionAssigns() {
    return (dispatch) => {
        return fetchAll('inspection-assign-projection')(dispatch).then(
            (entities) => {
                // TODO: REWORK API + WEB Part. Only entities should be uses and type = inspectionAssigns. NOT inspectionAssignProjections
                if (entities.inspectionAssignProjections) {
                    dispatch(setEntities('inspectionAssignProjections', entities.inspectionAssignProjections));
                } else {
                    dispatch(setEntities('inspectionAssignProjections', entities));
                }
            }
        );
    };
}

export function fetchInspectionGroupAssigns() {
    return (dispatch) => {
        return fetchAll('inspection-group-assigns')(dispatch).then(
            (entities) => {
                dispatch(setEntities('inspectionGroupAssigns', entities));
            }
        );
    };
}

export function sendInspectionReminder(inspectionId, users) {
    return (dispatch) => {
        api.sendInspectionPushReminder(inspectionId, users)
            .then((entity) => {
                dispatch(showSnackbar(i18n.t('Reminder sent')));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function cancelInspection(inspectionId) {
    return (dispatch) => {
        api.cancelInspection('inspection', inspectionId)
            .then((entity) => {
                dispatch(removeEntity('inspectionAssigns', entity));
                dispatch(showSnackbar(i18n.t('Workplace inspection canceled')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not cancel workplace inspection')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function cancelInspectionGroup(inspectionId) {
    return (dispatch) => {
        api.cancelInspectionGroup('inspection', inspectionId)
            .then((entity) => {
                console.log(entity);
                dispatch(fetchInspectionGroupAssigns());
                dispatch(showSnackbar(i18n.t('Workplace inspection canceled')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not cancel workplace inspection')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function saveInspection(inspectionDraftData, showNewWerkplekinspectie) {
    return (dispatch, getState) => {
        const contentBlockDrafts = getState().drafts.contentBlocks;

        const inspectionData = {
            ...inspectionDraftData,
            category: inspectionDraftData.category
                ? inspectionDraftData.category.id
                : undefined,
            inspection: {
                ...inspectionDraftData.inspection,
                categories: inspectionDraftData.inspection.blocks
                    .map((blockId) => contentBlockDrafts[blockId])
                    .map((block) => {
                        if (block.type === 'listBlock') {
                            return {
                                ...block,
                                listBlock: {
                                    ...block.listBlock,
                                    items: block.listBlock.items.map(
                                        (item) => item.text
                                    ),
                                },
                            };
                        } else if (block.type === 'checkListBlock') {
                            return {
                                ...block,
                                items: block.listBlock.items.map((item) => {
                                    item.title = item.text;
                                    return item;
                                }),
                            };
                        }
                        return block;
                    }),
            },
        };

        return create(
            'inspection',
            inspectionData
        )(dispatch)
            .then((entity) => {
                dispatch(
                    setEntity('inspections', {
                        ...inspectionData,
                        ...entity,
                        inspection: {
                            ...inspectionData.inspection,
                            ...entity.inspection,
                            categories: entity.inspection.categories.map(
                                (block) => ({ id: uuidv4(), ...block })
                            ),
                        },
                    })
                ); // add new version
                dispatch(
                    showSnackbar(
                        `${i18n.t('Workplace inspection version')} ` +
                            entity.version +
                            ` ${i18n.t("is Saved")}.` 
                    )
                );
                dispatch(
                    editInspectionDraft(
                        inspectionData.id,
                        'version',
                        entity.version
                    )
                );

                dispatch(push('/werkplekinspecties/' + entity.id));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error when saving workplace inspection'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function createInspection(inspectionData, redirect = false) {
    return (dispatch) => {
        return create(
            'inspection',
            inspectionData
        )(dispatch).then((entity) => {
            if (!entity.inspection.checkList) {
                entity.inspection.checkList = [];
            }
            dispatch(closeDialog('inspectionCreate'));
            dispatch(
                setEntity('inspections', { ...inspectionData, ...entity })
            );
            if (redirect) {
                dispatch(push('/werkplekinspecties/' + entity.id));
            }
            return { ...inspectionData, ...entity };
        });
    };
}

export function deleteInspection(inspectionData) {
    return (dispatch) => {
        api.deleteInspection('inspection', inspectionData)
            .then((entities) => {
                if (entities) {
                    entities.forEach((entity) => {
                        dispatch(removeEntity('inspections', { ...entity }));
                    });

                    //Just take the first one to get the title. The first one is the newest version
                    if (entities[0]) {
                        dispatch(
                            showSnackbar(
                                `${i18n.t('Workplace inspection')} ` +
                                    entities[0].title +
                                    ' is verwijderd'
                            )
                        );
                    }
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not delete workplace inspection')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function getAppInspections() {
    return (dispatch) => {
        return fetchAll('inspection/assigned')(dispatch).then((entities) => {
            dispatch(setEntities('appInspections', entities));
        });
    };
}

export function getPublicInspections() {
    return (dispatch) => {
        api.getPublicInspections()
            .then((entities) => {
                dispatch(
                    setEntities(
                        'publicInspections',
                        entities.body._embedded.inspections
                    )
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function getAppIncidents() {
    return (dispatch) => {
        api.getIncidents()
            .then((entities) => {
                dispatch(
                    setEntities('incidents', entities.body._embedded.incidents)
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function getAppSpeakups() {
    return (dispatch) => {
        api.getSpeakups()
            .then((entities) => {
                dispatch(
                    setEntities('speakups', entities.body._embedded.speakups)
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function sendInspectionResult(tokenId, result) {
    return (dispatch) => {
        api.sendResults('inspection/' + tokenId + '/results', result)
            .then((entities) => {
                dispatch(push('/web/opdrachten'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function sendPublicInspectionResult(tokenId, result) {
    return (dispatch) => {
        api.sendResults('inspection/selfAssignable/' + tokenId, result)
            .then((entities) => {
                dispatch(push('/web/opdrachten'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function sendIncidentResult(result) {
    return (dispatch) => {
        api.sendResults('incident', result)
            .then((entities) => {
                dispatch(getAppIncidents());
                dispatch(push('/web/incident'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function sendSpeakupResult(result) {
    return (dispatch) => {
        api.sendResults('speakup', result)
            .then((entities) => {
                dispatch(fetchSpeakups());
                dispatch(push('/web/speakup'));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not send speakup results')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function publishInspection(inspectionId: string) {
    return (dispatch) => {
        api.publish('inspection', inspectionId)
            .then((entity) => {
                const inspection = entity.body;
                dispatch(
                    showSnackbar(
                        `${i18n.t('Workplace inspection')} "` +
                            inspection.title +
                            '" versie ' +
                            inspection.version +
                            ' is gepubliceerd.'
                    )
                );
                dispatch(setEntity('inspections', { ...inspection }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error publishing workplace inspection'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function retractInspection(inspectionId: string) {
    return (dispatch) => {
        api.retract('inspection', inspectionId)
            .then((entity) => {
                const inspection = entity.body;
                dispatch(
                    showSnackbar(
                        `${i18n.t('Workplace inspection')} "` +
                            inspection.title +
                            '" versie ' +
                            inspection.version +
                            ' is teruggetrokken.'
                    )
                );
                dispatch(setEntity('inspections', { ...inspection }));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error withdrawing from workplace inspection'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function updateInspectionAssign(id, data) {
    return (dispatch) => {

        api.updateInspectionAssign(id, data)
            .then((entity) => {
                dispatch(setEntity('inspectionAssigns', entity.body));
                dispatch(showSnackbar('WPI opgeslagen'));
            })
            .catch((error) => {
                dispatch(showSnackbar(`${i18n.t('Error')}: ` + error.message));
                throw error;
            });
    };
}

export function deleteInspectionAssign(inspectionId: string) {
    return (dispatch) => {
        api.deleteInspectionAssign('inspection', inspectionId)
            .then((entity) => {
                dispatch(showSnackbar(i18n.t('The results are deleted')));
                dispatch(
                    removeEntity(
                        'inspectionAssigns',
                        JSON.parse(entity.text)._embedded.inspectionAssigns
                    )
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error withdrawing from workplace inspection'
                        )}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function changeInspectionProject(
    inspectionAssign: any,
    newObj: String
): Function {
    return (dispatch: PlainDispatch<any>) => {
        api.upsert('changeproject/' + newObj + '/' + inspectionAssign.id)
            .then((entity) => {
                window.location.reload();
            })
            .catch((error) => {
                // dispatch(showSnackbar('Onbekende fout bij het uitschakelen van abonnee: ' + error.message));
                throw error;
            });
    };
}
