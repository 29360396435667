export const isUndefinedOrNull = (value) =>
    value === null || value === undefined;
export const isUndefinedOrNullOrNaN = (value) =>
    value === null || value === undefined || isNaN(value);

const allowedKeys = [
    'root',
    'dialogContent',
    'errorMessage',
    'sectorSelection',
    'sectorInput',
    'appBar',
    'appBarButton',
    'content',
    'flex',
    'sectorsSelect',
    'toolboxInfoContent',
    'toolboxInfoIcon',
    'toolboxInfoForm',
    'editors',
    'tooltiptext',
    'chip',
];
export const parseClassesForAutocompleteView = (props) => {
    const classes = { ...props.classes };
    Object.keys(classes).forEach((className) => {
        if (allowedKeys.indexOf(className) === -1) delete classes[className];
    });

    return classes;
};

const grey = { r: 0xc9, g: 0xc9, b: 0xc9 };
const red = { r: 0xe6, g: 0x2a, b: 0x10 };
const orange = { r: 0xfe, g: 0x9b, b: 0x1e };
const green = { r: 0x2b, g: 0xc7, b: 0 };

export const percentColors1 = [red, orange, green];
export const percentColors2 = [red, grey, green];
export const percentColors3 = [grey, grey, green];
export const percentColors4 = [grey, red, green];
export const percentColors5 = [red, green];

export const getLanguage = (lang) => {
    switch (lang.toLowerCase()) {
        case 'en-us':
        case 'en-en':
            return 'en';
        case 'nl-nl':
            return 'nl';
        case 'es':
            return 'es';
        default:
            return 'nl'
    }
};

export const getLanguages = () => {
    const { REACT_APP_LANGUAGES = '' } = process.env;
    const languages = REACT_APP_LANGUAGES.split(',') || [];

    return languages;
};

export const formatFullName = (firstName: string, middleName: string, lastName: string) => {
    return firstName + (middleName ? ` ${middleName} ` : ' ')  + lastName
}

export const formatV2User = (user: any) => {
    return formatFullName(user.firstName, user.middleName, user.lastName)
}