// @flow
import React from 'react';
import './ChartLabel.css';

type Props = {
  label: string,
  vertical?: boolean,
  horizontal?: boolean
};

const ChartLabel = (props: Props) => {
  let clazz : string = '';

  if (props.vertical) clazz = 'vertical';
  else if (props.horizontal) clazz = 'horizontal';

  return (
    <div className={'chartlabel-container ' + clazz}>
      <div className={'chartlabel ' + clazz}>
        {props.label}
      </div>
    </div>
  );
};

export default ChartLabel;
