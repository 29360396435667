export const V2_TOOLBOX_OVERVIEW_API = 'v2/toolboxes/overview'
export const V2_TOOLBOX_OVERVIEW_REDUX = 'toolboxOverviewV2'

export const V2_TOOLBOX_ASSIGN_OVERVIEW_API = 'v2/toolboxes/overview/sent'
export const V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX = 'toolboxAssignOverviewV2'

export const V2_TOOLBOX_SCHEDULED_OVERVIEW_API = 'v2/toolboxes/overview/scheduled'
export const V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX = 'toolboxScheduledOverviewV2'

export const V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX = 'toolboxAssignResultOverviewV2'
export const V2_TOOLBOX_ASSIGN_REDUX = 'toolboxAssignV2'

export const V2_TOOLBOX_INFORMATION_REDUX = 'toolboxInformationV2'

export const V2_PUBLISH_TOOLBOX_API = 'v2/toolboxes'