// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import classes from './PhotosBlockPreview.module.scss';

type Props = {

};


const PhotosBlockPreview = (props: Props) => {
    const { block  } = props;

    return (
        <div className={ classes.root }>
            { block.photos && (
                <ul className={ classes.list } id="externalUl" style={{paddingLeft: 0}}>
                    { block.photos.filter(photo => photo.url).map((photo, i) => {return (<li key={ i }><img className={classes.desktop} src={photo.url} alt="" /></li>)}) }
                </ul>
            ) }
        </div>
    );
}

export default (PhotosBlockPreview);
